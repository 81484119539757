/* eslint-disable max-len */
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import {
	ChangeDetectorRef,
	Component,
	computed,
	effect,
	OnDestroy,
	OnInit,
	signal,
	Signal,
	ViewChild,
	WritableSignal
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Platform, NavController, ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import {
	Subscription,
	take,
	combineLatest,
	skipWhile,
	interval,
	map,
	switchMap,
	of,
	Observable,
	filter,
	BehaviorSubject,
	from
} from "rxjs";
import { ResetHeaderState, GetHeaderTitle } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { TypeCustomFields } from "src/app/shared/enums/type-custom-fields";
import { TypeModule } from "src/app/shared/enums/type-module";
import {
	IEventUser,
	IModule,
	IEvent,
	IGroup,
	IFullCustomField,
	IModuleCustomField,
	ILanguage,
	ICustomFieldData
} from "src/app/shared/interfaces";
import { IFilteredItemFormat } from "src/app/shared/interfaces/custom-fields.interfaces";
import { IModuleUpdatedSettings } from "src/app/shared/interfaces/modules.interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getBaseCustomFields,
	getModulesCustomsFieldsOfModule,
	getGroupsByOrder
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getModulesByType, getModulesByTypes, getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectUrl, selectRouteNestedParams } from "src/app/shared/selectors/router.selectors";
import {
	AnalyticsService,
	EventUsersService,
	FirestoreService,
	UtilityService,
	CardExchangeService,
	CustomFieldsService,
	MongodbService
} from "src/app/shared/services";
import { environment } from "src/environments/environment";
import { CardExchangeFormComponent } from "../../components/card-exchange-form/card-exchange-form.component";
import { ICardExchangeForm } from "src/app/shared/interfaces/card-exchange.interfaces";
import { LogProperty } from "src/app/shared/utils-func/debug-decorators";
import { buildFiltersQuery, filterSearch } from "src/app/shared/helpers-functions/filter-search";
import { IFilters } from "src/app/shared/interfaces/filters.interfaces";
import { ISearchFilter } from "src/app/shared/interfaces/search.interfaces";
import { getNetworkStatus } from "src/app/shared/selectors/utility.selectors";
import { DateTime } from "luxon";
import { TypeUser } from "src/app/shared/enums/type-user";
import { QuerySnapshot, where } from "firebase/firestore";

@Component({
    selector: "app-card-exchange-list",
    templateUrl: "./card-exchange-list.component.html",
    styleUrls: ["./card-exchange-list.component.scss"],
    standalone: false
})
export class CardExchangeListComponent implements OnInit, OnDestroy {
	@ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

	subscriptions: Subscription[] = [];
	formSub: Subscription;
	analyticsArraySub: { name: string; sub: Subscription }[] = [];
	searchSub: Subscription;
	eventUserSub: Subscription;

	loader: boolean = true;
	limit: number = 999999;
	p: number = 1;

	searchOpen: boolean = false;
	searchText: string = "";

	totalEventUsers: number = 0;
	eventId: string;
	event: IEvent;
	module: IModule;
	moduleId: string;
	moduleUpdatedSettings: IModuleUpdatedSettings;
	eventUser: IEventUser;
	allDatas: WritableSignal<IEventUser[]> = signal([]);
	datasFiltered: Signal<IEventUser[]> = computed(() => {
		const allDatas = this.allDatas();

		if (this.datasGetType === "firestore") {
			const filtersQuery = this.filtersQuery();

			const datasFiltered = filterSearch(filtersQuery, allDatas);

			return datasFiltered.datas;
		} else {
			return this.allDatas();
		}
	});

	datasFilteredInTwo: WritableSignal<IEventUser[]> = signal([]);
	groups: IGroup[] = [];
	customFields: { [moduleId: string]: IFullCustomField[] } = {};
	flattenCustomFields: IFullCustomField[] = [];
	tagCustomFields: IFullCustomField[] = [];

	// filter properties
	filterItems: IFilteredItemFormat[];

	typeOrder: string;

	showNoResultImage: boolean = false;

	allowFilterTag: boolean = false; // To show or hide filter tag
	isMobile = window.innerWidth < 768 ? true : false;
	selectedFilters: IFilteredItemFormat[] = [];

	groupedLetters: any;
	itemSize: number = 97;
	height: number = 873;

	dontBelongAnyGroups: boolean = false;

	init: boolean = false;
	navigationSaved: { moduleId: string; lastIndex: number }[] = [];
	currentLanguage: string = environment.platform.defaultLanguage;
	searchValue: string = "";
	datasGetType: "mongo" | "firestore" = "firestore";

	filters: IFilters = {
		locations: [],
		tracks: [],
		groups: [],
		customFields: [],
		principalKey: ""
	};
	filtersLoader: boolean = true;

	filtersQuery: WritableSignal<ISearchFilter> = signal({
		equalityFields: [],
		inequalityFields: [],
		includeTextFields: [],
		includeOrTextFields: [],
		isIncludedInFields: [],
		superiorFields: [],
		superiorOrEqualFields: [],
		inferiorFields: [],
		inferiorOrEqualFields: [],
		anyTextFields: [],
		arrayContainsAnyFields: [],
		arrayContainsAllFields: [],
		arrayContainsBlocAndOrFields: [],
		arrayNotContainsFields: [],
		elemMatchArrayFields: [],
		page: 0,
		itemsPerPage: this.limit,
		sortBy: []
	});

	eventUserModule: IModule;
	eventUserModules: WritableSignal<IModule[]> = signal([]);
	languageSub: Subscription;
	cardExchangeModule: IModule;

	networkStatus: boolean = false;

	initDatasSubject: BehaviorSubject<{
		initEvent: boolean;
		initModule: boolean;
		initCardExchangeForm: boolean;
		initEventUserModule: boolean;
		initAllEventUserModules: boolean;
		initEventUser: boolean;
		initEventUsers: boolean;
		initGroups: boolean;
		initCustomFields: boolean;
		initFilters: boolean;
	}> = new BehaviorSubject(null);
	initDatasChecks: {
		initEvent: boolean;
		initModule: boolean;
		initCardExchangeForm: boolean;
		initEventUserModule: boolean;
		initAllEventUserModules: boolean;
		initEventUser: boolean;
		initEventUsers: boolean;
		initGroups: boolean;
		initCustomFields: boolean;
		initFilters: boolean;
	} = {
		initEvent: false,
		initModule: false,
		initCardExchangeForm: false,
		initEventUserModule: false,
		initAllEventUserModules: false,
		initEventUser: false,
		initEventUsers: false,
		initGroups: false,
		initCustomFields: false,
		initFilters: false
	};

	firstTimeModuleAccess: boolean = true;
	lastTimeGetDatas: number = 0;
	@LogProperty
	cardExchangeForm: ICardExchangeForm;
	currentEventUserModule: IModule;
	eventUserModulesByKeys: { [modId: string]: IModule }[] = [];
	myEventUserContacts: IEventUser[];
	eventUserStates: { [userId: string]: boolean } = {};
	moduleCustomFieldsList: IModuleCustomField[][];

	constructor(
		private cdr: ChangeDetectorRef,
		public platform: Platform,
		private SAnalytics: AnalyticsService,
		private store: Store,
		private SEventUsers: EventUsersService,
		private SFirestore: FirestoreService,
		private navCtrl: NavController,
		private STranslate: TranslateService,
		private modalController: ModalController,
		private SUtility: UtilityService,
		private snackBar: MatSnackBar,
		private router: Router,
		private SCardExchange: CardExchangeService,
		private SCustomFields: CustomFieldsService,
		private SMongo: MongodbService
	) {
		effect(
			() => {
				const computeReachableDatas = this.SUtility.computeReachableDatas(
					this.event,
					this.allDatas(),
					this.datasFiltered(),
					this.flattenCustomFields,
					this.filters,
					this.currentLanguage,
					this.groups,
					[],
					[],
					"firestore"
				); //this.preBuildComputedReachableDatas();
				// obj['notSelectedDatas']["principalKey"] = ""

				this.filters = computeReachableDatas.filters;

				this.groupedLetters = this.datasFiltered().reduce((groups, eventUser) => {
					const letter = eventUser.queryName.charAt(0);
					if (!Object.values(groups).includes(letter)) {
						const uid = eventUser.uid;
						groups[uid] = letter;
					}
					return groups;
				}, {});

				// this.getListOnTwoBloc();

				if (this.eventUserModules()?.length > 0) {
					this.getCustomFields();
				}

				// this.initDatasChecks.initEventUsers = true;
				// this.initDatasSubject.next(this.initDatasChecks);
				this.loader = false;
				this.cdr.markForCheck();
			},
			{ allowSignalWrites: true }
		);
	}

	ngOnInit() {
		this.currentLanguage = this.STranslate.currentLang;

		if (this.languageSub && !this.languageSub.closed) {
			this.languageSub.unsubscribe();
		}

		this.languageSub = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
			// if (this.module && this.cardExchangeModule?.options && this.cardExchangeModule?.options.showFilter)
			// 	this.initFilterData();
		});

		if (this.router.getCurrentNavigation().extras.state) {
			this.cardExchangeForm = this.router.getCurrentNavigation().extras.state.form;
		} else {
			this.cardExchangeForm = null;
		}
	}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);

		this.subscriptions.push(
			this.store.select(getNetworkStatus).subscribe((status) => {
				this.networkStatus = status;
			})
		);

		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.init = false;
						this.filtersLoader = true;

						if (this.checkNeedReloadDatas()) {
							this.firstTimeModuleAccess = true;
							this.unloadDatas();
							this.initDatas();
						} else {
							if (this.module) {
								this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
							}
							this.getCurrentEventUser();
						}

						// Analytics
						this.SAnalytics.moduleAccess(
							this.eventId,
							this.moduleId,
							TypeTracking.ACCESS_TO_EVENT_USERS_MODULE
						);
					});
			});
	}

	ionViewDidEnter() {
		interval(100)
			.pipe(
				skipWhile(() => this.loader || !this.virtualScroll),
				take(1)
			)
			.subscribe(() => {
				if (this.virtualScroll) {
					const nav = this.navigationSaved.find((navMod) => navMod.moduleId === this.moduleId);
					this.virtualScroll.scrollToIndex(nav ? nav.lastIndex : 0, "auto");
					this.init = true;
				}
			});
	}

	ionViewWillLeave() {
		this.allDatas.set([]);
		// this.datasFiltered.set([]);
		// this.filtersQuery.set(null);
		this.datasFilteredInTwo.set([]);
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.store.dispatch(ResetHeaderState(null));
		this.loader = true;
		this.init = false;
	}

	ngOnDestroy() {
		this.subscriptions.concat([this.eventUserSub, this.searchSub]).forEach((sub) => sub?.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub?.unsubscribe());
	}

	initDatas() {
		this.getEvent();
		this.getCardExchangeModule();
		this.getCurrentEventUser();
		this.getCustomFields();
		this.getCurrentEventUserModule();
		this.getAllEventUserModules();
		this.getGroups();
		this.getEventUsers();
		this.getFilters();
		this.getCardExchangeForm();
	}

	checkNeedReloadDatas() {
		let needReloadDatas: boolean = false;
		if (this.firstTimeModuleAccess || !this.moduleId || (this.module && this.module.type === TypeModule.ATTENDEE)) {
			needReloadDatas = true;
		}
		return needReloadDatas;
	}

	/**
	 * unloadDatas
	 */
	unloadDatas() {
		this.subscriptions.forEach((sub) => sub?.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());

		this.initDatasChecks = {
			initEvent: false,
			initModule: false,
			initCardExchangeForm: false,
			initEventUserModule: false,
			initAllEventUserModules: false,
			initEventUser: false,
			initEventUsers: false,
			initGroups: false,
			initCustomFields: false,
			initFilters: false
		};
		this.event = null;
		this.module = null;
		this.eventUser = null;
		this.cardExchangeForm = null;
		this.eventUserModule = null;
		this.eventUserModules.set([]);
		this.p = 0;
		this.searchValue = "";
		this.allDatas.set([]);
		this.eventUserStates = null;
		// this.datasFiltered.set([]);
		this.datasFilteredInTwo.set([]);
		this.firstTimeModuleAccess = true;
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
				this.initDatasChecks.initEvent = true;
				this.initDatasSubject.next(this.initDatasChecks);
			})
		);
	}

	/**
	 * Get groups
	 */
	getGroups() {
		this.store
			.select(getGroupsByOrder("asc"))
			.pipe(take(1))
			.subscribe((groups) => {
				if (!_.isEqual(this.groups, groups)) {
					this.groups = groups;
				}
				this.initDatasChecks.initGroups = true;

				this.initDatasSubject.next(this.initDatasChecks);
			});
	}

	/**
	 * Getting module and event user
	 */
	getCurrentEventUser() {
		if (this.eventUserSub && !this.eventUserSub.closed) {
			this.eventUserSub.unsubscribe();
		}

		this.eventUserSub = this.store.select(getCurrentEventUser).subscribe((eventUser) => {
			if (!_.isEqual(this.eventUser, eventUser)) {
				this.eventUser = eventUser;
				if (!this.eventUser.contactIds) {
					this.eventUser.contactIds = [];
				}
				this.getCurrentEventUserContactIds();
			}
			this.initDatasChecks.initEventUser = true;
			this.initDatasSubject.next(this.initDatasChecks);
			this.cdr.markForCheck();
		});
	}

	/**
	 * getCurrentEventUserModule
	 */
	getCurrentEventUserModule() {
		this.subscriptions.push(
			this.store
				.select(getSpecificModule(this.eventUser?.moduleId))
				.pipe(skipWhile(() => !this.initDatasChecks.initEventUser))
				.subscribe((module) => {
					if (module) {
						this.currentEventUserModule = module;
						this.initDatasChecks.initEventUserModule = true;
						this.initDatasSubject.next(this.initDatasChecks);
					}
				})
		);
	}

	/**
	 *
	 * getAllEventUserModules
	 */
	getAllEventUserModules() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getModulesByTypes([TypeModule.ATTENDEE, TypeModule.SPEAKER])).pipe(
					skipWhile(() => !this.initDatasChecks.initModule || !this.initDatasChecks.initEventUser),
					switchMap((modules) =>
						of(
							modules.filter((mod) =>
								this.cardExchangeModule.options.activatecardExchangeOn.includes(mod.uid)
							)
						)
					)
				),
				this.SFirestore.valueChangesDocument(
					`events/${this.eventId}/modules-updated-settings/${this.eventUser?.moduleId}`
				)
			]).subscribe((results) => {
				if (!_.isEqual(this.eventUserModules(), results[0])) {
					this.eventUserModules.set(results[0]);

					this.eventUserModules().forEach((module) => {
						this.eventUserModulesByKeys[module.uid] = module;
					});

					this.eventUserModules().forEach((module) => {
						this.customFields[module.uid] = [];
					});
				}

				if (!_.isEqual(this.moduleUpdatedSettings, results[1])) {
					this.moduleUpdatedSettings = results[1];
				}

				this.initDatasChecks.initAllEventUserModules = true;

				this.initDatasSubject.next(this.initDatasChecks);
			})
		);
	}

	/**
	 * getCardExchangeModule
	 */
	getCardExchangeModule() {
		this.subscriptions.push(
			this.store
				.select(getModulesByType(TypeModule.CARD_EXCHANGE))
				.pipe(map((modules) => (modules.length > 0 ? modules[0] : null)))
				.subscribe((module) => {
					if (module) {
						if (!_.isEqual(this.module, module)) {
							this.module = module;
							this.cardExchangeModule = module;

							this.initDatasChecks.initModule = true;
							this.initDatasSubject.next(this.initDatasChecks);
						}
						this.store.dispatch(
							GetHeaderTitle({
								payload: {
									FrFR: this.STranslate.instant("buttons.add-contact"),
									EnUS: this.STranslate.instant("buttons.add-contact"),
									EsES: this.STranslate.instant("buttons.add-contact"),
									PtBR: this.STranslate.instant("buttons.add-contact"),
									DeDE: this.STranslate.instant("buttons.add-contact")
								} as ILanguage
							})
						);
					}
				})
		);
	}

	/**
	 * getCurrentEventUserContactIds
	 */
	async getCurrentEventUserContactIds() {
		this.myEventUserContacts = await this.SCardExchange.getUserContacts(this.eventUser?.contactIds, this.eventId);

		this.buildContactsState();
	}

	/**
	 * Get custom fields
	 */
	getCustomFields() {
		combineLatest([
			this.store.select(getBaseCustomFields),
			...this.eventUserModules()
				.filter((mod) => this.cardExchangeModule.options.activatecardExchangeOn.includes(mod.uid))
				.map((module) => this.store.select(getModulesCustomsFieldsOfModule(module.uid)))
		])
			.pipe(
				skipWhile(
					() =>
						!this.initDatasChecks.initAllEventUserModules ||
						!this.initDatasChecks.initEvent ||
						!this.initDatasChecks.initModule ||
						!this.initDatasChecks.initEventUserModule
				)
			)
			.pipe(take(1))
			.subscribe((results) => {
				const baseCustomFields = results[0];
				this.moduleCustomFieldsList = results.slice(1) as IModuleCustomField[][];

				if (this.moduleCustomFieldsList.length > 0) {
					this.moduleCustomFieldsList.forEach((moduleCustomFields) => {
						moduleCustomFields.forEach((customField) => {
							const baseCustomFieldCorresponding = baseCustomFields.find(
								(custField) => custField.uid === customField.uid
							);

							if (baseCustomFieldCorresponding) {
								this.customFields[customField.moduleId].push({
									baseSettings: baseCustomFieldCorresponding,
									moduleSettings: customField,
									fieldDatas: {
										uid: "",
										field: {
											date: {
												day: 0,
												month: 0,
												year: 0,
												value: "",
												fullDateISO: "",
												zone: ""
											},
											file: {
												lastModifiedDate: "",
												name: "",
												type: "",
												url: "",
												size: 0
											},
											image: {
												format: "",
												lastModifiedDate: "",
												name: "",
												url: "",
												size: 0
											},
											module: {
												items: [],
												moduleType: -1
											},
											multiLanguageText: {
												ArAR: "",
												DeDE: "",
												EnUS: "",
												EsES: "",
												FrFR: "",
												PtBR: ""
											},
											multiLanguageSelectArray: [],
											multiLanguageTextArray: {
												ArAR: [],
												DeDE: [],
												EnUS: [],
												EsES: [],
												FrFR: [],
												PtBR: []
											},
											numeric: 0,
											phoneNumber: {
												countryCode: "",
												dialCode: "",
												e164Number: "",
												internationalNumber: "",
												nationalNumber: "",
												number: ""
											},
											text: ""
										}
									}
								});
							}
						});
					});
				}

				this.tagCustomFields = this.customFields[this.currentEventUserModule.uid]?.filter(
					(custom) => custom.moduleSettings
				);

				// flatten custom fields
				// this.flattenCustomFields = _.uniqWith(
				// 	this.eventUserModules
				// 		.map((mod) => this.customFields[mod.uid])
				// 		.reduce((acc, curr) => acc.concat(curr), []),
				// 	(a, b) => _.isEqual(a.baseSettings.uid, b.baseSettings.uid)
				// );
				this.flattenCustomFields = this.eventUserModules()
					.map((mod) => this.customFields[mod.uid])
					.reduce((acc, curr) => acc.concat(curr), []);

				this.initDatasChecks.initCustomFields = true;
				this.initDatasSubject.next(this.initDatasChecks);
			});
	}

	/**
	 * Get event
	 */
	getEventUsers() {
		this.loader = true;

		this.initDatasSubject
			.pipe(
				skipWhile(
					(initState) =>
						!initState.initEvent ||
						!initState.initModule ||
						!initState.initEventUser ||
						!initState.initCustomFields ||
						!initState.initGroups
				),
				take(1),
				switchMap(() => {
					const constraints = [
						where("eventId", "==", this.eventId),
						where(
							"moduleId",
							"in",
							this.eventUserModules().map((mod) => mod.uid)
						)
					];
					if (this.event.settings.userLimit <= 1000) {
						if (this.module.options.showOnlyLoggedUsers) {
							constraints.push(where("firstAccess", "==", true));
						}
						return from(this.SFirestore.getDocumentsCollectionGroup("event-users", constraints));
					} else {
						this.filtersQuery().equalityFields.push({
							fieldKey: "eventId",
							compareData: this.eventId
						});
						this.filtersQuery().isIncludedInFields.push({
							fieldKey: "moduleId",
							compareData: this.eventUserModules().map((mod) => mod.uid)
						});

						if (this.module.options?.showOnlyLoggedUsers) {
							this.filtersQuery().equalityFields.push({
								fieldKey: "firstAccess",
								compareData: true
							});
						}

						return this.SMongo.queryDatasMongodb(
							this.SMongo.convertSearchFilterToQueryOrAggregationMongo(
								this.filtersQuery(),
								"aggregation",
								"eventUsersSearch"
							),
							"event-users",
							{},
							"aggregation"
						);
					}
				})
			)
			.pipe(
				take(1),
				switchMap((result) => {
					if (this.event.settings.userLimit <= 1000) {
						const datas = (result as QuerySnapshot<IEventUser>)?.docs.map((doc) => doc.data());
						return of({
							totalItems: datas.length,
							datas
						});
					} else {
						return of({
							totalItems: (result as any).totalDocuments,
							datas: result.docs
						});
					}
				}),
				switchMap((result) => {
					this.totalEventUsers = (result as any)?.totalDocuments;

					if (result.datas.length === 0) {
						return of({
							totalItems: 0,
							datas: []
						});
					}
					const eventUserIds: string[] = _.uniq(
						result.datas
							.filter((data) => data.uid !== this.eventUser.uid)
							.map((eventUserData) => eventUserData.uid)
					);
					if (eventUserIds.length === 0) {
						return of({
							totalItems: 0,
							datas: []
						});
					} else {
						return of({
							totalItems: result.totalItems,
							datas: result.datas
								.filter((data) => data.uid !== this.eventUser.uid)
								.map((eventUserData) => {
									eventUserData.updatedSettings = null;
									return eventUserData;
								})
						});
					}
				})
			)
			.subscribe({
				next: (res: { totalItems: number; datas: IEventUser[] }) => {
					this.allDatas.set(
						res.datas.sort((a, b) => {
							if (this.module.options.usersOrder === "asc") {
								return a.queryName > b.queryName ? 1 : a.queryName < b.queryName ? -1 : 0;
							} else if (this.module.options.usersOrder === "desc") {
								return a.queryName < b.queryName ? 1 : a.queryName > b.queryName ? -1 : 0;
							} else if (this.module.options.usersOrder === "oldest") {
								return a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0;
							} else if (this.module.options.usersOrder === "recent") {
								return a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0;
							} else {
								return a.identifier > b.identifier ? 1 : a.identifier < b.identifier ? -1 : 0;
							}
						})
					);

					this.totalEventUsers = res.totalItems;
					this.initDatasChecks.initEventUsers = true;

					this.initDatasSubject.next(this.initDatasChecks);

					this.buildFilterQuery();

					this.lastTimeGetDatas = DateTime.local().toMillis();

					this.loader = false;
				}
			});
	}

	/**
	 * getFilters
	 */
	getFilters() {
		this.initDatasSubject
			.pipe(
				skipWhile((initState) => {
					return (
						!initState.initEvent ||
						!initState.initModule ||
						!initState.initCustomFields ||
						!initState.initGroups ||
						!initState.initAllEventUserModules ||
						!initState.initEventUsers
					);
				}),
				take(1),
				switchMap(() => {
					if (
						this.module &&
						this.module.options &&
						(this.module.options.showFilter || this.module.options.showSearchIcon)
					) {
						const results: Observable<IFilters>[] = [];

						this.eventUserModules().forEach((module) => {
							try {
								results.push(
									this.SCustomFields.getFiltersForModule(
										module,
										this.eventUser,
										this.customFields[module.uid],
										[],
										[],
										this.groups.map((group) => {
											return { name: group.name, uid: group.uid };
										}),
										this.currentLanguage,
										this.allDatas()
									)
								);
							} catch (error) {
								//
							}
						});

						return combineLatest(results).pipe(
							take(1),
							map((filters) => {
								if (filters.length > 0) {
									return filters;
								} else {
									return [];
								}
							})
						);
					} else {
						return of([]);
					}
				})
			)
			.subscribe({
				next: (filters: IFilters[]) => {
					if (filters) {
						const flatteredFilters: IFilters = filters.reduce(
							(acc, currentFilter) => {
								acc.locations = acc.locations.concat(currentFilter.locations);
								acc.tracks = acc.tracks.concat(currentFilter.tracks);
								acc.groups = acc.groups.concat(currentFilter.groups);
								acc.customFields = acc.customFields.concat(currentFilter.customFields);
								return acc;
							},
							{
								locations: [],
								tracks: [],
								groups: [],
								customFields: [],
								principalKey: ""
							}
						);

						flatteredFilters.customFields = flatteredFilters.customFields.map((loc, i, arr) => {
							// merge "values" property of duplicates uid location
							const checkCus = arr.find((l) => l.uid === loc.uid);

							if (checkCus) {
								checkCus.values = checkCus.values.concat(loc.values);
								// removes duplicates values in "values" (by "filterId" property)
								checkCus.values = _.uniqBy(checkCus.values, "value").sort((a, b) => {
									if (a.value.toString().toLowerCase() == b.value.toString().toLowerCase()) return 0;
									if (
										a.value.toString().toLowerCase() ==
										this.SCustomFields.notSpecifiedTrad[this.currentLanguage].toLowerCase()
									)
										return 1;
									if (
										b.value.toString().toLowerCase() ==
										this.SCustomFields.notSpecifiedTrad[this.currentLanguage].toLowerCase()
									)
										return -1;

									if (a.value.toString().toLowerCase() > b.value.toString().toLowerCase()) return 1;
									if (a.value.toString().toLowerCase() < b.value.toString().toLowerCase()) return -1;
									return 0;
								});

								return checkCus;
							}

							return loc;
						});

						// remove duplicates in each object properties if needed
						flatteredFilters.customFields = _.uniqBy(flatteredFilters.customFields, "uid");

						this.integratePreviousFiltersState(flatteredFilters);
					}

					this.initDatasChecks.initFilters = true;

					this.initDatasSubject.next(this.initDatasChecks);
					this.filtersLoader = false;
				},
				error: () => {
					this.filtersLoader = false;
				}
			});
	}

	/**
	 * integratePreviousFiltersState
	 * @param filters
	 */
	integratePreviousFiltersState(filters: IFilters) {
		const newFilters: IFilters = _.cloneDeep(filters);

		this.filters.locations.forEach((filter) => {
			const checkFilter = newFilters.locations.find((specFilter) => specFilter.uid === filter.uid);
			if (checkFilter) {
				checkFilter.checked = filter.checked;
				checkFilter.isReachable = filter.isReachable;
			}
		});
		this.filters.tracks.forEach((filter) => {
			const checkFilter = newFilters.tracks.find((specFilter) => specFilter.uid === filter.uid);
			if (checkFilter) {
				checkFilter.checked = filter.checked;
				checkFilter.isReachable = filter.isReachable;
			}
		});
		this.filters.groups.forEach((filter) => {
			const checkFilter = newFilters.groups.find((specFilter) => specFilter.uid === filter.uid);
			if (checkFilter) {
				checkFilter.checked = filter.checked;
				checkFilter.isReachable = filter.isReachable;
			}
		});
		this.filters.customFields.forEach((filter) => {
			const checkFilter = newFilters.customFields.find((specFilter) => specFilter.uid === filter.uid);
			if (checkFilter) {
				filter.values.forEach((value) => {
					const checkFilterValue = checkFilter.values.find((val) => val.value === value.value);
					if (checkFilterValue) {
						checkFilterValue.isSelected = value.isSelected;
						checkFilterValue.isReachable = value.isReachable;
					}
				});
			}
		});
		this.filters = newFilters;
	}

	/**
	 * get Form
	 */
	getCardExchangeForm() {
		this.initDatasSubject
			.pipe(
				skipWhile(() => !this.initDatasChecks.initEventUser),
				switchMap((subs) => of(subs.initEventUser)),
				take(1)
			)
			.subscribe(() => {
				this.SCardExchange.getSpecificFormByGroupId(this.eventId, this.moduleId, this.eventUser.groups)
					.pipe(
						skipWhile(() => !this.initDatasChecks.initEventUser),
						filter((form) => (form && form?.visibility) || !form),
						take(1)
					)
					.subscribe((form) => {
						this.cardExchangeForm = form;

						this.initDatasChecks.initCardExchangeForm = true;
						this.initDatasSubject.next(this.initDatasChecks);
					});
			});
	}

	/**
	 * canShowFilterBloc
	 * @description return true if the filter is at least one custom field that can be filtered
	 * @returns boolean
	 */

	canShowFilterBloc() {
		// merge all eventUserModules requiredFileds in one array and remove duplicates
		const allModuleGroupFilterStatus = this.eventUserModules().map(
			(mod) => mod.options.requiredFields.group.filter
		);

		return (
			this.flattenCustomFields.some((cus) => cus.moduleSettings.enableFilter) ||
			(allModuleGroupFilterStatus.some((status) => status) && this.groups.length > 0)
		);
	}

	/**
	 * Reset filter
	 */
	resetFilter() {
		this.searchValue = "";
		this.buildFilterQuery();
		this.showNoResultImage = false;
	}

	/**
	 * getcustomFieldType
	 * @param customFieldId
	 * @returns
	 */
	getCustomFieldType(customFieldId: string): number {
		return this.flattenCustomFields?.find((cus) => cus.baseSettings.uid === customFieldId)?.baseSettings.type;
	}

	/**
	 * Navigate to path
	 * @param path
	 */
	navigateTo(path: string, targetEventUser: IEventUser) {
		this.navCtrl.navigateForward(path, {
			state: {
				form: this.cardExchangeForm,
				module: this.eventUser.contactIds.includes(targetEventUser.uid)
					? this.cardExchangeModule
					: this.eventUserModules()?.find((mod) => mod.uid === targetEventUser.moduleId)
			}
		});
	}

	/**
	 * scrolled item index changed
	 * @param index
	 */
	limitScroll: boolean = false;
	scrolledIndexChanged(index: number) {
		const nav = this.navigationSaved.find((navMod) => navMod.moduleId === this.moduleId);
		if (this.init) {
			if (!nav) {
				this.navigationSaved.push({
					moduleId: this.moduleId,
					lastIndex: index
				});
			} else {
				nav.lastIndex = index;
			}
		}
	}

	/**
	 * buildContactsState
	 */
	buildContactsState() {
		if (this.eventUser && this.myEventUserContacts.length > 0) {
			// Build eventUser card exchange state
			this.eventUserStates = this.myEventUserContacts.reduce(
				(acc, user) => ({
					...acc,
					[user.uid]: this.myEventUserContacts.map((evtUser) => evtUser.uid).includes(user.uid)
				}),
				{}
			);
		}
	}

	/**
	 * addToContacts
	 * @param eventUser
	 * @param event
	 */
	async addToContacts(eventUser: IEventUser, event) {
		try {
			event.stopPropagation();
			// open card exchange form component
			if (this.cardExchangeForm) {
				// add contact with form
				const modal = await this.modalController.create({
					component: CardExchangeFormComponent,
					componentProps: {
						event: this.event,
						module: this.module,
						myEventUser: this.eventUser,
						eventUserContact: eventUser,
						eventUserContactId: eventUser.uid
					},
					cssClass: this.isMobile ? "card-exhange-form-modal-css-mobile" : "card-exchange-form-modal-css"
				});

				await modal.present();

				const { data } = await modal.onWillDismiss();

				if (data && data?.success) {
					await this.storeContactInEventUserDoc(eventUser.uid);
				}
			} else {
				await this.storeContactInEventUserDoc(eventUser.uid);
			}

			this.buildFilterQuery();
			this.SUtility.presentToast(
				this.STranslate.instant("snackbar.update_successfull"),
				3000,
				"bottom",
				"success"
			);
		} catch (error) {
			this.eventUserStates[eventUser.uid] = false;
			this.snackBar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}

	/**
	 * storeContactInEventUserDoc
	 * @param contactId
	 */
	async storeContactInEventUserDoc(contactId: string) {
		try {
			await this.SCardExchange.storeContactInEventUserDoc(this.event, this.eventUser, contactId);
			this.eventUserStates[contactId] = true;
		} catch (error) {
			this.snackBar.open(this.STranslate.instant("snackabr.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	trackByFn(index, item: ICustomFieldData): string {
		return item.uid;
	}

	/**
	 * Filter datas
	 */
	buildFilterQuery() {
		this.loader = true;
		this.resetQueryFilters();
		let filtersQuery = this.filtersQuery();

		filtersQuery.itemsPerPage = 999999;

		// get only users in enabled modules
		filtersQuery.isIncludedInFields.push({
			fieldKey: "moduleId",
			compareData: this.module.options.activatecardExchangeOn
		});

		// exclude current user
		filtersQuery.inequalityFields.push({
			fieldKey: "uid",
			compareData: this.eventUser.uid
		});

		// exclude contacts
		this.eventUser.contactIds.forEach((contactId) => {
			filtersQuery.inequalityFields.push({
				fieldKey: "uid",
				compareData: contactId
			});
		});

		// exclude speakers
		filtersQuery.equalityFields.push({
			fieldKey: "type",
			compareData: TypeUser.ATTENDEE
		});

		if (this.filters && this.filters.customFields) {
			this.filters.customFields.forEach((filter) => {
				filter.values.forEach((value) => {
					if (value.isSelected) {
						const cus = this.flattenCustomFields.find(
							(cus) => cus.baseSettings.uid === value.filterId
						).moduleSettings;

						const fieldKey =
							this.getCustomFieldType(cus.uid) === TypeCustomFields.TEXT ||
							this.getCustomFieldType(cus.uid) === TypeCustomFields.SELECT
								? "multiLanguageText"
								: this.getCustomFieldType(cus.uid) === TypeCustomFields.URL ||
								  this.getCustomFieldType(cus.uid) === TypeCustomFields.EMAIL
								? "text"
								: this.getCustomFieldType(cus.uid) === TypeCustomFields.NUMERIC
								? "numeric"
								: this.getCustomFieldType(cus.uid) === TypeCustomFields.MULTI_SELECT
								? "multiLanguageSelectArray"
								: false;

						const isMultilanguage: boolean =
							this.getCustomFieldType(cus.uid) === TypeCustomFields.TEXT ||
							this.getCustomFieldType(cus.uid) === TypeCustomFields.SELECT ||
							this.getCustomFieldType(cus.uid) === TypeCustomFields.MULTI_SELECT
								? true
								: false;

						const bloc = filtersQuery.arrayContainsBlocAndOrFields.find(
							(bloc) =>
								bloc.fieldKey ===
								"customFields|" +
									fieldKey +
									"|" +
									value.filterId +
									(isMultilanguage ? "|" + this.currentLanguage : "")
						);
						if (!bloc) {
							filtersQuery.arrayContainsBlocAndOrFields.push({
								fieldKey:
									"customFields|" +
									fieldKey +
									"|" +
									value.filterId +
									(isMultilanguage ? "|" + this.currentLanguage : ""),
								compareData: [value.isNotSpecified ? "" : value.value]
							});
						} else {
							bloc.compareData.push(value.isNotSpecified ? "" : value.value);
						}
					}
				});
			});
		}

		if (this.filters && this.filters.groups) {
			this.filters.groups.forEach((filter) => {
				if (filter.checked) {
					const bloc = filtersQuery.arrayContainsBlocAndOrFields.find((bloc) => bloc.fieldKey === "groups");
					if (!bloc) {
						filtersQuery.arrayContainsBlocAndOrFields.push({
							fieldKey: "groups",
							compareData: [filter.uid]
						});
					} else {
						bloc.compareData.push(filter.uid);
					}
				}
			});
		}

		// Filter on searchbar term if needed
		filtersQuery = this.buildSearchQueryFilters(filtersQuery);

		filtersQuery.sortBy.push({
			fieldKey: "queryName",
			type: "asc"
		});

		this.filtersQuery.set({ ...filtersQuery });
		this.cdr.markForCheck();
	}

	/**
	 * Get list of event users on two bloc
	 * @returns
	 */
	getListOnTwoBloc() {
		let count = 0;
		let index = 0;
		const newFilteredEventUsersOnTwo = [];
		this.datasFiltered().forEach((eventUser) => {
			if (count === 2) {
				index++;
				count = 0;
			}
			if (!newFilteredEventUsersOnTwo[index]) {
				newFilteredEventUsersOnTwo[index] = [];
			}
			newFilteredEventUsersOnTwo[index].push(eventUser);
			count++;
		});
		if (!_.isEqual(this.datasFilteredInTwo(), newFilteredEventUsersOnTwo)) {
			this.datasFilteredInTwo.set(newFilteredEventUsersOnTwo);
		}
	}

	/**
	 * buildSearchQueryFilters
	 * @param filtersQuery
	 * @returns
	 */
	buildSearchQueryFilters(filtersQuery: ISearchFilter) {
		return buildFiltersQuery(
			this.eventUserModules(),
			"firestore",
			filtersQuery,
			this.searchValue,
			this.currentLanguage,
			this.flattenCustomFields,
			[],
			[],
			[],
			[],
			[],
			this.groups,
			[],
			[]
		);
	}

	/**
	 * resetQueryFilters
	 */
	resetQueryFilters() {
		const filtersQuery = this.filtersQuery();
		if (!filtersQuery) {
			return;
		}
		filtersQuery.arrayContainsAnyFields = [];
		filtersQuery.equalityFields = [];
		filtersQuery.includeTextFields = [];
		filtersQuery.includeOrTextFields = [];
		filtersQuery.isIncludedInFields = [];
		filtersQuery.inferiorFields = [];
		filtersQuery.inferiorOrEqualFields = [];
		filtersQuery.superiorFields = [];
		filtersQuery.superiorOrEqualFields = [];
		filtersQuery.arrayContainsBlocAndOrFields = [];
		filtersQuery.sortBy = [];
		filtersQuery.page = this.p;

		this.filtersQuery.set({ ...filtersQuery });
	}

	/**
	 * preBuildComputedReachableDatas
	 * @returns
	 */
	preBuildComputedReachableDatas(): { notSelectedDatas: IFilters; filters: IFilters } {
		return this.eventUserModules()
			.map((module) =>
				this.SUtility.computeReachableDatas(
					this.event,
					this.allDatas(),
					this.datasFiltered(),
					this.customFields[module.uid],
					this.filters,
					this.currentLanguage,
					this.groups,
					[],
					[],
					"firestore"
				)
			)
			.reduce(
				(acc, curr) => {
					acc["notSelectedDatas"]["locations"] = acc["notSelectedDatas"]["locations"].concat(
						_.uniqBy(curr.notSelectedDatas["locations"], "uid")
					);
					acc["notSelectedDatas"]["tracks"] = acc["notSelectedDatas"]["tracks"].concat(
						_.uniqBy(curr.notSelectedDatas["tracks"], "uid")
					);
					acc["notSelectedDatas"]["customFields"] = acc["notSelectedDatas"]["customFields"].concat(
						_.uniqBy(curr.notSelectedDatas["customFields"], "uid")
					);
					acc["notSelectedDatas"]["groups"] = acc["notSelectedDatas"]["groups"].concat(
						_.uniqBy(curr.notSelectedDatas["groups"], "uid")
					);

					acc["filters"]["locations"] = acc["filters"]["locations"].concat(
						_.uniqBy(curr.filters["locations"], "uid")
					);
					acc["filters"]["tracks"] = acc["filters"]["tracks"].concat(_.uniqBy(curr.filters["tracks"], "uid"));
					acc["filters"]["customFields"] = acc["filters"]["customFields"].concat(
						_.uniqBy(curr.filters["customFields"], "uid")
					);
					acc["filters"]["groups"] = acc["filters"]["groups"].concat(_.uniqBy(curr.filters["groups"], "uid"));

					return { ...acc };
				},
				{
					notSelectedDatas: {
						locations: [],
						tracks: [],
						customFields: [],
						groups: [],
						principalKey: ""
					},
					filters: {
						locations: [],
						tracks: [],
						customFields: [],
						groups: [],
						principalKey: ""
					}
				}
			);
	}
	/**
	 * getTotalFiltersChecked
	 * @returns
	 */
	getTotalFiltersChecked() {
		let count: number = 0;

		this.filters.locations.forEach((filter) => {
			if (filter.checked) {
				count++;
			}
		});
		this.filters.groups.forEach((filter) => {
			if (filter.checked) {
				count++;
			}
		});
		this.filters.tracks.forEach((filter) => {
			if (filter.checked) {
				count++;
			}
		});
		this.filters.customFields.forEach((filter) => {
			filter.values.forEach((value) => {
				if (value.isSelected) {
					count++;
				}
			});
		});

		return count;
	}
}
