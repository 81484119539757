import { Component, OnDestroy } from "@angular/core";
import { NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, combineLatest, take } from "rxjs";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeModule } from "src/app/shared/enums/type-module";
import { IEvent, IEventUser, IModule } from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getTreasureHuntsOfModule } from "src/app/shared/selectors/gamification.selectors";
import { getGamificationModule, getGamificationModules } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AnalyticsService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-gamification",
    templateUrl: "./gamification.component.html",
    styleUrls: ["./gamification.component.scss"],
    standalone: false
})
export class GamificationComponent implements OnDestroy {
	subscriptions: Subscription[] = [];

	eventId: string;
	event: IEvent;
	eventUser: IEventUser;

	moduleGamification: IModule;
	moduleTreasureHunts: IModule;
	currentLanguage: string = environment.platform.defaultLanguage;

	constructor(
		private SAnalytics: AnalyticsService,
		private STranslate: TranslateService,
		private store: Store,
		private navCtrl: NavController
	) {}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						// this.enableTrackingAnalytic(TypeTracking.ACCESS_TO_GAMIFICATION_MODULE);

						this.getEvent();
						this.getNecessaryModules();
						this.getEventUser();
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Get all interactivity module
	 */
	getNecessaryModules() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getGamificationModule),
				this.store.select(getGamificationModules)
			]).subscribe((results) => {
				this.moduleGamification = results[0];
				this.moduleTreasureHunts = results[1].find((module) => module.type === TypeModule.TREASURE_HUNTS);
				if (this.moduleGamification) {
					this.store.dispatch(GetHeaderTitle({ payload: this.moduleGamification.name }));
				}
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				this.eventUser = eventUser;
			})
		);
	}

	/**
	 * Navigate to path
	 * @param path
	 */
	navigateTo(module: IModule) {
		if (module.type === TypeModule.TREASURE_HUNTS) {
			this.store
				.select(getTreasureHuntsOfModule(module.uid))
				.pipe(take(1))
				.subscribe((treasureHunts) => {
					if (treasureHunts.length === 1) {
						this.navCtrl.navigateForward(
							`/event/${this.eventId}/treasure-hunts/${module.uid}/${treasureHunts[0].uid}`
						);
					} else {
						this.navCtrl.navigateForward(module.urlApp);
					}
				});
		} else {
			this.navCtrl.navigateForward(module.urlApp);
		}
	}
}
