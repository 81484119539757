/* eslint-disable max-len */
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	computed,
	effect,
	OnDestroy,
	OnInit,
	signal,
	Signal,
	ViewChild,
	WritableSignal
} from "@angular/core";
import { QueryDocumentSnapshot } from "@angular/fire/firestore";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, firstValueFrom, interval, of } from "rxjs";
import { distinctUntilChanged, skipWhile, switchMap, take } from "rxjs/operators";
import { GetHeaderState, GetScanning, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { TypeCustomFields } from "src/app/shared/enums/type-custom-fields";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { TypeModule } from "src/app/shared/enums/type-module";
import { TypeUser } from "src/app/shared/enums/type-user";
import { buildFiltersQuery, filterSearch } from "src/app/shared/helpers-functions/filter-search";
import {
	ICheckin,
	ICheckinChecked,
	IEvent,
	IEventUser,
	IFullCustomField,
	IGroup,
	IModule,
	IModuleCustomField
} from "src/app/shared/interfaces";
import { IMultiCheckRecord } from "src/app/shared/interfaces/checkin.interfaces";
import { IFilteredItemFormat } from "src/app/shared/interfaces/custom-fields.interfaces";
import { IFilters } from "src/app/shared/interfaces/filters.interfaces";
import { ISearchFilter } from "src/app/shared/interfaces/search.interfaces";
import { getMyEventUser } from "src/app/shared/selectors/auth.selectors";
import { getAllCheckins } from "src/app/shared/selectors/checkins.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getBaseCustomFields,
	getGroupsByOrder,
	getModulesCustomsFieldsOfModule
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getModulesByType, getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import {
	AnalyticsService,
	CheckinsService,
	CustomFieldsService,
	EventUsersService,
	FirestoreService,
	UtilityService
} from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-checkin",
    templateUrl: "./checkin.component.html",
    styleUrls: ["./checkin.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CheckinComponent implements OnInit, OnDestroy {
	@ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
	subscriptions: Subscription[] = [];
	searchSub: Subscription;

	loader: boolean = false;

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	checkinId: string;
	checkin: WritableSignal<ICheckin> = signal(null);
	checkeds: WritableSignal<ICheckinChecked[]> = signal([]);
	eventUsersModules: WritableSignal<IModule[]> = signal([]);
	eventUser: IEventUser;
	groups: IGroup[] = [];
	customFields: IFullCustomField[] = [];

	allDatas: WritableSignal<IEventUser[]> = signal([]);
	datasFiltered: Signal<IEventUser[]> = computed(() => {
		const filterType = this.filterType();
		if (this.datasGetType === "firestore") {
			const preFilteredDatas = this.allDatas().filter((data) => {
				if (filterType === "all") {
					return true;
				} else if (filterType === "present") {
					return this.checkeds().find((check) => check.userId === data.uid && check.checkinStatus);
				} else if (filterType === "away") {
					return (
						!this.checkeds().find((check) => check.userId === data.uid) ||
						this.checkeds().find((check) => check.userId === data.uid && !check.checkinStatus)
					);
				} else {
					return false;
				}
			});
			const filtersQuery = this.filtersQuery();

			const datasFiltered = filterSearch(filtersQuery, preFilteredDatas);
			return datasFiltered.datas.sort((a, b) => {
				return a.queryName > b.queryName ? 1 : a.queryName < b.queryName ? -1 : 0;
			});
		} else {
			return this.allDatas();
		}
	});

	itemSize: number = 97;
	height: number = 873;

	scanning: boolean = false;
	scanningWeb: boolean = false;
	enableScanWeb: boolean = false;

	isMobile = window.innerWidth < 768 ? true : false;
	filterType: WritableSignal<"all" | "present" | "away"> = signal("all");

	navigationSaved: { moduleId: string; lastIndex: number }[] = [];

	totalAway: number = 0;
	totalPresent: number = 0;
	total: number = 0;

	term: string = "";
	limit: number = 20;
	p: number = 0;

	mainLoader: boolean = true;
	init: boolean = false;
	updating: boolean = false;

	currentLanguage: string = environment.platform.defaultLanguage;

	lastQuery: {
		start: QueryDocumentSnapshot<IEventUser>;
		last: QueryDocumentSnapshot<IEventUser>;
		docsSnapshots: QueryDocumentSnapshot<IEventUser>[];
		docsDatas: IEventUser[];
	} = null;

	searchValue: string = "";
	searchValueUpdated: Subject<string> = new Subject<string>();

	filters: WritableSignal<IFilters> = signal({
		locations: [],
		tracks: [],
		groups: [],
		customFields: [],
		principalKey: ""
	});
	filtersLoader: boolean = true;
	selectedFilters: IFilteredItemFormat[] = [];

	filtersQuery: WritableSignal<ISearchFilter> = signal({
		equalityFields: [],
		inequalityFields: [],
		includeTextFields: [],
		includeOrTextFields: [],
		superiorFields: [],
		superiorOrEqualFields: [],
		inferiorFields: [],
		inferiorOrEqualFields: [],
		anyTextFields: [],
		arrayContainsAnyFields: [],
		arrayContainsAllFields: [],
		arrayContainsBlocAndOrFields: [],
		arrayNotContainsFields: [],
		elemMatchArrayFields: [],
		specificMongodbQuery: [],
		page: 1,
		itemsPerPage: this.limit,
		sortBy: []
	});

	filtersAll: ISearchFilter;
	filtersPresent: ISearchFilter;

	initDatasSubject: BehaviorSubject<{
		initEvent: boolean;
		initModule: boolean;
		initEventUsersModules: boolean;
		initCheckin: boolean;
		initCheckeds: boolean;
		initEventUser: boolean;
		initEventUsers: boolean;
		initGroups: boolean;
		initCustomFields: boolean;
		initFilters: boolean;
	}> = new BehaviorSubject(null);
	initDatasChecks: {
		initEvent: boolean;
		initModule: boolean;
		initEventUsersModules: boolean;
		initCheckin: boolean;
		initCheckeds: boolean;
		initEventUser: boolean;
		initEventUsers: boolean;
		initGroups: boolean;
		initCustomFields: boolean;
		initFilters: boolean;
	} = {
		initEvent: false,
		initModule: false,
		initEventUsersModules: false,
		initCheckin: false,
		initCheckeds: false,
		initEventUser: false,
		initEventUsers: false,
		initGroups: false,
		initCustomFields: false,
		initFilters: false
	};

	firstTimeModuleAccess: boolean = true;

	filtersUuid: string = this.SUtility.generateUuid();

	datasGetType: "firestore" | "mongo" = "firestore";
	totalEventUsers: number = 0;

	constructor(
		private store: Store,
		private SCheckins: CheckinsService,
		private SEventUsers: EventUsersService,
		private SFirestore: FirestoreService,
		private SCustomFields: CustomFieldsService,
		private STranslate: TranslateService,
		private SUtility: UtilityService,
		private SAnalytics: AnalyticsService,
		private cdr: ChangeDetectorRef
	) {
		this.searchSub = this.searchValueUpdated.pipe(distinctUntilChanged()).subscribe((evtValue) => {
			this.loader = true;
			this.resetPagination();
			if (evtValue.length >= 1) {
				this.searchValue = evtValue;
				this.buildFilterQuery();
			} else {
				this.resetFilter();
			}
		});
		effect(
			() => {
				const computeReachableDatas = this.SUtility.computeReachableDatas(
					this.event,
					this.allDatas(),
					this.datasFiltered(),
					this.customFields,
					this.filters(),
					this.currentLanguage,
					this.groups,
					[],
					[],
					this.datasGetType
				);

				this.filters.set(computeReachableDatas.filters);

				this.loader = false;
				this.cdr.markForCheck();
			},
			{ allowSignalWrites: true }
		);
	}

	ngOnInit(): void {
		this.SCheckins.scanQrSubject.subscribe((scanQr) => {
			if (scanQr && this.checkin) {
				this.scanQr();
			}
		});
	}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);

		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.checkinId = params.checkinId;

						this.initDatas();
					});
			});

		this.initDatasSubject
			.pipe(
				skipWhile(
					(initState) =>
						!initState ||
						!initState.initEvent ||
						!initState.initModule ||
						!initState.initEventUser ||
						!initState.initEventUsers ||
						!initState.initCustomFields ||
						!initState.initGroups ||
						!initState.initFilters
				),
				take(1)
			)
			.subscribe(() => {
				this.mainLoader = false;
				this.init = true;
			});
	}

	ionViewDidEnter() {
		interval(100)
			.pipe(
				skipWhile(() => this.loader || !this.virtualScroll),
				take(1)
			)
			.subscribe(() => {
				if (this.virtualScroll) {
					const nav = this.navigationSaved.find((navMod) => navMod.moduleId === this.moduleId);
					this.virtualScroll.scrollToIndex(nav ? nav.lastIndex : 0, "auto");
					this.init = true;
				}
			});
	}

	ionViewWillLeave() {
		this.mainLoader = true;
		// Case scanning running
		BarcodeScanner.showBackground();
		BarcodeScanner.stopScan();

		this.store.dispatch(GetScanning({ payload: false }));

		this.unloadDatas();

		// this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub.unsubscribe());

		// this.resetAllDatas();
	}

	/**
	 * Unsubscribe subscriptions on destroy
	 */
	ngOnDestroy() {
		this.subscriptions.concat([this.searchSub]).forEach((sub) => sub.unsubscribe());
	}

	checkNeedReloadDatas() {
		let needReloadDatas: boolean = false;
		if (
			this.firstTimeModuleAccess ||
			!this.moduleId ||
			!this.module ||
			(this.module && this.moduleId !== this.module.uid && this.module.type === TypeModule.CHECKIN)
		) {
			needReloadDatas = true;
		}
		return needReloadDatas;
	}

	/**
	 * unloadDatas
	 */
	unloadDatas() {
		this.subscriptions.forEach((sub) => sub?.unsubscribe());

		this.initDatasChecks = {
			...this.initDatasChecks,
			initModule: false,
			initCheckin: false,
			initCheckeds: false,
			initEventUsers: false,
			initCustomFields: false,
			initFilters: false
		};
		this.initDatasSubject.next(this.initDatasChecks);
		this.module = null;
		this.checkin.set(null);
		this.checkeds.set([]);
		this.customFields = [];
		this.allDatas.set([]);
		this.p = 0;
		this.searchValue = "";
		this.loader = true;
		this.init = false;
		this.filtersLoader = true;
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getFilters();
		this.getEventUsersModules();
		this.getMyEventUser();
		this.getCheckin();
		this.getGroups();
		this.getCustomFields();
		this.getFilters();
		this.buildFilterQuery();
		// this.getEventUsers();
		this.getCheckeds();
	}

	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;

					if (event && event.limitations && event.limitations.usersLimit <= 1000) {
						// If event has less or equal than 1000 users
						this.datasGetType = "firestore";
					} else {
						// If event has more than 1000 users
						this.datasGetType = "mongo";
					}

					this.initDatasChecks.initEvent = true;
					this.initDatasSubject.next(this.initDatasChecks);
				}
			})
		);
	}

	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;

					this.initDatasChecks.initModule = true;
					this.initDatasSubject.next(this.initDatasChecks);
				}
			})
		);
	}

	getEventUsersModules() {
		this.subscriptions.push(
			this.store.select(getModulesByType(TypeModule.ATTENDEE)).subscribe((modules) => {
				if (!_.isEqual(this.eventUsersModules(), modules)) {
					this.eventUsersModules.set(modules);

					this.initDatasChecks.initEventUsersModules = true;
					this.initDatasSubject.next(this.initDatasChecks);
				}
			})
		);
	}

	getSpecificEventUsersModule(moduleId: string) {
		return this.eventUsersModules().find((module) => module.uid === moduleId);
	}

	/**
	 * getMyEventUser
	 * @desc get actual (authenticated) eventuser
	 */
	getMyEventUser() {
		this.subscriptions.push(
			this.store.select(getMyEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;

					this.initDatasChecks.initEventUser = true;
					this.initDatasSubject.next(this.initDatasChecks);
				}
			})
		);
	}

	getCheckin() {
		this.subscriptions.push(
			this.SCheckins.getCheckin(this.eventId, this.moduleId, this.checkinId).subscribe((checkin) => {
				if (!_.isEqual(this.checkin(), checkin)) {
					this.checkin.set(checkin);

					if (this.checkin()) {
						if (this.firstTimeModuleAccess) this.resetQueryFilters();

						this.store.dispatch(
							GetHeaderState({
								payload: {
									item: this.checkin(),
									module: this.module,
									title: {
										ArAR: this.checkin().name,
										DeDE: this.checkin().name,
										EnUS: this.checkin().name,
										EsES: this.checkin().name,
										FrFR: this.checkin().name,
										PtBR: this.checkin().name
									},
									type: TypeHeader.CHECKIN
								}
							})
						);
					}

					this.initDatasChecks.initCheckin = true;
					this.initDatasSubject.next(this.initDatasChecks);
					this.cdr.markForCheck();
				}
			})
		);
	}

	/**
	 * Get groups
	 */
	getGroups() {
		this.store
			.select(getGroupsByOrder("asc"))
			.pipe(take(1))
			.subscribe((groups) => {
				if (!_.isEqual(this.groups, groups)) {
					this.groups = groups;
				}
				this.initDatasChecks.initGroups = true;
				this.initDatasSubject.next(this.initDatasChecks);
			});
	}

	/**
	 * Get custom fields
	 */
	getCustomFields() {
		this.store
			.select(getModulesByType(TypeModule.ATTENDEE))
			.pipe(take(1))
			.subscribe((modules) => {
				const obs: Observable<IModuleCustomField[]>[] = [];
				modules.forEach((module) => {
					obs.push(this.store.select(getModulesCustomsFieldsOfModule(module.uid)));
				});
				combineLatest([this.store.select(getBaseCustomFields), combineLatest(obs)])
					.pipe(take(1))
					.subscribe((results) => {
						const baseCustomFields = results[0];
						const modulesCustomFields = results[1];
						modulesCustomFields.forEach((moduleCustomFields) => {
							moduleCustomFields.forEach((customField) => {
								const baseCustomFieldCorresponding = baseCustomFields.find(
									(custField) => custField.uid === customField.uid
								);

								this.customFields.push({
									baseSettings: baseCustomFieldCorresponding ? baseCustomFieldCorresponding : null,
									moduleSettings: customField,
									fieldDatas: {
										uid: "",
										field: {
											date: {
												day: 0,
												month: 0,
												year: 0,
												value: "",
												fullDateISO: "",
												zone: ""
											},
											file: {
												lastModifiedDate: "",
												name: "",
												type: "",
												url: "",
												size: 0
											},
											image: {
												format: "",
												lastModifiedDate: "",
												name: "",
												url: "",
												size: 0
											},
											module: {
												items: [],
												moduleType: -1
											},
											multiLanguageText: {
												ArAR: "",
												DeDE: "",
												EnUS: "",
												EsES: "",
												FrFR: "",
												PtBR: ""
											},
											multiLanguageSelectArray: [],
											multiLanguageTextArray: {
												ArAR: [],
												DeDE: [],
												EnUS: [],
												EsES: [],
												FrFR: [],
												PtBR: []
											},
											numeric: 0,
											phoneNumber: {
												countryCode: "",
												dialCode: "",
												e164Number: "",
												internationalNumber: "",
												nationalNumber: "",
												number: ""
											},
											text: ""
										}
									}
								});
							});
						});
						this.initDatasChecks.initCustomFields = true;
						this.initDatasSubject.next(this.initDatasChecks);
						this.cdr.markForCheck();
					});
			});
	}

	getCustomFieldsOfModule(moduleId: string) {
		return this.customFields.filter((customField) => customField.moduleSettings.moduleId === moduleId);
	}

	getFilters() {
		this.initDatasSubject
			.pipe(
				skipWhile(
					(initState) =>
						!initState.initEvent ||
						!initState.initModule ||
						!initState.initCustomFields ||
						!initState.initGroups ||
						!initState.initCheckin ||
						!initState.initEventUsers
				),
				take(1),
				switchMap(() => {
					if (this.checkin() && this.checkin().showFilter) {
						return this.SCustomFields.getFiltersForModule(
							this.module,
							this.eventUser,
							this.customFields,
							[],
							[],
							this.groups.map((group) => {
								return { name: group.name, uid: group.uid };
							}),
							this.currentLanguage,
							this.allDatas()
						);
					} else {
						return of(null);
					}
				})
			)
			.subscribe({
				next: (filters: IFilters) => {
					if (filters) {
						this.integratePreviousFiltersState(filters);
					}

					this.initDatasChecks.initFilters = true;
					this.initDatasSubject.next(this.initDatasChecks);

					this.filtersLoader = false;
				},
				error: () => {
					this.filtersLoader = false;
				}
			});
	}

	integratePreviousFiltersState(filters: IFilters) {
		const newFilters: IFilters = _.cloneDeep(filters);
		this.filters().locations.forEach((filter) => {
			const checkFilter = newFilters.locations.find((specFilter) => specFilter.uid === filter.uid);
			if (checkFilter) {
				checkFilter.checked = filter.checked;
				checkFilter.isReachable = filter.isReachable;
			}
		});
		this.filters().tracks.forEach((filter) => {
			const checkFilter = newFilters.tracks.find((specFilter) => specFilter.uid === filter.uid);
			if (checkFilter) {
				checkFilter.checked = filter.checked;
				checkFilter.isReachable = filter.isReachable;
			}
		});
		this.filters().groups.forEach((filter) => {
			const checkFilter = newFilters.groups.find((specFilter) => specFilter.uid === filter.uid);
			if (checkFilter) {
				checkFilter.checked = filter.checked;
				checkFilter.isReachable = filter.isReachable;
			}
		});
		this.filters().customFields.forEach((filter) => {
			const checkFilter = newFilters.customFields.find((specFilter) => specFilter.uid === filter.uid);
			if (checkFilter) {
				filter.values.forEach((value) => {
					const checkFilterValue = checkFilter.values.find((val) => val.value === value.value);
					if (checkFilterValue) {
						checkFilterValue.isSelected = value.isSelected;
						checkFilterValue.isReachable = value.isReachable;
					}
				});
			}
		});
		newFilters.principalKey = this.filters().principalKey;
		this.filters.set(newFilters);
		this.cdr.markForCheck();
	}

	/**
	 * anyFilterIsActivated
	 * @description return boolean on whether at least one filter has a selected data in it or not
	 * @param filter
	 * @returns filter
	 */
	anyFilterIsActivated(): boolean {
		return this.selectedFilters.length > 0;
	}

	/**
	 * canShowFilterBloc
	 * @description return true if the filter is at least one custom field that can be filtered
	 * @returns boolean
	 */

	canShowFilterBloc() {
		return (
			this.customFields.some((cus) => cus.moduleSettings.enableFilter) ||
			(this.module.options.requiredFields.group.filter && this.groups.length > 0)
		);
	}

	getCheckinStats() {
		combineLatest([
			this.SEventUsers.getEventUsersOfModuleForEvent(this.moduleId, this.filtersAll, "aggregation").pipe(take(1)),
			this.SEventUsers.getEventUsersOfModuleForEvent(this.moduleId, this.filtersPresent, "aggregation").pipe(
				take(1)
			)
		])
			.pipe(take(1))
			.subscribe((results) => {
				this.total = results[0].totalDocuments;
				this.totalPresent = results[1].totalDocuments;
				this.totalAway = this.total - this.totalPresent;
			});
	}

	getEventUsers() {
		this.initDatasSubject
			.pipe(
				skipWhile(
					(initState) =>
						!initState.initEvent ||
						!initState.initModule ||
						!initState.initEventUsersModules ||
						!initState.initCheckin ||
						!initState.initCustomFields ||
						!initState.initGroups
				),
				take(1),
				switchMap(() => {
					if (this.datasGetType === "mongo") {
						this.getCheckinStats();
					}
					return this.SEventUsers.getEventUsersOfModuleForEvent(
						this.moduleId,
						this.datasGetType === "firestore" ? null : this.filtersQuery(),
						"aggregation"
					).pipe(take(1));
				})
			)
			.subscribe((res) => {
				if (this.datasGetType === "firestore" || (this.datasGetType === "mongo" && this.p === 0)) {
					this.allDatas.set(res.docs);
				} else {
					this.allDatas.set(this.allDatas().concat(res.docs));
				}
				this.totalEventUsers = res.totalDocuments;

				this.onLoadingMore = false;
				this.firstTimeModuleAccess = false;
				this.initDatasChecks.initEventUsers = true;
				this.initDatasSubject.next(this.initDatasChecks);
			});
	}

	getCheckeds() {
		if (this.datasGetType === "firestore") {
			this.initDatasSubject
				.pipe(
					skipWhile(
						(initState) =>
							!initState.initEvent ||
							!initState.initModule ||
							!initState.initCustomFields ||
							!initState.initGroups ||
							!initState.initEventUsers
					),
					take(1),
					switchMap(() => {
						return this.SFirestore.valueChangesDocuments(
							`events/${this.eventId}/modules/${this.moduleId}/checkins/${this.checkinId}/checkin-checked`,
							[]
						);
					})
				)
				.subscribe({
					next: (checkeds: ICheckinChecked[]) => {
						this.checkeds.set(checkeds);
						this.total = this.allDatas().length;
						this.totalPresent = this.checkeds().filter(
							(check) => this.allDatas().find((data) => data.uid === check.userId) && check.checkinStatus
						).length;
						this.totalAway = this.total - this.totalPresent;

						this.initDatasChecks.initCheckeds = true;
						this.initDatasSubject.next(this.initDatasChecks);
						this.loader = false;
					}
				});
		} else {
			this.initDatasChecks.initCheckeds = true;
			this.initDatasSubject.next(this.initDatasChecks);
			this.loader = false;
		}
	}

	onLoadingMore: boolean = false;
	scrolledIndexChanged(index: number) {
		const nav = this.navigationSaved.find((navMod) => navMod.moduleId === this.moduleId);
		if (this.init) {
			if (!nav) {
				this.navigationSaved.push({
					moduleId: this.moduleId,
					lastIndex: index
				});
			} else {
				nav.lastIndex = index;
			}
		}

		if (
			this.virtualScroll.getRenderedRange().end === this.virtualScroll.getDataLength() &&
			this.datasGetType === "mongo" &&
			this.virtualScroll.getDataLength() < this.totalEventUsers &&
			!this.onLoadingMore
		) {
			this.onLoadingMore = true;
			this.moreDatas();
		}
	}

	resetPagination() {
		this.p = 0;
		if (this.virtualScroll) {
			this.virtualScroll.scrollToIndex(0);
		}
	}

	moreDatas() {
		if (this.datasGetType === "mongo") {
			this.p++;
			this.buildFilterQuery();
		}
	}

	/**
	 * Filter
	 */
	buildFilterQuery() {
		this.initDatasSubject
			.pipe(
				skipWhile(
					(initState) =>
						!initState.initEvent ||
						!initState.initModule ||
						!initState.initEventUser ||
						!initState.initCustomFields ||
						!initState.initGroups
				),
				take(1)
			)
			.subscribe(() => {
				this.loader = true;
				this.resetQueryFilters();
				let filtersQuery = this.filtersQuery();

				filtersQuery.page = this.p;
				filtersQuery.itemsPerPage = this.datasGetType === "firestore" ? 999999 : this.limit;

				if (this.filters && this.filters().customFields) {
					this.filters().customFields.forEach((filter) => {
						filter.values.forEach((value) => {
							const cus = this.customFields.find(
								(cus) => cus.baseSettings.uid === value.filterId
							).moduleSettings;
							const fieldKey =
								this.SCustomFields.getCustomFieldType(this.customFields, cus.uid) ===
									TypeCustomFields.TEXT ||
								this.SCustomFields.getCustomFieldType(this.customFields, cus.uid) ===
									TypeCustomFields.SELECT
									? "multiLanguageText"
									: this.SCustomFields.getCustomFieldType(this.customFields, cus.uid) ===
											TypeCustomFields.URL ||
									  this.SCustomFields.getCustomFieldType(this.customFields, cus.uid) ===
											TypeCustomFields.EMAIL
									? "text"
									: this.SCustomFields.getCustomFieldType(this.customFields, cus.uid) ===
									  TypeCustomFields.NUMERIC
									? "numeric"
									: this.SCustomFields.getCustomFieldType(this.customFields, cus.uid) ===
									  TypeCustomFields.MULTI_SELECT
									? "multiLanguageSelectArray"
									: false;

							const isMultilanguage: boolean =
								this.SCustomFields.getCustomFieldType(this.customFields, cus.uid) ===
									TypeCustomFields.TEXT ||
								this.SCustomFields.getCustomFieldType(this.customFields, cus.uid) ===
									TypeCustomFields.SELECT ||
								this.SCustomFields.getCustomFieldType(this.customFields, cus.uid) ===
									TypeCustomFields.MULTI_SELECT
									? true
									: false;

							if (
								this.datasGetType === "mongo" &&
								this.getCustomFieldType(cus.uid) === TypeCustomFields.TEXT
							) {
								if (filter.search) {
									const bloc = filtersQuery.includeOrTextFields.find(
										(bloc) =>
											bloc.fieldKey ===
											"customFields|" +
												fieldKey +
												"|" +
												cus.uid +
												(isMultilanguage ? "|" + this.currentLanguage : "")
									);
									if (!bloc) {
										filtersQuery.includeOrTextFields.push({
											fieldKey:
												"customFields|" +
												fieldKey +
												"|" +
												cus.uid +
												(isMultilanguage ? "|" + this.currentLanguage : ""),
											compareData: [filter.search]
										});
									} else {
										bloc.compareData.push(filter.search);
									}
								}
							} else {
								filter.values.forEach((value) => {
									if (value.isSelected) {
										const bloc = filtersQuery.arrayContainsBlocAndOrFields.find(
											(bloc) =>
												bloc.fieldKey ===
												"customFields|" +
													fieldKey +
													"|" +
													value.filterId +
													(isMultilanguage ? "|" + this.currentLanguage : "")
										);
										if (!bloc) {
											filtersQuery.arrayContainsBlocAndOrFields.push({
												fieldKey:
													"customFields|" +
													fieldKey +
													"|" +
													value.filterId +
													(isMultilanguage ? "|" + this.currentLanguage : ""),
												compareData: [value.isNotSpecified ? "" : value.value]
											});
										} else {
											bloc.compareData.push(value.isNotSpecified ? "" : value.value);
										}
									}
								});
							}
						});
					});
				}

				if (this.filters && this.filters().groups) {
					this.filters().groups.forEach((filter) => {
						if (filter.checked) {
							const bloc = filtersQuery.arrayContainsBlocAndOrFields.find(
								(bloc) => bloc.fieldKey === "groups"
							);
							if (!bloc) {
								filtersQuery.arrayContainsBlocAndOrFields.push({
									fieldKey: "groups",
									compareData: [filter.uid]
								});
							} else {
								bloc.compareData.push(filter.uid);
							}
						}
					});
				}

				this.filtersAll = _.cloneDeep(filtersQuery);
				this.filtersPresent = _.cloneDeep(filtersQuery);
				this.filtersAll.page = 0;
				this.filtersAll.itemsPerPage = 1;
				this.filtersPresent.page = 0;
				this.filtersPresent.itemsPerPage = 1;
				this.filtersPresent.specificMongodbQuery.push({
					"checkinCheckedDatas.checkinStatus": {
						$exists: true,
						$eq: true
					}
				});

				if (this.filterType() === "present") {
					filtersQuery.specificMongodbQuery.push({
						"checkinCheckedDatas.checkinStatus": {
							$exists: true,
							$eq: true
						}
					});
				} else if (this.filterType() === "away") {
					filtersQuery.specificMongodbQuery.push({
						$or: [
							{
								"checkinCheckedDatas.checkinStatus": {
									$eq: false
								}
							},
							{
								"checkinCheckedDatas.checkinStatus": {
									$exists: false
								}
							}
						]
					});
				}

				// Filter on searchbar term if needed
				filtersQuery = this.buildSearchQueryFilters(filtersQuery);

				this.filtersAll = this.buildSearchQueryFilters(this.filtersAll);
				this.filtersPresent = this.buildSearchQueryFilters(this.filtersPresent);

				filtersQuery.sortBy.push({
					fieldKey: "queryName",
					type: "desc"
				});
				this.filtersQuery.set({ ...filtersQuery });

				this.initDatasChecks.initFilters = true;
				this.initDatasSubject.next(this.initDatasChecks);

				this.getEventUsers();

				this.cdr.markForCheck();
			});
	}

	resetQueryFilters() {
		const filtersQuery = this.filtersQuery();
		filtersQuery.arrayContainsAnyFields =
			this.checkin() && this.checkin().groupsInType === 1
				? [
						{
							fieldKey: "groups",
							compareData: this.checkin().groupsInCheckin
						}
				  ]
				: [];
		filtersQuery.equalityFields = [
			{
				fieldKey: "eventId",
				compareData: this.eventId
			},
			{
				fieldKey: "type",
				compareData: TypeUser.ATTENDEE
			}
		];
		filtersQuery.includeTextFields = [];
		filtersQuery.includeOrTextFields = [];
		filtersQuery.inferiorFields = [];
		filtersQuery.inferiorOrEqualFields = [];
		filtersQuery.superiorFields = [];
		filtersQuery.superiorOrEqualFields = [];
		filtersQuery.arrayContainsBlocAndOrFields = [];
		filtersQuery.specificMongodbQuery = [];
		filtersQuery.lookup = [
			{
				where: "beforeMatch",
				stage: {
					$lookup: {
						from: "checkin-checked",
						localField: "uid",
						foreignField: "userId",
						pipeline: [
							{
								$match: {
									checkinId: { $eq: this.checkinId }
								}
							}
						],
						as: "checkinCheckedDatas"
					}
				}
			}
		];
		filtersQuery.sortBy = [];
		filtersQuery.page = 0;

		this.filtersQuery.set({ ...filtersQuery });
	}

	buildSearchQueryFilters(filtersQuery: ISearchFilter) {
		return buildFiltersQuery(
			this.eventUsersModules(),
			"firestore",
			filtersQuery,
			this.searchValue,
			this.currentLanguage,
			this.customFields,
			[],
			[],
			[],
			[],
			[],
			this.groups,
			[],
			[]
		);
	}

	/**
	 * Reset filter
	 */
	resetFilter() {
		this.searchValue = "";
		this.buildFilterQuery();
		this.cdr.markForCheck();
	}

	/**
	 * getCustomFieldType
	 * @param customFieldId
	 * @returns
	 */
	getCustomFieldType(customFieldId: string): number {
		return this.customFields.find((cus) => cus.baseSettings.uid === customFieldId)?.baseSettings.type;
	}

	/**
	 * Scan success event for web
	 * @param evt
	 */
	scanSuccessHandler(barcodeData: string) {
		if (this.enableScanWeb) {
			this.enableScanWeb = false;
			this.createCheckinResult(
				barcodeData && barcodeData.split("+") && barcodeData.split("+").length > 0
					? barcodeData.split("+")[0]
					: "",
				barcodeData && barcodeData.split("+") && barcodeData.split("+").length > 0
					? barcodeData.split("+")[1]
					: ""
			);
		}
	}

	/**
	 * Emits an array of video-devices after view was initialized.
	 */
	camerasFoundHandler() {
		this.enableScanWeb = true;
	}

	/**
	 * Not camera found for scanning
	 */
	async camerasNotFoundHandler() {
		this.scanning = false;
		this.scanningWeb = false;
		this.enableScanWeb = false;
		const alert = await this.SUtility.presentAlert(
			this.STranslate.instant("alerts.error_scanning"),
			this.STranslate.instant("alerts.no_camera"),
			[
				{
					text: this.STranslate.instant("buttons.no"),
					role: "cancel"
				}
			]
		);
		alert.present();
	}

	/**
	 * Scan error handler
	 * @param evt
	 */
	async scanErrorHandler() {
		this.scanning = false;
		this.scanningWeb = false;
		this.enableScanWeb = false;
		const alert = await this.SUtility.presentAlert(
			this.STranslate.instant("alerts.error_scanning"),
			this.STranslate.instant("alerts.invalid_qrcode"),
			[
				{
					text: this.STranslate.instant("buttons.yes"),
					handler: () => {
						this.scanQr();
					}
				},
				{
					text: this.STranslate.instant("buttons.no"),
					role: "cancel"
				}
			]
		);
		alert.present();
	}

	async checkPermission() {
		const status = await BarcodeScanner.checkPermission({ force: true });
		if (status.granted) {
			return true;
		} else if (status.denied) {
			BarcodeScanner.openAppSettings();
			return false;
		}
	}

	/**
	 * Scan qr code
	 */
	async scanQr() {
		if (!this.scanning) {
			this.scanning = true;

			// For web scanning
			// if (!this.isMobile) {
			// 	this.scanning = false;
			// 	this.scanningWeb = true;
			// 	// this.enableScanWeb = true;
			// 	return;
			// }

			// For web scanning
			const allowed = await this.checkPermission();
			if (allowed) {
				try {
					BarcodeScanner.hideBackground(); // make background of WebView transparent
					this.store.dispatch(GetScanning({ payload: true }));
					const result = await BarcodeScanner.startScan(); // start scanning and wait for a result
					this.store.dispatch(GetScanning({ payload: false }));
					// if the result has content
					if (result.hasContent) {
						this.createCheckinResult(
							result.content.split("+") && result.content.split("+").length > 0
								? result.content.split("+")[0]
								: "",
							result.content.split("+") && result.content.split("+").length > 0
								? result.content.split("+")[1]
								: ""
						);
						// this.scanning = false;
					} else {
						this.scanning = false;
						this.scanningWeb = false;
						this.enableScanWeb = false;
						this.SUtility.callGoBackOnHeader();
					}
				} catch (error) {
					this.store.dispatch(GetScanning({ payload: false }));
					this.scanning = false;
					this.scanningWeb = false;
					this.enableScanWeb = false;
					const alert = await this.SUtility.presentAlert(
						this.STranslate.instant("alerts.error_scanning"),
						this.STranslate.instant("alerts.no_camera"),
						[
							{
								text: this.STranslate.instant("buttons.yes"),
								handler: () => {
									this.scanQr();
								}
							},
							{
								text: this.STranslate.instant("buttons.no"),
								role: "cancel"
							}
						]
					);
					alert.present();
				}
			} else {
				this.scanning = false;
			}
		}
	}

	/**
	 * Create checkin result
	 * @param qrEventId
	 * @param qrEventUserId
	 */
	async createCheckinResult(qrEventId: string, qrEventUserId: string) {
		try {
			const eventUser = this.allDatas().find((eventUser) => eventUser.uid === qrEventUserId);

			if (qrEventId && qrEventUserId && eventUser) {
				this.changeEventUserStatus(eventUser);
			} else {
				this.scanning = false;
				this.scanningWeb = false;
				this.enableScanWeb = false;
				const alert = await this.SUtility.presentAlert(
					this.STranslate.instant("alerts.error_scanning"),
					this.STranslate.instant("alerts.invalid_qrcode"),
					[
						{
							text: this.STranslate.instant("buttons.yes"),
							handler: () => {
								this.scanQr();
							}
						},
						{
							text: this.STranslate.instant("buttons.no"),
							role: "cancel"
						}
					]
				);
				alert.present();
			}
		} catch (error) {
			this.scanning = false;
			this.scanningWeb = false;
			this.enableScanWeb = false;
			const alert = await this.SUtility.presentAlert(
				this.STranslate.instant("alerts.error_scanning"),
				this.STranslate.instant("alerts.invalid_qrcode"),
				[
					{
						text: this.STranslate.instant("buttons.yes"),
						handler: () => {
							this.scanQr();
						}
					},
					{
						text: this.STranslate.instant("buttons.no"),
						role: "cancel"
					}
				]
			);
			alert.present();
		}
	}

	/**
	 * Confirm change of status
	 * @param attendee
	 */
	async confirmChangeStatus(eventUser: IEventUser) {
		const alert = await this.SUtility.presentAlert(
			this.STranslate.instant("alerts.change_status"),
			this.STranslate.instant("alerts.change_status_confirm_msg") + " " + eventUser.name + "?",
			[
				{
					text: this.STranslate.instant("buttons.yes"),
					handler: () => {
						this.changeEventUserStatus(eventUser);
					}
				},
				{
					text: this.STranslate.instant("buttons.no")
				}
			]
		);

		await alert.present();
	}

	/**
	 * Change status
	 * @param eventUser
	 */
	async changeEventUserStatus(eventUser: IEventUser) {
		try {
			let checkChecked =
				this.datasGetType === "firestore"
					? this.checkeds().find((checked) => checked.userId === eventUser.uid)
					: eventUser["checkinCheckedDatas"]
					? eventUser["checkinCheckedDatas"].find(
							(check: ICheckinChecked) =>
								check.userId === eventUser.uid && check.checkinId === this.checkinId
					  )
					: null;
			if (this.checkin().onlyOneCheck && checkChecked && checkChecked.checkinStatus) {
				this.SUtility.presentToast(
					this.STranslate.instant("checkin.only-one-check-authorized"),
					3000,
					"bottom",
					"danger"
				);
				return;
			} else {
				if (checkChecked) {
					checkChecked.checkinStatus = !checkChecked.checkinStatus;

					// If checkin status true
					if (checkChecked.checkinStatus) {
						if (!eventUser.checkins) {
							const checkins = await firstValueFrom(this.store.select(getAllCheckins));
							eventUser.checkins = {
								checkinsChecked: [checkChecked.checkinId],
								checkinsNotChecked: checkins
									.filter((checkin) => checkin.uid !== checkChecked.checkinId)
									.map((checkin) => checkin.uid)
							};
						} else {
							if (!eventUser.checkins.checkinsChecked.includes(checkChecked.checkinId)) {
								eventUser.checkins.checkinsChecked.push(checkChecked.checkinId);
							}
							eventUser.checkins.checkinsChecked.push(checkChecked.checkinId);
							eventUser.checkins.checkinsNotChecked = eventUser.checkins.checkinsNotChecked.filter(
								(uid) => uid !== checkChecked.checkinId
							);
						}
					} else {
						// If checkin status false
						if (!eventUser.checkins) {
							const checkins = await firstValueFrom(this.store.select(getAllCheckins));
							eventUser.checkins = {
								checkinsChecked: [],
								checkinsNotChecked: checkins.map((checkin) => checkin.uid)
							};
						} else {
							eventUser.checkins.checkinsChecked = eventUser.checkins.checkinsChecked.filter(
								(uid) => uid !== checkChecked.checkinId
							);
							if (!eventUser.checkins.checkinsNotChecked.includes(checkChecked.checkinId)) {
								eventUser.checkins.checkinsNotChecked.push(checkChecked.checkinId);
							}
						}
					}

					if (this.checkin().multiCheck && checkChecked.checkinStatus) {
						checkChecked = this.constructMultiCheckRecord(
							checkChecked,
							checkChecked.checkinStatus ? "add" : "remove"
						);
					}

					checkChecked.userQueryName = eventUser.queryName;
					checkChecked.groupsWhenChecked = eventUser.groups;
					checkChecked.creationDate = DateTime.local().toISO();
					await this.SCheckins.updateCheckinChecked(
						this.eventId,
						checkChecked.moduleId,
						checkChecked.checkinId,
						checkChecked
					);
					await this.SEventUsers.updatePartOfEventUser(eventUser.eventId, eventUser.moduleId, eventUser.uid, {
						checkins: eventUser.checkins
					});
					await this.SAnalytics.checkinProcessed(this.eventId, eventUser, {
						checkinId: this.checkinId,
						checkinStatus: checkChecked.checkinStatus,
						moduleId: this.moduleId,
						type: TypeTracking.CHECKIN_ACTION_PROCESSED
					});

					this.scanning = false;
					this.scanningWeb = false;
					this.enableScanWeb = false;
					this.SUtility.presentToast(
						this.STranslate.instant(
							checkChecked.checkinStatus ? "checkin.user_registered" : "checkin.user_unregistered"
						),
						3000,
						"bottom",
						"success"
					);
				} else if (!checkChecked) {
					const newChecked: ICheckinChecked = {
						uid: "",
						checkinId: this.checkinId,
						checkinStatus: true,
						creationDate: DateTime.local().toISO(),
						eventId: this.eventId,
						groupsWhenChecked: eventUser.groups,
						moduleId: this.moduleId,
						multiCheckRecords: [],
						multiCheckRecordsCount: 0,
						userQueryName: eventUser.queryName,
						userId: eventUser.uid,
						validationType: 2
					};

					// If checkin status true
					if (!eventUser.checkins) {
						const checkins = await firstValueFrom(this.store.select(getAllCheckins));
						eventUser.checkins = {
							checkinsChecked: [newChecked.checkinId],
							checkinsNotChecked: checkins
								.filter((checkin) => checkin.uid !== newChecked.checkinId)
								.map((checkin) => checkin.uid)
						};
					} else if (!eventUser.checkins.checkinsChecked.includes(newChecked.checkinId)) {
						eventUser.checkins.checkinsChecked.push(newChecked.checkinId);
						eventUser.checkins.checkinsNotChecked = eventUser.checkins.checkinsNotChecked.filter(
							(uid) => uid !== newChecked.checkinId
						);
					}
					await this.SCheckins.createCheckinChecked(this.eventId, this.moduleId, this.checkinId, newChecked);
					await this.SEventUsers.updatePartOfEventUser(eventUser.eventId, eventUser.moduleId, eventUser.uid, {
						checkins: eventUser.checkins
					});

					eventUser["checkinCheckedDatas"] = [newChecked];

					await this.SAnalytics.checkinProcessed(this.eventId, eventUser, {
						checkinId: this.checkinId,
						checkinStatus: newChecked.checkinStatus,
						moduleId: this.moduleId,
						type: TypeTracking.CHECKIN_ACTION_PROCESSED
					});

					this.scanning = false;
					this.scanningWeb = false;
					this.enableScanWeb = false;
					this.SUtility.presentToast(
						this.STranslate.instant("checkin.user_registered"),
						3000,
						"bottom",
						"success"
					);
				} else {
					this.scanning = false;
					this.scanningWeb = false;
					this.enableScanWeb = false;
					// Already registered
					this.SUtility.presentToast(
						this.STranslate.instant("checkin.already_checked"),
						3000,
						"bottom",
						"success"
					);
				}
			}
			this.cdr.markForCheck();
		} catch (error) {
			this.scanning = false;
			this.scanningWeb = false;
			this.enableScanWeb = false;
			const alert = await this.SUtility.presentAlert(
				this.STranslate.instant("alerts.error"),
				this.STranslate.instant("alerts.not_possible_change_status") +
					" " +
					eventUser.name +
					" " +
					this.STranslate.instant("alerts.please_tryagain"),
				[
					{
						text: this.STranslate.instant("buttons.ok"),
						role: "cancel"
					}
				]
			);

			await alert.present();
			this.cdr.markForCheck();
		}
	}

	constructMultiCheckRecord(checked: ICheckinChecked, type: "add" | "remove") {
		const newCheck: IMultiCheckRecord = {
			checkDate: DateTime.local().toISO(),
			checkedUserEmail: "",
			checkedUserId: checked.userId,
			checkerUserEmail: this.eventUser.email,
			checkerUserId: this.eventUser.uid,
			type: type
		};

		if (type === "add") {
			checked.multiCheckRecordsCount =
				checked.multiCheckRecordsCount === undefined || checked.multiCheckRecordsCount === null
					? 1
					: checked.multiCheckRecordsCount + 1;
		} else {
			checked.multiCheckRecordsCount =
				checked.multiCheckRecordsCount === undefined || checked.multiCheckRecordsCount === null
					? 0
					: checked.multiCheckRecordsCount - 1;
		}

		checked.multiCheckRecords.push(newCheck);
		return checked;
	}

	checkProgress: boolean = false;
	async modifyMultiCheck(eventUserId: string, type: "add" | "remove") {
		if (!this.updating) {
			this.updating = true;
			const checkCheckedDocs = this.checkeds().filter((checked) => checked.userId === eventUserId);
			let checked = checkCheckedDocs.length > 0 ? checkCheckedDocs[0] : null;
			if (
				type === "remove" &&
				checked &&
				(!checked.multiCheckRecordsCount || checked.multiCheckRecordsCount <= 0) &&
				!this.checkProgress
			) {
				return;
			}
			this.checkProgress = true;
			checked = this.constructMultiCheckRecord(checked, type);

			try {
				await this.SCheckins.updateCheckinChecked(this.eventId, checked.moduleId, checked.checkinId, checked);
				await this.SAnalytics.checkinProcessed(this.eventId, this.eventUser, {
					checkinId: this.checkinId,
					checkinStatus: checked.checkinStatus,
					moduleId: this.moduleId,
					type:
						type === "remove"
							? TypeTracking.CHECKIN_MULTI_CHECK_REMOVE_ACTION_PROCESSED
							: TypeTracking.CHECKIN_MULTI_CHECK_ADD_ACTION_PROCESSED
				});

				this.checkProgress = false;
				this.updating = false;
				this.cdr.markForCheck();
			} catch (error) {
				this.checkProgress = false;
				this.updating = false;
				this.cdr.markForCheck();
			}
		}
	}

	getCheckedForEventUser(eventUser: IEventUser) {
		return this.datasGetType === "firestore"
			? this.checkeds().find((checked) => checked.userId === eventUser.uid)
			: eventUser["checkinCheckedDatas"]
			? eventUser["checkinCheckedDatas"].find(
					(check) => check.userId === eventUser.uid && check.checkinId === this.checkinId
			  )
			: null;
	}

	/**
	 * Get checked of event user
	 * @param eventUser
	 * @returns
	 */
	checkIsCheckedEventUser(eventUser: IEventUser) {
		if (this.datasGetType === "firestore") {
			return this.checkeds().find((check) => check.userId === eventUser.uid && check.checkinStatus)
				? true
				: false;
		} else {
			return eventUser["checkinCheckedDatas"] &&
				eventUser["checkinCheckedDatas"].length > 0 &&
				eventUser["checkinCheckedDatas"].find(
					(check) =>
						check.userId === eventUser.uid && check.checkinId === this.checkinId && check.checkinStatus
				)
				? true
				: false;
		}
	}

	getTotalFiltersChecked() {
		let count: number = 0;

		this.filters().locations.forEach((filter) => {
			if (filter.checked) {
				count++;
			}
		});
		this.filters().groups.forEach((filter) => {
			if (filter.checked) {
				count++;
			}
		});
		this.filters().tracks.forEach((filter) => {
			if (filter.checked) {
				count++;
			}
		});
		this.filters().customFields.forEach((filter) => {
			filter.values.forEach((value) => {
				if (value.isSelected) {
					count++;
				}
			});
		});

		return count;
	}
}
