import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject, delay, filter } from "rxjs";

@Directive({
    selector: "[observeVisibility]",
    standalone: false
})
export class ObserveVisibilityDirective implements OnDestroy, OnInit, AfterViewInit {
	@Input() debounceTime: number = 0;
	@Input() threshold: number = 0;
	@Input() rootMargin: string = "0px";
	@Input() root: string = "";
	@Input() rootElement: Element = null;

	@Output() visible = new EventEmitter<{
		target: HTMLElement;
		state: "ENTER" | "LEAVE";
		scrollDirection: "UP" | "DOWN";
	}>();

	private previousRatio: number = 0;
	private previousY: number = 0;

	private observer: IntersectionObserver | undefined;
	private subject$ = new Subject<{
		entry: IntersectionObserverEntry;
		observer: IntersectionObserver;
		state: "ENTER" | "LEAVE";
		scrollDirection: "UP" | "DOWN";
	}>();

	constructor(private element: ElementRef) {}

	ngOnInit() {
		this.createObserver();
	}

	ngAfterViewInit() {
		this.startObservingElements();
	}

	ngOnDestroy() {
		if (this.observer) {
			this.observer.disconnect();
			this.observer = undefined;
		}

		this.subject$.next(null);
		this.subject$.complete();
	}

	private isVisible(element: HTMLElement) {
		return new Promise((resolve) => {
			const observer = new IntersectionObserver(([entry]) => {
				resolve(entry.intersectionRatio >= this.threshold);
				observer.disconnect();
			});

			observer.observe(element);
		});
	}

	private createObserver() {
		const options = {
			root: document.querySelector(this.root),
			rootMargin: this.rootMargin,
			threshold: this.threshold
		};

		this.observer = new IntersectionObserver((entries, observer) => {
			entries.forEach((entry) => {
				const currentY = entry.boundingClientRect.y;
				const currentRatio = entry.intersectionRatio;
				const isIntersecting = entry.isIntersecting;
				// Scrolling down/up
				if (currentY < this.previousY) {
					if (currentRatio > this.previousRatio && isIntersecting) {
						// observer.unobserve(entry.target);
						this.subject$.next({
							entry: entry,
							observer: observer,
							state: "ENTER",
							scrollDirection: "DOWN"
						});
					} else {
						this.subject$.next({
							entry: entry,
							observer: observer,
							state: "LEAVE",
							scrollDirection: "DOWN"
						});
					}
				} else if (currentY > this.previousY && isIntersecting) {
					if (currentRatio < this.previousRatio) {
						this.subject$.next({
							entry: entry,
							observer: observer,
							state: "LEAVE",
							scrollDirection: "UP"
						});
					} else {
						// observer.unobserve(entry.target);
						this.subject$.next({
							entry: entry,
							observer: observer,
							state: "ENTER",
							scrollDirection: "UP"
						});
					}
				}
				this.previousY = currentY;
				this.previousRatio = currentRatio;
			});
		}, options);
	}

	private startObservingElements() {
		if (!this.observer) {
			return;
		}

		this.observer.observe(this.element.nativeElement);

		this.subject$
			.pipe(delay(this.debounceTime), filter(Boolean))
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			.subscribe(({ entry, observer, state, scrollDirection }) => {
				const target = entry.target as HTMLElement;
				this.visible.emit({ target: target, state: state, scrollDirection: scrollDirection });
			});
	}
}
