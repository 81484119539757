/* eslint-disable max-len */
import { HttpClient } from "@angular/common/http";
import { Injectable, WritableSignal, signal } from "@angular/core";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Observable, Subscription, combineLatest, from, of } from "rxjs";
import { distinctUntilChanged, map, switchMap, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TypeCustomFields } from "../enums/type-custom-fields";
import { TypeModule } from "../enums/type-module";
import {
	ICustomFieldData,
	IDocument,
	IEvent,
	IEventUser,
	IFullCustomField,
	ILanguage,
	ILocation,
	IModule,
	ITrack
} from "../interfaces";
import { ISchedule, IScheduleByTrackDate } from "../interfaces/schedules.interfaces";
import { CustomFieldsService } from "./custom-fields.service";
import { FirestoreService } from "./firestore.service";
import { UtilityService } from "./utility.service";
import { ISearchFilter } from "../interfaces/search.interfaces";
import { StorageService } from "./storage.service";
import { filterSearch } from "../helpers-functions/filter-search";
import { Store } from "@ngrx/store";
import { getInitSpecificEventDatasPart, getNetworkStatus } from "../selectors/utility.selectors";
import { getModulesByType, getSpecificModule } from "../selectors/modules.selectors";
import { where } from "@angular/fire/firestore";
import { getCurrentEventUser } from "../selectors/auth.selectors";
import { getCurrentEvent } from "../selectors/events.selectors";
import { getTracks } from "../selectors/generics-modules-data.selectors";
import { getAllAccessiblesInteractivity } from "../selectors/interactivity.selectors";
import { IICS } from "../interfaces/ics.interfaces";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";

@Injectable({
	providedIn: "root"
})
export class SchedulesService {
	private networkSub: Subscription;

	datasStateSubs: { eventId: string; moduleId: string; datasSub: Subscription }[] = [];

	sessionsByModulesState: WritableSignal<any> = signal({});
	sessionsOfAllModules: WritableSignal<ISchedule[] | null> = signal(null);
	lastTimeGetSessions: WritableSignal<number> = signal(null);

	constructor(
		private SStorage: StorageService,
		private SFirestore: FirestoreService,
		private https: HttpClient,
		private SCustomFields: CustomFieldsService,
		private platform: Platform,
		private SUtility: UtilityService,
		private STranslate: TranslateService,
		private store: Store
	) {}

	isSubscriptionOfModuleActivated(eventId: string, moduleId: string) {
		return this.datasStateSubs.find(
			(state) =>
				state.eventId === eventId && state.moduleId === moduleId && state.datasSub && !state.datasSub.closed
		)
			? true
			: false;
	}

	initDatasOfAllModules(eventId: string) {
		this.checkSubOnWrongEvent(eventId);

		this.store
			.select(getModulesByType(TypeModule.SCHEDULE))
			.pipe(take(1))
			.subscribe((modules) => {
				if (modules.length === 0) {
					this.initSessionsStore();
				}
				modules.forEach((module) => {
					this.initDatasOfOneModule(eventId, module.uid);
				});
			});
	}

	initDatasOfOneModule(eventId: string, moduleId: string) {
		this.checkSubOnWrongEvent(eventId);

		const checkModuleSub = this.datasStateSubs.find(
			(state) => state.eventId === eventId && state.moduleId === moduleId
		);
		if (!checkModuleSub) {
			this.store
				.select(getSpecificModule(moduleId))
				.pipe(take(1))
				.subscribe((module) => {
					if (module) {
						const datasSub = this.SFirestore.valueChangesDocuments(
							`events/${module.eventId}/modules/${module.uid}/schedules`,
							[]
						)
							.pipe(distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)))
							.subscribe((sessions) => {
								this.rebuildModuleSessions(module.eventId, module.uid, sessions);
							});
						this.datasStateSubs.push({
							eventId: eventId,
							moduleId: module.uid,
							datasSub: datasSub
						});
					}
				});
		} else if (checkModuleSub && checkModuleSub.datasSub && checkModuleSub.datasSub.closed) {
			this.store
				.select(getSpecificModule(moduleId))
				.pipe(take(1))
				.subscribe((module) => {
					if (module) {
						checkModuleSub.datasSub = this.SFirestore.valueChangesDocuments(
							`events/${module.eventId}/modules/${module.uid}/schedules`,
							[]
						)
							.pipe(distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)))
							.subscribe((sessions) => {
								this.rebuildModuleSessions(eventId, module.uid, sessions);
							});
					}
				});
		}
	}

	rebuildModuleSessions(eventId: string, moduleId: string, datas: ISchedule[]) {
		const state = this.sessionsByModulesState();
		state[moduleId] = datas;
		this.sessionsByModulesState.set({ ...state });
		let allDatas = this.sessionsOfAllModules() ? this.sessionsOfAllModules() : [];
		allDatas = allDatas.filter((session) => session.moduleId !== moduleId).concat(datas);
		this.sessionsOfAllModules.set([...allDatas]);
		// this.updateLocalFile(eventId, "schedules.json", allDatas);
		this.lastTimeGetSessions.set(DateTime.local().toMillis());

		this.initSessionsStore();
	}

	initSessionsStore() {
		this.store
			.select(getInitSpecificEventDatasPart("initSchedules"))
			.pipe(take(1))
			.subscribe((init) => {
				if (!init) {
					this.store.dispatch(
						InitSpecificEventDatasPart({
							part: "initSchedules",
							payload: true
						})
					);
				}
			});
	}

	cleanOneSubscription(moduleId: string) {
		const checkExist = this.datasStateSubs.find((state) => state.moduleId === moduleId);

		if (checkExist && checkExist.datasSub && !checkExist.datasSub.closed) {
			checkExist.datasSub.unsubscribe();
		}

		this.datasStateSubs = this.datasStateSubs.filter((state) => state.moduleId !== moduleId);
	}

	cleanAllSubscriptions() {
		this.datasStateSubs.forEach((state) => {
			if (state && state.datasSub && !state.datasSub.closed) {
				state.datasSub.unsubscribe();
			}
		});

		this.datasStateSubs = [];
	}

	checkSubOnWrongEvent(eventId: string) {
		this.datasStateSubs.forEach((state) => {
			if (state && state.eventId !== eventId) {
				if (state.datasSub && !state.datasSub.closed) {
					state.datasSub.unsubscribe();
				}
			}
		});

		this.datasStateSubs = this.datasStateSubs.filter((state) => state.datasSub && !state.datasSub.closed);
	}

	/**
	 *
	 *
	 * End of new functions
	 *
	 *
	 */

	unsubscribeAll() {
		[this.networkSub].forEach((sub) => {
			if (sub) sub.unsubscribe();
		});
		this.cleanAllSubscriptions();
	}

	/**
	 *
	 *
	 * Specific functions for datas
	 *
	 *
	 */

	getPersonalScheduleSessions(eventId: string, eventUserId: string) {
		return from(
			this.SFirestore.getDocumentsCollectionGroup(`schedules`, [
				where("eventId", "==", eventId),
				where("registeredUsers", "array-contains", eventUserId)
			])
		).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as ISchedule)));
	}

	/**
	 * Get all sessions of all modules from local state of file if state don't exist
	 * @returns
	 */
	getAllSessionsOfModulesFromFile(): Observable<ISchedule[]> {
		return this.sessionsOfAllModules()
			? of(this.sessionsOfAllModules())
			: this.store.select(getModulesByType(TypeModule.SCHEDULE)).pipe(
					switchMap((modules) => {
						if (modules.length === 0) {
							return of([]);
						}
						const obsArray: Observable<any[]>[] = [];
						modules.forEach((module) => {
							obsArray.push(
								this.getSessionsOfModuleWithFilters(module.eventId, module.uid, null, false).pipe(
									switchMap((result: any) =>
										of(result && result.datas.length > 0 ? (result.datas as ISchedule[]) : [])
									)
								)
							);
						});

						return combineLatest(obsArray).pipe(
							switchMap((allResults) => {
								return of(_.flatten(allResults) as ISchedule[]);
							})
						);
					})
			  );
	}

	/**
	 * Get sessions of specific module with filters from state or file if state don't exist
	 * @param eventId
	 * @param moduleId
	 * @param filters
	 * @returns
	 */
	getSessionsOfModuleWithFilters(eventId: string, moduleId: string, filters: ISearchFilter, forceReload: boolean) {
		return combineLatest([
			this.store.select(getSpecificModule(moduleId)).pipe(take(1)),
			this.store.select(getCurrentEventUser).pipe(take(1)),
			this.sessionsByModulesState() && this.sessionsByModulesState()[moduleId] && !forceReload
				? of(this.sessionsByModulesState()[moduleId])
				: of([])
		]).pipe(
			take(1),
			switchMap((results) => {
				const module = results[0];
				const eventUser = results[1];
				if (this.sessionsByModulesState() && this.sessionsByModulesState()[moduleId] && !forceReload) {
					const sessions: ISchedule[] = this.sessionsByModulesState()[moduleId];
					const sessionsFiltered = sessions.filter(
						(session) =>
							session.moduleId === module.uid &&
							(session.visibility || session.visibility === undefined) &&
							module &&
							module.options &&
							(!module.options.viewOnlyGroupsContent ||
								(module.options.viewOnlyGroupsContent &&
									eventUser &&
									session.groups.some((grpId) => eventUser.groups.includes(grpId))))
					);
					return of(filterSearch(filters, sessionsFiltered));
				} else {
					return of({
						totalItems: 0,
						datas: []
					});
				}
			})
		);
	}

	/**
	 * Get sessions and dates of specific module with filters from state or file if state don't exist
	 * @param eventId
	 * @param moduleId
	 * @param filters
	 * @param currentLanguage
	 * @returns
	 */
	getSessionsAndDatesOfModuleWithFilters(
		eventId: string,
		moduleId: string,
		filters: ISearchFilter,
		currentLanguage: string
	) {
		return combineLatest([
			this.store.select(getCurrentEvent),
			this.store.select(getSpecificModule(moduleId)).pipe(take(1)),
			this.store.select(getCurrentEventUser).pipe(take(1)),
			this.sessionsByModulesState() && this.sessionsByModulesState()[moduleId]
				? of(this.sessionsByModulesState()[moduleId])
				: of([])
		]).pipe(
			take(1),
			switchMap((results) => {
				const event = results[0];
				const module = results[1];
				const eventUser = results[2];
				const datasFile = results[3];
				if (this.sessionsByModulesState() && this.sessionsByModulesState()[moduleId]) {
					const sessions: ISchedule[] = this.sessionsByModulesState()[moduleId];
					const sessionsFiltered = sessions.filter(
						(session) =>
							session.moduleId === module.uid &&
							(session.visibility || session.visibility === undefined) &&
							module &&
							module.options &&
							(!module.options.viewOnlyGroupsContent ||
								(module.options.viewOnlyGroupsContent &&
									eventUser &&
									session.groups.some((grpId) => eventUser.groups.includes(grpId))))
					);
					const filteredDatas = filterSearch(filters, sessionsFiltered);
					const dates = this.getDatesOfSessionsForModuleWithSessions(
						event,
						eventUser,
						currentLanguage,
						filteredDatas.datas
					);
					return of({
						...filteredDatas,
						dates: dates
					});
				} else if (datasFile.status === "OK") {
					const sessions: ISchedule[] = datasFile.datas;
					const sessionsFiltered = sessions.filter(
						(session) =>
							session.moduleId === module.uid &&
							(session.visibility || session.visibility === undefined) &&
							module &&
							module.options &&
							(!module.options.viewOnlyGroupsContent ||
								(module.options.viewOnlyGroupsContent &&
									eventUser &&
									session.groups.some((grpId) => eventUser.groups.includes(grpId))))
					);
					const filteredDatas = filterSearch(filters, sessionsFiltered);
					const dates = this.getDatesOfSessionsForModuleWithSessions(
						event,
						eventUser,
						currentLanguage,
						filteredDatas.datas
					);
					return of({
						...filteredDatas,
						dates: dates
					});
				} else {
					return of({
						totalItems: 0,
						datas: []
					});
				}
			})
		);
	}

	getSpecificSession(eventId: string, moduleId: string, sessionId: string) {
		return this.store.select(getNetworkStatus).pipe(
			take(1),
			switchMap((status: boolean) => {
				return this.SFirestore.valueChangesDocument(
					`events/${eventId}/modules/${moduleId}/schedules/${sessionId}`
				).pipe(map((doc) => doc as ISchedule));
			})
		);
	}

	buildSessionsByTracks = (sessions: ISchedule[], tracks: ITrack[], dates: string[]) => {
		const sessionsByTrackDatesFiltered: IScheduleByTrackDate[] = dates.map((date) => {
			return {
				date: date,
				tracks: []
			};
		});
		sessionsByTrackDatesFiltered.forEach((trackDate) => {
			sessions.forEach((session) => {
				if (DateTime.fromISO(session.startDate).hasSame(DateTime.fromISO(trackDate.date), "day")) {
					if (session.tracks.length > 0) {
						session.tracks.forEach((trackId) => {
							const track = tracks.find((track) => track.uid === trackId);
							if (track) {
								const groupTrack = trackDate.tracks.find(
									(group) => group.track && group.track.uid === track.uid
								);
								if (!groupTrack) {
									trackDate.tracks.push({
										beginDate: "",
										endDate: "",
										mainLocation: null,
										sessions: [session],
										track: track ? track : null
									});
								} else {
									groupTrack.sessions.push(session);
								}
							}
						});
					} else {
						trackDate.tracks.push({
							beginDate: session.startDate,
							endDate: session.endDate ? session.endDate : "",
							mainLocation: null,
							sessions: [session],
							track: null
						});
					}
				}
			});
		});
		sessionsByTrackDatesFiltered.map((trackDate) => {
			trackDate.tracks
				.map((groupTrack) => {
					groupTrack.sessions.sort((a, b) =>
						a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0
					);
					groupTrack.beginDate = groupTrack.sessions.length > 0 ? groupTrack.sessions[0].startDate : "";
					groupTrack.endDate =
						groupTrack.sessions.length > 0 && groupTrack.sessions[groupTrack.sessions.length - 1].endDate
							? groupTrack.sessions[groupTrack.sessions.length - 1].endDate
							: "";
					const locations: ILocation[] = [];
					groupTrack.sessions.forEach((session) => {
						if (session.locations.length > 0) {
							session.locations.forEach((locationId) => {
								const location = locations.find((loc) => loc.uid === locationId);
								if (location) {
									if (!locations.find((loc) => loc.uid === location.uid)) {
										locations.push(location);
									}
								}
							});
						}
					});

					if (locations.length === 1) {
						groupTrack.mainLocation = locations[0];
					}

					return groupTrack;
				})
				.sort((a, b) => (a.beginDate > b.beginDate ? 1 : a.beginDate < b.beginDate ? -1 : 0));
		});
		sessionsByTrackDatesFiltered.map((trackDate) => {
			trackDate.tracks
				.map((groupTrack) => {
					groupTrack.sessions.sort((a, b) =>
						a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0
					);
					groupTrack.beginDate = groupTrack.sessions.length > 0 ? groupTrack.sessions[0].startDate : "";
					groupTrack.endDate =
						groupTrack.sessions.length > 0 && groupTrack.sessions[groupTrack.sessions.length - 1].endDate
							? groupTrack.sessions[groupTrack.sessions.length - 1].endDate
							: "";
					const locations: ILocation[] = [];
					groupTrack.sessions.forEach((session) => {
						if (session.locations.length > 0) {
							session.locations.forEach((locationId) => {
								const location = locations.find((loc) => loc.uid === locationId);
								if (location) {
									if (!locations.find((loc) => loc.uid === location.uid)) {
										locations.push(location);
									}
								}
							});
						}
					});

					if (locations.length === 1) {
						groupTrack.mainLocation = locations[0];
					}

					return groupTrack;
				})
				.sort((a, b) => (a.beginDate > b.beginDate ? 1 : a.beginDate < b.beginDate ? -1 : 0));
		});
		sessionsByTrackDatesFiltered.map((trackDate) => {
			trackDate.tracks = trackDate.tracks.sort((a, b) =>
				a.beginDate > b.beginDate ? 1 : a.beginDate < b.beginDate ? -1 : 0
			);
		});

		return sessionsByTrackDatesFiltered;
	};

	/**
	 * Check for first and last
	 */
	sessionIsLastPrev = (eventId: string, moduleId: string, currentSessionId: string) => {
		return combineLatest([
			this.store.select(getSpecificModule(moduleId)).pipe(take(1)),
			this.store.select(getCurrentEventUser).pipe(take(1)),
			this.getSessionsOfModuleWithFilters(eventId, moduleId, null, false)
		]).pipe(
			take(1),
			switchMap((results) => {
				const module = results[0];
				const eventUser = results[1];
				const sessions = results[2].datas
					.filter((session) => session.visibility || session.visibility === undefined)
					.filter(
						(session) =>
							!module.options.viewOnlyGroupsContent ||
							(module.options.viewOnlyGroupsContent &&
								eventUser &&
								session.groups.some((grpId) => eventUser.groups.includes(grpId)))
					)
					.sort((a, b) => (a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0));
				if (!sessions || sessions.length <= 1) {
					return of({
						status: "error",
						last: false,
						first: false
					});
				}
				const currentIndex = sessions.indexOf(sessions.find((session) => session.uid == currentSessionId));
				const response = {
					status: "valid",
					last: false,
					first: false
				};

				if (currentIndex == -1) {
					return of({
						status: "error",
						last: false,
						first: false
					});
				}
				if (currentIndex === sessions.length - 1) {
					response.last = true;
				}
				if (currentIndex === 0) {
					response.first = true;
				}

				return of(response);
			})
		);
	};

	/**
	 * Return next session
	 */
	getNextSession = (eventId: string, moduleId: string, currentSessionId: string) => {
		return combineLatest([
			this.store.select(getSpecificModule(moduleId)).pipe(take(1)),
			this.store.select(getCurrentEventUser).pipe(take(1)),
			this.store.select(getTracks),
			this.getSessionsOfModuleWithFilters(eventId, moduleId, null, false)
		]).pipe(
			take(1),
			switchMap((results) => {
				const module = results[0];
				const eventUser = results[1];
				const tracks = results[2];
				const filteredSchedules = results[3].datas
					.filter(
						(session) =>
							session.moduleId === moduleId && (session.visibility || session.visibility === undefined)
					)
					.filter(
						(session) =>
							!module.options.viewOnlyGroupsContent ||
							(module.options.viewOnlyGroupsContent &&
								eventUser &&
								session.groups.some((grpId) => eventUser.groups.includes(grpId)))
					)
					.sort((a, b) => (a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0));
				const sessions = module.options.activateTracksFiltering
					? this.filterByTracks(tracks, filteredSchedules)
					: filteredSchedules;
				const currentIndex = sessions.indexOf(sessions.find((session) => session.uid == currentSessionId));

				if (currentIndex === -1) {
					return of({
						status: "error",
						session: null
					});
				} else if (currentIndex === sessions.length - 1) {
					return of({
						status: "last",
						session: sessions[currentIndex]
					});
				} else {
					return of({
						status: "valid",
						session: sessions[currentIndex + 1]
					});
				}
			})
		);
	};

	/**
	 * Return previous session
	 */
	getPrevSession = (eventId: string, moduleId: string, currentSessionId: string) => {
		return combineLatest([
			this.store.select(getSpecificModule(moduleId)).pipe(take(1)),
			this.store.select(getCurrentEventUser).pipe(take(1)),
			this.store.select(getTracks),
			this.getSessionsOfModuleWithFilters(eventId, moduleId, null, false)
		]).pipe(
			take(1),
			switchMap((results) => {
				const module = results[0];
				const eventUser = results[1];
				const tracks = results[2];
				const filteredSessions = results[3].datas
					.filter(
						(session) =>
							session.moduleId === moduleId && (session.visibility || session.visibility === undefined)
					)
					.filter(
						(session) =>
							!module.options.viewOnlyGroupsContent ||
							(module.options.viewOnlyGroupsContent &&
								eventUser &&
								session.groups.some((grpId) => eventUser.groups.includes(grpId)))
					)
					.sort((a, b) => (a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0));
				const sessions = module.options.activateTracksFiltering
					? this.filterByTracks(tracks, filteredSessions)
					: filteredSessions;
				const currentIndex = sessions.indexOf(sessions.find((session) => session.uid == currentSessionId));

				if (currentIndex === -1) {
					return of({
						status: "error",
						session: null
					});
				} else if (currentIndex === 0) {
					return of({
						status: "first",
						session: sessions[currentIndex]
					});
				} else {
					return of({
						status: "valid",
						session: sessions[currentIndex - 1]
					});
				}
			})
		);
	};

	checkFullWidth = (session: ISchedule) => {
		return combineLatest([
			this.store.select(getCurrentEventUser),
			this.store.select(getAllAccessiblesInteractivity(session))
		]).pipe(
			take(1),
			switchMap((results) => {
				const eventUser = results[0];
				const interactivityState = results[1];
				const feedbacks = interactivityState.feedbacksState.filter(
					(feedback) =>
						feedback.link.linkType === 0 ||
						(feedback.link.linkType === 1 && feedback.link.linkedModules.includes(session.moduleId)) ||
						(feedback.link.linkType === 2 && feedback.link.linkedSpecifics.includes(session.uid))
				);
				const askQuestions = interactivityState.asksQuestionsState
					.filter(
						(askQuestions) =>
							!askQuestions.groupsLink.linked ||
							(askQuestions.groupsLink.linked &&
								eventUser &&
								askQuestions.groupsLink.groups.some((groupId) => eventUser.groups.includes(groupId)))
					)
					.filter(
						(askQuestion) =>
							askQuestion.schedulesLink.linked &&
							(askQuestion.schedulesLink.linkType === 0 ||
								(askQuestion.schedulesLink.linkType === 1 &&
									askQuestion.schedulesLink.linkedModules.includes(session.moduleId)) ||
								(askQuestion.schedulesLink.linkType === 2 &&
									askQuestion.schedulesLink.linkedSchedules.includes(session.uid)))
					);
				const surveys = interactivityState.surveysState
					.filter(
						(survey) =>
							!survey.groupsLink.linked ||
							(survey.groupsLink.linked &&
								eventUser &&
								survey.groupsLink.groups.some((groupId) => eventUser.groups.includes(groupId)))
					)
					.filter(
						(survey) =>
							survey.schedulesLink.linked &&
							(survey.schedulesLink.linkType === 0 ||
								(survey.schedulesLink.linkType === 1 &&
									survey.schedulesLink.linkedModules.includes(session.moduleId)) ||
								(survey.schedulesLink.linkType === 2 &&
									survey.schedulesLink.linkedSchedules.includes(session.uid)))
					);
				const quizs = interactivityState.quizsState
					.filter(
						(quiz) =>
							!quiz.groupsLink.linked ||
							(quiz.groupsLink.linked &&
								eventUser &&
								quiz.groupsLink.groups.some((groupId) => eventUser.groups.includes(groupId)))
					)
					.filter(
						(quiz) =>
							quiz.schedulesLink.linked &&
							(quiz.schedulesLink.linkType === 0 ||
								(quiz.schedulesLink.linkType === 1 &&
									quiz.schedulesLink.linkedModules.includes(session.moduleId)) ||
								(quiz.schedulesLink.linkType === 2 &&
									quiz.schedulesLink.linkedSchedules.includes(session.uid)))
					);
				const externalsInteractivity = interactivityState.externalsInteractivityState
					.filter(
						(externalInteractivity) =>
							!externalInteractivity.groupsLink.linked ||
							(externalInteractivity.groupsLink.linked &&
								eventUser &&
								externalInteractivity.groupsLink.groups.some((groupId) =>
									eventUser.groups.includes(groupId)
								))
					)
					.filter(
						(externalInteractivity) =>
							externalInteractivity.schedulesLink.linked &&
							(externalInteractivity.schedulesLink.linkType === 0 ||
								(externalInteractivity.schedulesLink.linkType === 1 &&
									externalInteractivity.schedulesLink.linkedModules.includes(session.moduleId)) ||
								(externalInteractivity.schedulesLink.linkType === 2 &&
									externalInteractivity.schedulesLink.linkedSchedules.includes(session.uid)))
					);
				const discussionsGroups = interactivityState.chatsState
					.filter(
						(chat) =>
							chat.options &&
							chat.options.groupsLink &&
							(!chat.options.groupsLink.linked ||
								(chat.options.groupsLink.linked &&
									eventUser &&
									chat.options.groupsLink.groups.some((groupId) =>
										eventUser.groups.includes(groupId)
									)))
					)
					.filter(
						(chat) =>
							chat.type === 1 &&
							chat.options &&
							chat.options.schedulesLink.linked &&
							(chat.options.schedulesLink.linkType === 0 ||
								(chat.options.schedulesLink.linkType === 1 &&
									chat.options.schedulesLink.linkedModules.includes(session.moduleId)) ||
								(chat.options.schedulesLink.linkType === 2 &&
									chat.options.schedulesLink.linkedSpecifics.includes(session.uid)))
					);
				return of(
					(askQuestions.length === 0 || askQuestions.every((askQuestion) => !askQuestion.visibility)) &&
						(feedbacks.length === 0 || feedbacks.every((feedback) => !feedback.visibility)) &&
						(surveys.length === 0 || surveys.every((survey) => !survey.visibility)) &&
						(quizs.length === 0 || quizs.every((quiz) => !quiz.visibility)) &&
						(externalsInteractivity.length === 0 ||
							externalsInteractivity.every(
								(externalInteractivity) => !externalInteractivity.visibility
							)) &&
						(discussionsGroups.length === 0 || discussionsGroups.every((chat) => !chat.visibility))
				);
			})
		);
	};

	/**
	 * Filter schedules by tracks
	 * @param tracks
	 * @param schedules
	 * @returns
	 */
	filterByTracks = (tracks: ITrack[], schedules: ISchedule[]) => {
		const sessionsByTrackFiltered: {
			beginDate: string;
			endDate: string;
			mainLocation: any;
			sessions: ISchedule[];
			track: ITrack;
		}[] = [];
		schedules.forEach((session) => {
			if (session.tracks.length > 0) {
				session.tracks.forEach((trackId) => {
					const track = tracks.find((track) => track.uid === trackId);
					const groupTrack = sessionsByTrackFiltered.find(
						(group) => group.track && group.track.uid === track.uid
					);
					if (!groupTrack) {
						sessionsByTrackFiltered.push({
							beginDate: "",
							endDate: "",
							mainLocation: null,
							sessions: [session],
							track: track ? track : null
						});
					} else {
						groupTrack.sessions.push(session);
					}
				});
			} else {
				sessionsByTrackFiltered.push({
					beginDate: session.startDate,
					endDate: session.endDate ? session.endDate : null,
					mainLocation: null,
					sessions: [session],
					track: null
				});
			}
		});
		sessionsByTrackFiltered
			.map((groupTrack) => {
				groupTrack.sessions.sort((a, b) =>
					a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0
				);
				groupTrack.beginDate = groupTrack.sessions.length > 0 ? groupTrack.sessions[0].startDate : "";
				groupTrack.endDate =
					groupTrack.sessions.length > 0 && groupTrack.sessions[groupTrack.sessions.length - 1].endDate
						? groupTrack.sessions[groupTrack.sessions.length - 1].endDate
						: "";

				return groupTrack;
			})
			.sort((a, b) => (a.beginDate > b.beginDate ? 1 : a.beginDate < b.beginDate ? -1 : 0));
		let allSessionsSorted: ISchedule[] = [];
		sessionsByTrackFiltered.forEach((groupTrack) => {
			allSessionsSorted = allSessionsSorted.concat(groupTrack.sessions);
		});
		return allSessionsSorted;
	};

	/************************************************************************************************
                                        ICS functions
    ************************************************************************************************/

	/**
	 * Generate ics file
	 * @param eventId
	 * @param moduleId
	 * @param sessionId
	 * @param language
	 * @param offsetHours
	 * @returns
	 */
	generateIcsFile(eventId: string, moduleId: string, sessionId: string, language: string, offsetHours: number) {
		return this.https.post(
			environment.platform.apiV2BaseUrl
				? "https://schedules-generateIcsFile" + environment.platform.apiV2BaseUrl
				: environment.platform.apiBaseUrl + "schedules-generateIcsFile",
			{
				eventId: eventId,
				moduleId: moduleId,
				sessionId: sessionId,
				language: language,
				offsetHours: offsetHours
			}
		);
	}

	/**
	 * generateSessionIcsFileFromClient
	 * @param session
	 * @param language
	 */
	generateSessionIcsFileFromClient(session: ISchedule, language: string) {
		let sessionEndDate: Date;
		if (!session.endDate || session.endDate === "") {
			//  add 1 hour to sessionStartDate
			sessionEndDate = Object.assign(new Date(session.startDate));
			sessionEndDate.setHours(sessionEndDate.getHours() + 1);
		} else {
			sessionEndDate = new Date(session.endDate);
		}

		const event = {
			summary: session.name[language],
			location: "",
			description: session.description[language] ? session.description[language] : "",
			start: this.formatDateTime(new Date(session.startDate)),
			end: this.formatDateTime(sessionEndDate),
			organizer: "",
			attendee: ""
		};

		event.description = this.convertHtmlToText(event.description);

		let icsFileContent = `BEGIN:VCALENDAR
		VERSION:2.0
		PRODID:${environment.platform.appName}
		BEGIN:VEVENT
		UID:${event.summary}
		SUMMARY:${event.summary}
		LOCATION:${event.location}
		DESCRIPTION:${event.description}
		DTSTAMP:${event.start}
		DTSTART:${event.start}
		DTEND:${event.end}
		ORGANIZER:${event.organizer}
		ATTENDEE:${event.attendee}
		END:VEVENT
		END:VCALENDAR`;

		icsFileContent = icsFileContent.replace(/\t/g, "");

		if (!this.platform.is("mobileweb")) {
			const blob = new Blob([icsFileContent], { type: "charset=utf-8" });
			// convent blob to base64 text string
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				reader.result;
				this.downloadFile(`${session.name[language]}.ics`, icsFileContent);
			};
		} else {
			// for web browser
			const element = document.createElement("a");
			element.setAttribute("href", "data:text/calendar;charset=utf-8," + encodeURIComponent(icsFileContent));
			element.setAttribute("download", session.name[language]);
			element.setAttribute("target", "_blank");
			element.style.display = "none";
			element.click();
		}
	}

	/**
	 * generateSessionsIcsFileFromClient
	 * @param module
	 * @param sessions
	 * @param language
	 */
	generateSessionsIcsFileFromClient(module: IModule, sessions: ISchedule[], language: string) {
		let icsFileContent = `BEGIN:VCALENDAR
		VERSION:2.0
		PRODID:${environment.platform.appName}
		`;

		sessions.forEach((session) => {
			let sessionEndDate: Date;
			if (!session.endDate || session.endDate === "") {
				//  add 1 hour to sessionStartDate
				sessionEndDate = Object.assign(new Date(session.startDate));
				sessionEndDate.setHours(sessionEndDate.getHours() + 1);
			} else {
				sessionEndDate = new Date(session.endDate);
			}

			const event = {
				summary: session.name[language],
				location: "",
				description: session.description[language] ? session.description[language] : "",
				start: this.formatDateTime(new Date(session.startDate)),
				end: this.formatDateTime(sessionEndDate),
				organizer: "",
				attendee: ""
			};

			event.description = this.convertHtmlToText(event.description);
			icsFileContent += `BEGIN:VEVENT
			UID:${event.summary}
			SUMMARY:${event.summary}
			LOCATION:${event.location}
			DESCRIPTION:${event.description}
			DTSTAMP:${event.start}
			DTSTART:${event.start}
			DTEND:${event.end}
			ORGANIZER:${event.organizer}
			ATTENDEE:${event.attendee}
			END:VEVENT\n`;

			icsFileContent = icsFileContent.replace(/\t/g, "");
		});

		icsFileContent += `END:VCALENDAR`;

		if (!this.platform.is("mobileweb")) {
			const blob = new Blob([icsFileContent], { type: "charset=utf-8" });
			// convent blob to base64 text string
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				reader.result;
				this.downloadFile(`${module.name[language]}.ics`, icsFileContent);
			};
		} else {
			// for web browser
			const element = document.createElement("a");
			element.setAttribute("href", "data:text/calendar;charset=utf-8," + encodeURIComponent(icsFileContent));
			element.setAttribute("download", module.name[language]);
			element.setAttribute("target", "_blank");
			element.style.display = "none";
			element.click();
		}
	}

	/**
	 * generateEventIcsFileFromClient
	 * @param session
	 * @param language
	 */
	generateEventIcsFileFromClient(event: IEvent, calendarEvent?: IICS) {
		const icsEvent = {
			summary: calendarEvent ? calendarEvent.title : event.title,
			location: "",
			description: calendarEvent ? calendarEvent.description : "",
			start: this.formatDateTime(new Date(calendarEvent ? calendarEvent.start : event.startDate)),
			end: this.formatDateTime(new Date(calendarEvent ? calendarEvent.end : event.endDate)),
			organizer: calendarEvent ? calendarEvent.organizer : event.client ? event.client.name : "",
			attendee: ""
		};

		icsEvent.description = this.convertHtmlToText(calendarEvent ? calendarEvent.description : icsEvent.description);

		let icsFileContent = `BEGIN:VCALENDAR
		  VERSION:2.0
          PRODID:${environment.platform.appName}
		  BEGIN:VEVENT
		  UID:${icsEvent.summary}
		  SUMMARY:${icsEvent.summary}
		  LOCATION:${icsEvent.location}
		  DESCRIPTION:${icsEvent.description}
		  DTSTAMP:${icsEvent.start}
		  DTSTART:${icsEvent.start}
		  DTEND:${icsEvent.end}
		  ORGANIZER:${icsEvent.organizer}
		  ATTENDEE:${icsEvent.attendee}
		  END:VEVENT
		  END:VCALENDAR`;

		// eslint-disable-next-line no-useless-escape
		icsFileContent = icsFileContent.replace(/\ /g, "");
		icsFileContent = icsFileContent.replace(/\t/g, "");

		if (!this.platform.is("mobileweb")) {
			const blob = new Blob([icsFileContent], { type: "charset=utf-8" });
			// convent blob to base64 text string
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				reader.result;
				this.downloadFile(`${event.title}.ics`, icsFileContent);
			};
		} else {
			// for mobile web browser
			const element = document.createElement("a");
			element.setAttribute("href", "data:text/calendar;charset=utf-8," + encodeURIComponent(icsFileContent));
			element.setAttribute("download", event.title);
			element.setAttribute("target", "_blank");
			element.style.display = "none";
			element.click();
		}
	}

	/**
	 * Generate all ics file of a schedule
	 * @param eventId
	 * @param moduleId
	 * @param language
	 * @param offsetHours
	 * @returns
	 */
	generateAllIcsFile(eventId: string, moduleId: string, language: string, offsetHours: number) {
		return this.https.post(
			environment.platform.apiV2BaseUrl
				? "https://schedules-generateAllIcsFile" + environment.platform.apiV2BaseUrl
				: environment.platform.apiBaseUrl + "schedules-generateAllIcsFile",
			{
				eventId: eventId,
				moduleId: moduleId,
				language: language,
				offsetHours: offsetHours
			}
		);
	}

	/**
	 * Update a session
	 * @param eventId
	 * @param moduleId
	 * @param session
	 */
	updateSession(eventId: string, moduleId: string, sessionId: string, session: any) {
		return this.SFirestore.updateDocument(
			`events/${eventId}/modules/${moduleId}/schedules/${sessionId}`,
			_.omit(session, ["eventId", "moduleId", "uid", "identifier", "creationDate"])
		);
	}

	/************************************************************************************************
                                        Schedules functions
    ************************************************************************************************/

	/**
	 * Download schedule sessions ics
	 */
	downloadSchedule(event: IEvent, module: IModule) {
		// Implement a loader
		const currentDate = new Date();
		const offsetHours = currentDate.getTimezoneOffset() / 60;
		this.generateAllIcsFile(event.uid, module.uid, event.language, offsetHours).subscribe((res) => {
			if (res && res["message"] === "success") {
				this.downloadFile("agenda.ics", res["result"]);
			}
		});
	}

	/**
	 * Download a file
	 * @param filename
	 * @param text
	 */
	downloadFile = async (filename: string, data) => {
		if (!(this.platform.is("mobile") && window.innerWidth < 768)) {
			const element = document.createElement("a");
			element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(data));
			element.setAttribute("download", filename);
			element.setAttribute("target", "_blank");
			element.style.display = "none";
			element.click();
		} else {
			try {
				if (this.platform.is("ios")) {
					const fileWriteResult = await Filesystem.writeFile({
						path: filename,
						data: data,
						directory: Directory.Cache,
						encoding: Encoding.UTF8,
						recursive: true
					});
					if (fileWriteResult && fileWriteResult.uri) this.SUtility.shareImage(fileWriteResult.uri);
				} else if (this.platform.is("android")) {
					const fileWriteResult = await Filesystem.writeFile({
						path: `download/${filename}`,
						data: data,
						directory: Directory.Cache,
						encoding: Encoding.UTF8,
						recursive: true
					});
					if (fileWriteResult && fileWriteResult.uri) this.SUtility.shareImage(fileWriteResult.uri);
				}
			} catch (error) {
				this.SUtility.presentToast(
					this.STranslate.instant("toasts.errors.download_error"),
					2000,
					"bottom",
					"danger"
				);
			}
		}
	};

	/**
	 * Paginate dates
	 * @returns
	 */
	getPaginatedDates(sessions: ISchedule[], eventUserSessions: ISchedule[]) {
		return eventUserSessions
			.filter((session, index) => {
				const sessionsBefore = index > 0 ? eventUserSessions.slice(0, index) : [];
				return sessionsBefore.some(
					(sessionBefore) =>
						DateTime.fromISO(sessionBefore.sessionDate).hasSame(
							DateTime.fromISO(session.sessionDate),
							"day"
						) &&
						DateTime.fromISO(sessionBefore.sessionDate).hasSame(
							DateTime.fromISO(session.sessionDate),
							"year"
						) &&
						DateTime.fromISO(sessionBefore.sessionDate).hasSame(
							DateTime.fromISO(session.sessionDate),
							"month"
						)
				)
					? false
					: true;
			})
			.map((session) => session.sessionDate)
			.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
	}

	/**
	 * Check session date and given date
	 * @param session
	 * @param date
	 * @returns
	 */
	checkSessionDate(session: ISchedule, date: string) {
		return DateTime.fromISO(session.sessionDate).hasSame(DateTime.fromISO(date), "day") &&
			DateTime.fromISO(session.sessionDate).hasSame(DateTime.fromISO(date), "year") &&
			DateTime.fromISO(session.sessionDate).hasSame(DateTime.fromISO(date), "month")
			? true
			: false;
	}

	/**
	 * Get first track for session
	 * @param session
	 * @returns
	 */
	getFirstTrackForSession(session: ISchedule, tracks: ITrack[]) {
		return session.tracks.length > 0 ? tracks.find((track) => track.uid === session.tracks[0]) : null;
	}

	/**
	 * Get specific track
	 * @param uid
	 */
	getSpecificTrack(uid: string, tracks: ITrack[]) {
		return tracks.find((track) => track.uid === uid);
	}

	/**
	 * Get specific location
	 * @param uid
	 */
	getSpecificLocation(uid: string, locations: ILocation[]) {
		return locations?.find((location) => location.uid === uid);
	}

	/**
	 * Get all custom fields tags for session
	 * @param session
	 */
	getTagsForSession(session: ISchedule, event: IEvent, computedCustomFields: IFullCustomField[]) {
		return computedCustomFields.filter((custom) => {
			const fieldData = session.customFields.find((fieldData) => fieldData.uid === custom.baseSettings.uid);
			return custom.moduleSettings.canBeTag &&
				fieldData &&
				event &&
				this.SCustomFields.checkValueCustomField(custom.baseSettings.type, fieldData, event.language)
				? true
				: false;
		});
	}

	/**
	 * Get tag value
	 * @param session
	 * @param tag
	 */
	getTagValue(session: ISchedule, tag: IFullCustomField, event: IEvent, eventUserProfile: IEventUser) {
		const fieldData = session.customFields.find((fieldData) => fieldData.uid === tag.baseSettings.uid);

		if (tag.baseSettings.type !== TypeCustomFields.MODULE) {
			return this.SCustomFields.getValueForCustomField(
				tag.baseSettings.type,
				fieldData,
				event.language,
				eventUserProfile.updatedSettings.language
			);
		}
	}

	/**
	 * getEventUsersOfSession
	 * @param session
	 * @param tag
	 */
	getEventUsersOfSession(session: ISchedule, tag: IFullCustomField, eventUsers: IEventUser[]) {
		const itemIds: string[] = [];
		const users: IEventUser[] = [];

		const customFieldsSession = session.customFields
			.slice()
			.filter(
				(cus) =>
					tag.baseSettings.uid === cus.uid &&
					tag.baseSettings.type === TypeCustomFields.MODULE &&
					cus.field.module.items.length > 0
			);

		for (const cus of customFieldsSession) {
			for (const itemId of cus.field.module.items) {
				if (!itemIds.includes(itemId)) {
					itemIds.push(itemId);

					if (
						tag.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
						tag.baseSettings.customFieldModuleType === TypeModule.SPEAKER
					) {
						const user = eventUsers.find((user) => user.uid === itemId);
						user && users.push(user);
					}
				}
			}
		}

		return users;
	}

	/**
	 * getModuleOfSession
	 * @param sessionModleId
	 * @param scheduleModules
	 * @returns
	 */
	getModuleOfSession(sessionModuleId: string, scheduleModules: IModule[]): IModule {
		return (
			sessionModuleId &&
			scheduleModules.length > 0 &&
			scheduleModules.find((module) => module.uid === sessionModuleId)
		);
	}

	/**
	 * getNumberOfAttendeeModuleOfSession
	 * @param session
	 * @param tag
	 */
	getNumberOfItemModuleOfSession(session: ISchedule, computedCustomFields: IFullCustomField[]) {
		const customFieldIds: string[] = [];

		const customFieldsSession = session.customFields
			.slice()
			.filter((cus) =>
				computedCustomFields.find(
					(computedCustomField) =>
						computedCustomField.baseSettings.moduleId === session.moduleId &&
						computedCustomField.baseSettings.uid === cus.uid &&
						computedCustomField.baseSettings.type === TypeCustomFields.MODULE &&
						(computedCustomField.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
							computedCustomField.baseSettings.customFieldModuleType === TypeModule.SPEAKER ||
							computedCustomField.baseSettings.customFieldModuleType === TypeModule.DOCUMENT) &&
						cus.field?.module?.items.length > 0
				)
			);

		customFieldsSession.forEach((cus) => {
			const customField = computedCustomFields.find(
				(computedCustomField) =>
					computedCustomField.baseSettings.moduleId === session.moduleId &&
					computedCustomField.baseSettings.uid === cus.uid
			);

			if (customField && !customFieldIds.includes(customField.baseSettings.uid)) {
				customFieldIds.push(customField.baseSettings.uid);
			}
		});

		return customFieldIds.length;
	}

	/**
	 * checkEventUsersModule
	 * @param tag
	 * @returns
	 */
	checkEventUsersModule(tag: IFullCustomField) {
		return (
			tag.baseSettings.type === TypeCustomFields.MODULE &&
			(tag.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
				tag.baseSettings.customFieldModuleType === TypeModule.SPEAKER)
		);
	}

	/**
	 * getTagsOfEventUser
	 * @description returns tags of eventuser
	 * @param eventUser
	 */
	getTagsOfEventUser(
		event: IEvent,
		eventUser: IEventUser,
		computedCustomFields: IFullCustomField[]
	): ICustomFieldData[] {
		return eventUser.customFields.filter((cus) => {
			return computedCustomFields.some(
				(computedCustomField) =>
					computedCustomField.baseSettings.uid === cus.uid &&
					computedCustomField.moduleSettings.moduleId === eventUser.moduleId &&
					computedCustomField.moduleSettings.canBeTag &&
					((cus.field.multiLanguageText && cus.field.multiLanguageText[event.language] !== "") ||
						(cus.field.text && cus.field.text !== ""))
			);
		});
	}

	/**
	 * getDocumentsOfSession
	 * @param session
	 * @param tag
	 */
	getDocumentsOfSession(session: ISchedule, tag: IFullCustomField, documents: IDocument[]) {
		const itemIds: string[] = [];
		const sessionDocuments: IDocument[] = [];

		const customFieldsSession = session.customFields
			.slice()
			.filter(
				(cus) =>
					tag.baseSettings.uid === cus.uid &&
					tag.baseSettings.type === TypeCustomFields.MODULE &&
					cus.field.module.items.length > 0
			);

		for (const cus of customFieldsSession) {
			for (const itemId of cus.field.module.items) {
				if (!itemIds.includes(itemId)) {
					itemIds.push(itemId);

					if (tag.baseSettings.customFieldModuleType === TypeModule.DOCUMENT) {
						const document = documents.find((document) => document.uid === itemId);
						document && sessionDocuments.push(document);
					}
				}
			}
		}

		return documents;
	}

	/**
	 * checkDocumentsModule
	 * @param tag
	 * @returns boolean
	 */
	checkDocumentsModule(tag: IFullCustomField) {
		return (
			tag.baseSettings.type === TypeCustomFields.MODULE &&
			tag.baseSettings.customFieldModuleType === TypeModule.DOCUMENT
		);
	}

	/************************************************************************************************
                                        Dates functions
    ************************************************************************************************/

	getDatesOfSessionsForModuleWithSessions(
		event: IEvent,
		eventUser: IEventUser,
		currentLanguage: string,
		sessions: ISchedule[]
	) {
		if (sessions.length === 0) {
			return [];
		}
		const dates = [];
		// let allDates = _.uniq(
		sessions.forEach((session) => {
			const date = this.getDateOnConfigTimezone(
				event ? event.timezone : environment.platform.defaultTimezone,
				event ? event.timezoneType : "date",
				eventUser && eventUser.updatedSettings ? eventUser.updatedSettings.timezoneType : event.timezoneType,
				eventUser ? true : false,
				currentLanguage
					? currentLanguage.slice(0, 2).toLowerCase() + "-" + currentLanguage.slice(2, 4).toUpperCase()
					: event.language.slice(0, 2).toLowerCase() + "-" + event.language.slice(2, 4).toUpperCase(),
				session.startDate
			)
				.startOf("day")
				.toISO();
			if (!dates.includes(date)) {
				dates.push(date);
			}
		});
		return dates.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
	}

	compareDates(
		dateOne: string,
		dateTwo: string,
		eventTimezone: string,
		eventTimezoneType: string,
		eventUserTimezoneType: string,
		eventUserExist: boolean,
		locale: string
	) {
		return (
			this.getDateOnConfigTimezone(
				eventTimezone,
				eventTimezoneType,
				eventUserTimezoneType,
				eventUserExist,
				locale,
				dateOne
			).hasSame(
				this.getDateOnConfigTimezone(
					eventTimezone,
					eventTimezoneType,
					eventUserTimezoneType,
					eventUserExist,
					locale,
					dateTwo
				),
				"day"
			) &&
			this.getDateOnConfigTimezone(
				eventTimezone,
				eventTimezoneType,
				eventUserTimezoneType,
				eventUserExist,
				locale,
				dateOne
			).hasSame(
				this.getDateOnConfigTimezone(
					eventTimezone,
					eventTimezoneType,
					eventUserTimezoneType,
					eventUserExist,
					locale,
					dateTwo
				),
				"year"
			) &&
			this.getDateOnConfigTimezone(
				eventTimezone,
				eventTimezoneType,
				eventUserTimezoneType,
				eventUserExist,
				locale,
				dateOne
			).hasSame(
				this.getDateOnConfigTimezone(
					eventTimezone,
					eventTimezoneType,
					eventUserTimezoneType,
					eventUserExist,
					locale,
					dateTwo
				),
				"month"
			)
		);
	}

	getDateOnConfigTimezone(
		eventTimezone: string,
		eventTimezoneType: string,
		eventUserTimezoneType: string,
		eventUserExist: boolean,
		locale: string,
		date: string
	) {
		let newDate: DateTime;
		if (
			(eventTimezoneType === "local" && (!eventUserExist || (eventUserExist && !eventUserTimezoneType))) ||
			(eventTimezoneType === "local" && eventUserExist && eventUserTimezoneType === "local")
		) {
			newDate = DateTime.fromISO(date).setLocale(locale);
		} else if (eventTimezoneType === "local" && eventUserExist && eventUserTimezoneType === "event") {
			newDate = DateTime.fromISO(date).setLocale(locale).setZone(eventTimezone);
		} else {
			newDate = DateTime.fromISO(date && date.split("+").length > 0 ? date.split("+")[0] : date).setLocale(
				locale
			);
		}
		return newDate;
	}

	// Generate ICS part

	/**
	 * addSessionToCalendar
	 * @description add session to calendar
	 * @param event
	 * @param type
	 */
	addSessionToCalendar(session: ISchedule, type: string, eventUser: IEventUser, language: string) {
		const sessionStartDate = new Date(session.startDate);
		let sessionEndDate = session.endDate && session.endDate !== "" ? new Date(session.endDate) : null;
		const sessionStartDateFormatted = sessionStartDate.toISOString().replace(/-|:|\.\d\d\d/g, "");
		if (!sessionEndDate) {
			//  add 1 hour to sessionStartDate
			sessionEndDate = Object.assign(new Date(sessionStartDate));
			sessionEndDate.setHours(sessionEndDate.getHours() + 1);
		}
		const sessionEndDateFormatted = sessionEndDate.toISOString().replace(/-|:|\.\d\d\d/g, "");

		const description: string =
			session.description && session.description[language ? language : eventUser.updatedSettings.language]
				? session.description[language ? language : eventUser.updatedSettings.language]
				: this.findAvaibleItemLanguage(session.description);

		const descriptionFormated = this.convertHtmlToText(description).replaceAll("\\n", "<br>");

		let eventUrl = "";
		switch (type) {
			case "google":
				// eslint-disable-next-line max-len
				eventUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${
					session.name[language ? language : eventUser.updatedSettings.language]
				}&dates=${sessionStartDateFormatted}/${sessionEndDateFormatted}&details=${descriptionFormated}&sf=true&output=xml`;
				break;
			case "outlook":
				// eslint-disable-next-line max-len
				eventUrl = `https://outlook.live.com/owa/?path=/calendar/action/compose&rru=addevent&startdt=${sessionStartDateFormatted}&enddt=${sessionEndDateFormatted}&subject=${
					session.name[language ? language : eventUser.updatedSettings.language]
				}&body=${descriptionFormated}`;
				break;

			case "apple":
				// eslint-disable-next-line max-len
				eventUrl = `https://calendar.apple.com/?rrule=&action=TEMPLATE&text=${
					session.name[language ? language : eventUser.updatedSettings.language]
				}&dates=${sessionStartDateFormatted}/${sessionEndDateFormatted}&details=${descriptionFormated}`;
				break;

			case "yahoo":
				// eslint-disable-next-line max-len
				eventUrl = `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${
					session.name[language ? language : eventUser.updatedSettings.language]
				}&st=${sessionStartDateFormatted}&et=${sessionEndDateFormatted}&desc=${descriptionFormated}`;
				break;

			case "ics": {
				this.generateSessionIcsFileFromClient(
					session,
					language ? language : eventUser.updatedSettings.language
				);
				return;
			}
		}

		window.open(eventUrl, "_blank");
	}

	/**
	 * addToCalendar
	 * @description add event to calendar
	 * @param event
	 * @param type
	 */
	addEventToCalendar(event: IEvent, type: string, calendarEvent?: IICS) {
		const eventStartDate = new Date(calendarEvent ? calendarEvent.start : event.startDate);
		const eventEndDate = new Date(calendarEvent ? calendarEvent.end : event.endDate);
		const eventStartDateFormatted = eventStartDate.toISOString().replace(/-|:|\.\d\d\d/g, "");
		const eventEndDateFormatted = eventEndDate.toISOString().replace(/-|:|\.\d\d\d/g, "");
		const description = "";

		let eventUrl = "";
		switch (type) {
			case "google":
				// eslint-disable-next-line max-len
				eventUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${
					calendarEvent ? calendarEvent.title : event.title
				}&dates=${eventStartDateFormatted}/${eventEndDateFormatted}&organizer=${
					calendarEvent ? calendarEvent.organizer : ""
				}&details=${calendarEvent ? calendarEvent.description : description}&sf=true&output=xml`;
				break;
			case "outlook":
				// eslint-disable-next-line max-len
				eventUrl = `https://outlook.live.com/owa/?path=/calendar/action/compose&rru=addevent&startdt=${eventStartDate.toISOString()}&enddt=${eventEndDate.toISOString()}&subject=${
					calendarEvent ? calendarEvent.title : event.title
				}&body=${calendarEvent ? calendarEvent.description : description}`;
				break;

			case "apple":
				// eslint-disable-next-line max-len
				eventUrl = `https://calendar.apple.com/?rrule=&action=TEMPLATE&text=${
					calendarEvent ? calendarEvent.title : event.title
				}&dates=${eventStartDateFormatted}/${eventEndDateFormatted}&organizer=${
					calendarEvent ? calendarEvent.organizer : ""
				}&details=${calendarEvent ? calendarEvent.description : description}`;
				break;

			case "yahoo":
				// eslint-disable-next-line max-len
				eventUrl = `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${
					calendarEvent ? calendarEvent.title : event.title
				}&st=${eventStartDateFormatted}&et=${eventEndDateFormatted}&desc=${
					calendarEvent ? calendarEvent.description : description
				}`;
				break;

			case "ics": {
				this.generateEventIcsFileFromClient(event, calendarEvent);
				return;
			}
		}

		window.open(eventUrl, "_blank");
	}

	/**
	 * formatDateTime
	 */
	formatDateTime = (date: Date) => {
		return (
			date.toISOString().concat(date.toLocaleTimeString()).replaceAll("-", "").replaceAll(":", "").slice(0, 15) +
			"Z"
		);
	};

	/**
	 * convertHtmlToText
	 * @param html
	 * @returns
	 */
	convertHtmlToText = (html: string) => {
		const tmp = document.createElement("div");
		tmp.innerHTML = html.replaceAll("<br>", "\\n");
		let result = tmp.textContent || tmp.innerText || html;
		result = result && result.replace(/\n/g, "\\n");
		return result;
	};

	/**
	 * findAvaibleItemLanguage
	 * @param item
	 * @returns
	 */
	findAvaibleItemLanguage(item: ILanguage) {
		return Object.keys(item).reduce((acc, key) => {
			if (item[key] !== "") {
				acc = item[key];
			}
			return acc;
		}, "");
	}

	getAllPersonalSessionsOfEventUser(eventId: string, eventUserId: string) {
		return from(
			this.SFirestore.getDocumentsCollectionGroup(`schedules`, [
				where("eventId", "==", eventId),
				where("registeredUsers", "array-contains", eventUserId)
			])
		).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as ISchedule)));
	}
}
