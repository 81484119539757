/* eslint-disable no-duplicate-case */
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { where } from "@angular/fire/firestore";
import { Device } from "@capacitor/device";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription, of } from "rxjs";
import { catchError, switchMap, take } from "rxjs/operators";
import { GetAllDocuments, GetAllFolders, GetAllImages, GetAllVideos } from "../actions/documents.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { IDocument, IGalleryEmbededVideo, IGalleryImage } from "../interfaces/documents.interfaces";
import { IFolder } from "../interfaces/folders.interfaces";
import { checkSameEvent } from "../selectors/documents.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";
import { UtilityService } from "./utility.service";

@Injectable({
	providedIn: "root"
})
export class DocumentsService {
	readonly CARD_EXCHANGE_DOC_SIZE_LIMIT = 1000000; // 1MB

	documentsSub: Subscription;
	foldersSub: Subscription;
	imagesSub: Subscription;
	videosSub: Subscription;

	supportedExtensions: string =
		".doc,.docx,.docm,.DOC,.DOCX,.DOCM,.vnd.openxmlformats-officedocument.wordprocessingml.document,\
		.xls,.xlt,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.XLS,.XLT,.XML,.XLSX,.XLSM,.XLSB,.XLTX,.XLTM,\
		.vnd.openxmlformats-officedocument.spreadsheetml.sheet,.pdf,.PDF,.png,.PNG,.jpg,.JPG,.jpeg,.JPEG,\
		.ppt,.pptx,.thmx,.ppsx,.pps,.ppsm,.PPT,.PPTX,.THMX,.PPSX,.PPS,.PPSM,\
		.vnd.openxmlformats-officedocument.presentationml.presentation,.mp4,.wmv,.3gp,.avi,.mp3,.wav,.MP4,\
		.WMV,.3GP,.AVI,.MP3,.WAV";

	typeFormatFiles: string[] = [
		"audio",
		"doc",
		"excel",
		"jpg",
		"media",
		"pdf",
		"photo",
		"ppt",
		"vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"vnd.openxmlformats-officedocument.wordprocessingml.document"
	];

	constructor(
		private STranslate: TranslateService,
		private SUtility: UtilityService,
		private SFirestore: FirestoreService,
		private platform: Platform,
		private store: Store,
		private http: HttpClient
	) {}

	unsubscribeAll() {
		[this.documentsSub, this.foldersSub, this.imagesSub, this.videosSub].forEach((sub) => {
			if (sub) sub.unsubscribe();
		});
	}

	/**
	 * Get all documents of event
	 * @param eventId
	 */
	getAllDocumentsOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent(eventId))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.documentsSub && !this.documentsSub.closed) {
					return;
				} else if (!sameEvent && this.documentsSub && !this.documentsSub.closed) {
					this.documentsSub.unsubscribe();
				}

				this.documentsSub = this.SFirestore.collectionGroupValueChangesDocuments("documents", [
					where("eventId", "==", eventId)
				]).subscribe((documents: IDocument[]) => {
					this.store.dispatch(GetAllDocuments({ payload: documents, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initDocuments"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initDocuments", payload: true })
								);
							}
						});
				});
			});
	}

	/*******************************/
	/*******************************/
	/********  Images part  ********/
	/*******************************/
	/*******************************/

	/**
	 * Get all images of event
	 * @param eventId
	 */
	getAllImagesOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent(eventId))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.imagesSub && !this.imagesSub.closed) {
					return;
				} else if (!sameEvent && this.imagesSub && !this.imagesSub.closed) {
					this.imagesSub.unsubscribe();
				}

				this.imagesSub = this.SFirestore.collectionGroupValueChangesDocuments("images", [
					where("eventId", "==", eventId)
				]).subscribe((images: IGalleryImage[]) => {
					this.store.dispatch(GetAllImages({ payload: images, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initImages"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(InitSpecificEventDatasPart({ part: "initImages", payload: true }));
							}
						});
				});
			});
	}

	/*******************************/
	/*******************************/
	/********  Videos part  ********/
	/*******************************/
	/*******************************/

	/**
	 * Get all images of event
	 * @param eventId
	 */
	getAllVideosOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent(eventId))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.videosSub && !this.videosSub.closed) {
					return;
				} else if (!sameEvent && this.videosSub && !this.videosSub.closed) {
					this.videosSub.unsubscribe();
				}

				this.videosSub = this.SFirestore.collectionGroupValueChangesDocuments("videos", [
					where("eventId", "==", eventId)
				]).subscribe((videos: IGalleryEmbededVideo[]) => {
					this.store.dispatch(GetAllVideos({ payload: videos, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initVideos"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(InitSpecificEventDatasPart({ part: "initVideos", payload: true }));
							}
						});
				});
			});
	}

	/*******************************/
	/*******************************/
	/*******  Folders part  ********/
	/*******************************/
	/*******************************/

	/**
	 * Get all folders of event
	 * @param eventId
	 * @returns
	 */
	getAllFoldersOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent(eventId))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.foldersSub && !this.foldersSub.closed) {
					return;
				} else if (!sameEvent && this.foldersSub && !this.foldersSub.closed) {
					this.foldersSub.unsubscribe();
				}

				this.foldersSub = this.SFirestore.collectionGroupValueChangesDocuments("folders", [
					where("eventId", "==", eventId)
				]).subscribe((folders: IFolder[]) => {
					this.store.dispatch(GetAllFolders({ payload: folders, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initFolders"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(InitSpecificEventDatasPart({ part: "initFolders", payload: true }));
							}
						});
				});
			});
	}

	/**
	 * Checck document type
	 * @param type
	 * @returns
	 */
	checkType(type: string): string {
		switch (type) {
			case "doc":
			case "docx":
			case "docm":
			case "DOC":
			case "DOCX":
			case "DOCM":
			case "vnd.openxmlformats-officedocument.wordprocessingml.document":
			case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
				return "Word";

			case "xlt":
			case "xls":
			case "xml":
			case "xlsx":
			case "xlsm":
			case "xlsb":
			case "xltx":
			case "xltm":
			case "XLT":
			case "XML":
			case "XLSX":
			case "XLSM":
			case "XLSB":
			case "XLTX":
			case "XLTM":
			case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
			case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
				return "Excel";

			case "pdf":
			case "PDF":
				return "PDF";

			case "png":
			case "PNG":
				return "PNG";

			case "jpg":
			case "JPG":
				return "JPG";
			case "jpeg":
			case "JPEG":
				return "JPEG";

			case "ppt":
			case "pptx":
			case "thmx":
			case "ppsx":
			case "pps":
			case "ppsm":
			case "PPT":
			case "PPTX":
			case "THMX":
			case "PPSX":
			case "PPS":
			case "PPSM":
			case "vnd.openxmlformats-officedocument.presentationml.presentation":
			case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
				return "PowerPoint";

			case "mp4":
			case "wmv":
			case "3gp":
			case "avi":
			case "mp3":
			case "wav":
			case "MP4":
			case "WMV":
			case "3GP":
			case "AVI":
			case "MP3":
			case "WAV":
				return "Media";

			default:
				return "Generic File";
		}
	}

	/**
	 * getFomatForSVG
	 * @desc get format type for retreiving corresponding file in assets folder
	 * @param type
	 * @returns
	 */
	getFomatForSVG(type: string): string {
		switch (type) {
			case "doc":
			case "docx":
			case "docm":
			case "DOC":
			case "DOCX":
			case "DOCM":
			case "vnd.openxmlformats-officedocument.wordprocessingml.document":
			case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
				return "doc_ico";

			case "xlt":
			case "xls":
			case "xml":
			case "xlsx":
			case "xlsm":
			case "xlsb":
			case "xltx":
			case "xltm":
			case "XLT":
			case "XLS":
			case "XML":
			case "XLSX":
			case "XLSM":
			case "XLSB":
			case "XLTX":
			case "XLTM":
			case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
			case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
				return "excel_ico";

			case "pdf":
			case "PDF":
				return "pdf_ico";

			case "png":
			case "PNG":
				return "media_ico";

			case "jpg":
			case "JPG":
				return "jpg_ico";
			case "jpeg":
			case "JPEG":
				return "media_ico";

			case "ppt":
			case "pptx":
			case "thmx":
			case "ppsx":
			case "pps":
			case "ppsm":
			case "PPT":
			case "PPTX":
			case "THMX":
			case "PPSX":
			case "PPS":
			case "PPSM":
			case "vnd.openxmlformats-officedocument.presentationml.presentation":
			case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
				return "ppt_ico";

			case "mp4":
			case "wmv":
			case "3gp":
			case "avi":
			case "mp3":
			case "wav":
			case "MP4":
			case "WMV":
			case "3GP":
			case "AVI":
			case "MP3":
			case "WAV":
				return "media_ico";

			default:
				return "generic-file_ico";
		}
	}

	/**
	 * Download a file
	 * @param filename
	 * @param text
	 */
	async downloadFile(filename: string, base64Data) {
		try {
			if (this.platform.is("ios")) {
				const fileWriteResult = await Filesystem.writeFile({
					path: filename,
					data: base64Data,
					directory: Directory.Cache,
					encoding: Encoding.UTF8,
					recursive: true
				});
				if (fileWriteResult && fileWriteResult.uri) {
					this.SUtility.shareImage(fileWriteResult.uri);
				}
			} else if (this.platform.is("android")) {
				const info = await Device.getInfo();
				if (this.platform.is("android") && info.osVersion <= "10") {
					const permission = await Filesystem.checkPermissions();

					if (!permission.publicStorage || permission.publicStorage !== "granted") {
						await Filesystem.requestPermissions();
					}

					if (!permission.publicStorage || permission.publicStorage !== "granted") {
						throw new Error("Permission not granted");
					}
				}

				const fileWriteResult = await Filesystem.writeFile({
					path: `download/${filename}`,
					data: base64Data,
					directory: Directory.Cache,
					encoding: Encoding.UTF8,
					recursive: true
				});

				if (fileWriteResult && fileWriteResult.uri) this.SUtility.shareImage(fileWriteResult.uri);
			} else {
				// web platform
				const a = document.createElement("a");
				a.href = base64Data;
				a.download = filename;
				a.click();
				window.URL.revokeObjectURL(base64Data);
			}
		} catch (error) {
			console.error("🚀 ~ DocumentsService ~ downloadFile ~ error:", error);
			this.SUtility.presentToast(
				this.STranslate.instant("toasts.errors.download_error"),
				2000,
				"bottom",
				"danger"
			);
		}
	}

	/**
	 * convertFileUrlToBase64
	 * @param url
	 * @returns
	 */
	convertFileUrlToBase64(url: string): Observable<string | ArrayBuffer> {
		return this.http
			.get(url, {
				responseType: "arraybuffer",
				reportProgress: true,
				observe: "body"
			})
			.pipe(
				switchMap((data): Observable<string | ArrayBuffer> => {
					// convert array buffer to base64 string
					const base64String = btoa(
						new Uint8Array(data).reduce((data, byte) => data + String.fromCharCode(byte), "")
					);
					return of(base64String);
				}),
				catchError((error) => {
					console.error("🚀 ~ catchError ~ error:", error);
					return of(null);
				})
			);
	}

	async urlToBase64(url) {
		try {
			const response = await fetch(url);
			const blob = await response.blob();

			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onloadend = () => resolve(reader.result);
				reader.onerror = reject;
				reader.readAsDataURL(blob);
			});
		} catch (error) {
			console.error("🚀 ~ ~ error:", error);
		}
	}
}
