<div
	*ngIf="event"
	style="
		background: rgba(0, 0, 0, 0.1);
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99999999;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 50px;
	"
>
	<div
		[style.max-width]="maxWidth"
		[style.background-color]="'white'"
		style="
			width: fit-content;
			height: fit-content;
			border-radius: 10px;
			padding: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		"
	>
		<div
			[style.background-color]="bgIconColor ? bgIconColor : event.styling.btnBgColor"
			style="
				width: 60px;
				height: 60px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				padding: 10px;
				border-radius: 50%;
			"
		>
			<ion-icon
				*ngIf="type === 'ok'"
				style="width: 100%; height: 100%"
				[style.color]="iconColor ? iconColor : event.styling.btnTextColor"
				name="checkmark-outline"
			></ion-icon>
			<ion-icon
				*ngIf="type === 'error'"
				style="width: 100%; height: 100%"
				[style.color]="iconColor ? iconColor : 'red'"
				name="close-outline"
			></ion-icon>
		</div>
		<div
			style="
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				text-align: center;
				white-space: pre-line;
				font-size: 20px;
				font-weight: 700;
				margin: 15px 0;
			"
		>
			{{ title | translate }}
		</div>
		<div
			style="
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				text-align: center;
				white-space: pre-line;
				font-size: 14;
				font-weight: 400;
			"
		>
			{{ message | translate }}
		</div>

		<div style="display: flex; flex-direction: row; align-items: center; justify-content: center; width: 100%">
			<div
				*ngIf="cancelBtn"
				(click)="cancel()"
				[style.background-color]="event.styling.btnBgColor"
				[style.color]="event.styling.btnTextColor"
				style="
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					font-weight: 700;
					border-radius: 28px;
					padding: 10px 20px;
					margin: 15px 20px 0 20px;
				"
			>
				{{ cancelBtn | translate }}
			</div>
			<div
				*ngIf="confirmBtn"
				(click)="confirm()"
				[style.background-color]="event.styling.btnBgColor"
				[style.color]="event.styling.btnTextColor"
				style="
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					font-weight: 700;
					border-radius: 28px;
					padding: 10px 20px;
					margin: 15px 20px 0 20px;
				"
			>
				{{ confirmBtn | translate | uppercase }}
			</div>
		</div>
	</div>
</div>
