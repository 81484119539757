import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Platform } from "@ionic/angular";
import { saveAs } from "file-saver";
import { of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { UtilityService } from "./utility.service";
import { TranslateService } from "@ngx-translate/core";
import { Device } from "@capacitor/device";

@Injectable({
	providedIn: "root"
})
export class FileService {
	constructor(
		private http: HttpClient,
		private platform: Platform,
		private SUtility: UtilityService,
		private STranslate: TranslateService
	) {}

	/**
	 * Download a file from url
	 * @param url
	 * @returns
	 */
	downloadFileFromUrl(url: string, name: string) {
		return this.http
			.get(url, {
				responseType: "blob",
				reportProgress: true,
				observe: "body"
			})
			.pipe(
				switchMap((blob) => {
					if (this.platform.is("mobile") && window.innerWidth < 768) {
						// if (this.platform.is("ios")) {
						return new Promise((resolve) => {
							const reader = new FileReader();
							reader.readAsDataURL(blob);
							reader.onloadend = async () => {
								resolve(reader.result);
								const fileWriteResult = await Filesystem.writeFile({
									path: name,
									data: reader.result as string,
									directory: Directory.Cache,
									recursive: true
								});
								if (fileWriteResult && fileWriteResult.uri)
									this.SUtility.shareImage(fileWriteResult.uri);
							};
						});
						// } else {
						// 	return new Promise((resolve, reject) => {
						// 		const reader = new FileReader();
						// 		reader.readAsDataURL(blob);
						// 		reader.onloadend = async () => {
						// 			resolve(reader.result);
						// 			await Filesystem.writeFile({
						// 				path: `Download/${name}`,
						// 				data: reader.result as string,
						// 				directory: Directory.External,
						// 				recursive: true
						// 			});
						// 		};
						// 	});
						// }
					} else {
						saveAs(blob, name);
						return of(url);
					}
				}),
				catchError((error, caught) => {
					return caught;
				})
			);
	}

	async downloadFile(data: string | Blob, filename?: string) {
		try {
			if (this.platform.is("ios")) {
				const fileWriteResult = await Filesystem.writeFile({
					path: filename,
					data: data,
					directory: Directory.Cache,
					recursive: true
				});
				if (fileWriteResult && fileWriteResult.uri) {
					this.SUtility.shareImage(fileWriteResult.uri);
				}
			} else if (this.platform.is("android")) {
				const info = await Device.getInfo();
				if (this.platform.is("android") && info.osVersion <= "10") {
					const permission = await Filesystem.checkPermissions();

					if (!permission.publicStorage || permission.publicStorage !== "granted") {
						await Filesystem.requestPermissions();
					}

					if (!permission.publicStorage || permission.publicStorage !== "granted") {
						throw new Error("Permission not granted");
					}
				}

				const fileWriteResult = await Filesystem.writeFile({
					path: `download/${filename}`,
					data: data,
					directory: Directory.Cache,
					recursive: true
				});

				if (fileWriteResult && fileWriteResult.uri) this.SUtility.shareImage(fileWriteResult.uri);
			} else {
				// web platform
				const url = typeof data === "string" ? data : URL.createObjectURL(data);
				const a = document.createElement("a");
				a.href = url;
				a.download = filename;
				a.click();
				window.URL.revokeObjectURL(url);
			}
		} catch (error) {
			console.error("🚀 ~ DocumentsService ~ downloadFile ~ error:", error);
			this.SUtility.presentToast(
				this.STranslate.instant("toasts.errors.download_error"),
				2000,
				"bottom",
				"danger"
			);
		}
	}

	/**
	 * base64ToExcelBlob
	 * @param base64String
	 * @param contentType
	 * @returns
	 */
	base64ToExcelBlob(base64String: string, contentType: string) {
		const binaryString = atob(base64String);
		const byteArray = new Uint8Array(binaryString.length);
		for (let i = 0; i < binaryString.length; i++) {
			byteArray[i] = binaryString.charCodeAt(i);
		}
		const blob = new Blob([byteArray], {
			type: contentType
		});

		return blob;
	}
}
