import { Component, OnDestroy } from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Subscription, map, take } from "rxjs";
import { GetHeaderTitle, Loading, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { IEvent, IModule } from "src/app/shared/interfaces";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { FirestoreService, UtilityService } from "src/app/shared/services";
import * as _ from "lodash-es";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { INote } from "src/app/shared/interfaces/notes.interfaces";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-note-taking-edit",
    templateUrl: "./note-taking-edit.component.html",
    styleUrls: ["./note-taking-edit.component.scss"],
    standalone: false
})
export class NoteTakingEditComponent implements OnDestroy {
	subscriptions: Subscription[] = [];
	updating: boolean = false;

	event: IEvent;
	eventId: string;
	moduleId: string;
	module: IModule;
	noteId: string;
	note: INote;

	isMobile: boolean = false;

	noteForm: FormGroup;

	constructor(
		private fb: FormBuilder,
		private store: Store,
		private platform: Platform,
		private navCtrl: NavController,
		private snackbar: MatSnackBar,
		private STranslate: TranslateService,
		private SFirestore: FirestoreService,
		public SUtility: UtilityService
	) {
		this.noteForm = this.fb.group({
			content: ["", Validators.maxLength(250)],
			title: ["", Validators.maxLength(100)]
		});
	}

	ionViewWillEnter() {
		this.isMobile = this.platform.is("mobile") && window.innerWidth < 768 ? true : false;

		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.noteId = params.noteId;

						this.getEvent();
						this.getModule();
						this.getNote();
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.store
			.select(getCurrentEvent)
			.pipe(take(1))
			.subscribe((event) => {
				this.event = event;
			});
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.store
			.select(getSpecificModule(this.moduleId))
			.pipe(take(1))
			.subscribe((module) => {
				this.module = module;
			});
	}

	/**
	 * Getting note
	 */
	getNote() {
		this.SFirestore.getDocumentObs(`events/${this.eventId}/modules/${this.moduleId}/notes/${this.noteId}`)
			.pipe(map((snapshot) => snapshot.data() as INote))
			.subscribe((note) => {
				this.note = note;

				if (this.note) {
					this.store.dispatch(
						GetHeaderTitle({
							payload: {
								ArAR: this.note.title,
								DeDE: this.note.title,
								EnUS: this.note.title,
								EsES: this.note.title,
								FrFR: this.note.title,
								PtBR: this.note.title
							}
						})
					);
					this.noteForm.patchValue({
						content: this.note.content,
						title: this.note.title
					});
				}
			});
	}

	async updateNote() {
		if (!this.updating) {
			this.updating = true;
			try {
				this.store.dispatch(Loading({ payload: true }));
				const data = this.noteForm.getRawValue();
				this.note.content = data.content;
				this.note.title = data.title;
				await this.SFirestore.updateDocument(
					`events/${this.eventId}/modules/${this.moduleId}/notes/${this.noteId}`,
					_.omit(this.note, ["uid", "eventId", "moduleId", "creationDate", "userId"])
				);
				this.updating = false;
				this.store.dispatch(Loading({ payload: false }));
				this.snackbar.open(this.STranslate.instant("note-taking.success-edit"), "", {
					duration: 3000,
					panelClass: "success-snackbar"
				});
				this.navCtrl.navigateBack(`event/${this.eventId}/note-taking/${this.moduleId}`);
			} catch (error) {
				this.updating = false;
				this.store.dispatch(Loading({ payload: false }));
				this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		}
	}
}
