import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Subscription, of, skipWhile, switchMap, take, tap, timer } from "rxjs";
import { IEvent } from "src/app/shared/interfaces";

@Component({
    selector: "app-ads-modal",
    templateUrl: "./ads-modal.component.html",
    styleUrls: ["./ads-modal.component.scss"],
    standalone: false
})
export class AdsModalComponent implements OnInit, OnDestroy {
	timerSub: Subscription;
	event: IEvent;
	loader: boolean = false;
	device: string;
	isMobile: boolean;
	canCloseModal: boolean = false;
	subscriptions: Subscription[] = [];
	languages: any[];
	language: string;
	timerCount: number;

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private modalCtrl: ModalController,
		private platform: Platform // private store: Store
	) {}

	ngOnInit() {
		if ((this.platform.is("mobile") && window.innerWidth < 768) || window.innerWidth < 768) {
			this.isMobile = true;
		}

		this.device = this.platform.is("android") ? "android" : this.platform.is("ios") ? "ios" : "desktop";

		if (this.event) {
			this.languages = [];

			for (const key of Object.keys(this.event.languagesSettings)) {
				if (this.event.languagesSettings[key]) {
					this.languages.push(key);
				}
			}
		}

		this.timerSub = timer(0, 1000)
			.pipe(
				tap((value) => {
					this.timerCount = this.event.adsContent.exitDelayTime - value;
				}),
				skipWhile((value) => value <= this.event.adsContent.exitDelayTime),
				switchMap((value) => of(value)),
				take(1)
			)
			.subscribe(() => {
				this.canCloseModal = true;
			});
	}

	ngOnDestroy() {
		this.timerSub.unsubscribe();
	}

	close() {
		if (this.timerSub && !this.timerSub.closed) {
			this.timerSub.unsubscribe();
		}
		this.modalCtrl.dismiss();
	}
}
