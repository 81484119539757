import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { Component, Input, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ModalController, NavController, Platform, ToastController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { QueryConstraint, QueryDocumentSnapshot, limit, orderBy, startAfter, where } from "firebase/firestore";
import * as _ from "lodash";
import {
	Observable,
	Subscription,
	combineLatest,
	firstValueFrom,
	forkJoin,
	interval,
	map,
	of,
	skipWhile,
	switchMap,
	take
} from "rxjs";
import { GetHeaderItem, GetHeaderTitle, GetHeaderType, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { TypeCustomFields } from "src/app/shared/enums/type-custom-fields";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { TypeModule } from "src/app/shared/enums/type-module";
import {
	ICustomField,
	IEvent,
	IEventUser,
	IFullCustomField,
	IGroup,
	ILanguage,
	IModule,
	IModuleCustomField
} from "src/app/shared/interfaces";
import {
	ICustomFieldData,
	IFilterOptions,
	IFilteredItemFormat
} from "src/app/shared/interfaces/custom-fields.interfaces";
import { IFavoriteFolder } from "src/app/shared/interfaces/folders.interfaces";
import { IModuleUpdatedSettings } from "src/app/shared/interfaces/modules.interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getBaseCustomFields,
	getGroupsByOrder,
	getModulesCustomsFieldsOfModule,
	getSpecificFavoriteFolder
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import {
	AnalyticsService,
	EventUsersService,
	FavoritesService,
	FirestoreService,
	UtilityService
} from "src/app/shared/services";
import { environment } from "src/environments/environment";
import { FilterComponent } from "../../components/custom-fields/filter/filter.component";

@Component({
    selector: "app-event-users-favorites",
    templateUrl: "./event-users-favorites.component.html",
    styleUrls: ["./event-users-favorites.component.scss"],
    standalone: false
})
export class EventUserFavoriteComponent implements OnInit, OnDestroy {
	@ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
	@Input() eventUsers: IEventUser[] = [];
	@Input() module: IModule;
	@Input() mode: "normal" | "favorites";

	subscriptions: Subscription[] = [];
	analyticsArraySub: { name: string; sub: Subscription }[] = [];

	loader: boolean = true;
	limit: number = 30;
	currentSize: number = 0;

	searchOpen: boolean = false;
	searchText: string = "";

	totalEventUsers: number = 0;
	lastQuery: {
		start: QueryDocumentSnapshot<IEventUser>;
		last: QueryDocumentSnapshot<IEventUser>;
		docsSnapshots: QueryDocumentSnapshot<IEventUser>[];
		docsDatas: IEventUser[];
	} = null;

	eventId: string;
	event: IEvent;
	moduleId: string;
	moduleUpdatedSettings: IModuleUpdatedSettings;
	eventUser: IEventUser;
	filteredEventUsers: IEventUser[] = [];
	filteredEventUsersInTwo: IEventUser[][] = [];
	groupIds: string[];
	groups: IGroup[] = [];
	customFields: IFullCustomField[] = [];
	tagCustomFields: IFullCustomField[] = [];

	// filter properties
	filterItems: IFilteredItemFormat[];
	typeModule = TypeModule;
	typeOrder: string;

	showNoResultImage: boolean = false;

	allowFilterTag: boolean = false; // To show or hide filter tag
	isMobile = window.innerWidth < 768 ? true : false;
	selectedFilters: IFilteredItemFormat[] = [];
	selectedDatas: IFilterOptions[] = [];
	notSelectedFilters: IFilteredItemFormat[];

	groupedLetters: any;
	itemSize: number = 97;
	height: number = 873;

	dontBelongAnyGroups: boolean = false;

	// scrolledContainer: any = null;
	init: boolean = false;
	initEventUsersDatas: boolean = false;
	navigationSaved: { moduleId: string; lastIndex: number }[] = [];
	currentLanguage: string = environment.platform.defaultLanguage;
	searchValue: string = "";

	filtersCollapseState: { uid: string; collapsed: boolean }[] = [];

	resultsSaved: [ICustomField[], IModuleCustomField[]] = [[], []];

	searchDatas: any[] = [];
	folderId: any;
	folder: IFavoriteFolder;
	favoriteModule: IModule;
	eventUserFavoritesState: any = {};
	scanning: boolean = false;

	constructor(
		public platform: Platform,
		private SAnalytics: AnalyticsService,
		private store: Store,
		private SEventUsers: EventUsersService,
		private SFirestore: FirestoreService,
		private toastCtrl: ToastController,
		private navCtrl: NavController,
		private snackBar: MatSnackBar,
		private STranslate: TranslateService,
		private modalController: ModalController,
		private SUtility: UtilityService,
		public SFavorite: FavoritesService
	) {}

	ionViewWillEnter() {
		this.store.dispatch(GetHeaderType({ payload: TypeHeader.FAVORITE_EVENT_USERS_FOLDERS }));
		this.currentLanguage = this.STranslate.currentLang;

		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
				if (this.module.options.showFilter) this.initFilterData();
			})
		);
		this.subscriptions.push(
			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe(() => {
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.init = false;
							this.initEventUsersDatas = false;

							this.moduleId = params.moduleId;
							this.folderId = params.folderId;
							this.getEvent();
							this.getFolder();
							this.getGroups();

							// Analytics
							this.SAnalytics.moduleAccess(
								this.eventId,
								this.moduleId,
								TypeTracking.ACCESS_TO_EVENT_USERS_MODULE
							);
						});
				})
		);
	}

	ionViewDidEnter() {
		this.subscriptions.push(
			interval(100)
				.pipe(
					skipWhile(() => this.loader || !this.virtualScroll),
					take(1)
				)
				.subscribe(() => {
					if (this.virtualScroll) {
						const nav = this.navigationSaved.find((navMod) => navMod.moduleId === this.moduleId);
						this.virtualScroll.scrollToIndex(nav ? nav.lastIndex : 0, "auto");
						this.init = true;
					}
				})
		);
	}

	ngOnInit() {
		this.subscriptions.push(
			this.SFavorite.favoriteFolderScanQrSubject.subscribe((scanQr) => {
				if (scanQr) {
					this.scanQr();
				}
			})
		);
	}

	ionViewWillLeave() {
		this.eventUsers = [];
		this.filteredEventUsers = [];
		this.lastQuery = null;
		this.initEventUsersDatas = false;
		this.eventUser = null;
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.store.dispatch(ResetHeaderState(null));
		this.loader = true;
		this.init = false;
		this.resetFilter();
		// this.store
		// 	.select(selectUrl)
		// 	.pipe(take(1))
		// 	.subscribe(() => {
		// 		this.store
		// 			.select(selectRouteNestedParams)
		// 			.pipe(take(1))
		// 			.subscribe((params) => {
		// 				if (params.moduleId !== this.moduleId) {
		// 					this.eventUser = null;
		// 					this.subscriptions.forEach((sub) => sub.unsubscribe());
		// 				}
		// 			});
		// 	});
	}

	ngOnDestroy() {
		this.eventUsers = [];
		this.filteredEventUsers = [];
		this.lastQuery = null;
		this.initEventUsersDatas = false;

		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * getFolder
	 */
	getFolder() {
		this.subscriptions.push(
			this.store.select(getSpecificFavoriteFolder(this.folderId)).subscribe((folder) => {
				this.folder = folder;
				if (this.folder) {
					this.store.dispatch(GetHeaderTitle({ payload: this.folder.name }));
					this.getModuleAndEventUser();
				}
			})
		);
	}

	/**
	 * Getting module and event user
	 */
	getModuleAndEventUser() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getCurrentEventUser),
				this.store.select(getSpecificModule(this.folder.moduleLinkedId)),
				this.store.select(getSpecificModule(this.moduleId)),
				this.SFirestore.valueChangesDocument(`events/${this.eventId}/modules-updated-settings/${this.moduleId}`)
			]).subscribe({
				next: (results) => {
					const tmpEventUserSaved: IEventUser = _.cloneDeep(this.eventUser);
					const tmpEventUserComing: IEventUser = _.cloneDeep(results[0]);
					const module = results[1];
					if (tmpEventUserSaved && tmpEventUserSaved["updatedSettings"]) {
						delete tmpEventUserSaved["updatedSettings"];
					}
					if (tmpEventUserComing && tmpEventUserComing["updatedSettings"]) {
						delete tmpEventUserComing["updatedSettings"];
					}

					if (
						!_.isEqual(this.module, module) ||
						!_.isEqual(tmpEventUserSaved, tmpEventUserComing) ||
						!this.eventUser
					) {
						this.module = results[1];
						this.favoriteModule = results[2];
						if (this.module) {
							// this.SStorage.getSearchDatas(this.eventId, this.moduleId).subscribe({
							// 	next: (res: any) => {
							// 		this.searchDatas = res.result;
							// 	}
							// });
							const nav = this.navigationSaved.find((navMod) => navMod.moduleId === this.moduleId);
							if (!nav || (nav && nav.lastIndex <= 30)) {
								this.limit = 30;
							} else if (nav && nav.lastIndex > 30) {
								this.limit = 30 * Math.ceil(nav.lastIndex / 30);
							}

							this.getCustomFields();
						}

						if (!_.isEqual(this.eventUser, results[0])) {
							this.eventUser = results[0];
							this.initEventUsersDatas = false;
						}

						this.eventUser && this.buildFavorites();
						if (!this.initEventUsersDatas) {
							this.getEventUsers();
						}
					}

					if (!_.isEqual(this.moduleUpdatedSettings, results[3])) {
						this.moduleUpdatedSettings = results[3];
					}

					if (this.module) {
						this.store.dispatch(GetHeaderTitle({ payload: this.folder.name }));
						this.store.dispatch(GetHeaderItem({ payload: module }));
						this.store.dispatch(GetHeaderType({ payload: TypeHeader.FAVORITE_EVENT_USERS_FOLDERS }));
					}
				},
				error: () => {
					//
				}
			})
		);
	}

	/**
	 * Get custom fields
	 */
	getCustomFields() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getBaseCustomFields),
				this.store.select(getModulesCustomsFieldsOfModule(this.folder.moduleLinkedId))
			])
				.pipe(take(1))
				.subscribe((results) => {
					if (!_.isEqual(results, this.resultsSaved)) {
						this.resultsSaved = results;
						this.customFields = [];
						if (results[1].length > 0) {
							results[1].forEach((customField) => {
								const baseCustomFieldCorresponding = results[0].find(
									(custField) => custField.uid === customField.uid
								);

								if (baseCustomFieldCorresponding) {
									this.customFields.push({
										baseSettings: baseCustomFieldCorresponding,
										moduleSettings: customField,
										fieldDatas: null
									});
								}
							});
						}
						this.tagCustomFields = this.customFields.filter((custom) => custom.moduleSettings);
						if (this.module && this.module?.options?.showFilter) this.initFilterData();
					}
				})
		);
	}

	/**
	 * Get event users updated settings
	 */
	getEventUsersUpdatedSettings() {
		this.subscriptions.push(
			this.SEventUsers.getSpecificsEventUsersUpdatedSettings(
				this.eventId,
				this.folder?.moduleLinkedId,
				[...this.eventUsers].slice(0, this.limit).map((eventUser) => eventUser.uid)
			).subscribe({
				next: (eventUsersUpdatedSettings) => {
					this.eventUsers = this.eventUsers.map((eventUser) => {
						const updatedSettings = eventUsersUpdatedSettings.find(
							(settings) => settings.userId === eventUser.uid
						);
						if (updatedSettings) {
							eventUser.updatedSettings = updatedSettings;
						}
						return eventUser;
					});
				},
				error: () => {
					//
				}
			})
		);
	}

	/**
	 * initFilterData
	 */
	initFilterData() {
		this.filterItems = [];
		this.filtersCollapseState = [];
		// Event user group filter datas
		if (this.module.options.requiredFields.group.filter) {
			const userGroupIds: string[] = [];

			this.filterItems.push({
				uid: "group",
				name: this.STranslate.instant("labels.groups"),
				strictSelectionMode: null,
				values: [] as IFilterOptions[]
			} as IFilteredItemFormat);

			this.filtersCollapseState.push({
				uid: "group",
				collapsed: false
			});

			// if (this.moduleUpdatedSettings) {
			if (this.moduleUpdatedSettings && !this.module.options.showFilter) {
				// Build queries for counting each groups
				const promiseArrayFilterTerms: Promise<number>[] = [];
				const filteredTerm = this.moduleUpdatedSettings.searchStringDatas.filter(
					(filterTerm) =>
						filterTerm.split("|")[0] === "group" || filterTerm.split("|")[2] === this.currentLanguage
				);
				filteredTerm.forEach((filterTerm) => {
					promiseArrayFilterTerms.push(
						firstValueFrom(
							this.SFirestore.getCountOfQueryObs(
								`events/${this.eventId}/modules/${this.moduleId}/event-users`,
								"default",
								[where("searchStringDatas", "array-contains", filterTerm)]
							).pipe(take(1))
						)
					);
				});

				// Activate promises
				Promise.all(promiseArrayFilterTerms)
					.then((results) => {
						// Filter for removing all count at 0
						const newTerms = filteredTerm.filter((filterTerm, index) => results[index] > 0);

						const groupsTerms = newTerms.filter((filterTerm) => filterTerm.split("|")[0] === "group");
						const customsTerms = newTerms.filter((filterTerm) => filterTerm.split("|")[0] !== "group");

						// Build filter for groups
						if (groupsTerms.length === 0) {
							const index = this.filterItems.findIndex((filter) => filter.uid === "group");
							index !== -1 &&
								this.filterItems[index].values.push({
									isNotSpecified: true,
									isReachable: true,
									isSelected: false,
									filterId: "group",
									value: this.STranslate.instant("filter.not_specified"),
									totalDatas: 0
								});
						} else {
							groupsTerms.forEach((filterTerm) => {
								const groupId = filterTerm.split("|")[1];
								const group = this.groups.find((groupFind) => groupFind.uid === groupId);
								if (group) {
									userGroupIds.push(group.uid);
									const index = this.filterItems.findIndex((filter) => filter.uid === "group");
									index !== -1 &&
										this.filterItems[index].values.push({
											isNotSpecified: false,
											isReachable: true,
											isSelected: false,
											filterId: "group",
											value: group.name,
											totalDatas: 0
										});

									this.filterItems[index].values.sort((a, b) =>
										a.value.toLowerCase() > b.value.toLowerCase()
											? 1
											: a.value.toLowerCase() < b.value.toLowerCase()
											? -1
											: 0
									);
								}
							});
						}

						// Build filter for custom fields
						_.uniq(customsTerms).forEach((filterTerm) => {
							const customId = filterTerm.split("|")[0];
							const customValue = filterTerm.split("|")[1];
							const cus = this.customFields.find((custom) => custom.baseSettings.uid === customId);
							if (cus) {
								const filterItem = this.filterItems.find((item) => item.uid === customId);
								if (filterItem) {
									// If filter item already exist push on new value
									cus.baseSettings.type === TypeCustomFields.TEXT ||
									cus.baseSettings.type === TypeCustomFields.SELECT
										? filterItem.values.push({
												isReachable: true,
												isSelected: false,
												filterId: cus.baseSettings.uid,
												value: customValue
													? customValue
													: this.STranslate.instant("filter.not_specified")
										  } as IFilterOptions)
										: cus.baseSettings.type === TypeCustomFields.URL ||
										  cus.baseSettings.type === TypeCustomFields.EMAIL
										? filterItem.values.push({
												isNotSpecified: customValue ? false : true,
												isReachable: true,
												isSelected: false,
												filterId: cus.baseSettings.uid,
												value: customValue
													? customValue
													: this.STranslate.instant("filter.not_specified"),
												totalDatas: 0
										  })
										: cus.baseSettings.type === TypeCustomFields.NUMERIC
										? filterItem.values.push({
												isNotSpecified: customValue !== "-1" ? false : true,
												isReachable: true,
												isSelected: false,
												filterId: cus.baseSettings.uid,
												value:
													customValue !== "-1"
														? customValue
														: this.STranslate.instant("filter.not_specified"),
												totalDatas: 0
										  })
										: filterItem.values.push({
												isReachable: true,
												isSelected: false,
												filterId: cus.baseSettings.uid,
												value: customValue
													? customValue
													: this.STranslate.instant("filter.not_specified"),
												totalDatas: 0
										  } as IFilterOptions);
								} else {
									// If filter don't exist create it
									this.filterItems.push({
										uid: cus.baseSettings.uid,
										name: cus.baseSettings.name[this.currentLanguage],
										strictSelectionMode:
											cus.baseSettings.type === TypeCustomFields.MULTI_SELECT ? false : null,
										values: [
											{
												isReachable: true,
												isSelected: false,
												filterId: cus.baseSettings.uid,
												value: customValue
													? customValue
													: this.STranslate.instant("filter.not_specified")
											} as IFilterOptions
										]
									} as IFilteredItemFormat);
								}
							}

							// Sort all values on filter
							this.filterItems.forEach((filterItem) => {
								filterItem.values = filterItem.values.sort((a, b) => {
									if (a.value.toLowerCase() == b.value.toLowerCase()) return 0;
									if (
										a.value.toLowerCase() ==
										this.STranslate.instant("filter.not_specified").toLowerCase()
									)
										return 1;
									if (
										b.value.toLowerCase() ==
										this.STranslate.instant("filter.not_specified").toLowerCase()
									)
										return -1;

									if (a.value.toLowerCase() > b.value.toLowerCase()) return 1;
									if (a.value.toLowerCase() < b.value.toLowerCase()) return -1;
									return 0;
								});
							});

							this.filtersCollapseState.push({
								uid: cus.baseSettings.uid,
								collapsed: false
							});
						});
					})
					.catch(() => {
						//
					});
			} else {
				// Init filter based on event users groups if advanced filters activated
				this.eventUsers
					.filter((eventUser) => eventUser.groups.length > 0)
					.forEach((eventUser) => {
						eventUser.groups.forEach((groupId) => {
							if (!userGroupIds.includes(groupId)) {
								const group = this.groups.find((group) => group.uid === groupId);
								if (group) {
									userGroupIds.push(groupId);
									const index = this.filterItems.findIndex((filter) => filter.uid === "group");
									index !== -1 &&
										this.filterItems[index].values.push({
											isNotSpecified: false,
											isReachable: true,
											isSelected: false,
											filterId: "group",
											value: group.name,
											totalDatas: 0
										});

									this.filterItems[index].values.sort((a, b) =>
										a.value.toLowerCase() > b.value.toLowerCase()
											? 1
											: a.value.toLowerCase() < b.value.toLowerCase()
											? -1
											: 0
									);
								}
							}
						});
					});

				if (
					this.eventUsers.filter((eventUser) => eventUser.groups.length === 0).length > 0 ||
					this.filterItems.find((item) => item.uid === "group").values.length === 0
				) {
					const index = this.filterItems.findIndex((filter) => filter.uid === "group");
					index !== -1 &&
						this.filterItems[index].values.push({
							isNotSpecified: true,
							isReachable: true,
							isSelected: false,
							filterId: "group",
							value: this.STranslate.instant("filter.not_specified"),
							totalDatas: 0
						});
				}
			}
		}

		// if (!this.moduleUpdatedSettings) {
		if (this.module.options.showFilter || !this.moduleUpdatedSettings) {
			// Custom fields filter data
			this.customFields.forEach((customField) => {
				if (
					customField.moduleSettings.enableFilter &&
					(customField.baseSettings.type === TypeCustomFields.TEXT ||
						customField.baseSettings.type === TypeCustomFields.SELECT ||
						customField.baseSettings.type === TypeCustomFields.MULTI_SELECT ||
						customField.baseSettings.type === TypeCustomFields.DATE ||
						customField.baseSettings.type === TypeCustomFields.URL ||
						customField.baseSettings.type === TypeCustomFields.EMAIL ||
						customField.baseSettings.type === TypeCustomFields.NUMERIC)
				) {
					const values: IFilterOptions[] = [];

					this.eventUsers.forEach((eventUser) => {
						const cus = eventUser.customFields.find((cus) => cus.uid === customField.baseSettings.uid);
						if (cus) {
							customField.baseSettings.type === TypeCustomFields.TEXT ||
							customField.baseSettings.type === TypeCustomFields.SELECT
								? values.push({
										isReachable: true,
										isSelected: false,
										filterId: customField.baseSettings.uid,
										value:
											cus.field?.multiLanguageText[this.currentLanguage] !== ""
												? cus.field?.multiLanguageText[this.currentLanguage]
												: this.STranslate.instant("filter.not_specified")
								  } as IFilterOptions)
								: customField.baseSettings.type === TypeCustomFields.URL ||
								  customField.baseSettings.type === TypeCustomFields.EMAIL
								? values.push({
										isNotSpecified: cus.field.text !== "" ? false : true,
										isReachable: true,
										isSelected: false,
										filterId: customField.baseSettings.uid,
										value:
											cus.field.text !== ""
												? cus.field.text
												: this.STranslate.instant("filter.not_specified"),
										totalDatas: 0
								  })
								: customField.baseSettings.type === TypeCustomFields.NUMERIC
								? values.push({
										isNotSpecified: cus.field.numeric?.toString() !== "-1" ? false : true,
										isReachable: true,
										isSelected: false,
										filterId: customField.baseSettings.uid,
										value:
											cus.field.numeric?.toString() !== "-1"
												? cus.field.numeric?.toString()
												: this.STranslate.instant("filter.not_specified"),
										totalDatas: 0
								  })
								: cus.field.multiLanguageSelectArray
										?.map((option) => option[this.currentLanguage])
										.forEach((option: string) => {
											values.push({
												isNotSpecified:
													option !== "" && !values.find((value) => value.value === option)
														? false
														: true,
												isReachable: true,
												isSelected: false,
												filterId: customField.baseSettings.uid,
												value:
													option !== "" && !values.find((value) => value.value === option)
														? option
														: this.STranslate.instant("filter.not_specified"),
												totalDatas: 0
											});
										});
						}

						return values;
					});

					this.filterItems.push({
						uid: customField.baseSettings.uid,
						name: customField.baseSettings.name[this.currentLanguage],
						strictSelectionMode:
							customField.baseSettings.type === TypeCustomFields.MULTI_SELECT ? false : null,
						values: values
							.filter(
								(item, i, self) =>
									i ===
									self.findIndex(
										(it) =>
											item &&
											item.value &&
											item.value !== "" &&
											it.value?.toLowerCase() === item.value?.toLowerCase()
									)
							)
							.sort((a, b) => {
								if (a.value.toLowerCase() == b.value.toLowerCase()) return 0;
								if (
									a.value.toLowerCase() ==
									this.STranslate.instant("filter.not_specified").toLowerCase()
								)
									return 1;
								if (
									b.value.toLowerCase() ==
									this.STranslate.instant("filter.not_specified").toLowerCase()
								)
									return -1;

								if (a.value.toLowerCase() > b.value.toLowerCase()) return 1;
								if (a.value.toLowerCase() < b.value.toLowerCase()) return -1;
								return 0;
							})
					} as IFilteredItemFormat);

					this.filtersCollapseState.push({
						uid: customField.baseSettings.uid,
						collapsed: false
					});
				}
			});
		}
	}

	/**
	 * computeReachableDatas
	 * @description Calculates reachables options (checkboxs) based on the selected items
	 */
	computeReachableDatas() {
		if (this.eventUsers.length !== this.filteredEventUsers.length) {
			this.notSelectedFilters = this.filterItems.filter(
				(filter) => !filter.values.find((value) => value.isSelected)
			);
			this.selectedFilters = this.filterItems.filter((filter) => filter.values.find((value) => value.isSelected));

			// if (this.moduleUpdatedSettings) {
			if (this.moduleUpdatedSettings && !this.module.options.showFilter) {
				for (let i = 0; i < this.filterItems.length; i++) {
					const filter = this.filterItems[i];
					for (let iF = 0; iF < filter.values.length; iF++) {
						const filterValue = filter.values[iF];
						if (!filterValue.isSelected) {
							const values: string[] = [];

							// Push all selected filters each time
							this.selectedFilters.forEach((filterSelected) => {
								filterSelected.values.forEach((value) => {
									if (value.isSelected) {
										if (filterSelected.uid === "group") {
											const group = this.groups.find((group) => group.name === value.value);
											if (group) {
												values.push(`${filterSelected.uid}|${group.uid}`);
											}
										} else {
											values.push(`${filterSelected.uid}|${value.value}`);
										}
									}
								});
							});

							// Push filter to check
							values.push(`${filter.uid}|${filterValue.value}`);

							firstValueFrom(
								this.SFirestore.getDocumentsObs(
									`events/${this.eventId}/modules/${this.moduleId}/event-users`,
									[where("searchStringDatas", "array-contains-any", values)]
								).pipe(
									take(1),
									map((snapshot) => snapshot.docs.map((doc) => doc.data() as IEventUser))
								)
							)
								.then((results) => {
									if (results.length > 0) {
										const resultsIntersec: IEventUser[] = [];
										results.forEach((eventUser) => {
											if (
												_.intersection(values, eventUser.searchStringDatas).length ===
													values.length &&
												!resultsIntersec.find((evt) => evt.uid === eventUser.uid)
											) {
												resultsIntersec.push(eventUser);
											}
										});
										if (resultsIntersec.length > 0) {
											filterValue.isReachable = true;
										} else {
											filterValue.isReachable = false;
										}
									} else {
										filterValue.isReachable = false;
									}
								})
								.catch(() => {
									//
								});
						}
					}
				}
			} else {
				this.notSelectedFilters.forEach((filter) => {
					let matches = 0;
					const datas: string[] = [];

					this.filteredEventUsers.forEach((user) => {
						const custom = user.customFields.find(
							(cus) =>
								cus.uid === filter.uid &&
								(((this.getCustomFieldType(cus.uid) === TypeCustomFields.TEXT ||
									this.getCustomFieldType(cus.uid) === TypeCustomFields.SELECT) &&
									filter.values.find(
										(value) =>
											value.value.toLowerCase() ===
												cus.field.multiLanguageText[this.currentLanguage].toLowerCase() ||
											(value.value.toLowerCase() ===
												this.STranslate.instant("filter.not_specified").toLowerCase() &&
												cus.field.multiLanguageText[this.currentLanguage] === "")
									)) ||
									((this.getCustomFieldType(cus.uid) === TypeCustomFields.URL ||
										this.getCustomFieldType(cus.uid) === TypeCustomFields.EMAIL) &&
										filter.values.find(
											(value) =>
												value.value.toLowerCase() === cus.field.text.toLowerCase() ||
												(value.value.toLowerCase() ===
													this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.text === "")
										)) ||
									(this.getCustomFieldType(cus.uid) === TypeCustomFields.NUMERIC &&
										filter.values.find(
											(value) =>
												value.value === cus.field.numeric.toString() ||
												(value.value.toLowerCase() ===
													this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.numeric === -1)
										)) ||
									(this.getCustomFieldType(cus.uid) === TypeCustomFields.MULTI_SELECT &&
										filter.values.find(
											(value) =>
												cus.field.multiLanguageSelectArray
													.map((opt) => opt[this.currentLanguage].toLowerCase())
													.includes(value.value.toLowerCase()) ||
												(value.value.toLowerCase() ===
													this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.multiLanguageSelectArray.map(
														(opt) => opt[this.currentLanguage]
													).length === 0)
										)))
						);

						if (custom) {
							// this.getCustomFieldType(custom.uid) === TypeCustomFields.MULTI_SELECT &&
							if (
								!datas.find(
									(data) =>
										((this.getCustomFieldType(custom.uid) === TypeCustomFields.TEXT ||
											this.getCustomFieldType(custom.uid) === TypeCustomFields.SELECT) &&
											data.toLowerCase() ===
												custom.field.multiLanguageText[this.currentLanguage].toLowerCase()) ||
										((this.getCustomFieldType(custom.uid) === TypeCustomFields.URL ||
											this.getCustomFieldType(custom.uid) === TypeCustomFields.EMAIL) &&
											data.toLowerCase() === custom.field.text.toLowerCase()) ||
										(this.getCustomFieldType(custom.uid) === TypeCustomFields.NUMERIC &&
											data === custom.field.numeric.toString()) ||
										(this.getCustomFieldType(custom.uid) === TypeCustomFields.MULTI_SELECT &&
											custom.field.multiLanguageSelectArray
												?.map((opt: ILanguage) => {
													return opt[this.currentLanguage].toLowerCase();
												})
												.some((dat: string) => dat === data.toLowerCase()))
								)
							)
								this.getCustomFieldType(custom.uid) === TypeCustomFields.TEXT ||
								this.getCustomFieldType(custom.uid) === TypeCustomFields.SELECT
									? datas.push(custom.field.multiLanguageText[this.currentLanguage])
									: this.getCustomFieldType(custom.uid) === TypeCustomFields.URL ||
									  this.getCustomFieldType(custom.uid) === TypeCustomFields.EMAIL
									? datas.push(custom.field.text)
									: this.getCustomFieldType(custom.uid) === TypeCustomFields.NUMERIC
									? datas.push(custom.field.numeric.toString())
									: custom.field.multiLanguageSelectArray.length > 0 &&
									  datas.push(
											custom.field.multiLanguageSelectArray
												?.map((opt: ILanguage) => {
													return opt[this.currentLanguage].toLowerCase();
												})
												.join("*")
									  );

							if (
								(((this.getCustomFieldType(custom.uid) === TypeCustomFields.TEXT ||
									this.getCustomFieldType(custom.uid) === TypeCustomFields.SELECT) &&
									custom.field.multiLanguageText[this.currentLanguage] === "") ||
									((this.getCustomFieldType(custom.uid) === TypeCustomFields.URL ||
										this.getCustomFieldType(custom.uid) === TypeCustomFields.EMAIL) &&
										custom.field.text === "") ||
									(this.getCustomFieldType(custom.uid) === TypeCustomFields.NUMERIC &&
										custom.field.numeric === -1) ||
									(this.getCustomFieldType(custom.uid) === TypeCustomFields.MULTI_SELECT &&
										custom.field.multiLanguageSelectArray?.map(
											(option) => option[this.currentLanguage]
										).length === 0)) &&
								!datas.find(
									(data) =>
										data.toLowerCase() ===
										this.STranslate.instant("filter.not_specified").toLowerCase()
								)
							)
								datas.push(this.STranslate.instant("filter.not_specified"));

							matches++;
						}
					});

					if (matches === this.filteredEventUsers.length) {
						// Flat arrays and remove duplicated items
						const splitedDatas = [
							...new Set(datas.map((dat) => dat.split("*")).reduce((acc, val) => acc.concat(val), []))
						];

						filter.values
							.map((fil) => fil.value !== "-1" && fil)
							.filter((fil) => {
								if (
									splitedDatas
										.filter((dat) => dat)
										.findIndex((dat) => dat?.toLowerCase() === fil.value?.toLowerCase()) === -1
								) {
									fil.isReachable = false;
									return true;
								} else {
									filter.strictSelectionMode &&
									fil.value.toLowerCase() ===
										this.STranslate.instant("filter.not_specified").toLowerCase()
										? (fil.isReachable = false)
										: (fil.isReachable = true);
									return false;
								}
							});
					}
				});
			}
		} else {
			// Back to initial state (every checkbox are reachable)
			this.filterItems.forEach((item) => {
				item.values.forEach((data) => {
					item.strictSelectionMode &&
					data.value.toLowerCase() === this.STranslate.instant("filter.not_specified").toLowerCase()
						? (data.isReachable = false)
						: (data.isReachable = true);
				});
			});
		}
	}

	/**
	 * Get event users
	 */
	getEventUsers() {
		this.initEventUsersDatas = true;
		let queryConstraints: QueryConstraint[] = [];

		let arrayContainsTerm: string[] = [];
		if (
			this.module &&
			this.module.options &&
			this.module.options.viewOnlyGroupsContent &&
			this.eventUser &&
			this.eventUser.groups.length > 0
		) {
			queryConstraints.push(where("groups", "array-contains-any", this.eventUser.groups));
			if (this.eventUser?.groups.length === 0 && this.module.options.viewOnlyGroupsContent) {
				this.dontBelongAnyGroups = true;
			}
		}

		arrayContainsTerm = _.uniq(arrayContainsTerm);

		if (arrayContainsTerm.length > 0) {
			queryConstraints.push(where("searchStringDatas", "array-contains-any", arrayContainsTerm));
		}

		if (this.module.options.showOnlyLoggedUsers) {
			queryConstraints.push(where("firstAccess", "==", true));
		}

		// Filter on searchbar term if needed
		if (this.searchValue) {
			const queryNameTerm = ["asc", "desc", "recent", "oldest"].includes(this.module.options.usersOrder)
				? this.searchValue.trim().toLocaleUpperCase()
				: this.searchValue.trim();
			queryConstraints = queryConstraints.concat([
				where(
					["asc", "desc", "recent", "oldest"].includes(this.module.options.usersOrder)
						? "queryName"
						: "identifier",
					">=",
					queryNameTerm
				),
				where(
					["asc", "desc", "recent", "oldest"].includes(this.module.options.usersOrder)
						? "queryName"
						: "identifier",
					"<",
					queryNameTerm + "\uf8ff"
				)
			]);
		}

		queryConstraints.push(
			orderBy(
				["asc", "desc"].includes(this.module.options.usersOrder)
					? "queryName"
					: ["recent", "oldest"].includes(this.module.options.usersOrder)
					? "creationDate"
					: "identifier",
				["desc", "recent"].includes(this.module.options.usersOrder) ? "desc" : "asc"
			)
		);

		if (
			this.module &&
			this.module.options &&
			(this.module.options.showFilter || this.module.options.showSearchIcon)
		) {
			const obsArray = [];

			const favoriteChunked = _.chunk(this.eventUser?.favorites, 10);

			if (favoriteChunked.length > 0) {
				favoriteChunked.forEach((chunk) => {
					const clonedQueryConstraint = _.cloneDeep(queryConstraints);
					clonedQueryConstraint.push(where("uid", "in", chunk));

					obsArray.push(
						this.SEventUsers.getEventUsersWithConstraints(
							this.eventId,
							this.folder.moduleLinkedId,
							clonedQueryConstraint
						)
					);
				});
			}

			if (obsArray.length > 0) {
				forkJoin(obsArray)
					.pipe(
						switchMap((results) => {
							const eventUsers = _.uniqBy(_.flatten(results), "uid");
							return of(eventUsers);
						})
					)
					.subscribe((eventUsers) => {
						this.eventUsers = eventUsers;
						this.totalEventUsers = this.eventUsers.length;
						this.buildEventUser();
						this.buildFavorites();
					});
			} else {
				this.eventUsers = [];
				this.totalEventUsers = this.eventUsers.length;
				this.buildEventUser();
				this.buildFavorites();
			}
		} else {
			if (this.eventUser && this.eventUser.favorites && this.eventUser.favorites.length > 0) {
				if (this.lastQuery && this.lastQuery.start && this.lastQuery.last) {
					queryConstraints.push(startAfter(this.lastQuery.last));
				}

				const favoriteChunked = _.chunk(this.eventUser.favorites, 30);

				if (favoriteChunked.length > 0) {
					const obsArray: Observable<any>[] = [];
					const obsCountArray: Observable<number>[] = [];

					favoriteChunked.forEach((chunk) => {
						const clonedQueryConstraint = [...queryConstraints, where("uid", "in", chunk)];

						obsCountArray.push(
							this.SFirestore.getCountOfQueryObs(
								`events/${this.eventId}/modules/${this.folder.moduleLinkedId}/event-users`,
								"default",
								clonedQueryConstraint
							)
						);

						obsArray.push(
							this.SFirestore.getDocumentsPaginated(
								`events/${this.eventId}/modules/${this.folder.moduleLinkedId}/event-users`,
								clonedQueryConstraint.concat([limit(30)]),
								"get",
								"default"
							)
						);
					});

					combineLatest([...obsCountArray, ...obsArray])
						.pipe(
							switchMap((results) => {
								const total = _.sum(results.slice(0, favoriteChunked.length));
								const eventUsers = _.flatten(
									results
										.slice(favoriteChunked.length)
										.map((result) => result.docsDatas as IEventUser)
								);
								results[results.length - 1].docsDatas = eventUsers;
								return of({ total, lastQuery: results[results.length - 1] });
							})
						)
						.subscribe(({ total, lastQuery }) => {
							this.lastQuery = lastQuery;

							this.eventUsers = this.eventUsers.concat(
								lastQuery.docsDatas.filter((eventUser) => {
									return !this.eventUsers.find((evt) => evt.uid === eventUser.uid);
								})
							);
							this.totalEventUsers = total;
							this.buildEventUser();
							this.buildFavorites();
						});
				} else {
					this.eventUsers = [];
					this.buildEventUser();
					this.buildFavorites();
				}
			} else {
				this.eventUsers = [];
				this.buildEventUser();
				this.buildFavorites();
			}
		}
	}

	/**
	 * Filter, sort etc event user
	 */
	buildEventUser() {
		this.getEventUsersUpdatedSettings();
		// Assign data on filteredEventUsers, if filter on slice it with limit number
		if (this.module.options.showFilter) {
			if (this.searchValue.length === 0) {
				if (!this.module.options.showFilter || !this.anyFilterIsActivated()) {
					this.filteredEventUsers = [...this.eventUsers].slice(0, this.limit);
				}
			}
		} else {
			if (this.searchValue.length === 0) {
				if (!this.module.options.showFilter || !this.anyFilterIsActivated()) {
					this.filteredEventUsers = [...this.eventUsers].slice(0, this.limit);
				}
			} else {
				this.filteredEventUsers = _.cloneDeep(this.eventUsers).filter((eventUser) =>
					this.SUtility.removeAccents(eventUser.name)
						.toLocaleLowerCase()
						.includes(this.searchValue.toLocaleLowerCase())
				);
				// this.filteredEventUsers = _.cloneDeep(this.eventUsers);
			}
		}

		this.groupedLetters = this.filteredEventUsers.reduce((groups, eventUser) => {
			const letter = eventUser.queryName.charAt(0);
			if (!Object.values(groups).includes(letter)) {
				const uid = eventUser.uid;
				groups[uid] = letter;
			}
			return groups;
		}, {});

		this.getCustomFields();

		if (this.anyFilterIsActivated()) {
			this.currentSize = this.filteredEventUsers.length;
		} else {
			this.currentSize = this.eventUsers.length;
		}

		if (this.module && this.module.options && this.module.options.showFilter) {
			// this.totalEventUsers = this.filteredEventUsers.length;
		} else {
			this.getListOnTwoBloc();
		}
		this.loader = false;
	}

	/**
	 * Take more event users
	 * @param evt
	 */
	// takeMoreEventUsers() {
	// 	this.limit = this.limit + 30;
	// 	if (this.module && this.module.options && this.module.options.showFilter) {
	// 		this.buildEventUser();
	// 	} else {
	// 		this.getEventUsers();
	// 	}
	// }

	/**
	 * canShowFilterBloc
	 * @description return true if the filter is at least one custom field that can be filtered
	 * @returns boolean
	 */

	canShowFilterBloc() {
		return (
			this.customFields.some((cus) => cus.moduleSettings.enableFilter) ||
			(this.module.options.requiredFields.group.filter && this.groups.length > 0)
		);
	}

	/**
	 * Get list of event users on two bloc
	 * @returns
	 */
	getListOnTwoBloc() {
		let count = 0;
		let index = 0;
		const newFilteredEventUsersOnTwo = [];
		this.filteredEventUsers.forEach((eventUser) => {
			if (count === 2) {
				index++;
				count = 0;
			}
			if (!newFilteredEventUsersOnTwo[index]) {
				newFilteredEventUsersOnTwo[index] = [];
			}
			newFilteredEventUsersOnTwo[index].push(eventUser);
			count++;
		});
		if (!_.isEqual(this.filteredEventUsersInTwo, newFilteredEventUsersOnTwo)) {
			this.filteredEventUsersInTwo = newFilteredEventUsersOnTwo;
		}
	}

	/**
	 * Get filter term exist
	 * @param term
	 * @returns
	 */
	getFilterTermExist(term: string) {
		return this.SFirestore.getCountOfQueryObs(
			`events/${this.eventId}/modules/${this.moduleId}/event-users`,
			"default",
			[where("searchStringDatas", "array-contains", term)]
		).pipe(
			take(1),
			switchMap((count) => of(count > 0 ? true : false))
		);
	}

	/**
	 * Get total of filter term
	 * @param term
	 * @returns
	 */
	getTotalFilterTerm(term: string) {
		return this.SFirestore.getCountOfQueryObs(
			`events/${this.eventId}/modules/${this.moduleId}/event-users`,
			"default",
			[where("searchStringDatas", "array-contains", term)]
		).pipe(take(1));
	}

	/**
	 * Get groups
	 */
	getGroups() {
		this.subscriptions.push(
			this.store.select(getGroupsByOrder("asc")).subscribe((groups) => {
				this.groups = groups;
				this.groupIds = this.groups.map((group) => group.uid);
			})
		);
	}

	/**
	 * Get custom field data
	 * @returns
	 */
	getCustomFieldData(user: IEventUser, index: number) {
		const customField = this.tagCustomFields[index];
		const customFieldData = user.customFields.find((customData) => customData.uid === customField.baseSettings.uid);
		return customFieldData && customFieldData.field
			? customFieldData.field.multiLanguageText[this.currentLanguage]
			: "";
	}

	/**
	 * Present toast time
	 * @param time
	 */
	async presentToast(time) {
		const toast = await this.toastCtrl.create({
			message: time,
			duration: 3000
		});
		toast.present();
	}

	/**
	 * Show letters
	 * @returns
	 */
	eventUserHeader() {
		if (!this.module.options.showLetters) {
			return null;
		} else {
			return (eventUser, i, eventUsers) => {
				const letter = eventUser.name[0].toUpperCase();
				if (i === 0 || (i !== 0 && letter !== eventUsers[i - 1].name[0].toUpperCase())) {
					return letter;
				}
			};
		}
	}

	/**
	 * Search bar
	 * @param ev
	 */
	searchBar(ev) {
		if (ev.target.value.length >= 1) {
			const value = this.SUtility.removeAccents(ev.target.value);
			this.searchValue = value;
			if (this.module.options.showFilter || this.module.options.showSearchIcon) {
				// this.filteredEventUsers = [];
				this.filteredEventUsers = [...this.eventUsers].filter((eventUser) =>
					this.SUtility.removeAccents(eventUser.name).toLocaleLowerCase().includes(value.toLocaleLowerCase())
				);
				this.getListOnTwoBloc();
			} else {
				this.getEventUsers();
			}
			if (this.filteredEventUsers.length === 0) this.showNoResultImage = true;
		} else {
			this.resetFilter();
		}
	}

	/**
	 * filterEventUser
	 * @description filter event users with custom fields
	 * @param ev
	 * @param filterId
	 */
	filterEventUser(ev, filterId: string) {
		if (ev && ev !== null) {
			const data = ev.target.value as IFilterOptions;
			this.filterItems
				.find((item) => item.uid === filterId)
				.values.find((value) => value.value.toLowerCase() === data.value.toLowerCase()).isSelected =
				!data.isSelected;

			this.selectedDatas.indexOf(data) === -1
				? this.selectedDatas.push(data)
				: this.selectedDatas.splice(this.selectedDatas.indexOf(data), 1);

			this.selectedFilters = this.filterItems.filter((item) =>
				item.values.some(() => item.values.find((value) => this.selectedDatas.indexOf(value) !== -1))
			);
		}

		// if (this.module && this.module.options && this.module.options.showFilter && !this.moduleUpdatedSettings) {
		if (this.module && this.module.options && this.module.options.showFilter) {
			this.filteredEventUsers = this.eventUsers.filter((eventUser) => {
				let matches = 0;
				this.selectedFilters.forEach((filter) => {
					if (
						(filter.uid === "group" &&
							filter.values.find((value) =>
								value.isSelected
									? eventUser.groups.find(
											(grId) =>
												this.getCorrespondingGroup(grId)?.name.toLowerCase() ===
												value.value.toLowerCase()
									  ) ||
									  (eventUser.groups.length === 0 &&
											value.value.toLowerCase() ===
												this.STranslate.instant("filter.not_specified").toLowerCase())
									: false
							)) ||
						filter.values.find((value) =>
							value.isSelected
								? eventUser.customFields.find((cus) =>
										this.getCustomFieldType(cus.uid) === TypeCustomFields.TEXT ||
										this.getCustomFieldType(cus.uid) === TypeCustomFields.SELECT
											? (filter.uid === cus.uid &&
													value.value.toLowerCase() ===
														cus.field.multiLanguageText[
															this.currentLanguage
														].toLowerCase()) ||
											  (filter.uid === cus.uid &&
													value.value.toLowerCase() ===
														this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.multiLanguageText[this.currentLanguage] === "")
											: this.getCustomFieldType(cus.uid) === TypeCustomFields.URL ||
											  this.getCustomFieldType(cus.uid) === TypeCustomFields.EMAIL
											? (filter.uid === cus.uid &&
													value.value.toLowerCase() === cus.field.text?.toLowerCase()) ||
											  (filter.uid === cus.uid &&
													value.value.toLowerCase() ===
														this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.text === "")
											: this.getCustomFieldType(cus.uid) === TypeCustomFields.NUMERIC
											? (filter.uid === cus.uid &&
													value.value.toLowerCase() ===
														cus.field.numeric?.toString().toLowerCase()) ||
											  (filter.uid === cus.uid &&
													value.value.toLowerCase() ===
														this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.numeric === -1)
											: this.getCustomFieldType(cus.uid) === TypeCustomFields.MULTI_SELECT
											? (filter.strictSelectionMode &&
													filter.uid === cus.uid &&
													filter.values
														.filter((v) => v.isSelected)
														.map((opt) => opt.value.toLowerCase())
														.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
														.join(", ") ===
														cus.field.multiLanguageSelectArray
															.map((opt) => opt[this.currentLanguage].toLowerCase())
															.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
															.join(", ")) ||
											  (!filter.strictSelectionMode &&
													filter.uid === cus.uid &&
													cus.field.multiLanguageSelectArray
														.map((opt) => opt[this.currentLanguage].toLowerCase())
														.includes(value.value.toLowerCase())) ||
											  (filter.uid === cus.uid &&
													value.value.toLowerCase() ===
														this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.multiLanguageSelectArray.map(
														(opt) => opt[this.currentLanguage]
													).length === 0)
											: false
								  )
								: false
						)
					)
						matches++;
				});

				if (matches === this.selectedFilters.length) {
					return eventUser;
				}
			});
			this.getListOnTwoBloc();

			this.computeReachableDatas();
		}
		// else {
		// 	this.getEventUsers();
		// 	this.computeReachableDatas();
		// }
	}

	/**
	 * changeSelectionMode
	 * @param filterItem
	 */
	changeSelectionMode(filterItem: IFilteredItemFormat) {
		this.clearFilter(filterItem);

		// Disable the "not_specified" field
		const notSpecifierValue = filterItem.values.find(
			(v) => v.value.toLowerCase() === this.STranslate.instant("filter.not_specified").toLowerCase()
		);

		!filterItem.strictSelectionMode && notSpecifierValue
			? (notSpecifierValue.isReachable = false)
			: (notSpecifierValue.isReachable = true);

		// Change mode
		filterItem.strictSelectionMode = !filterItem.strictSelectionMode;
	}

	/**
	 * clearFilter
	 * @description remove checked option from the given filter
	 * @param filter
	 */
	clearFilter(filter: IFilteredItemFormat) {
		this.filterItems.find((filterItem) => filterItem.uid === filter.uid).values = this.filterItems
			.find((filterItem) => filterItem.uid === filter.uid)
			.values.map((data) => {
				return {
					isNotSpecified: data.isNotSpecified,
					isReachable:
						filter.strictSelectionMode &&
						data.value.toLowerCase() === this.STranslate.instant("filter.not_specified").toLowerCase()
							? false
							: data.isReachable,
					isSelected: false,
					value: data.value,
					filterId: filter.uid,
					totalDatas: 0
				};
			});

		this.selectedFilters = this.selectedFilters.filter((selectedFilter) => selectedFilter.uid !== filter.uid);
		this.selectedDatas = this.selectedDatas.filter((selectedData) => selectedData.filterId !== filter.uid);

		this.filterEventUser(null, filter.uid);
	}

	/**
	 * clearAllFilter
	 * @description remove all checked options from all filters
	 */
	clearAllFilter() {
		this.filterItems = this.filterItems.map((filterItem) => {
			filterItem.values = filterItem.values.map((data) => {
				return {
					isNotSpecified: data.isNotSpecified,
					isReachable: true,
					isSelected: false,
					value: data.value,
					filterId: filterItem.uid,
					totalDatas: 0
				};
			});
			this.selectedFilters = [];
			this.selectedDatas = [];
			this.filteredEventUsers = [...this.eventUsers];
			this.getListOnTwoBloc();

			return filterItem;
		});
	}

	/**
	 * filterIsActivated
	 * @description return boolean on whether the given filter has a selected data in it or not
	 * @param filter
	 * @returns filter
	 */
	filterIsActivated(filter: IFilteredItemFormat) {
		return filter.values.some((data) => data.isSelected);
	}

	/**
	 * anyFilterIsActivated
	 * @description return boolean on whether at least one filter has a selected data in it or not
	 * @param filter
	 * @returns filter
	 */
	anyFilterIsActivated(): boolean {
		return this.selectedFilters.length > 0;
	}

	/**
	 * getEventUserTags
	 * @param eventUser
	 * @returns
	 */
	getEventUserTags(eventUser: IEventUser): ICustomFieldData[] {
		return eventUser.customFields
			.filter(
				(eventUserCustomField) =>
					eventUserCustomField &&
					this.customFields.find(
						(computedCustomField) =>
							computedCustomField.baseSettings.uid === eventUserCustomField.uid &&
							computedCustomField.moduleSettings.canBeTag
					) &&
					!this.isUserOrModuleFieldsVisibilityHidden(eventUser, eventUserCustomField.uid, "customFields") &&
					((eventUserCustomField.field.text &&
						eventUserCustomField.field.text.trim() &&
						eventUserCustomField.field.text?.trim() !== "") ||
						(eventUserCustomField.field.multiLanguageText &&
							eventUserCustomField.field.multiLanguageText[this.currentLanguage]?.trim() &&
							eventUserCustomField.field.multiLanguageText[this.currentLanguage]?.trim() !== "") ||
						(eventUserCustomField.field.numeric && eventUserCustomField.field.numeric !== -1))
			)
			.sort((a, b) =>
				this.getCustomFieldOrder(a) > this.getCustomFieldOrder(b)
					? 1
					: this.getCustomFieldOrder(a) < this.getCustomFieldOrder(b)
					? -1
					: 0
			);
	}

	/**
	 * isUserOrModuleFieldsVisibilityHidden
	 * @param key
	 */
	isUserOrModuleFieldsVisibilityHidden(eventUser: IEventUser, key: string, type: "baseFields" | "customFields") {
		if (type === "baseFields") {
			return this.module.options.enableUserFieldsHideAbility &&
				(eventUser.updatedSettings?.fieldsVisibility?.[key] === undefined ||
					(eventUser.updatedSettings?.fieldsVisibility?.[key] !== undefined &&
						eventUser.updatedSettings?.fieldsVisibility?.[key] !==
							this.module.options.requiredFields?.[key]?.hiding?.default &&
						!eventUser.editedProfile))
				? this.module.options.requiredFields?.[key]?.hiding?.default
				: this.module.options.requiredFields?.[key]?.hiding?.default
				? eventUser.updatedSettings?.fieldsVisibility?.[key]
				: eventUser.updatedSettings?.fieldsVisibility?.[key]
				? false
				: false;
		} else {
			const correspondingCus = this.customFields.find((cus) => cus.baseSettings.uid === key);

			if (correspondingCus) {
				return !this.module.options.enableUserFieldsHideAbility
					? false
					: this.module.options.enableUserFieldsHideAbility &&
					  (eventUser.updatedSettings?.fieldsVisibility?.[key] === undefined ||
							(eventUser.updatedSettings?.fieldsVisibility?.[key] !== undefined &&
								eventUser.updatedSettings?.fieldsVisibility?.[key] !==
									correspondingCus.moduleSettings.hiding?.default &&
								!eventUser.editedProfile))
					? correspondingCus.moduleSettings.hiding?.default
					: correspondingCus.moduleSettings.hiding?.default
					? eventUser.updatedSettings?.fieldsVisibility?.[key]
					: eventUser.updatedSettings?.fieldsVisibility?.[key]
					? false
					: false;
			}
		}
	}

	/**
	 * getCustomFieldOrder
	 */
	getCustomFieldOrder(customField: ICustomFieldData): number {
		return this.customFields.find((cus) => cus.baseSettings.uid === customField.uid)
			? this.customFields.find((cus) => cus.baseSettings.uid === customField.uid).moduleSettings.order
			: -1;
	}

	/**
	 * Reset filter
	 */
	resetFilter() {
		this.searchValue = "";
		if (this.module && this.module.options && this.module.options.showFilter) {
			this.filteredEventUsers = this.eventUsers.length === 0 ? [] : [...this.eventUsers];
			this.getListOnTwoBloc();
			this.showNoResultImage = false;
		} else {
			this.getEventUsers();
			this.showNoResultImage = false;
		}
	}

	/**
	 * openFilterModal
	 */
	async openMobileFilterModal() {
		// this.loader = true;
		const filterModal = await this.modalController.create({
			component: FilterComponent,
			componentProps: {
				event: this.event,
				module: this.module,
				language: this.currentLanguage,
				eventUsersfilters: this.filterItems,
				selectedFilters: this.selectedFilters,
				notSelectedFilters: this.notSelectedFilters,
				filteredEventUsers: this.filteredEventUsers,
				eventUsers: this.eventUsers,
				selectedDatas: this.selectedDatas,
				customFields: this.customFields,
				groups: this.groups
			},
			cssClass: "filter-modal-css",
			showBackdrop: true,
			presentingElement: await this.modalController.getTop() // Get the top-most ion-modal
		});

		await filterModal.present();

		const { data } = await filterModal.onWillDismiss();

		if (data) {
			this.filterItems = data.filterItems;
			this.notSelectedFilters = data.notSelectedFilters;
			this.filteredEventUsers = data.filteredEventUsers;
			this.getListOnTwoBloc();
			this.eventUsers = data.eventUsers;
			this.selectedDatas = data.selectedDatas;
			this.selectedFilters = data.selectedFilters;
			this.customFields = data.customFields;
			this.selectedFilters = data.selectedFilters;
		}
	}

	/**
	 * getCustomFieldType
	 * @param customFieldId
	 * @returns
	 */
	getCustomFieldType(customFieldId: string): number {
		return this.customFields.find((cus) => cus.baseSettings.uid === customFieldId)?.baseSettings.type;
	}

	/**
	 * Navigate to path
	 * @param path
	 */
	navigateTo(path: string) {
		this.navCtrl.navigateForward(path);
	}

	/**
	 * scrolled item index changed
	 * @param index
	 */
	limitScroll: boolean = false;
	scrolledIndexChanged(index: number, _el: any) {
		const nav = this.navigationSaved.find((navMod) => navMod.moduleId === this.moduleId);
		if (this.init) {
			if (!nav) {
				this.navigationSaved.push({
					moduleId: this.moduleId,
					lastIndex: index
				});
			} else {
				nav.lastIndex = index;
			}
		}
		if (
			this.virtualScroll.getRenderedRange().end >= this.virtualScroll.getDataLength() - 2 &&
			this.virtualScroll.getRenderedRange().end <= this.virtualScroll.getDataLength() &&
			this.init &&
			!this.limitScroll &&
			this.totalEventUsers > this.virtualScroll.getDataLength()
		) {
			this.limitScroll = true;
			this.limit = this.limit + 30;
			if (
				this.module &&
				this.module.options &&
				(this.module.options.showFilter || this.module.options.showSearchIcon)
			) {
				this.buildEventUser();
				this.buildFavorites();
			} else {
				this.getEventUsers();
			}
			setTimeout(() => {
				this.limitScroll = false;
			}, 1000);
		}
	}

	/**
	 * collapse
	 * @param index
	 */
	collapseFilter(filterId: string) {
		this.filtersCollapseState.find((filter) => filter.uid === filterId).collapsed = !this.filtersCollapseState.find(
			(filter) => filter.uid === filterId
		).collapsed;
	}

	/**
	 * getFilterCollapseState
	 */
	getFilterCollapseState(filterId: string): boolean {
		return this.filtersCollapseState.find((filter) => filter.uid === filterId)
			? this.filtersCollapseState.find((filter) => filter.uid === filterId).collapsed
			: false;
	}

	/**
	 * getCorrespondingGroup
	 * @param groupId
	 * @returns
	 */
	getCorrespondingGroup(groupId: string) {
		return this.groups.find((gr) => gr.uid === groupId);
	}

	/**
	 * removeFromFavorite
	 * @param eventUserId
	 * @param event
	 */
	async removeFromFavorite(eventUserId: string, event) {
		try {
			event.stopPropagation();

			this.eventUser.favorites.includes(eventUserId) &&
				(this.eventUser.favorites = this.eventUser.favorites.filter((fav) => fav !== eventUserId));

			this.eventUsers = this.eventUsers.filter((eventUser) => eventUser.uid !== eventUserId);
			await this.SEventUsers.updatePartOfEventUser(this.eventId, this.eventUser.moduleId, this.eventUser.uid, {
				favorites: this.eventUser.favorites
			});
			this.getEventUsers();
			this.snackBar.open(this.STranslate.instant("snackbar.update_successfull"), null, {
				duration: 3000,
				panelClass: ["snackbar-success"]
			});
		} catch (error) {
			console.error("🚀 ~ removeFromFavorite ~ error:", error);
			// Snackbar error
			this.snackBar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}

	/**
	 * checkFavorite
	 * @param eventUserId
	 * @returns
	 */
	buildFavorites() {
		this.eventUsers.forEach((eventUser) => {
			if (this.eventUser?.favorites?.includes(eventUser.uid)) {
				this.eventUserFavoritesState = {
					...this.eventUserFavoritesState,
					[eventUser.uid]: true
				};
			} else {
				this.eventUserFavoritesState = {
					...this.eventUserFavoritesState,
					[eventUser.uid]: false
				};
			}
		});
	}

	/**
	 * Scan qr code
	 */
	scanQr() {
		try {
			if (!this.scanning) {
				this.loader = true;
				this.scanning = true;

				// For web scanning
				if (!this.isMobile) {
					return;
				}

				this.SFavorite.perfomScanQr(this.event, this.module, this.eventUser, this.customFields);

				this.scanning = false;
				this.initEventUsersDatas = false;
			}
		} catch (error) {
			this.scanning = false;

			console.error("🚀 ~ scanQr ~ error:", error);
			this.snackBar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}
}
