import { Component, OnDestroy } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Subscription, map, take } from "rxjs";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { IEvent, IModule } from "src/app/shared/interfaces";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { FirestoreService, UtilityService } from "src/app/shared/services";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { INote } from "src/app/shared/interfaces/notes.interfaces";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-note-taking-details",
    templateUrl: "./note-taking-details.component.html",
    styleUrls: ["./note-taking-details.component.scss"],
    standalone: false
})
export class NoteTakingDetailsComponent implements OnDestroy {
	subscriptions: Subscription[] = [];
	updating: boolean = false;

	event: IEvent;
	eventId: string;
	moduleId: string;
	module: IModule;
	noteId: string;
	note: INote;

	isMobile: boolean = false;

	constructor(
		private store: Store,
		private platform: Platform,
		private STranslate: TranslateService,
		private SFirestore: FirestoreService,
		public SUtility: UtilityService
	) {}

	ionViewWillEnter() {
		this.isMobile = this.platform.is("mobile") && window.innerWidth < 768 ? true : false;

		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.noteId = params.noteId;

						this.getEvent();
						this.getModule();
						this.getNote();
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.store
			.select(getCurrentEvent)
			.pipe(take(1))
			.subscribe((event) => {
				this.event = event;
			});
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.store
			.select(getSpecificModule(this.moduleId))
			.pipe(take(1))
			.subscribe((module) => {
				this.module = module;
			});
	}

	/**
	 * Getting note
	 */
	getNote() {
		this.SFirestore.getDocumentObs(`events/${this.eventId}/modules/${this.moduleId}/notes/${this.noteId}`)
			.pipe(map((snapshot) => snapshot.data() as INote))
			.subscribe((note) => {
				this.note = note;

				if (this.note) {
					this.store.dispatch(
						GetHeaderTitle({
							payload: {
								ArAR: this.note.title,
								DeDE: this.note.title,
								EnUS: this.note.title,
								EsES: this.note.title,
								FrFR: this.note.title,
								PtBR: this.note.title
							}
						})
					);
				}
			});
	}

	async shareNote() {
		try {
			await this.SUtility.shareText(
				this.note.title,
				`${this.note.title}\n\n${this.note.content}`,
				this.STranslate.instant("note-taking.share-title")
			);
		} catch (error) {
			//console.log("Error: ", error);
		}
	}
}
