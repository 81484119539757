import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { DateTime } from "luxon";
import { combineLatest, Subscription } from "rxjs";
import { TypeUser } from "src/app/shared/enums/type-user";
import { ICustomFieldData, IEvent, IEventUser, IFullCustomField } from "src/app/shared/interfaces";
import { ILike, IReactionEmoji } from "src/app/shared/interfaces/feed-news.interfaces";
import {
	getBaseCustomFields,
	getModulesCustomsFieldsOfModule
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { EventUsersService, FeedNewsService } from "src/app/shared/services";

@Component({
    selector: "app-feed-news-list-reaction",
    templateUrl: "./feed-news-list-reaction.component.html",
    styleUrls: ["./feed-news-list-reaction.component.scss"],
    standalone: false
})
export class FeedNewsListReactionComponent implements OnInit, OnDestroy {
	eventUsersSub: Subscription;
	subscriptions: Subscription[] = [];
	@Input() likes: ILike[] = [];
	@Input() event: IEvent;
	groupedReaction = [];
	listIcons: IReactionEmoji[] = [];
	allUser: IEventUser[] = [];
	filtredUser: IEventUser[] = [];

	customFields: IFullCustomField[] = [];

	isMobile: boolean = window.innerWidth < 768;

	constructor(
		private SEventUsers: EventUsersService,
		private navCtrl: NavController,
		private store: Store,
		public modalCtr: ModalController,
		private SFeedNews: FeedNewsService
	) {
		this.listIcons = this.SFeedNews.getReactionEmojis();
	}

	ngOnInit(): void {
		this.groupedReaction = this.listIcons
			.filter((icon) => this.likes.some((like) => like.type.toString() === icon.name))
			.map((icon) => {
				const totalUser = this.likes.filter((like) => like.type.toString() === icon.name).length;
				return Object.assign(icon, { totalUser });
			});
		const allUserIds = this.likes
			.sort((a, b) => DateTime.fromISO(b.creationDate).valueOf() - DateTime.fromISO(a.creationDate).valueOf())
			.map((like) => like.userId);
		if (this.eventUsersSub && !this.eventUsersSub.closed) {
			this.eventUsersSub.unsubscribe();
		}
		this.eventUsersSub = combineLatest([
			this.SEventUsers.valueChangesSpecificEventUsersForEvent(this.event.uid, TypeUser.ATTENDEE, allUserIds),
			this.SEventUsers.valueChangesSpecificEventUsersForEvent(this.event.uid, TypeUser.SPEAKER, allUserIds)
		]).subscribe((results) => {
			this.allUser = results[0];
			this.allUser = [...this.allUser, ...results[1]];
			const moduleIds = Array.from(new Set(this.allUser.map((user) => user.moduleId)));
			moduleIds.forEach((moduleId) => this.getCustomFields(moduleId));
			this.segmentChanged({ detail: { value: "all" } });
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		if (this.eventUsersSub && !this.eventUsersSub.closed) {
			this.eventUsersSub.unsubscribe();
		}
	}

	/**
	 * Segment Changed
	 * @param ev
	 */
	segmentChanged(ev) {
		const value = ev.detail.value;
		if (value === "all") {
			this.filtredUser = this.allUser;
		} else {
			this.filtredUser = this.likes
				.filter((like) => like.type.toString() === value)
				.map((like) => this.getUser(like.userId));
		}
	}

	/**
	 * Get user
	 * @param userId
	 * @returns
	 */
	getUser(userId: string) {
		return this.allUser.find((user) => user.uid === userId);
	}

	/**
	 * Visite profile
	 * @param eventUser
	 */
	visiteProfile(eventUser: IEventUser) {
		this.modalCtr.dismiss();
		this.navCtrl.navigateForward(`/event/${this.event.uid}/profile/${eventUser.moduleId}/${eventUser.uid}`);
	}

	/**
	 * getEventUserTags
	 * @param eventUser
	 * @returns
	 */
	getEventUserTags(eventUser: IEventUser): ICustomFieldData[] {
		return eventUser.customFields.filter(
			(eventUserCustomField) =>
				this.customFields.find(
					(computedCustomField) =>
						computedCustomField.baseSettings.uid === eventUserCustomField.uid &&
						computedCustomField.moduleSettings.canBeTag
				) &&
				((eventUserCustomField.field.text && eventUserCustomField.field.text.trim()) ||
					(eventUserCustomField.field.multiLanguageText &&
						eventUserCustomField.field.multiLanguageText[this.event.language]?.trim()))
		);
	}

	/**
	 * Get custom fields
	 */
	getCustomFields(moduleId: string) {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getBaseCustomFields),
				this.store.select(getModulesCustomsFieldsOfModule(moduleId))
			]).subscribe((results) => {
				this.customFields = [];
				if (results[1].length > 0) {
					this.customFields = results[1]
						.map((customField) => {
							const baseCustomFieldCorresponding = results[0].find(
								(custField) => custField.uid === customField.uid
							);
							return baseCustomFieldCorresponding
								? {
										baseSettings: baseCustomFieldCorresponding,
										moduleSettings: customField,
										fieldDatas: null
								  }
								: null;
						})
						.filter((customField) => customField);
				}
				// this.tagCustomFields = this.customFields.filter((custom) => custom.moduleSettings);
			})
		);
	}

	/**
	 * Get icon
	 * @param eventUser
	 * @returns
	 */
	getIcon(eventUser: IEventUser) {
		return this.listIcons.find((icon) =>
			this.likes.find((like) => like.userId === eventUser.uid && like.type.toString() === icon.name)
		);
	}
}
