import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalController, NavParams, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash-es";
import { Subscription } from "rxjs";
import { IEvent, ILocation } from "src/app/shared/interfaces";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificLocation } from "src/app/shared/selectors/generics-modules-data.selectors";

@Component({
    selector: "app-location-modal",
    templateUrl: "./location-modal.component.html",
    styleUrls: ["./location-modal.component.scss"],
    standalone: false
})
export class LocationModalComponent implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];

	event: IEvent;
	locationId: string;
	location: ILocation;

	isMobile: boolean = false;

	constructor(
		private store: Store,
		private platform: Platform,
		public modalCtrl: ModalController,
		private navParams: NavParams
	) {}

	ngOnInit() {
		this.isMobile =
			this.platform.is("ios") || this.platform.is("android") || this.platform.is("mobileweb") ? true : false;
		this.locationId = this.navParams.get("locationId");

		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;
				}
			})
		);
		this.subscriptions.push(
			this.store.select(getSpecificLocation(this.locationId)).subscribe((location) => {
				if (!_.isEqual(this.location, location)) {
					this.location = location;
				}
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub?.unsubscribe());
	}

	/**
	 * Close modal
	 */
	close() {
		this.modalCtrl.dismiss();
	}

	removeSpace(text: string) {
		return text.trim();
	}

	/**
	 * Open google plan
	 * @param address
	 */
	openGooglePlan(address: string) {
		const addressFormated = address.replaceAll(", ", ",").replaceAll(" ", "+");
		window.open("https://www.google.com/maps/place/" + addressFormated, "_blank");
	}

	/**
	 * Get google plan
	 * @param address
	 * @returns
	 */
	getGooglePlan(address: string, width: number) {
		const addressFormated = address.replaceAll(", ", ",").replaceAll(" ", "+");

		return (
			"https://maps.googleapis.com/maps/api/staticmap?size=" +
			Math.ceil(width) +
			"x" +
			Math.ceil(width / 2) +
			"&maptype=roadmap" +
			"&markers=color:red|" +
			addressFormated +
			"&key=AIzaSyAOhR7K9nZ0INeOIR_SL8bE5xzP2sV84do"
		);
	}
}
