/* eslint-disable no-empty */
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { DateTime } from "luxon";
import { combineLatest, Subscription } from "rxjs";
import { TypeLikeFeedNews } from "src/app/shared/enums/feed-new-like-type";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { IEvent, IEventUser, IModule, IPost, IUser } from "src/app/shared/interfaces";
import { IComment, ILike } from "src/app/shared/interfaces/feed-news.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getCurrentEventUser, getCurrentUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificPost } from "src/app/shared/selectors/feed-news.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { AnalyticsService, FeedNewsService, UtilityService } from "src/app/shared/services";

@Component({
    selector: "app-feed-news-comments",
    templateUrl: "./feed-news-comments.component.html",
    styleUrls: ["./feed-news-comments.component.scss"],
    standalone: false
})
export class FeedNewsCommentsComponent implements OnInit, OnChanges, OnDestroy {
	subscriptions: Subscription[] = [];
	analyticsArraySub: { name: string; sub: Subscription }[] = [];

	@Output() closeComments: EventEmitter<boolean> = new EventEmitter();

	@Input() eventId: string;
	event: IEvent;
	@Input() moduleId: string;
	module: IModule;
	@Input() postId: string;
	post: IPost;
	@Input() userId: string;
	eventUser: IEventUser;
	user: IUser;
	comments: IComment[] = [];
	likes: ILike[] = [];

	textArea: string = "";

	constructor(
		// private navParams: NavParams,
		private SAnalytics: AnalyticsService,
		private modalCtrl: ModalController,
		private SFeedNews: FeedNewsService,
		private SUtility: UtilityService,
		private platform: Platform,
		private store: Store
	) {
		// this.eventId = this.navParams.get("eventId");
		// this.moduleId = this.navParams.get("moduleId");
		// this.postId = this.navParams.get("postId");
		// this.userId = this.navParams.get("userId");
	}

	mobileBrowser = false;

	ngOnInit() {
		// this.getEvent();
		// this.getPost();
		// this.getModule();
		// this.getUser();
		// this.getComments();
		// this.getLikes();
		this.mobileBrowser = this.platform.platforms().includes("mobileweb");
	}

	ngOnChanges(changes) {
		if (changes.eventId || changes.moduleId || changes.postId || changes.userId) {
			this.subscriptions.forEach((sub) => sub.unsubscribe());

			this.getEvent();
			this.getPost();
			this.getModule();
			this.getUser();
			this.getComments();
			this.getLikes();
			this.mobileBrowser = this.platform.platforms().includes("mobileweb");
		}
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());
	}

	/**
	 * Getting event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Get module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				this.module = module;

				if (this.module) {
					this.getPost();
				}
			})
		);
	}

	/**
	 * Get post
	 */
	getPost() {
		this.subscriptions.push(
			this.store.select(getSpecificPost(this.postId)).subscribe((post) => {
				if (post) {
					this.post = post;
				}
			})
		);
	}

	/**
	 * Get comments of post
	 */
	getComments() {
		this.subscriptions.push(
			this.SFeedNews.getCommentsOfPost(this.eventId, this.moduleId, this.postId).subscribe((comments) => {
				this.comments = comments;
			})
		);
	}

	/**
	 * Get likes of post
	 */
	getLikes() {
		this.subscriptions.push(
			this.SFeedNews.getLikesOfPost(this.eventId, this.moduleId, this.postId).subscribe((likes) => {
				this.likes = likes;
			})
		);
	}

	/**
	 * Get user data
	 */
	getUser() {
		this.subscriptions.push(
			combineLatest([this.store.select(getCurrentUser), this.store.select(getCurrentEventUser)]).subscribe(
				(results) => {
					this.user = results[0];
					this.eventUser = results[1];
				}
			)
		);
	}

	/**
	 * Verify if user have liked the post
	 * @returns
	 */
	verifiesLikedPost() {
		return this.likes.find((like) => like.userId === this.userId) ? true : false;
	}

	/**
	 * Add or remove like
	 */
	async addOrRemoveLike() {
		try {
			if (this.userId) {
				if (!this.verifiesLikedPost()) {
					const like: ILike = {
						uid: "",
						creationDate: DateTime.local().toISO(),
						eventId: this.eventId,
						moduleId: this.moduleId,
						postId: this.postId,
						userId: this.userId,
						type: TypeLikeFeedNews.CLAP
					};
					this.post.totalLikes++;
					this.SFeedNews.updatePost(this.eventId, this.moduleId, this.post.uid, this.post);
					await this.SFeedNews.createLike(this.eventId, this.moduleId, this.postId, like);

					// Tracking analytic (send like)
					this.SAnalytics.sendOrRemoveLike(
						this.eventId,
						this.moduleId,
						this.eventUser,
						this.post.uid,
						TypeTracking.SEND_LIKE_ON_FEED
					);
				} else {
					const like = this.likes.find((like) => like.userId === this.userId);

					if (like) {
						this.post.totalLikes--;
						this.SFeedNews.updatePost(this.eventId, this.moduleId, this.post.uid, this.post);
						await this.SFeedNews.deleteLike(this.eventId, this.moduleId, this.postId, like);

						// Tracking analytic (remove like)
						this.SAnalytics.sendOrRemoveLike(
							this.eventId,
							this.moduleId,
							this.eventUser,
							this.post.uid,
							TypeTracking.REMOVE_LIKE_ON_FEED
						);
					}
				}
			}
		} catch (error) {}
	}

	/**
	 * Create a new comment
	 */
	async createComment() {
		if (this.userId && this.eventUser && this.textArea.length > 0) {
			const comment: IComment = {
				comment: this.textArea,
				creationDate: DateTime.local().toISO(),
				eventId: this.eventId,
				moduleId: this.moduleId,
				postId: this.postId,
				user: {
					uid: this.eventUser.uid,
					creationDate: this.eventUser.creationDate,
					name: this.eventUser.name,
					photoUrl: this.eventUser.photoUrl,
					type: this.eventUser.type
				},
				uid: ""
			};

			try {
				this.post.totalComments++;
				this.SFeedNews.updatePost(this.eventId, this.moduleId, this.post.uid, this.post);
				await this.SFeedNews.createComment(this.eventId, this.moduleId, this.postId, comment);

				this.textArea = "";
				const textAreaEl = document.getElementById("chatInput");
				textAreaEl.style.minHeight = "50px";
				textAreaEl.style.height = "auto";

				// Tracking analytic (Send comment on feed)
				this.SAnalytics.sendOrRemoveComment(
					this.eventId,
					this.moduleId,
					this.eventUser,
					this.post.uid,
					TypeTracking.SEND_COMMENT_ON_FEED,
					comment.creationDate
				);
			} catch (error) {}
		}
	}

	/**
	 * Delete a comment
	 * @param comment
	 */
	async deleteComment(comment: IComment) {
		try {
			this.post.totalComments--;
			this.SFeedNews.updatePost(this.eventId, this.moduleId, this.post.uid, this.post);
			await this.SFeedNews.deleteComment(this.eventId, this.moduleId, this.postId, comment);

			// Tracking analytic (Delete comment on feed)
			this.SAnalytics.sendOrRemoveComment(
				this.eventId,
				this.moduleId,
				this.eventUser,
				this.post.uid,
				TypeTracking.DELETE_COMMENT_ON_FEED,
				comment.creationDate
			);
		} catch (error) {}
	}

	/**
	 * Close modal
	 */
	close() {
		const textAreaEl = document.getElementById("chatInput");
		textAreaEl.style.minHeight = "50px";
		textAreaEl.style.height = "auto";
		this.closeComments.emit(false);
		// this.modalCtrl.dismiss();
	}

	/**
	 * Open image
	 * @param url
	 */
	async openImage(url: string) {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.imageViewerModal,
				componentProps: {
					img: url
				}
			});
			modal.present();
		} catch (error) {}
	}

	/**
	 * Convert date to string
	 * @param date
	 * @returns
	 */
	getDate(date: string) {
		return this.SUtility.getPartOfDate(this.event, this.eventUser, date, "full");
	}

	/**
	 * Check overflow
	 * @param event
	 */
	checkOverFlow(event) {
		const el = event.target;
		setTimeout(() => {
			// eslint-disable-next-line max-len
			el.style.cssText = `height:auto;border-color: ${this.event.styling.contentTextColor}; color: ${this.event.styling.contentTextColor} `;
			el.style.height = el.scrollHeight + "px";
		}, 0);
	}
}
