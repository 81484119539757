import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { Subject, Subscription, combineLatest, firstValueFrom, skipWhile, take } from "rxjs";
import { GetHeaderState, GetScanning, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { TypeModule } from "src/app/shared/enums/type-module";
import {
	IEvent,
	IEventUser,
	IModule,
	IPointRecord,
	ITreasureHunt,
	ITreasureHuntQrcode,
	ITreasureHuntQrcodeResult
} from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificTreasureHunt, getTreasureHuntQrcodes } from "src/app/shared/selectors/gamification.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { PointsService, UtilityService } from "src/app/shared/services";
import { TreasureHuntsService } from "src/app/shared/services/treasure-hunts.service";

@Component({
    selector: "app-treasure-hunt",
    templateUrl: "./treasure-hunt.component.html",
    styleUrls: ["./treasure-hunt.component.scss"],
    standalone: false
})
export class TreasureHuntComponent implements OnDestroy, AfterViewInit {
	@ViewChild("headerDiv") headerDiv: ElementRef<HTMLDivElement>;
	@ViewChild("counterDiv") counterDiv: ElementRef<HTMLDivElement>;
	@ViewChild("historyDiv") historyDiv: ElementRef<HTMLDivElement>;
	@ViewChild("btnDiv") btnDiv: ElementRef<HTMLDivElement>;

	componentState$: Subject<{
		eventLoaded: boolean;
		moduleLoaded: boolean;
		eventUserLoaded: boolean;
		treasureHuntLoaded: boolean;
	}> = new Subject();

	subscriptions: Subscription[] = [];
	languageSubscription: Subscription;

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	eventUser: IEventUser;
	treasureHuntId: string;
	treasureHunt: ITreasureHunt;
	treasureHuntQrcodes: ITreasureHuntQrcode[] = [];
	treasureHuntQrcodesResults: ITreasureHuntQrcodeResult[] = [];

	scanning: boolean = false;
	scanningWeb: boolean = false;
	enableScanWeb: boolean = false;

	isMobile: boolean = false;
	currentLanguage: string;
	windowHeight: number = window.innerHeight;
	loader: boolean = true;
	btnPos: "up" | "down" = window.innerHeight <= 667 ? "up" : "down";

	constructor(
		private platform: Platform,
		private store: Store,
		private SPoints: PointsService,
		private STreasureHunts: TreasureHuntsService,
		private STranslate: TranslateService,
		private SUtility: UtilityService
	) {
		this.isMobile =
			(this.platform.is("ios") || this.platform.is("android")) && !this.platform.is("mobileweb") ? true : false;

		this.languageSubscription = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});

		this.currentLanguage = this.STranslate.currentLang;
	}

	ngAfterViewInit(): void {
		this.componentState$
			.pipe(
				skipWhile(
					(state) =>
						!state.eventLoaded || !state.eventUserLoaded || !state.moduleLoaded || !state.treasureHuntLoaded
				),
				take(1)
			)
			.subscribe((state) => {
				if (state.eventLoaded && state.moduleLoaded && state.eventUserLoaded && state.treasureHuntLoaded) {
					this.getElementsSize();
				}
			});
	}

	ionViewWillEnter() {
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.treasureHuntId = params.treasureHuntId;
						this.subscriptions.forEach((sub) => sub.unsubscribe());

						this.initDatas();
					});
			});
	}

	initDatas() {
		this.getBasicDatas();
		this.getTreasureHunt();
		this.getTreasureHuntQrcodes();
		this.getTreasureHuntQrcodesResults();
	}

	ionViewWillLeave() {
		// Case scanning running
		BarcodeScanner.showBackground();
		BarcodeScanner.stopScan();
		this.store.dispatch(GetScanning({ payload: false }));

		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Unsubscribe subscriptions on destroy
	 */
	ngOnDestroy() {
		this.subscriptions.concat([this.languageSubscription]).forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get basic datas
	 */
	getBasicDatas() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getCurrentEvent),
				this.store.select(getSpecificModule(this.moduleId)),
				this.store.select(getCurrentEventUser)
			]).subscribe((results) => {
				this.event = results[0];
				this.module = results[1];
				this.eventUser = results[2];
				this.componentState$.next({
					eventLoaded: !!this.event,
					moduleLoaded: !!this.module,
					eventUserLoaded: !!this.eventUser,
					treasureHuntLoaded: !!this.treasureHunt
				});
			})
		);
	}

	/**
	 * Get treasure hunt
	 */
	getTreasureHunt() {
		this.subscriptions.push(
			this.store.select(getSpecificTreasureHunt(this.treasureHuntId)).subscribe((treasureHunt) => {
				this.treasureHunt = treasureHunt;

				if (this.treasureHunt) {
					this.store.dispatch(
						GetHeaderState({
							payload: {
								item: this.treasureHunt,
								module: this.module,
								title: {
									ArAR: this.treasureHunt.name.ArAR,
									DeDE: this.treasureHunt.name.DeDE,
									EnUS: this.treasureHunt.name.EnUS,
									EsES: this.treasureHunt.name.EsES,
									FrFR: this.treasureHunt.name.FrFR,
									PtBR: this.treasureHunt.name.PtBR
								},
								type: TypeHeader.TREASURE_HUNTS
							}
						})
					);

					this.componentState$.next({
						eventLoaded: !!this.event,
						moduleLoaded: !!this.module,
						eventUserLoaded: !!this.eventUser,
						treasureHuntLoaded: !!this.treasureHunt
					});
				}
			})
		);
	}

	/**
	 * Get treasure hunt qrcodes
	 */
	getTreasureHuntQrcodes() {
		this.subscriptions.push(
			this.store.select(getTreasureHuntQrcodes(this.treasureHuntId)).subscribe((treasureHuntQrcodes) => {
				this.treasureHuntQrcodes = treasureHuntQrcodes;
			})
		);
	}

	/**
	 * Get treasure hunt qrcodes results
	 */
	getTreasureHuntQrcodesResults() {
		this.subscriptions.push(
			this.STreasureHunts.getResultsOfTreasureHunt(
				this.eventId,
				this.moduleId,
				this.treasureHuntId,
				this.eventUser ? this.eventUser.uid : ""
			).subscribe((treasureHuntQrcodesResults) => {
				this.treasureHuntQrcodesResults = treasureHuntQrcodesResults;
			})
		);
	}

	/**
	 * Scan success event for web
	 * @param evt
	 */
	scanSuccessHandler(qrcodeId: string) {
		if (this.enableScanWeb) {
			this.enableScanWeb = false;
			this.createTreasureHuntResult(qrcodeId);
		}
	}

	/**
	 * Emits an array of video-devices after view was initialized.
	 */
	camerasFoundHandler() {
		this.enableScanWeb = true;
	}

	/**
	 * Not camera found for scanning
	 */
	async camerasNotFoundHandler() {
		this.scanning = false;
		this.scanningWeb = false;
		this.enableScanWeb = false;
		const alert = await this.SUtility.presentAlert(
			this.STranslate.instant("alerts.error_scanning"),
			this.STranslate.instant("alerts.no_camera"),
			[
				{
					text: this.STranslate.instant("buttons.no"),
					role: "cancel"
				}
			]
		);
		alert.present();
	}

	/**
	 * Scan error handler
	 */
	async scanErrorHandler() {
		this.scanning = false;
		this.scanningWeb = false;
		this.enableScanWeb = false;
		const alert = await this.SUtility.presentAlert(
			this.STranslate.instant("alerts.error_scanning"),
			this.STranslate.instant("alerts.invalid_qrcode"),
			[
				{
					text: this.STranslate.instant("buttons.yes"),
					handler: () => {
						this.scanQr();
					}
				},
				{
					text: this.STranslate.instant("buttons.no"),
					role: "cancel"
				}
			]
		);
		alert.present();
	}

	async checkPermission() {
		const status = await BarcodeScanner.checkPermission({ force: true });
		if (status.granted) {
			return true;
		} else if (status.denied) {
			BarcodeScanner.openAppSettings();
			return false;
		}
	}

	/**
	 * Scan qr code
	 */
	async scanQr() {
		if (!this.scanning) {
			this.scanning = true;

			// For web scanning
			if (!this.isMobile) {
				// this.enableScanWeb = true;
				this.scanningWeb = true;
				this.scanning = false;
				return;
			}

			const allowed = await this.checkPermission();
			if (allowed) {
				try {
					BarcodeScanner.hideBackground(); // make background of WebView transparent
					this.store.dispatch(GetScanning({ payload: true }));
					const result = await BarcodeScanner.startScan(); // start scanning and wait for a result
					this.store.dispatch(GetScanning({ payload: false }));

					// if the result has content
					if (result.hasContent) {
						this.createTreasureHuntResult(result.content);
					} else {
						this.scanning = false;
					}
				} catch (error) {
					this.store.dispatch(GetScanning({ payload: false }));
					this.scanning = false;
					this.scanningWeb = false;
					this.enableScanWeb = false;
					const alert = await this.SUtility.presentAlert(
						this.STranslate.instant("alerts.error_scanning"),
						this.STranslate.instant("alerts.no_camera"),
						[
							{
								text: this.STranslate.instant("buttons.yes"),
								handler: () => {
									this.scanQr();
								}
							},
							{
								text: this.STranslate.instant("buttons.no"),
								role: "cancel"
							}
						]
					);
					alert.present();
				}
			}
		}
	}

	async createTreasureHuntResult(qrcodeId: string) {
		try {
			const checkResultAlreadyExist = this.treasureHuntQrcodesResults.find(
				(result) => result.treasureHuntQrcodeId === qrcodeId && result.treasureHuntId === this.treasureHuntId
			);
			if (!this.treasureHuntQrcodes.find((qrcode) => qrcode.uid === qrcodeId)) {
				this.scanning = false;
				this.enableScanWeb = false;
				this.scanningWeb = false;
				const alert = await this.SUtility.presentAlert(
					this.STranslate.instant("alerts.error_scanning"),
					this.STranslate.instant("alerts.invalid_qrcode"),
					[
						{
							text: this.STranslate.instant("buttons.yes"),
							handler: () => {
								this.scanQr();
							}
						},
						{
							text: this.STranslate.instant("buttons.no"),
							role: "cancel"
						}
					]
				);
				alert.present();
				return;
			}
			if (qrcodeId && !checkResultAlreadyExist && this.eventUser) {
				// Result not exist
				const qrcodeResult: ITreasureHuntQrcodeResult = {
					uid: "",
					creationDate: DateTime.local().toISO(),
					eventId: this.eventId,
					moduleId: this.moduleId,
					treasureHuntId: this.treasureHuntId,
					treasureHuntQrcodeId: qrcodeId,
					userId: this.eventUser.uid
				};
				await this.STreasureHunts.createTreasureHuntQrcodeResult(
					this.eventId,
					this.moduleId,
					this.treasureHuntId,
					qrcodeId,
					qrcodeResult
				);

				if (this.eventUser) {
					const points = this.treasureHuntQrcodes.find((qrcode) => qrcode.uid === qrcodeId).points ?? 0;
					const pointRecord: IPointRecord = {
						uid: "",
						creationDate: DateTime.local().toISO(),
						eventId: this.eventId,
						fixedPoints: points,
						moduleId: this.eventUser ? this.eventUser.moduleId : "",
						options: {
							moduleId: this.moduleId,
							qrCodeId: qrcodeId,
							treasureHuntId: this.treasureHuntId
						},
						type: TypeModule.TREASURE_HUNTS,
						userId: this.eventUser ? this.eventUser.uid : ""
					};
					const checkPointRecord = await firstValueFrom(
						this.SPoints.checkPointRecord(
							this.eventId,
							this.moduleId,
							pointRecord,
							this.eventUser.uid,
							"treasureHunt"
						)
					);
					if (checkPointRecord.length > 0) {
						await this.SPoints.deleteEventUserPointRecord(this.eventId, checkPointRecord[0]);
					}
					await this.SPoints.createEventUserPointRecord(this.eventId, pointRecord);
				}
				this.scanning = false;
				this.enableScanWeb = false;
				this.scanningWeb = false;
				this.SUtility.presentToast(
					this.STranslate.instant("treasure_hunts.success_scan"),
					3000,
					"bottom",
					"success"
				);
			} else {
				// Already scanned
				this.scanning = false;
				this.enableScanWeb = false;
				this.scanningWeb = false;
				const alert = await this.SUtility.presentAlert(
					this.STranslate.instant("alerts.error_scanning"),
					this.STranslate.instant("alerts.already_scanned"),
					[
						{
							text: this.STranslate.instant("buttons.no"),
							role: "cancel"
						}
					]
				);
				alert.present();
			}
		} catch (error) {
			this.scanning = false;
			this.enableScanWeb = false;
			this.scanningWeb = false;
			const alert = await this.SUtility.presentAlert(
				this.STranslate.instant("alerts.error_scanning"),
				this.STranslate.instant("alerts.invalid_qrcode"),
				[
					{
						text: this.STranslate.instant("buttons.yes"),
						handler: () => {
							this.scanQr();
						}
					},
					{
						text: this.STranslate.instant("buttons.no"),
						role: "cancel"
					}
				]
			);
			alert.present();
		}
	}

	/**
	 * Get Treasure hunt data
	 * @param checked
	 * @returns
	 */
	getTreasureHuntData(data: ITreasureHuntQrcodeResult): ITreasureHuntQrcode {
		return this.treasureHuntQrcodes.find((treasure) => treasure.uid === data.treasureHuntQrcodeId);
	}

	/**
	 * Get date
	 * @param date
	 * @returns
	 */
	getDate(date: string, unit: string) {
		return this.SUtility.getPartOfDate(this.event, this.eventUser, date, unit);
	}

	getElementsSize() {
		if (!this.isMobile || (this.isMobile && this.platform.is("android"))) {
			setTimeout(() => {
				if (this.headerDiv && this.counterDiv && this.historyDiv && this.btnDiv) {
					// this.windowHeight = window.innerHeight;
					// const { headerDivHeight, counterDivHeight, btnDivHeight } = {
					// 	headerDivHeight: this.headerDiv.nativeElement.clientHeight,
					// 	counterDivHeight: this.counterDiv.nativeElement.clientHeight,
					// 	btnDivHeight: this.btnDiv.nativeElement.clientHeight
					// };

					// const height =
					// 	this.windowHeight -
					// 	headerDivHeight -
					// 	counterDivHeight -
					// 	btnDivHeight -
					// 	(this.isMobile && this.event.settings.activateBurgerMenuOnMobile ? 180 : 130);

					// this.historyDiv.nativeElement.style.height = `${height}px`;

					// height < 300 ? (this.btnPos = "up") : (this.btnPos = "down");

					this.loader = false;
				}
			}, 100);
		}

		this.loader = false;
	}
}
