import { Component, Input, OnDestroy } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { IEvent, IEventUser } from "src/app/shared/interfaces";
import { IFilters } from "src/app/shared/interfaces/filters.interfaces";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-schedules-filters",
    templateUrl: "./schedules-filters.component.html",
    styleUrls: ["./schedules-filters.component.scss"],
    standalone: false
})
export class SchedulesFiltersComponent implements OnDestroy {
	subscriptions: Subscription[] = [];
	@Input() event: IEvent;
	@Input() eventUser: IEventUser;
	@Input() filters: IFilters = {
		locations: [],
		name: "",
		tracks: [],
		customFields: [],
		principalKey: ""
	};

	currentLanguage: string = environment.platform.defaultLanguage;

	constructor(private modalCtrl: ModalController, private STranslate: TranslateService) {
		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub?.unsubscribe());
	}

	dismiss() {
		this.modalCtrl.dismiss({
			filters: this.filters
		});
	}
}
