import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash-es";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderState, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { IEvent, ILocation, IModule } from "src/app/shared/interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificLocation } from "src/app/shared/selectors/generics-modules-data.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AnalyticsService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-location",
    templateUrl: "./location.component.html",
    styleUrls: ["./location.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LocationComponent implements OnDestroy {
	subscriptions: Subscription[] = [];

	eventId: string;
	moduleId: string;
	event: IEvent;
	module: IModule;
	locationId: string;
	location: ILocation;

	isMobile: boolean = false;

	constructor(
		private store: Store,
		private platform: Platform,
		private modalCtrl: ModalController,
		private SAnalytics: AnalyticsService,
		private cd: ChangeDetectorRef,
		private sanitization: DomSanitizer
	) {}

	ionViewWillEnter() {
		this.isMobile =
			this.platform.is("ios") || this.platform.is("android") || this.platform.is("mobileweb") ? true : false;
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.subscriptions.forEach((sub) => {
							if (sub && !sub.closed) {
								sub.unsubscribe();
							}
						});

						// Analytics
						this.SAnalytics.locationAccess(this.eventId, this.moduleId, this.locationId);

						this.locationId = params.locationId;
						this.getEvent();
						this.getModule();
						this.getLocation();
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => {
			if (sub && !sub.closed) {
				sub.unsubscribe();
			}
		});
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => {
			if (sub && !sub.closed) {
				sub.unsubscribe();
			}
		});
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
				this.cd.markForCheck();
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
					this.cd.markForCheck();
				}
			})
		);
	}

	/**
	 * Getting location
	 */
	getLocation() {
		this.subscriptions.push(
			this.store.select(getSpecificLocation(this.locationId)).subscribe((location) => {
				if (!_.isEqual(this.location, location)) {
					this.location = location;

					this.store.dispatch(
						GetHeaderState({
							payload: {
								item: this.location,
								title: {
									ArAR: this.location.name,
									DeDE: this.location.name,
									EnUS: this.location.name,
									EsES: this.location.name,
									FrFR: this.location.name,
									PtBR: this.location.name
								},
								type: TypeHeader.LOCATION
							}
						})
					);
					this.cd.markForCheck();
				}
			})
		);
	}

	/**
	 * Open image viewer
	 * @param url
	 */
	async openImage(url: string) {
		const modal = await this.modalCtrl.create({
			component: PathComponents.imageViewerModal,
			componentProps: {
				url: url
			}
		});
		modal.present();
	}

	removeSpace(text: string) {
		return text.trim();
	}

	/**
	 * Open google plan
	 * @param address
	 */
	openGooglePlan(address: string) {
		const addressFormated = address.replaceAll(", ", ",").replaceAll(" ", "+");
		window.open("https://www.google.com/maps/place/" + addressFormated, "_blank");
	}

	/**
	 * Get google plan
	 * @param address
	 * @returns
	 */
	getGooglePlan(address: string, width: number) {
		const addressFormated = address.replaceAll(", ", ",").replaceAll(" ", "+");
		return this.sanitization.bypassSecurityTrustUrl(
			"https://maps.googleapis.com/maps/api/staticmap?size=" +
				Math.ceil(width) +
				"x" +
				Math.ceil(width / 2) +
				"&maptype=roadmap" +
				"&markers=color:red|" +
				addressFormated +
				"&key=" +
				environment.platform.googleMapApiKey
		);
	}
}
