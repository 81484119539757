import { Component, Input } from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { TypeCustomFields } from "src/app/shared/enums/type-custom-fields";
import { TypeModule } from "src/app/shared/enums/type-module";
import { IFullCustomField } from "src/app/shared/interfaces/custom-fields.interfaces";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "cf-form-visual-icon",
    templateUrl: "./cf-form-visual-icon.component.html",
    styleUrls: ["./cf-form-visual-icon.component.scss"],
    standalone: false
})
export class FormVisualIconComponent {
	@Input() computedCustomField: IFullCustomField;
	@Input() form: UntypedFormGroup | AbstractControl;
	@Input() language: string;
	@Input() mode: "singleLanguage" | "multiLanguage";

	typeCustomFields = TypeCustomFields;
	typeModule = TypeModule;
}
