import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { Component, OnDestroy, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Platform, ToastController, NavController, ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { QueryConstraint, QueryDocumentSnapshot, orderBy, startAfter, where } from "firebase/firestore";
import * as _ from "lodash";
import {
	Subscription,
	take,
	interval,
	skipWhile,
	combineLatest,
	firstValueFrom,
	map,
	switchMap,
	of,
	Observable,
	BehaviorSubject,
	lastValueFrom,
	filter
} from "rxjs";
import { GetHeaderType, ResetHeaderState, GetHeaderTitle, GetScanning } from "src/app/shared/actions/utility.actions";
import { TypeCustomFields } from "src/app/shared/enums/type-custom-fields";
import { TypeModule } from "src/app/shared/enums/type-module";
import {
	IEvent,
	IGroup,
	IFullCustomField,
	ICustomField,
	IModuleCustomField,
	ILanguage,
	ICustomFieldData,
	IEventUser
} from "src/app/shared/interfaces";
import { IFilteredItemFormat, IFilterOptions } from "src/app/shared/interfaces/custom-fields.interfaces";
import { IFavoriteFolder } from "src/app/shared/interfaces/folders.interfaces";
import { IModule, IModuleUpdatedSettings } from "src/app/shared/interfaces/modules.interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getBaseCustomFields,
	getModulesCustomsFieldsOfModule,
	getSpecificGroups
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getModulesByType, getModulesByTypes, getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectUrl, selectRouteNestedParams } from "src/app/shared/selectors/router.selectors";
import {
	AnalyticsService,
	EventUsersService,
	FirestoreService,
	UtilityService,
	FavoritesService
} from "src/app/shared/services";
import { environment } from "src/environments/environment";
import { FilterComponent } from "../components/custom-fields/filter/filter.component";
import { CardExchangeService } from "src/app/shared/services/card-exchange.service";
import { ICardExchangeForm } from "src/app/shared/interfaces/card-exchange.interfaces";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { CardExchangeFormComponent } from "../components/card-exchange-form/card-exchange-form.component";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { LogFuncTrace, LogProperty } from "src/app/shared/utils-func/debug-decorators";
import { Router } from "@angular/router";

@Component({
    selector: "app-card-exchange",
    templateUrl: "./card-exchange.component.html",
    styleUrls: ["./card-exchange.component.scss"],
    standalone: false
})
export class CardExchangeComponent implements OnDestroy {
	@ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
	eventUsers: IEventUser[] = [];
	module: IModule;

	subscriptions: Subscription[] = [];
	moduleSub: Subscription;
	analyticsArraySub: { name: string; sub: Subscription }[] = [];

	loader: boolean = true;
	limit: number = 30;
	currentSize: number = 0;

	searchOpen: boolean = false;
	searchText: string = "";

	totalEventUsers: number = 0;
	lastQuery: {
		start: QueryDocumentSnapshot<IEventUser>;
		last: QueryDocumentSnapshot<IEventUser>;
		docsSnapshots: QueryDocumentSnapshot<IEventUser>[];
		docsDatas: IEventUser[];
	} = null;

	eventId: string;
	event: IEvent;
	moduleId: string;
	moduleUpdatedSettings: IModuleUpdatedSettings;
	@LogProperty
	myEventUser: IEventUser;
	filteredEventUsers: IEventUser[] = [];
	filteredEventUsersInTwo: IEventUser[][] = [];
	groupIds: string[] = [];
	groups: IGroup[] = [];
	customFields: { [moduleId: string]: IFullCustomField[] } = {};
	tagCustomFields: IFullCustomField[] = [];
	form: ICardExchangeForm;

	// filter properties
	filterItems: IFilteredItemFormat[];
	typeModule = TypeModule;
	typeOrder: string;

	showNoResultImage: boolean = false;

	allowFilterTag: boolean = false; // To show or hide filter tag
	isMobile = window.innerWidth < 768 ? true : false;
	selectedFilters: IFilteredItemFormat[] = [];
	selectedDatas: IFilterOptions[] = [];
	notSelectedFilters: IFilteredItemFormat[];
	groupedLetters: any;
	itemSize: number = 97;
	height: number = 873;

	dontBelongAnyGroups: boolean = false;

	// scrolledContainer: any = null;
	init: boolean = false;
	shouldGetEventUsers$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	navigationSaved: { moduleId: string; lastIndex: number }[] = [];
	currentLanguage: string = environment.platform.defaultLanguage;
	searchValue: string = "";

	filtersCollapseState: { uid: string; collapsed: boolean }[] = [];

	resultsSaved: [ICustomField[], IModuleCustomField[], ...IModuleCustomField[][]] = [[], [], []];

	searchDatas: any[] = [];
	folder: IFavoriteFolder;
	favoriteModule: IModule;
	eventUserFavoritesState: any = {};
	scanning: boolean = false;
	eventUserModule: IModule;
	otherEventUsersModules: IModule[] = [];

	constructor(
		public platform: Platform,
		private SAnalytics: AnalyticsService,
		private store: Store,
		private SEventUsers: EventUsersService,
		private SFirestore: FirestoreService,
		private toastCtrl: ToastController,
		private navCtrl: NavController,
		private snackBar: MatSnackBar,
		private STranslate: TranslateService,
		private modalController: ModalController,
		private SUtility: UtilityService,
		private router: Router,
		public SCardExchange: CardExchangeService,
		public SFavorite: FavoritesService
	) {}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;

		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
				if (this.eventUserModule?.options?.showFilter) this.initFilterData();
			})
		);

		this.subscriptions.push(
			this.SCardExchange.cardExchangeScanQrSubject.subscribe((scanQr) => {
				if (scanQr) {
					this.scanQr();
				}
			})
		);

		this.subscriptions.push(
			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe(() => {
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.moduleId = params.moduleId;
							this.init = false;
							this.initDatas();

							this.SAnalytics.moduleAccess(
								this.eventId,
								this.moduleId,
								TypeTracking.ACCESS_TO_CARD_EXCHANGE_MODULE
							);
						});
				})
		);
	}

	ionViewDidEnter() {
		this.subscriptions.push(
			interval(100)
				.pipe(
					skipWhile(() => this.loader || !this.virtualScroll),
					take(1)
				)
				.subscribe(() => {
					if (this.virtualScroll) {
						const nav = this.navigationSaved.find((navMod) => navMod.moduleId === this.moduleId);
						this.virtualScroll.scrollToIndex(nav ? nav.lastIndex : 0, "auto");
						this.init = true;
					}
				})
		);
	}

	@LogFuncTrace
	ionViewWillLeave() {
		BarcodeScanner.showBackground();
		BarcodeScanner.stopScan();
		this.store.dispatch(GetScanning({ payload: false }));
		this.eventUsers = [];
		this.myEventUser = null;
		this.module = null;
		this.filteredEventUsers = [];
		this.lastQuery = null;
		this.shouldGetEventUsers$.next(false);
		this.subscriptions.concat(this.moduleSub).forEach((sub) => sub?.unsubscribe());
		this.store.dispatch(ResetHeaderState(null));
		this.loader = true;
		this.init = false;
		this.scanning = false;
		this.eventUserModule = null;
		this.moduleUpdatedSettings = null;
		this.otherEventUsersModules = [];
		this.resetFilter();
	}

	@LogFuncTrace
	ngOnDestroy() {
		this.subscriptions.concat(this.moduleSub).forEach((sub) => sub?.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());
	}

	initDatas() {
		this.getEvent();
		this.getEventUser();
		this.getEventUsers();
		this.getModule();
		this.getGroups();
		this.getForm();
		this.getEventUserModuleAndSettings();
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * get Form
	 */
	getForm() {
		this.subscriptions.push(
			this.SCardExchange.getSpecificFormByGroupId(this.eventId, this.moduleId, this.myEventUser.groups)
				.pipe(
					skipWhile(() => !this.myEventUser || this.myEventUser?.groups?.length === 0),
					filter((form) => (form && form?.visibility) || !form),
					take(1)
				)
				.subscribe((form) => {
					this.form = form;
				})
		);
	}

	/**
	 * Get groups
	 */
	getGroups() {
		this.subscriptions.push(
			this.store
				.select(getSpecificGroups(this.myEventUser?.groups))
				.pipe(
					skipWhile(() => !this.myEventUser),
					take(1)
				)
				.subscribe((groups) => {
					this.groups = groups;
					this.groupIds = this.groups.map((group) => group.uid);
				})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe({
				next: (eventUser) => {
					if (
						!this.myEventUser ||
						(this.myEventUser && !_.isEqual(this.myEventUser?.contactIds, eventUser?.contactIds))
					) {
						this.myEventUser = eventUser;
						if (!this.myEventUser.contactIds) {
							this.myEventUser.contactIds = [];
						}
						this.shouldGetEventUsers$.next(true);
					}
					// this.shouldGetEventUsers$.next(true);
				},
				error: (e) => {
					console.error("🚀 ~ error:", e);
				}
			})
		);
	}

	/**
	 * getModule (Card exchange module)
	 */
	getModule() {
		this.moduleSub = this.store
			.select(getModulesByType(TypeModule.CARD_EXCHANGE))
			.pipe(map((modules) => modules[0]))
			.subscribe((module) => {
				this.module = module;

				// disptach header
				this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
				this.store.dispatch(GetHeaderType({ payload: TypeHeader.CARD_EXCHANGE }));
			});
	}

	/**
	 * getEventUserModuleAndSettings
	 */
	getEventUserModuleAndSettings() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getSpecificModule(this.myEventUser?.moduleId)),
				this.SFirestore.valueChangesDocument(
					`events/${this.eventId}/modules-updated-settings/${this.myEventUser?.moduleId}`
				),
				this.store.select(getModulesByTypes([TypeModule.ATTENDEE, TypeModule.SPEAKER]))
			])
				.pipe(skipWhile(() => !this.myEventUser))
				.subscribe((results) => {
					if (
						!_.isEqual(this.eventUserModule, results[0]) ||
						!_.isEqual(this.moduleUpdatedSettings, results[1])
					) {
						this.eventUserModule = results[0];
						this.moduleUpdatedSettings = results[1];
						this.otherEventUsersModules = results[2].filter(
							(module) => module.uid !== this.eventUserModule.uid
						);

						if (this.eventUserModule) {
							this.shouldGetEventUsers$.next(true);

							this.getCustomFields();

							const nav = this.navigationSaved.find((navMod) => navMod.moduleId === this.moduleId);
							if (!nav || (nav && nav.lastIndex <= 30)) {
								this.limit = 30;
							} else if (nav && nav.lastIndex > 30) {
								this.limit = 30 * Math.ceil(nav.lastIndex / 30);
							}
						}
					}
				})
		);
	}
	/**
	 * Get custom fields
	 */
	getCustomFields() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getBaseCustomFields),
				this.store.select(getModulesCustomsFieldsOfModule(this.eventUserModule.uid)),
				...this.otherEventUsersModules.map((module) =>
					this.store.select(getModulesCustomsFieldsOfModule(module.uid))
				)
			])
				.pipe(take(1))
				.subscribe((results) => {
					if (!_.isEqual(results, this.resultsSaved)) {
						this.resultsSaved = results;

						// Init custom fields blank values
						this.customFields[this.eventUserModule.uid] = [];
						this.otherEventUsersModules.forEach((module) => {
							this.customFields[module.uid] = [];
						});

						const otherModulesCus = results.slice(2);
						otherModulesCus.push(results[1]);
						otherModulesCus?.forEach((modsCus: IModuleCustomField[]) => {
							if (modsCus.length > 0) {
								modsCus.forEach((customField) => {
									const baseCustomFieldCorresponding = results[0].find(
										(custField) => custField.uid === customField.uid
									);

									if (baseCustomFieldCorresponding) {
										this.customFields[customField.moduleId]?.push({
											baseSettings: baseCustomFieldCorresponding,
											moduleSettings: customField,
											fieldDatas: null
										});
									}
								});
							}
						});

						this.tagCustomFields = this.customFields[results[1]?.[0]?.moduleId].filter(
							(custom) => custom.moduleSettings
						);
						if (this.eventUserModule && this.eventUserModule?.options?.showFilter) this.initFilterData();
					}
				})
		);
	}

	/**
	 * Get event users updated settings
	 */
	getEventUsersUpdatedSettings() {
		this.subscriptions.push(
			this.SEventUsers.getSpecificsEventUsersUpdatedSettings(
				this.eventId,
				this.moduleId,
				[...this.eventUsers].slice(0, this.limit).map((eventUser) => eventUser.uid)
			).subscribe({
				next: (eventUsersUpdatedSettings) => {
					this.eventUsers = this.eventUsers.map((eventUser) => {
						const updatedSettings = eventUsersUpdatedSettings.find(
							(settings) => settings.userId === eventUser.uid
						);
						if (updatedSettings) {
							eventUser.updatedSettings = updatedSettings;
						}
						return eventUser;
					});
				},
				error: () => {
					//
				}
			})
		);
	}

	/**
	 * initFilterData
	 */
	initFilterData() {
		this.filterItems = [];
		this.filtersCollapseState = [];
		// Event user group filter datas
		if (this.eventUserModule?.options?.requiredFields.group.filter) {
			this.filterItems.push({
				uid: "group",
				name: this.STranslate.instant("labels.groups"),
				strictSelectionMode: null,
				values: [] as IFilterOptions[]
			} as IFilteredItemFormat);

			this.filtersCollapseState.push({
				uid: "group",
				collapsed: false
			});

			// if (this.moduleUpdatedSettings) {
			if (this.moduleUpdatedSettings && !this.eventUserModule?.options?.showFilter) {
				// Build queries for counting each groups
				const promiseArrayFilterTerms: Promise<number>[] = [];
				const filteredTerm = this.moduleUpdatedSettings.searchStringDatas.filter(
					(filterTerm) =>
						filterTerm.split("|")[0] === "group" || filterTerm.split("|")[2] === this.currentLanguage
				);
				filteredTerm.forEach((filterTerm) => {
					promiseArrayFilterTerms.push(
						firstValueFrom(
							this.SFirestore.getCountOfQueryObs(
								`events/${this.eventId}/modules/${this.moduleId}/event-users`,
								"default",
								[where("searchStringDatas", "array-contains", filterTerm)]
							).pipe(take(1))
						)
					);
				});

				// Activate promises
				// Promise.all(promiseArrayFilterTerms)
				// 	.then((results) => {})
				// 	.catch(() => {
				// 		//
				// 	});
			} else {
				// Init filter based on event users groups if advanced filters activated
			}
		}

		// if (!this.moduleUpdatedSettings) {
	}

	/**
	 * computeReachableDatas
	 * @description Calculates reachables options (checkboxs) based on the selected items
	 */
	computeReachableDatas() {
		if (this.eventUsers.length !== this.filteredEventUsers.length) {
			this.notSelectedFilters = this.filterItems.filter(
				(filter) => !filter.values.find((value) => value.isSelected)
			);
			this.selectedFilters = this.filterItems.filter((filter) => filter.values.find((value) => value.isSelected));

			// if (this.moduleUpdatedSettings) {
			if (this.moduleUpdatedSettings && !this.eventUserModule?.options?.showFilter) {
				for (let i = 0; i < this.filterItems.length; i++) {
					const filter = this.filterItems[i];
					for (let iF = 0; iF < filter.values.length; iF++) {
						const filterValue = filter.values[iF];
						if (!filterValue.isSelected) {
							const values: string[] = [];

							// Push all selected filters each time
							this.selectedFilters.forEach((filterSelected) => {
								filterSelected.values.forEach((value) => {
									if (value.isSelected) {
										if (filterSelected.uid === "group") {
											const group = this.groups.find((group) => group.name === value.value);
											if (group) {
												values.push(`${filterSelected.uid}|${group.uid}`);
											}
										} else {
											values.push(`${filterSelected.uid}|${value.value}`);
										}
									}
								});
							});

							// Push filter to check
							values.push(`${filter.uid}|${filterValue.value}`);

							firstValueFrom(
								this.SFirestore.getDocumentsObs(
									`events/${this.eventId}/modules/${this.moduleId}/event-users`,
									[where("searchStringDatas", "array-contains-any", values)]
								).pipe(
									take(1),
									map((snapshot) => snapshot.docs.map((doc) => doc.data() as IEventUser))
								)
							)
								.then((results) => {
									if (results.length > 0) {
										const resultsIntersec: IEventUser[] = [];
										results.forEach((eventUser) => {
											if (
												_.intersection(values, eventUser.searchStringDatas).length ===
													values.length &&
												!resultsIntersec.find((evt) => evt.uid === eventUser.uid)
											) {
												resultsIntersec.push(eventUser);
											}
										});
										if (resultsIntersec.length > 0) {
											filterValue.isReachable = true;
										} else {
											filterValue.isReachable = false;
										}
									} else {
										filterValue.isReachable = false;
									}
								})
								.catch(() => {
									//
								});
						}
					}
				}
			} else {
				this.notSelectedFilters.forEach((filter) => {
					let matches = 0;
					const datas: string[] = [];

					this.filteredEventUsers.forEach((user) => {
						const custom = user.customFields.find(
							(cus) =>
								cus.uid === filter.uid &&
								(((this.getCustomFieldType(cus.uid) === TypeCustomFields.TEXT ||
									this.getCustomFieldType(cus.uid) === TypeCustomFields.SELECT) &&
									filter.values.find(
										(value) =>
											value.value.toLowerCase() ===
												cus.field.multiLanguageText[this.currentLanguage].toLowerCase() ||
											(value.value.toLowerCase() ===
												this.STranslate.instant("filter.not_specified").toLowerCase() &&
												cus.field.multiLanguageText[this.currentLanguage] === "")
									)) ||
									((this.getCustomFieldType(cus.uid) === TypeCustomFields.URL ||
										this.getCustomFieldType(cus.uid) === TypeCustomFields.EMAIL) &&
										filter.values.find(
											(value) =>
												value.value.toLowerCase() === cus.field.text.toLowerCase() ||
												(value.value.toLowerCase() ===
													this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.text === "")
										)) ||
									(this.getCustomFieldType(cus.uid) === TypeCustomFields.NUMERIC &&
										filter.values.find(
											(value) =>
												value.value === cus.field.numeric.toString() ||
												(value.value.toLowerCase() ===
													this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.numeric === -1)
										)) ||
									(this.getCustomFieldType(cus.uid) === TypeCustomFields.MULTI_SELECT &&
										filter.values.find(
											(value) =>
												cus.field.multiLanguageSelectArray
													.map((opt) => opt[this.currentLanguage].toLowerCase())
													.includes(value.value.toLowerCase()) ||
												(value.value.toLowerCase() ===
													this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.multiLanguageSelectArray.map(
														(opt) => opt[this.currentLanguage]
													).length === 0)
										)))
						);

						if (custom) {
							// this.getCustomFieldType(custom.uid) === TypeCustomFields.MULTI_SELECT &&
							if (
								!datas.find(
									(data) =>
										((this.getCustomFieldType(custom.uid) === TypeCustomFields.TEXT ||
											this.getCustomFieldType(custom.uid) === TypeCustomFields.SELECT) &&
											data.toLowerCase() ===
												custom.field.multiLanguageText[this.currentLanguage].toLowerCase()) ||
										((this.getCustomFieldType(custom.uid) === TypeCustomFields.URL ||
											this.getCustomFieldType(custom.uid) === TypeCustomFields.EMAIL) &&
											data.toLowerCase() === custom.field.text.toLowerCase()) ||
										(this.getCustomFieldType(custom.uid) === TypeCustomFields.NUMERIC &&
											data === custom.field.numeric.toString()) ||
										(this.getCustomFieldType(custom.uid) === TypeCustomFields.MULTI_SELECT &&
											custom.field.multiLanguageSelectArray
												?.map((opt: ILanguage) => {
													return opt[this.currentLanguage].toLowerCase();
												})
												.some((dat: string) => dat === data.toLowerCase()))
								)
							)
								this.getCustomFieldType(custom.uid) === TypeCustomFields.TEXT ||
								this.getCustomFieldType(custom.uid) === TypeCustomFields.SELECT
									? datas.push(custom.field.multiLanguageText[this.currentLanguage])
									: this.getCustomFieldType(custom.uid) === TypeCustomFields.URL ||
									  this.getCustomFieldType(custom.uid) === TypeCustomFields.EMAIL
									? datas.push(custom.field.text)
									: this.getCustomFieldType(custom.uid) === TypeCustomFields.NUMERIC
									? datas.push(custom.field.numeric.toString())
									: custom.field.multiLanguageSelectArray.length > 0 &&
									  datas.push(
											custom.field.multiLanguageSelectArray
												?.map((opt: ILanguage) => {
													return opt[this.currentLanguage].toLowerCase();
												})
												.join("*")
									  );

							if (
								(((this.getCustomFieldType(custom.uid) === TypeCustomFields.TEXT ||
									this.getCustomFieldType(custom.uid) === TypeCustomFields.SELECT) &&
									custom.field.multiLanguageText[this.currentLanguage] === "") ||
									((this.getCustomFieldType(custom.uid) === TypeCustomFields.URL ||
										this.getCustomFieldType(custom.uid) === TypeCustomFields.EMAIL) &&
										custom.field.text === "") ||
									(this.getCustomFieldType(custom.uid) === TypeCustomFields.NUMERIC &&
										custom.field.numeric === -1) ||
									(this.getCustomFieldType(custom.uid) === TypeCustomFields.MULTI_SELECT &&
										custom.field.multiLanguageSelectArray?.map(
											(option) => option[this.currentLanguage]
										).length === 0)) &&
								!datas.find(
									(data) =>
										data.toLowerCase() ===
										this.STranslate.instant("filter.not_specified").toLowerCase()
								)
							)
								datas.push(this.STranslate.instant("filter.not_specified"));

							matches++;
						}
					});

					if (matches === this.filteredEventUsers.length) {
						// Flat arrays and remove duplicated items
						const splitedDatas = [
							...new Set(datas.map((dat) => dat.split("*")).reduce((acc, val) => acc.concat(val), []))
						];

						filter.values
							.map((fil) => fil.value !== "-1" && fil)
							.filter((fil) => {
								if (
									splitedDatas
										.filter((dat) => dat)
										.findIndex((dat) => dat?.toLowerCase() === fil.value?.toLowerCase()) === -1
								) {
									fil.isReachable = false;
									return true;
								} else {
									filter.strictSelectionMode &&
									fil.value.toLowerCase() ===
										this.STranslate.instant("filter.not_specified").toLowerCase()
										? (fil.isReachable = false)
										: (fil.isReachable = true);
									return false;
								}
							});
					}
				});
			}
		} else {
			// Back to initial state (every checkbox are reachable)
			this.filterItems.forEach((item) => {
				item.values.forEach((data) => {
					item.strictSelectionMode &&
					data.value.toLowerCase() === this.STranslate.instant("filter.not_specified").toLowerCase()
						? (data.isReachable = false)
						: (data.isReachable = true);
				});
			});
		}
	}

	/**
	 * Get event users
	 */
	getEventUsers() {
		this.subscriptions.push(
			this.shouldGetEventUsers$.pipe(skipWhile(() => !this.module)).subscribe(async (shouldGet) => {
				if (shouldGet) {
					let queryConstraints: QueryConstraint[] = [];
					// Filter on searchbar term if needed
					if (this.searchValue) {
						const queryNameTerm = ["asc", "desc", "recent", "oldest"].includes(
							this.eventUserModule.options.usersOrder
						)
							? this.searchValue.trim().toLocaleUpperCase()
							: this.searchValue.trim();
						queryConstraints = queryConstraints.concat([
							where("identifier", ">=", queryNameTerm),
							where("identifier", "<", queryNameTerm + "\uf8ff")
						]);
					}

					queryConstraints.push(orderBy("identifier"));

					if (
						this.eventUserModule &&
						this.eventUserModule.options &&
						(this.module.options.showFilter || this.eventUserModule.options.showSearchIcon)
					) {
						this.eventUsers = (
							await this.SCardExchange.getUserContacts(this.myEventUser.contactIds, this.eventId)
						)?.filter((eventUser) => eventUser.uid !== this.myEventUser.uid);

						this.totalEventUsers = this.myEventUser.contactIds.length;

						this.buildEventUser();
					} else {
						if (this.lastQuery && this.lastQuery.start && this.lastQuery.last) {
							queryConstraints.push(startAfter(this.lastQuery.last));
						}

						const obsArray: Observable<any>[] = [];

						obsArray.push(
							this.SCardExchange.getUserContactsWithPagination(
								this.event.uid,
								this.myEventUser.contactIds,
								queryConstraints
							)
						);

						combineLatest([...obsArray])
							.pipe(
								switchMap((results) => {
									return of({ total: this.myEventUser.contactIds.length, lastQuery: results[0] });
								})
							)
							.subscribe(({ total, lastQuery }) => {
								this.lastQuery = lastQuery;

								this.eventUsers = lastQuery.docsDatas
									? this.eventUsers
											.concat(
												lastQuery.docsDatas?.filter((eventUser) => {
													return !this.eventUsers.find((evt) => evt.uid === eventUser.uid);
												})
											)
											.filter((user) => this.myEventUser.contactIds.includes(user.uid))
									: this.eventUsers.filter((user) => this.myEventUser.contactIds.includes(user.uid));

								this.totalEventUsers = total;

								this.buildEventUser();
								this.shouldGetEventUsers$.next(false);
							});
					}
				}
			})
		);
	}

	/**
	 * Filter, sort etc event user
	 */
	buildEventUser() {
		this.getEventUsersUpdatedSettings();
		// Assign data on filteredEventUsers, if filter on slice it with limit number
		if (this.module.options.showFilter) {
			if (this.searchValue.length === 0) {
				if (!this.module.options.showFilter || !this.anyFilterIsActivated()) {
					this.filteredEventUsers = [...this.eventUsers].slice(0, this.limit);
				}
			}
		} else {
			if (this.searchValue.length === 0) {
				if (!this.module.options.showFilter || !this.anyFilterIsActivated()) {
					this.filteredEventUsers = [...this.eventUsers].slice(0, this.limit);
				}
			} else {
				this.filteredEventUsers = _.cloneDeep(this.eventUsers).filter((eventUser) =>
					this.SUtility.removeAccents(eventUser.name)
						.toLocaleLowerCase()
						.includes(this.searchValue.toLocaleLowerCase())
				);

				// this.filteredEventUsers = _.cloneDeep(this.eventUsers);
			}
		}

		this.groupedLetters = this.filteredEventUsers.reduce((groups, eventUser) => {
			const letter = eventUser.queryName.charAt(0);
			if (!Object.values(groups).includes(letter)) {
				const uid = eventUser.uid;
				groups[uid] = letter;
			}
			return groups;
		}, {});

		this.getCustomFields();

		if (this.anyFilterIsActivated()) {
			this.currentSize = this.filteredEventUsers.length;
		} else {
			this.currentSize = this.eventUsers.length;
		}

		this.getListOnTwoBloc();
		this.loader = false;
	}

	/**
	 * Take more event users
	 * @param evt
	 */
	// takeMoreEventUsers() {
	// 	this.limit = this.limit + 30;
	// 	if (this.module && this.module.options && this.module.options.showFilter) {
	// 		this.buildEventUser();
	// 	} else {
	// 		this.getEventUsers();
	// 	}
	// }

	/**
	 * canShowFilterBloc
	 * @description return true if the filter is at least one custom field that can be filtered
	 * @returns boolean
	 */

	canShowFilterBloc() {
		return (
			this.customFields[this.eventUserModule.uid]?.some((cus) => cus.moduleSettings.enableFilter) ||
			(this.eventUserModule.options.requiredFields.group.filter && this.groups.length > 0)
		);
	}

	/**
	 * Get list of event users on two bloc
	 * @returns
	 */
	getListOnTwoBloc() {
		let count = 0;
		let index = 0;
		const newFilteredEventUsersOnTwo = [];
		this.filteredEventUsers.forEach((eventUser) => {
			if (count === 2) {
				index++;
				count = 0;
			}
			if (!newFilteredEventUsersOnTwo[index]) {
				newFilteredEventUsersOnTwo[index] = [];
			}
			newFilteredEventUsersOnTwo[index].push(eventUser);
			count++;
		});
		if (!_.isEqual(this.filteredEventUsersInTwo, newFilteredEventUsersOnTwo)) {
			this.filteredEventUsersInTwo = newFilteredEventUsersOnTwo;
		}
	}

	/**
	 * Get filter term exist
	 * @param term
	 * @returns
	 */
	getFilterTermExist(term: string) {
		return this.SFirestore.getCountOfQueryObs(
			`events/${this.eventId}/modules/${this.moduleId}/event-users`,
			"default",
			[where("searchStringDatas", "array-contains", term)]
		).pipe(
			take(1),
			switchMap((count) => of(count > 0 ? true : false))
		);
	}

	/**
	 * Get total of filter term
	 * @param term
	 * @returns
	 */
	getTotalFilterTerm(term: string) {
		return this.SFirestore.getCountOfQueryObs(
			`events/${this.eventId}/modules/${this.moduleId}/event-users`,
			"default",
			[where("searchStringDatas", "array-contains", term)]
		).pipe(take(1));
	}

	/**
	 * Get custom field data
	 * @returns
	 */
	getCustomFieldData(user: IEventUser, index: number) {
		const customField = this.tagCustomFields[index];
		const customFieldData = user.customFields.find((customData) => customData.uid === customField.baseSettings.uid);
		return customFieldData && customFieldData.field
			? customFieldData.field.multiLanguageText[this.currentLanguage]
			: "";
	}

	/**
	 * Present toast time
	 * @param time
	 */
	async presentToast(time) {
		const toast = await this.toastCtrl.create({
			message: time,
			duration: 3000
		});
		toast.present();
	}

	/**
	 * Show letters
	 * @returns
	 */
	eventUserHeader() {
		if (!this.eventUserModule?.options?.showLetters) {
			return null;
		} else {
			return (eventUser, i, eventUsers) => {
				const letter = eventUser.name[0].toUpperCase();
				if (i === 0 || (i !== 0 && letter !== eventUsers[i - 1].name[0].toUpperCase())) {
					return letter;
				}
			};
		}
	}

	/**
	 * Search bar
	 * @param ev
	 */
	searchBar(ev) {
		if (ev.target.value.length >= 1) {
			const value = this.SUtility.removeAccents(ev.target.value);
			this.searchValue = value;
			if (this.eventUserModule?.options?.showFilter || this.eventUserModule?.options?.showSearchIcon) {
				// this.filteredEventUsers = [];
				this.filteredEventUsers = [...this.eventUsers].filter((eventUser) =>
					this.SUtility.removeAccents(eventUser.name).toLocaleLowerCase().includes(value.toLocaleLowerCase())
				);
			} else {
				this.shouldGetEventUsers$.next(true);
			}
			if (this.filteredEventUsers.length === 0) this.showNoResultImage = true;
		} else {
			this.resetFilter();
		}
	}

	/**
	 * filterEventUser
	 * @description filter event users with custom fields
	 * @param ev
	 * @param filterId
	 */
	filterEventUser(ev, filterId: string) {
		if (ev && ev !== null) {
			const data = ev.target.value as IFilterOptions;
			this.filterItems
				.find((item) => item.uid === filterId)
				.values.find((value) => value.value.toLowerCase() === data.value.toLowerCase()).isSelected =
				!data.isSelected;

			this.selectedDatas.indexOf(data) === -1
				? this.selectedDatas.push(data)
				: this.selectedDatas.splice(this.selectedDatas.indexOf(data), 1);

			this.selectedFilters = this.filterItems.filter((item) =>
				item.values.some(() => item.values.find((value) => this.selectedDatas.indexOf(value) !== -1))
			);
		}

		// if (this.module && this.module.options && this.module.options.showFilter && !this.moduleUpdatedSettings) {
		if (this.eventUserModule && this.eventUserModule?.options && this.eventUserModule?.options?.showFilter) {
			this.filteredEventUsers = this.eventUsers.filter((eventUser) => {
				let matches = 0;
				this.selectedFilters.forEach((filter) => {
					if (
						(filter.uid === "group" &&
							filter.values.find((value) =>
								value.isSelected
									? eventUser.groups.find(
											(grId) =>
												this.getCorrespondingGroup(grId)?.name.toLowerCase() ===
												value.value.toLowerCase()
									  ) ||
									  (eventUser.groups.length === 0 &&
											value.value.toLowerCase() ===
												this.STranslate.instant("filter.not_specified").toLowerCase())
									: false
							)) ||
						filter.values.find((value) =>
							value.isSelected
								? eventUser.customFields.find((cus) =>
										this.getCustomFieldType(cus.uid) === TypeCustomFields.TEXT ||
										this.getCustomFieldType(cus.uid) === TypeCustomFields.SELECT
											? (filter.uid === cus.uid &&
													value.value.toLowerCase() ===
														cus.field.multiLanguageText[
															this.currentLanguage
														].toLowerCase()) ||
											  (filter.uid === cus.uid &&
													value.value.toLowerCase() ===
														this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.multiLanguageText[this.currentLanguage] === "")
											: this.getCustomFieldType(cus.uid) === TypeCustomFields.URL ||
											  this.getCustomFieldType(cus.uid) === TypeCustomFields.EMAIL
											? (filter.uid === cus.uid &&
													value.value.toLowerCase() === cus.field.text?.toLowerCase()) ||
											  (filter.uid === cus.uid &&
													value.value.toLowerCase() ===
														this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.text === "")
											: this.getCustomFieldType(cus.uid) === TypeCustomFields.NUMERIC
											? (filter.uid === cus.uid &&
													value.value.toLowerCase() ===
														cus.field.numeric?.toString().toLowerCase()) ||
											  (filter.uid === cus.uid &&
													value.value.toLowerCase() ===
														this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.numeric === -1)
											: this.getCustomFieldType(cus.uid) === TypeCustomFields.MULTI_SELECT
											? (filter.strictSelectionMode &&
													filter.uid === cus.uid &&
													filter.values
														.filter((v) => v.isSelected)
														.map((opt) => opt.value.toLowerCase())
														.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
														.join(", ") ===
														cus.field.multiLanguageSelectArray
															.map((opt) => opt[this.currentLanguage].toLowerCase())
															.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
															.join(", ")) ||
											  (!filter.strictSelectionMode &&
													filter.uid === cus.uid &&
													cus.field.multiLanguageSelectArray
														.map((opt) => opt[this.currentLanguage].toLowerCase())
														.includes(value.value.toLowerCase())) ||
											  (filter.uid === cus.uid &&
													value.value.toLowerCase() ===
														this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.multiLanguageSelectArray.map(
														(opt) => opt[this.currentLanguage]
													).length === 0)
											: false
								  )
								: false
						)
					)
						matches++;
				});

				if (matches === this.selectedFilters.length) {
					return eventUser;
				}
			});
			this.getListOnTwoBloc();

			this.computeReachableDatas();
		}
		// else {
		// 	this.getEventUsers();
		// 	this.computeReachableDatas();
		// }
	}

	/**
	 * changeSelectionMode
	 * @param filterItem
	 */
	changeSelectionMode(filterItem: IFilteredItemFormat) {
		this.clearFilter(filterItem);

		// Disable the "not_specified" field
		const notSpecifierValue = filterItem.values.find(
			(v) => v.value.toLowerCase() === this.STranslate.instant("filter.not_specified").toLowerCase()
		);

		!filterItem.strictSelectionMode && notSpecifierValue
			? (notSpecifierValue.isReachable = false)
			: (notSpecifierValue.isReachable = true);

		// Change mode
		filterItem.strictSelectionMode = !filterItem.strictSelectionMode;
	}

	/**
	 * clearFilter
	 * @description remove checked option from the given filter
	 * @param filter
	 */
	clearFilter(filter: IFilteredItemFormat) {
		// this.filterItems.find((filterItem) => filterItem.uid === filter.uid).values = this.filterItems
		// 	.find((filterItem) => filterItem.uid === filter.uid)
		// 	.values.map((data) => {
		// 		return {
		// 			isReachable:
		// 				filter.strictSelectionMode &&
		// 				data.value.toLowerCase() === this.STranslate.instant("filter.not_specified").toLowerCase()
		// 					? false
		// 					: data.isReachable,
		// 			isSelected: false,
		// 			value: data.value,
		// 			filterId: filter.uid
		// 		};
		// 	});

		this.selectedFilters = this.selectedFilters.filter((selectedFilter) => selectedFilter.uid !== filter.uid);
		this.selectedDatas = this.selectedDatas.filter((selectedData) => selectedData.filterId !== filter.uid);

		this.filterEventUser(null, filter.uid);
	}

	/**
	 * clearAllFilter
	 * @description remove all checked options from all filters
	 */
	clearAllFilter() {
		// this.filterItems = this.filterItems.map((filterItem) => {
		// 	filterItem.values = filterItem.values.map((data) => {
		// 		return { isReachable: true, isSelected: false, value: data.value, filterId: filterItem.uid };
		// 	});
		// 	this.selectedFilters = [];
		// 	this.selectedDatas = [];
		// 	this.filteredEventUsers = [...this.eventUsers];
		// 	this.getListOnTwoBloc();
		// 	return filterItem;
		// });
	}

	/**
	 * filterIsActivated
	 * @description return boolean on whether the given filter has a selected data in it or not
	 * @param filter
	 * @returns filter
	 */
	filterIsActivated(filter: IFilteredItemFormat) {
		return filter.values.some((data) => data.isSelected);
	}

	/**
	 * anyFilterIsActivated
	 * @description return boolean on whether at least one filter has a selected data in it or not
	 * @param filter
	 * @returns filter
	 */
	anyFilterIsActivated(): boolean {
		return this.selectedFilters.length > 0;
	}

	/**
	 * getEventUserTags
	 * @param eventUser
	 * @returns
	 */
	getEventUserTags(eventUser: IEventUser): ICustomFieldData[] {
		return eventUser.customFields
			.filter(
				(eventUserCustomField) =>
					eventUserCustomField &&
					this.customFields[eventUser.moduleId].find(
						(computedCustomField) =>
							computedCustomField.baseSettings.uid === eventUserCustomField.uid &&
							computedCustomField.moduleSettings.canBeTag
					) &&
					((eventUserCustomField.field.text &&
						eventUserCustomField.field.text.trim() &&
						eventUserCustomField.field.text?.trim() !== "") ||
						(eventUserCustomField.field.multiLanguageText &&
							eventUserCustomField.field.multiLanguageText[this.currentLanguage]?.trim() &&
							eventUserCustomField.field.multiLanguageText[this.currentLanguage]?.trim() !== "") ||
						(eventUserCustomField.field.numeric && eventUserCustomField.field.numeric !== -1))
			)
			.sort((a, b) =>
				this.getCustomFieldOrder(a, eventUser.moduleId) > this.getCustomFieldOrder(b, eventUser.moduleId)
					? 1
					: this.getCustomFieldOrder(a, eventUser.moduleId) < this.getCustomFieldOrder(b, eventUser.moduleId)
					? -1
					: 0
			);
	}

	/**
	 * getCustomFieldOrder
	 */
	getCustomFieldOrder(customField: ICustomFieldData, moduleId: string): number {
		return this.customFields[moduleId].find((cus) => cus.baseSettings.uid === customField.uid)
			? this.customFields[moduleId].find((cus) => cus.baseSettings.uid === customField.uid).moduleSettings.order
			: -1;
	}

	/**
	 * Reset filter
	 */
	resetFilter() {
		this.searchValue = "";
		if (this.eventUserModule && this.eventUserModule?.options && this.eventUserModule?.options?.showFilter) {
			this.filteredEventUsers = this.eventUsers.length === 0 ? [] : [...this.eventUsers];
			this.getListOnTwoBloc();
			this.showNoResultImage = false;
		} else {
			if (this.eventUserModule) {
				this.shouldGetEventUsers$.next(true);
			}
			this.showNoResultImage = false;
		}
	}

	/**
	 * openFilterModal
	 */
	async openMobileFilterModal() {
		// this.loader = true;
		const filterModal = await this.modalController.create({
			component: FilterComponent,
			componentProps: {
				event: this.event,
				module: this.eventUserModule,
				language: this.currentLanguage,
				eventUsersfilters: this.filterItems,
				selectedFilters: this.selectedFilters,
				notSelectedFilters: this.notSelectedFilters,
				filteredEventUsers: this.filteredEventUsers,
				eventUsers: this.eventUsers,
				selectedDatas: this.selectedDatas,
				customFields: this.customFields[this.eventUserModule.uid],
				groups: this.groups
			},
			cssClass: "filter-modal-css",
			showBackdrop: true,
			presentingElement: await this.modalController.getTop() // Get the top-most ion-modal
		});

		await filterModal.present();

		const { data } = await filterModal.onWillDismiss();

		if (data) {
			this.filterItems = data.filterItems;
			this.notSelectedFilters = data.notSelectedFilters;
			this.filteredEventUsers = data.filteredEventUsers;
			this.getListOnTwoBloc();
			this.eventUsers = data.eventUsers;
			this.selectedDatas = data.selectedDatas;
			this.selectedFilters = data.selectedFilters;
			this.customFields[this.eventUserModule.uid] = data.customFields;
			this.selectedFilters = data.selectedFilters;
		}
	}

	/**
	 * getCustomFieldType
	 * @param customFieldId
	 * @returns
	 */
	getCustomFieldType(customFieldId: string): number {
		return this.customFields[this.eventUserModule.uid]?.find((cus) => cus.baseSettings.uid === customFieldId)
			?.baseSettings.type;
	}

	/**
	 * Navigate to path
	 * @param path
	 */
	navigateTo(path: string) {
		this.navCtrl.navigateForward(path, {
			state: { form: this.form, module: this.module }
		});
	}

	/**
	 * scrolled item index changed
	 * @param index
	 */
	limitScroll: boolean = false;
	scrolledIndexChanged(index: number, _el: any) {
		const nav = this.navigationSaved.find((navMod) => navMod.moduleId === this.moduleId);
		if (this.init) {
			if (!nav) {
				this.navigationSaved.push({
					moduleId: this.moduleId,
					lastIndex: index
				});
			} else {
				nav.lastIndex = index;
			}
		}
		if (
			this.virtualScroll.getRenderedRange().end >= this.virtualScroll.getDataLength() - 2 &&
			this.virtualScroll.getRenderedRange().end <= this.virtualScroll.getDataLength() &&
			this.init &&
			!this.limitScroll &&
			this.totalEventUsers > this.virtualScroll.getDataLength()
		) {
			this.limitScroll = true;
			this.limit = this.limit + 30;
			if (
				this.eventUserModule &&
				this.eventUserModule?.options &&
				(this.eventUserModule?.options?.showFilter || this.eventUserModule?.options?.showSearchIcon)
			) {
				this.buildEventUser();
				this.buildFavorites();
			} else {
				if (this.eventUserModule) {
					this.shouldGetEventUsers$.next(true);
				}
			}
			setTimeout(() => {
				this.limitScroll = false;
			}, 1000);
		}
	}

	/**
	 * collapse
	 * @param index
	 */
	collapseFilter(filterId: string) {
		this.filtersCollapseState.find((filter) => filter.uid === filterId).collapsed = !this.filtersCollapseState.find(
			(filter) => filter.uid === filterId
		).collapsed;
	}

	/**
	 * getFilterCollapseState
	 */
	getFilterCollapseState(filterId: string): boolean {
		return this.filtersCollapseState.find((filter) => filter.uid === filterId)
			? this.filtersCollapseState.find((filter) => filter.uid === filterId).collapsed
			: false;
	}

	/**
	 * getCorrespondingGroup
	 * @param groupId
	 * @returns
	 */
	getCorrespondingGroup(groupId: string) {
		return this.groups.find((gr) => gr.uid === groupId);
	}

	/**
	 * checkFavorite
	 * @param eventUserId
	 * @returns
	 */
	buildFavorites() {
		this.eventUsers.forEach((eventUser) => {
			if (this.myEventUser?.favorites?.includes(eventUser.uid)) {
				this.eventUserFavoritesState = {
					...this.eventUserFavoritesState,
					[eventUser.uid]: true
				};
			} else {
				this.eventUserFavoritesState = {
					...this.eventUserFavoritesState,
					[eventUser.uid]: false
				};
			}
		});
	}

	/**
	 * Scan qr code
	 */
	async scanQr() {
		try {
			if (!this.scanning) {
				this.loader = true;
				this.scanning = true;

				// For web scanning
				// if (!this.isMobile) {
				// 	this.loader = false;
				// 	this.scanning = false;
				// 	this.snackBar.open(this.STranslate.instant("alerts.cannot_scan_web"), null, {
				// 		duration: 3000,
				// 		panelClass: ["snackbar-error"]
				// 	});
				// 	return;
				// }
				this.store.dispatch(GetScanning({ payload: true }));

				const result = await this.SCardExchange.perfomScanQr();
				this.store.dispatch(GetScanning({ payload: false }));

				const userId =
					result.content.split("+") && result.content.split("+").length > 0
						? result.content.split("+")[1]
						: null;

				if (!userId) {
					throw new Error("No userId found in scan results");
				}
				this.openCardExchangeForm(userId);

				this.scanning = false;
				this.loader = false;
				// this.shouldGetEventUsers$.next(true);
			}
		} catch (error) {
			console.error("🚀 ~ scanQr ~ error:", error);
			this.scanning = false;
			this.loader = false;
			this.store.dispatch(GetScanning({ payload: false }));

			this.snackBar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}

	/**
	 * openCardExchangeForm
	 * @param contactId
	 * @returns
	 */
	async openCardExchangeForm(contactId: string) {
		try {
			const eventUser = await lastValueFrom(this.SEventUsers.getSpecificEventUser(this.eventId, contactId));

			if (!eventUser) {
				this.snackBar.open(this.STranslate.instant("snackbar.error_occured"), null, {
					duration: 3000,
					panelClass: ["snackbar-error"]
				});
				return;
			}

			if (this.form) {
				const modal = await this.modalController.create({
					component: CardExchangeFormComponent,
					componentProps: {
						event: this.event,
						module: this.module,
						myEventUser: this.myEventUser,
						eventUserContact: eventUser,
						eventUserContactId: eventUser.uid
					},

					cssClass: this.isMobile ? "card-exhange-form-modal-css-mobile" : "card-exchange-form-modal-css"
				});

				await modal.present();

				const { data } = await modal.onWillDismiss();

				if (data?.success) {
					await this.storeContactInEventUserDoc(this.myEventUser, contactId);
				}
			} else {
				await this.storeContactInEventUserDoc(this.myEventUser, contactId);
			}

			this.SUtility.presentToast(
				this.STranslate.instant("snackbar.update_successfull"),
				3000,
				"bottom",
				"success"
			);

			this.shouldGetEventUsers$.next(true);
		} catch (error) {
			this.snackBar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}

	async storeContactInEventUserDoc(eventUser: IEventUser, contactId: string) {
		try {
			await this.SCardExchange.storeContactInEventUserDoc(this.event, eventUser, contactId);
		} catch (error) {
			this.snackBar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				panelClass: ["snackbar-error"],
				duration: 3000
			});
		}
	}

	goToList() {
		this.navCtrl.navigateForward("/event/" + this.eventId + "/card-exchange/" + this.moduleId + "/list", {
			state: { form: this.form }
		});
	}
}
