/* eslint-disable max-len */
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, WritableSignal, signal } from "@angular/core";
import { QueryConstraint, arrayRemove, arrayUnion, where } from "@angular/fire/firestore";
import { NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import {
	BehaviorSubject,
	Observable,
	Subject,
	Subscription,
	combineLatest,
	firstValueFrom,
	forkJoin,
	from,
	of
} from "rxjs";
import { environment } from "src/environments/environment";
import { debounceTime, map, skipWhile, switchMap, take } from "rxjs/operators";
import { FirestoreService, MongodbService, StateManagerService, StorageService } from ".";
import { GetMyEventUser } from "../actions/auth.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { TypeUser } from "../enums/type-user";
import { IEvent, IFullCustomField, IModuleCustomField } from "../interfaces";
import {
	IEventUser,
	IEventUserUpdatedSettings,
	IEventUserUpdatedSettingsRulesAuthorized
} from "../interfaces/event-users.interfaces";
import { IModule } from "../interfaces/modules.interfaces";
import { getCurrentEventUser, getCurrentUser } from "../selectors/auth.selectors";
import { checkSameEvent, getAllEventUsers, getEventUsersForModule } from "../selectors/event-users.selectors";
import { getCurrentEvent } from "../selectors/events.selectors";
import { getInitSpecificEventDatasPart, getNetworkStatus } from "../selectors/utility.selectors";
import { Counter } from "./distributed-counter.service";
import { ISearchFilter } from "../interfaces/search.interfaces";
import { filterSearch } from "../helpers-functions/filter-search";
import { getModulesByTypes, getSpecificModule } from "../selectors/modules.selectors";
import { TypeModule } from "../enums/type-module";
import { GetAllEventUsers } from "../actions/event-users.actions";

@Injectable({
	providedIn: "root"
})
export class EventUsersService {
	headers: HttpHeaders;
	myEventUserSub: Subscription;
	initSub: Subscription;
	currentEventUser: IEventUser;

	eventUsersByModulesState: WritableSignal<any> = signal({});
	eventUsersOfAllModules: WritableSignal<IEventUser[] | null> = signal(null);

	subscriptionsStateReminder: any = {
		all: false
	};
	subscriptionsState: { moduleId: string; subscription: Subscription }[] = [];
	checkInitSubject: Subject<{ moduleId: string; type: "all" | "module" }> = new BehaviorSubject(
		this.subscriptionsStateReminder
	);

	constructor(
		private http: HttpClient,
		private SFirestore: FirestoreService,
		private SMongo: MongodbService,
		private store: Store,
		private SStorage: StorageService,
		private SState: StateManagerService,
		private navCtrl: NavController
	) {
		this.headers = new HttpHeaders();
		this.headers.append("content-type", "application/json");
	}

	checkDatasGetType(): Observable<"firestore" | "mongo"> {
		return this.store.select(getCurrentEvent).pipe(
			take(1),
			switchMap((event) => {
				if (event && event.limitations && event.limitations.usersLimit <= 1000) {
					// If event has less or equal than 1000 users
					return of("firestore" as const);
				} else {
					// If event has more than 1000 users
					return of("mongo" as const);
				}
			})
		);
	}

	initSubscriptionOfAllModules(eventId: string) {
		this.store
			.select(getInitSpecificEventDatasPart("initModules"))
			.pipe(
				skipWhile((init) => !init),
				take(1),
				switchMap(() => {
					return this.store
						.select(getModulesByTypes([TypeModule.ATTENDEE, TypeModule.SPEAKER]))
						.pipe(take(1));
				})
			)
			.subscribe((modules) => {
				modules.forEach((module) => {
					this.initSubscriptionOfModule(eventId, module.uid);
				});

				this.subscriptionsStateReminder["all"] = true;
				this.checkInitSubject.next(this.subscriptionsStateReminder);
			});
	}

	initSubscriptionOfModule(eventId: string, moduleId: string) {
		// Check if subscription already exist
		const checkSub = this.subscriptionsState.find((sub) => sub.moduleId === moduleId);

		if (!checkSub || (checkSub && checkSub.subscription && checkSub.subscription.closed)) {
			this.subscriptionsState = this.subscriptionsState.filter((sub) => sub.moduleId !== moduleId);
			this.initSubscription(eventId, moduleId);
		} else {
			this.store.dispatch(InitSpecificEventDatasPart({ part: "initAttendees", payload: true }));
			this.store.dispatch(InitSpecificEventDatasPart({ part: "initSpeakers", payload: true }));
		}
	}

	initSubscription(eventId: string, moduleId: string) {
		const subscription = this.SFirestore.valueChangesDocuments(
			`events/${eventId}/modules/${moduleId}/event-users`,
			[]
		)
			.pipe(
				switchMap((eventUsers: IEventUser[]) => {
					return this.store.select(getAllEventUsers("all")).pipe(
						take(1),
						switchMap((storedEventUsers) => {
							return of(
								storedEventUsers
									.filter((eventUser) => eventUser.moduleId !== moduleId)
									.concat(eventUsers)
							);
						})
					);
				})
			)
			.subscribe((eventUsers: IEventUser[]) => {
				this.store.dispatch(GetAllEventUsers({ payload: eventUsers, eventId: eventId }));
				this.subscriptionsStateReminder[moduleId] = true;
				this.checkInitSubject.next(this.subscriptionsStateReminder);
			});
		this.subscriptionsState.push({
			moduleId: moduleId,
			subscription: subscription
		});
		this.store.dispatch(InitSpecificEventDatasPart({ part: "initAttendees", payload: true }));
		this.store.dispatch(InitSpecificEventDatasPart({ part: "initSpeakers", payload: true }));
	}

	unsubscribeAll() {
		[this.myEventUserSub, this.initSub].forEach((sub) => {
			if (sub) sub.unsubscribe();
		});

		this.unsubscribeEventUsersState();
	}

	unsubscribeEventUsersState() {
		this.subscriptionsState.forEach((subscription) => {
			if (subscription && subscription.subscription && !subscription.subscription.closed) {
				subscription.subscription.unsubscribe();
			}
		});
		this.subscriptionsState = [];
		this.subscriptionsStateReminder = { all: false };
		this.store.dispatch(InitSpecificEventDatasPart({ part: "initAttendees", payload: false }));
		this.store.dispatch(InitSpecificEventDatasPart({ part: "initSpeakers", payload: false }));
	}

	/**
	 * Init my event user subscription
	 */
	initMyEventUser() {
		if (this.initSub && !this.initSub.closed) {
			this.initSub.unsubscribe();
		}

		this.initSub = combineLatest([
			this.store.select(getCurrentUser),
			this.store.select(getCurrentEvent).pipe(
				skipWhile((event) => !event),
				take(1)
			)
		])
			// .pipe(take(1))
			.subscribe((results) => {
				const user = results[0];
				const event = results[1];
				if (!user) {
					this.store.dispatch(GetMyEventUser({ payload: null, eventId: event ? event.uid : null }));
					if (this.myEventUserSub && !this.myEventUserSub.closed) {
						this.myEventUserSub.unsubscribe();
					}

					this.store
						.select(getInitSpecificEventDatasPart("initEventUser"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initEventUser", payload: true })
								);
							}
						});
				} else {
					this.getMyEventUserDataOnStore(event, user.uid);
				}
			});
	}

	/**
	 * Get event user data of user for event
	 * @param eventId
	 * @param userId
	 */
	getMyEventUserDataOnStore(event: IEvent, userId: string) {
		let count = 0;
		if (event && userId) {
			this.store
				.select(checkSameEvent(event.uid))
				.pipe(take(1))
				.subscribe((sameEvent) => {
					if (sameEvent && this.myEventUserSub && !this.myEventUserSub.closed) {
						return;
					} else if (!sameEvent && this.myEventUserSub && !this.myEventUserSub.closed) {
						this.myEventUserSub.unsubscribe();
					}
					this.myEventUserSub = combineLatest([
						this.SFirestore.collectionGroupValueChangesDocuments("event-users", [
							where("eventId", "==", event.uid),
							where("uid", "==", userId)
						]),
						this.SFirestore.collectionGroupValueChangesDocuments("event-users-updated-settings", [
							where("eventId", "==", event.uid),
							where("userId", "==", userId)
						])
					])
						.pipe(debounceTime(200))
						.subscribe({
							next: (results) => {
								const eventUser: IEventUser = results[0].length > 0 ? _.cloneDeep(results[0][0]) : null;
								const eventUserUpdatedSettings: IEventUserUpdatedSettings =
									results[1].length > 0 ? _.cloneDeep(results[1][0]) : null;
								if (eventUser && eventUserUpdatedSettings) {
									if (!eventUserUpdatedSettings.typeUser && eventUser && eventUser.type) {
										eventUserUpdatedSettings.typeUser = eventUser.type;
										this.updateEventUserUpdatedSettings(
											eventUser.eventId,
											eventUser.moduleId,
											eventUser.uid,
											eventUserUpdatedSettings
										);
									}
									eventUser.updatedSettings = eventUserUpdatedSettings;
								}
								// Check if old event user exist and now not only for private event
								if (this.currentEventUser && !eventUser && event && !event.settings.visibility) {
									this.SState.unsubscribeEvent();
									this.SState.resetStateEvent();
									this.unsubscribeAll();
									this.currentEventUser = eventUser;
									this.navCtrl.navigateRoot("/events-lists/all");
								} else {
									if (eventUser && !eventUser.firstAccess && count === 0) {
										count++;
										// Update first access of event and date if false
										this.updatePartOfEventUser(
											eventUser.eventId,
											eventUser.moduleId,
											eventUser.uid,
											{
												firstAccess: true,
												firstAccessDate: DateTime.local().toISO()
											}
										);

										// Update credits for first access
										this.SFirestore.getDocumentObs(`events/${eventUser.eventId}`).subscribe(
											(event) => {
												const eventData = event.data() as IEvent;
												this.SFirestore.getDocumentObs(`users/${eventData.clientId}`).subscribe(
													(user) => {
														if (user.get("isCreditsAllowed")) {
															const counterOnEvent = new Counter(
																this.SFirestore.docRef(
																	`events/${eventUser.eventId}/counters/event-users-firstaccess`
																),
																eventData.clientId,
																"count",
																eventData,
																"onEvent",
																this.SFirestore
															);

															const counterOnUser = new Counter(
																this.SFirestore.docRef(
																	`events/${eventUser.eventId}/counters/${eventUser.eventId}|event-users-firstaccess`
																),
																eventData.clientId,
																"count",
																eventData,
																"onUser",
																this.SFirestore
															);

															counterOnEvent.incrementBy(1);
															counterOnUser.incrementBy(1);
														}
													}
												);
											}
										);
									}

									this.store.dispatch(GetMyEventUser({ payload: eventUser, eventId: event.uid }));
									this.currentEventUser = eventUser;

									this.store
										.select(getInitSpecificEventDatasPart("initEventUser"))
										.pipe(take(1))
										.subscribe((init) => {
											if (!init) {
												this.store.dispatch(
													InitSpecificEventDatasPart({ part: "initEventUser", payload: true })
												);
											}
										});
								}
							},
							error: () => {
								//
							}
						});
				});
		}
	}

	/**
	 * Get specifics updated settings for event users
	 * @param eventId
	 * @param moduleId
	 * @param usersIds
	 * @returns
	 */
	getSpecificsEventUsersUpdatedSettings(eventId: string, moduleId: string, usersIds: string[]) {
		if (usersIds.length === 0) {
			return of([]);
		}
		const chunked = _.chunk(usersIds, 10);
		const obsUpdatedSettings: Observable<IEventUserUpdatedSettings[]>[] = [];

		if (moduleId) {
			chunked.forEach((chunk) => {
				obsUpdatedSettings.push(
					this.SFirestore.getDocumentsObs(
						`events/${eventId}/modules/${moduleId}/event-users-updated-settings`,
						[where("userId", "in", chunk)]
					).pipe(map((docs) => docs.docs.map((doc) => doc.data() as IEventUserUpdatedSettings)))
				);
			});
		} else {
			chunked.forEach((chunk) => {
				obsUpdatedSettings.push(
					from(
						this.SFirestore.getDocumentsCollectionGroup(`event-users-updated-settings`, [
							where("eventId", "==", eventId),
							where("userId", "in", chunk)
						])
					).pipe(map((docs) => docs.docs.map((doc) => doc.data() as IEventUserUpdatedSettings)))
				);
			});
		}

		return forkJoin(obsUpdatedSettings).pipe(
			take(1),
			switchMap((results) => {
				return of(_.flatten(results));
			})
		);
	}

	/**
	 * Get specifics event users
	 * @param eventId
	 * @param usersIds
	 * @returns
	 */
	getSpecificsEventUsers(eventId: string, usersIds: string[]) {
		if (usersIds.length === 0) {
			return of([]);
		}
		const chunked = _.chunk(usersIds, 10);
		const obsEventUsers: Observable<IEventUser[]>[] = [];

		chunked.forEach((chunk) => {
			obsEventUsers.push(
				from(
					this.SFirestore.getDocumentsCollectionGroup(`event-users`, [
						where("eventId", "==", eventId),
						where("uid", "in", chunk)
					])
				).pipe(map((docs) => docs.docs.map((doc) => doc.data() as IEventUser)))
			);
		});

		return forkJoin(obsEventUsers).pipe(
			take(1),
			switchMap((results) => {
				return of(_.flatten(results));
			})
		);
	}

	/**
	 * getSpecificsEventUsersPaginated
	 */
	getSpecificsEventUsersPaginated(eventId: string, usersIds: string[], queryConstraint: QueryConstraint[]) {
		if (usersIds.length === 0) {
			return of([]);
		}

		const chunked = _.chunk(usersIds, 10);
		const obsEventUsers: Observable<any>[] = [];

		chunked.forEach((chunk) => {
			obsEventUsers.push(
				from(
					this.SFirestore.getDocumentsCollectionGroup(
						`event-users`,
						queryConstraint
							? [where("eventId", "==", eventId), where("uid", "in", chunk), ...queryConstraint]
							: [where("eventId", "==", eventId), where("uid", "in", chunk)]
					)
				).pipe(
					switchMap((docs) =>
						of({
							start: docs.docs[0],
							last: docs.docs[docs.docs.length - 1],
							docsSnapshots: docs.docs,
							docsDatas: docs.docs.map((doc) => doc.data() as IEventUser)
						})
					)
				)
			);
		});

		return forkJoin(obsEventUsers).pipe(
			take(1),
			switchMap((results) => {
				return of({
					start: results[0].start,
					last: results[results.length - 1].last,
					docsSnapshots: _.flatten(results.map((result) => result.docsSnapshots)),
					docsDatas: _.flatten(results.map((result) => result.docsDatas))
				});
			})
		);
	}

	/**
	 * Get specifics updated settings for event users
	 * @param eventId
	 * @param moduleId
	 * @param userId
	 * @returns
	 */
	getSpecificEventUserUpdatedSettings(eventId: string, moduleId: string, userId: string) {
		return this.store.select(getNetworkStatus).pipe(
			take(1),
			switchMap((status) => {
				if (status) {
					return from(
						this.SFirestore.getDocument(
							`events/${eventId}/modules/${moduleId}/event-users-updated-settings/${userId}`
						)
					).pipe(map((snapshot) => snapshot.data() as IEventUserUpdatedSettings));
				} else {
					return of(null);
				}
			})
		);
	}

	/**
	 * Get all event users of an event
	 * @param eventId
	 * @returns
	 */
	getAllEventUsersForEvent(filters: ISearchFilter, type: "aggregation" | "normal") {
		return this.store.select(getCurrentEvent).pipe(
			take(1),
			switchMap((event) => {
				if (event && event.limitations && event.limitations.usersLimit <= 1000) {
					// If event has less or equal than 1000 users
					return this.checkInitSubject.pipe(
						take(1),
						switchMap((initState) => {
							if (initState && initState["all"]) {
								return this.store.select(getAllEventUsers("all")).pipe(
									switchMap((eventUsers) => {
										const eventUsersFiltered = filterSearch(filters, eventUsers);
										return of({
											docs: eventUsersFiltered.datas,
											totalDocuments: eventUsersFiltered.totalItems
										});
									})
								);
							} else {
								this.initSubscriptionOfAllModules(event.uid);
								return this.checkInitSubject.pipe(
									skipWhile((init) => !init || !init["all"]),
									take(1),
									switchMap(() => {
										return this.store.select(getAllEventUsers("all")).pipe(
											debounceTime(200),
											switchMap((eventUsers) => {
												const eventUsersFiltered = filterSearch(filters, eventUsers);
												return of({
													docs: eventUsersFiltered.datas,
													totalDocuments: eventUsersFiltered.totalItems
												});
											})
										);
									})
								);
							}
						})
					);
				} else {
					// If event has more than 1000 users
					return this.SMongo.queryDatasMongodb(
						this.SMongo.convertSearchFilterToQueryOrAggregationMongo(filters, type, "eventUsersSearch"),
						"event-users",
						{},
						type
					);
				}
			})
		);
	}

	/**
	 * Get event users of a module in an event
	 * @param eventId
	 * @returns
	 */
	getEventUsersOfModuleForEvent(moduleId: string, filters: ISearchFilter, type: "aggregation" | "normal") {
		return combineLatest([
			this.store.select(getCurrentEvent),
			this.store.select(getCurrentEventUser),
			this.store.select(getSpecificModule(moduleId))
		]).pipe(
			take(1),
			switchMap((results) => {
				const event = results[0];
				const currentEventUser = results[1];
				const module = results[2];
				if (event && event.limitations && event.limitations.usersLimit <= 1000) {
					// If event has less or equal than 1000 users
					return this.checkInitSubject.pipe(
						take(1),
						switchMap((initState) => {
							if (initState && initState[moduleId]) {
								return this.store
									.select(
										getEventUsersForModule({
											order: "asc",
											moduleId: moduleId
										})
									)
									.pipe(
										switchMap((eventUsers) => {
											const eventUsersFiltered = filters
												? filterSearch(
														filters,
														eventUsers.filter(
															(data) =>
																module &&
																data.moduleId === module.uid &&
																module.options &&
																(!module.options.viewOnlyGroupsContent ||
																	(module.options.viewOnlyGroupsContent &&
																		currentEventUser &&
																		data.groups.some((grpId) =>
																			currentEventUser.groups.includes(grpId)
																		)))
														)
												  )
												: {
														datas: eventUsers,
														totalItems: eventUsers.length,
														focusedItemId: ""
												  };
											return of({
												docs: eventUsersFiltered.datas,
												totalDocuments: eventUsersFiltered.totalItems
											});
										})
									);
							} else {
								this.initSubscriptionOfModule(event.uid, moduleId);
								return this.checkInitSubject.pipe(
									skipWhile((init) => !init || !init[moduleId]),
									take(1),
									switchMap(() => {
										return this.store
											.select(
												getEventUsersForModule({
													order: "asc",
													moduleId: moduleId
												})
											)
											.pipe(
												debounceTime(200),
												switchMap((eventUsers) => {
													const eventUsersFiltered = filters
														? filterSearch(
																filters,
																eventUsers.filter(
																	(data) =>
																		module &&
																		data.moduleId === module.uid &&
																		module.options &&
																		(!module.options.viewOnlyGroupsContent ||
																			(module.options.viewOnlyGroupsContent &&
																				currentEventUser &&
																				data.groups.some((grpId) =>
																					currentEventUser.groups.includes(
																						grpId
																					)
																				)))
																)
														  )
														: {
																datas: eventUsers,
																totalItems: eventUsers.length,
																focusedItemId: ""
														  };
													return of({
														docs: eventUsersFiltered.datas,
														totalDocuments: eventUsersFiltered.totalItems
													});
												})
											);
									})
								);
							}
						})
					);
				} else {
					// If event has more than 1000 users
					return this.SMongo.queryDatasMongodb(
						this.SMongo.convertSearchFilterToQueryOrAggregationMongo(filters, type, "eventUsersSearch"),
						"event-users",
						{},
						type
					);
				}
			})
		);
	}

	/**
	 * Getting one specific event user
	 * @param eventId
	 * @param uid
	 * @returns
	 */
	getSpecificEventUser(eventId: string, uid: string) {
		return from(
			this.SFirestore.getDocumentsCollectionGroup("event-users", [
				where("eventId", "==", eventId),
				where("uid", "==", uid)
			])
		).pipe(
			map((snapshot) => snapshot.docs.map((doc) => doc.data() as IEventUser)),
			switchMap((eventUsers) => {
				return of(eventUsers.length > 0 ? eventUsers[0] : null);
			})
		);
	}

	/**
	 * Get eventUsers for module
	 * @param eventId
	 * @param moduleId
	 * @param queryConstraints
	 * @returns
	 */
	getEventUsersWithConstraints(
		eventId: string,
		moduleId: string,
		queryConstraints: QueryConstraint[],
		additionalConstraints?: QueryConstraint[]
	) {
		if (moduleId) {
			return this.SFirestore.getDocumentsObs(
				`events/${eventId}/modules/${moduleId}/event-users`,
				additionalConstraints ? queryConstraints.concat(additionalConstraints) : queryConstraints
			).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as IEventUser)));
		} else {
			return from(
				this.SFirestore.getDocumentsCollectionGroup(
					`event-users`,
					additionalConstraints ? queryConstraints.concat(additionalConstraints) : queryConstraints
				)
			).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as IEventUser)));
		}
	}

	/**
	 * TODO REWORK THIS FUNCTION
	 */
	/**
	 * Get specific eventUsers of an event
	 * @param eventId
	 * @param eventUsersIds
	 * @returns
	 */
	getSpecificEventUsersForEvent(eventId: string, type: TypeUser, eventUsersIds: string[]) {
		if (eventUsersIds.length === 0) {
			return of([]);
		}
		const allIds = _.chunk(eventUsersIds, 10);
		const obsArray: Observable<IEventUser[]>[] = [];
		const queryConstraints: QueryConstraint[] = [where("eventId", "==", eventId)];

		if (type) {
			queryConstraints.push(where("type", "==", type));
		}

		allIds.forEach((ids) => {
			obsArray.push(
				from(
					this.SFirestore.getDocumentsCollectionGroup(
						`event-users`,
						queryConstraints.concat([where("uid", "in", ids)])
					)
				).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as IEventUser)))
			);
		});
		return combineLatest(obsArray).pipe(
			switchMap((results) => {
				const eventUsers: IEventUser[] = _.flatten(results);
				return of(eventUsers);
			})
		);
	}

	/**
	 * TODO REWORK THIS FUNCTION
	 */
	/**
	 * Get specific eventUsers of an event
	 * @param eventId
	 * @param eventUsersIds
	 * @returns
	 */
	valueChangesSpecificEventUsersForEvent(eventId: string, type: TypeUser, eventUsersIds: string[]) {
		if (eventUsersIds.length === 0) {
			return of([]);
		}
		const allIds = _.chunk(eventUsersIds, 10);
		const obsArray: Observable<IEventUser[]>[] = [];
		const queryConstraints: QueryConstraint[] = [where("eventId", "==", eventId)];

		if (type) {
			queryConstraints.push(where("type", "==", type));
		}

		allIds.forEach((ids) => {
			obsArray.push(
				this.SFirestore.collectionGroupValueChangesDocuments(
					`event-users`,
					queryConstraints.concat([where("uid", "in", ids)])
				)
			);
		});
		return combineLatest(obsArray).pipe(
			switchMap((results) => {
				const eventUsers: IEventUser[] = _.flatten(results);
				return of(eventUsers);
			})
		);
	}

	/**
	 * Get user by email
	 * @param email
	 * @returns
	 */
	getEventUserByEmail(email: string, eventId: string, returnType: string) {
		return this.http
			.post(
				environment.platform.apiV2BaseUrl
					? "https://eventUsers-getEventUserByEmail" + environment.platform.apiV2BaseUrl
					: environment.platform.apiBaseUrl + "eventUsers-getEventUserByEmail",
				{ email: email, eventId: eventId, returnType: returnType },
				{ headers: this.headers }
			)
			.pipe(
				switchMap((results: any) => {
					if (results.code === 201 || results.code === 200) {
						return of(results.result);
					} else {
						return of(null);
					}
				})
			);
	}

	/**
	 * getEventUserFromModuleByEmail
	 * @param eventId
	 * @param moduleId
	 * @param email
	 * @returns
	 */
	checkEventUsersPresenceInModule(eventId: string, moduleId: string, email: string) {
		return this.SFirestore.getDocumentsObs(`events/${eventId}/modules/${moduleId}/event-users`, [
			where("email", "==", email)
		]).pipe(
			switchMap((res) => {
				return of(res.docs.map((doc) => doc.data() as IEventUser));
			})
		);
	}

	/**
	 * Get event user by identifier
	 * @param identifier
	 * @param eventId
	 * @returns
	 */
	getEventUserIdentifierByIdentifier(identifier: string, eventId) {
		return from(
			this.SFirestore.getDocumentsCollectionGroup("event-users", [
				where("eventId", "==", eventId),
				where("identifier", ">=", identifier.toUpperCase()),
				where("identifier", "<=", identifier.toUpperCase() + "\uf8ff")
			])
		).pipe(
			map((docs) => docs.docs.map((doc) => doc.data())),
			switchMap((eventUsers) => {
				if (eventUsers.length > 0) {
					return of(eventUsers);
				} else {
					return of([]);
				}
			})
		);
	}

	/**
	 * Create an event user
	 * @param eventId
	 * @param moduleId
	 * @param eventUser
	 */
	async createEventUser(
		eventId: string,
		moduleId: string,
		eventUser: IEventUser,
		eventUserUpdatedSettings: IEventUserUpdatedSettings
	) {
		const id = eventUser.uid
			? eventUser.uid
			: this.SFirestore.createId(`events/${eventId}/modules/${moduleId}/event-users`);
		eventUser.uid = id;

		const promisesArray: Promise<any>[] = [];

		if (eventUserUpdatedSettings) {
			eventUserUpdatedSettings.userId = eventUser.uid;
			promisesArray.push(this.createEventUserUpdatedSettings(eventId, moduleId, eventUserUpdatedSettings));
		}

		promisesArray.push(
			this.SFirestore.setDocument(`events/${eventId}/modules/${moduleId}/event-users/${id}`, eventUser)
		);

		promisesArray.push(
			firstValueFrom(
				this.SMongo.manageDatasMongodb({
					type: "insert-one",
					collection: "event-users",
					datas: [eventUser]
				})
			)
		);

		return Promise.all(promisesArray);
	}

	/**
	 * Create an event user
	 * @param eventId
	 * @param moduleId
	 * @param eventUser
	 */
	async createAccompanyingEventUser(
		eventId: string,
		moduleId: string,
		eventUser: IEventUser,
		accompanyingEventUser: IEventUser,
		accompanyingEventUserUpdatedSettings: IEventUserUpdatedSettings
	) {
		const id = this.SFirestore.createId(
			`events/${eventId}/modules/${moduleId}/event-users/${eventUser}/accompanying-event-users`
		);
		accompanyingEventUser.uid = id;

		let batch = this.SFirestore.getBatch();

		if (accompanyingEventUserUpdatedSettings) {
			accompanyingEventUserUpdatedSettings.userId = accompanyingEventUser.uid;
			batch = this.SFirestore.setBatch(
				batch,
				this.SFirestore.docRef(`events/${eventId}/modules/${moduleId}/event-users-updated-settings/${id}`),
				accompanyingEventUserUpdatedSettings
			);
		}

		batch = this.SFirestore.setBatch(
			batch,
			this.SFirestore.docRef(
				`events/${eventId}/modules/${moduleId}/event-users/${eventUser.uid}/accompanying-event-users/${id}`
			),
			accompanyingEventUser
		);
		return batch.commit();
	}

	/**
	 * Create event user updated settings
	 * @param eventId
	 * @param moduleId
	 * @param eventUserUpdatedSettings
	 * @returns
	 */
	createEventUserUpdatedSettings(
		eventId: string,
		moduleId: string,
		eventUserUpdatedSettings: IEventUserUpdatedSettings
	) {
		return this.SFirestore.setDocument(
			`events/${eventId}/modules/${moduleId}/event-users-updated-settings/${eventUserUpdatedSettings.userId}`,
			eventUserUpdatedSettings
		);
	}

	/**
	 * Update event user
	 * @param eventId
	 * @param moduleId
	 * @param user
	 * @returns
	 */
	async updateEventUser(eventId: string, moduleId: string, eventUser: IEventUser) {
		if (eventUser.updatedSettings) {
			const updatedSettings = _.cloneDeep(eventUser.updatedSettings);
			await this.updateEventUserUpdatedSettings(
				eventId,
				moduleId,
				eventUser.uid,
				_.omit(updatedSettings, ["eventId", "moduleId", "userId", "typeUser"])
			);
			delete eventUser.updatedSettings;
		} else {
			const fieldsVisibility = await this.buildFieldsVisibility(eventId, moduleId);
			const newUpdatedSettings: IEventUserUpdatedSettings = {
				accessModule: eventUser["accessModule"] ? eventUser["accessModule"] : {},
				accessModulesArray: eventUser["accessModulesArray"] ? eventUser["accessModulesArray"] : [],
				appointmentsSchedules: eventUser["appointmentsSchedules"] ? eventUser["appointmentsSchedules"] : [],
				connected: eventUser["connected"] ? eventUser["connected"] : false,
				eventId: eventUser.eventId,
				fieldsVisibility: fieldsVisibility,
				language: eventUser["language"] ? eventUser["language"] : environment.platform.defaultLanguage,
				lastAccessDate: eventUser["lastAccessDate"] ? eventUser["lastAccessDate"] : "",
				lastSeenNotifTime: eventUser["lastSeenNotifTime"] ? eventUser["lastSeenNotifTime"] : 0,
				moduleId: eventUser.moduleId,
				readNotifications: eventUser["readNotifications"] ? eventUser["readNotifications"] : "",
				timezoneType: eventUser["timezoneType"] ? eventUser["timezoneType"] : "local",
				userId: eventUser.uid,
				typeUser: eventUser["typeUser"] ? eventUser["typeUser"] : TypeUser.ATTENDEE
			};
			await this.createEventUserUpdatedSettings(eventId, moduleId, newUpdatedSettings);
		}

		return this.updatePartOfEventUser(
			eventId,
			moduleId,
			eventUser.uid,
			_.omit(eventUser, ["eventId", "moduleId", "uid", "identifier", "createdInApp", "type"])
		);
	}

	updatePartOfEventUser(eventId: string, moduleId: string, eventUserId: string, datas: any) {
		const promisesArray: Promise<any>[] = [];

		promisesArray.push(
			firstValueFrom(
				this.SMongo.manageDatasMongodb({
					type: "update-one",
					query: {
						eventId: { $eq: eventId },
						moduleId: { $eq: moduleId },
						uid: { $eq: eventUserId }
					},
					collection: "event-users",
					datas: [this.SMongo.transformObjectToUpdateItem(datas)]
				})
			)
		);

		promisesArray.push(
			this.SFirestore.updateDocument(
				`events/${eventId}/modules/${moduleId}/event-users/${eventUserId}`,
				_.omit(datas, ["eventId", "moduleId", "uid", "identifier", "createdInApp", "type"])
			)
		);

		return Promise.all(promisesArray);
	}

	/**
	 * Update event user updated settings
	 * @param eventId
	 * @param moduleId
	 * @param eventUserUpdatedSettings
	 * @returns
	 */
	updateEventUserUpdatedSettings(
		eventId: string,
		moduleId: string,
		eventUserId: string,
		eventUserUpdatedSettings: IEventUserUpdatedSettingsRulesAuthorized
	) {
		return this.SFirestore.updateDocument(
			`events/${eventId}/modules/${moduleId}/event-users-updated-settings/${eventUserId}`,
			_.omit(eventUserUpdatedSettings, ["eventId", "moduleId", "userId"])
		);
	}

	/**
	 * Set attendee/speaker notification config
	 * @param user
	 * @param oneSignalId
	 * @returns
	 */
	setUserNotificationConfig(user: IEventUser, oneSignalId: any) {
		return this.updatePartOfEventUser(user.eventId, user.moduleId, user.uid, {
			options: { notifOneSignalConfig: oneSignalId }
		});
	}

	/**
	 * Update last access of event user
	 * @param eventId
	 * @param eventUserId
	 */
	updateLastAccessOfEventForEventUser(eventId: string, eventUserId: string) {
		firstValueFrom(
			from(
				this.SFirestore.getDocumentsCollectionGroup(`event-users`, [
					where("eventId", "==", eventId),
					where("uid", "==", eventUserId)
				])
			).pipe(
				switchMap((eventUsersDocs) => {
					if (eventUsersDocs.size > 0) {
						const eventUser = eventUsersDocs.docs[0].data() as IEventUser;
						return this.SFirestore.updateDocument(
							`events/${eventUser.eventId}/modules/${eventUser.moduleId}/event-users-updated-settings/${eventUser.uid}`,
							{
								lastAccessDate: DateTime.local().toISO()
							}
						);
					} else {
						return of(null);
					}
				})
			)
		);
	}

	/**
	 * compareEventUsersWithoutAField
	 * @param eventUser1
	 * @param eventUser2
	 * @returns
	 */
	compareEventUsersWithoutConnectedProp(eventUser1: any, eventUser2: any) {
		let result: boolean = false;

		if (!eventUser1 && !eventUser2) {
			return true;
		}

		const removeProp = "connected";

		if (eventUser1 && eventUser2 && eventUser1[removeProp] && eventUser2[removeProp]) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { [removeProp]: statusEventUser1, ...copyEventUser1 } = eventUser1;
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { [removeProp]: statusEventUser2, ...copyEventUser2 } = eventUser2;

			result = _.isEqual(copyEventUser1, copyEventUser2) && copyEventUser1.moduleId === copyEventUser2.moduleId;
		}

		return result;
	}

	/**
	 * addToFavorite
	 * @param eventId
	 * @param eventUserId
	 * @param moduleId
	 * @returns
	 */
	addToFavorite(eventId: string, moduleId: string, eventUserId: string) {
		return this.updatePartOfEventUser(eventId, moduleId, eventUserId, {
			favorites: arrayUnion(eventUserId)
		});
	}

	removeFromFavorite(eventId: string, moduleId: string, eventUserId: string) {
		return this.updatePartOfEventUser(eventId, moduleId, eventUserId, {
			favorites: arrayRemove(eventUserId)
		});
	}

	/**
	 * buildFieldsVisibility
	 * @returns
	 */
	async buildFieldsVisibility(eventId: string, moduleId: string, eventUser?: IEventUser) {
		try {
			const results = await Promise.all([
				this.SFirestore.getDocument(`events/${eventId}/modules/${moduleId}`),
				this.SFirestore.getDocuments(`events/${eventId}/modules/${moduleId}/custom-fields`, [])
			]);

			const [module, moduleCustomFields] = [
				results[0].data() as IModule,
				results[1].docs.map((doc) => doc.data() as IModuleCustomField)
			];

			if (!module || !moduleCustomFields) {
				throw new Error("Can't find module or custom fields !");
			}

			return Object.entries(module.options.requiredFields)
				.map((obj) => {
					return {
						[obj[0]]:
							!eventUser || (eventUser && !eventUser.editedProfile)
								? obj[1]?.["hiding"]?.["default"]
								: eventUser && eventUser.editedProfile && eventUser.updatedSettings?.fieldsVisibility
								? eventUser.updatedSettings?.fieldsVisibility?.[obj[0]]
								: false
					};
				})
				.concat(
					moduleCustomFields.map((cus) => {
						return {
							[cus.uid]:
								!eventUser || (eventUser && !eventUser.editedProfile)
									? cus.hiding.default !== undefined
										? cus.hiding.default
										: false
									: eventUser &&
									  eventUser.editedProfile &&
									  eventUser.updatedSettings?.fieldsVisibility &&
									  eventUser.updatedSettings?.fieldsVisibility?.[cus.uid]
									? eventUser.updatedSettings?.fieldsVisibility?.[cus.uid]
									: false
						};
					})
				)
				.reduce((result, obj) => {
					Object.keys(obj).forEach((key) => {
						result[key] = obj[key];
					});
					return result;
				}, {});
		} catch (error) {
			//console.error("🚀 => buildFieldsVisibility Error:", error);
		}
	}

	/**
	 * isUserOrModuleFieldsVisibilityHidden
	 * @param key
	 */
	isUserOrModuleFieldsVisibilityHidden(
		datas: { eventUser: IEventUser; eventUserModule: IModule; customFields: IFullCustomField[] },
		key: string,
		type: "baseFields" | "customFields"
	) {
		if (type === "baseFields") {
			return datas.eventUserModule.options.enableUserFieldsHideAbility &&
				(datas.eventUser.updatedSettings.fieldsVisibility?.[key] === undefined ||
					(datas.eventUser.updatedSettings.fieldsVisibility?.[key] !== undefined &&
						datas.eventUser.updatedSettings.fieldsVisibility?.[key] !==
							datas.eventUserModule.options.requiredFields?.[key]?.hiding?.default &&
						!datas.eventUser.editedProfile))
				? datas.eventUserModule.options.requiredFields?.[key]?.hiding?.default
				: datas.eventUserModule.options.requiredFields?.[key]?.hiding?.default
				? datas.eventUser.updatedSettings.fieldsVisibility?.[key]
				: datas.eventUser.updatedSettings.fieldsVisibility?.[key]
				? false
				: false;
		} else {
			const correspondingCus = datas.customFields.find((cus) => cus.baseSettings.uid === key);

			if (correspondingCus) {
				return datas.eventUserModule.options.enableUserFieldsHideAbility &&
					(datas.eventUser.updatedSettings.fieldsVisibility?.[key] === undefined ||
						(datas.eventUser.updatedSettings.fieldsVisibility?.[key] !== undefined &&
							datas.eventUser.updatedSettings.fieldsVisibility?.[key] !==
								correspondingCus.moduleSettings.hiding?.default &&
							!datas.eventUser.editedProfile))
					? correspondingCus.moduleSettings.hiding?.default
					: correspondingCus.moduleSettings.hiding?.default
					? datas.eventUser.updatedSettings.fieldsVisibility?.[key]
					: datas.eventUser.updatedSettings.fieldsVisibility?.[key]
					? false
					: false;
			}
		}
	}
}
