import { Pipe, PipeTransform } from "@angular/core";
import { IChat } from "../interfaces/chats.interfaces";

@Pipe({
    name: "filterChats",
    standalone: false
})
export class FilterChatsPipe implements PipeTransform {
	transform(chats: IChat[], q: string): any {
		return chats.filter(
			(chat) => !chat.lastMessageUser || chat.lastMessageUser.name.toLowerCase().includes(q.toLowerCase())
		);
	}
}
