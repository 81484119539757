<div class="container-infos" [style.height.px]="isMobile && 220 + containerInfosHeight">
	@if (currentEventUserProfile) {
	<div class="infos">
		<ion-avatar style="position: relative" slot="start" [style.background]="'rgb(234, 239, 242)'">
			@if ( event.settings.enableOnlineStatus && currentEventUserProfile["updatedSettings"] &&
			currentEventUserProfile["updatedSettings"].connected ) {
			<div class="is-online"></div>
			} @if (currentEventUserProfile.photoUrl || (actualImage && actualImage.base64String !== '')) {
			<ion-img
				[src]="
					!actualImage && currentEventUserProfile.photoUrl && currentEventUserProfile.photoUrl !== ''
						? currentEventUserProfile.photoUrl
						: actualImage && actualImage.base64String !== ''
						? actualImage.base64String
						: ''
				"
				class="profile-picture"
				(click)="
					openImage(
						currentEventUserProfile.photoUrl !== ''
							? currentEventUserProfile.photoUrl
							: actualImage.base64String !== ''
							? actualImage.base64String
							: null
					)
				"
				[style.border-color]="isMobile ? '#fff' : event.styling.menuTextColorGradient"
			>
			</ion-img>
			} @if (!currentEventUserProfile.photoUrl && !actualImage) {
			<div
				class="profile-picture"
				[ngStyle]="{
					background: 'rgb(234, 239, 242)',
					'border-color': isMobile ? '#fff' : event.styling.menuTextColorGradient
				}"
			>
				<ion-icon class="cus-picture" src="assets/icon/User2.svg"></ion-icon>
			</div>
			} @if ((currentEventUserProfile.photoUrl || (actualImage && actualImage.base64String !== '')) && editMode) {
			<ion-icon
				style="position: absolute; font-size: 30px; top: 0; right: 0; cursor: pointer"
				color="danger"
				name="close"
				(click)="deleteProfilePicture()"
			></ion-icon>
			} @if (editMode) {
			<button
				class="small-btn-cam"
				[style.background]="event.styling.btnBgColor"
				[style.color]="event.styling.btnTextColor"
				(click)="promptCameraModal()"
			>
				<ion-icon src="assets/icon/Camera.svg"></ion-icon>
			</button>
			}
		</ion-avatar>
		<ion-label [style.color]="isMobile ? menuColor : event.styling.menuTextColorGradient">
			<div class="bloc-tag">
				<div
					[ngClass]="!editMode ? 'name-view-mode' : 'name-edit-mode'"
					[style.color]="isMobile ? event.styling.titleColor : event.styling.menuTextColorGradient"
				>
					{{ currentEventUserProfile.name }}
					<!-- Download contact -->
					@if (myEventUser && cardExchangeModule && cardExchangeModule.options.enableVcardDownloading &&
					(SCardExchange.checkIsContact(myEventUser, eventUserProfile.uid)) && cardExchangeMode) {
					<ion-icon
						slot="end"
						name="bookmark_remove"
						fill="{{ event.styling.contentTextColor }}"
						style="position: relative; top: 3px; cursor: pointer"
						src="assets/icon/download.svg"
						(click)="
							SCardExchange.downloadVcard(
								event,
								module,
								myEventUser,
								eventUserProfile,
								allCustomFields,
								null,
								$event
							)
						"
					>
					</ion-icon>
					} @if (favoriteFolder && !cardExchangeMode) {
					<!-- add bookmark icon to the right -->
					@if (favoriteModule && favoriteModule.options && (!favoriteModule.options.hideFavoriteMenuIcon ||
					!favoriteModule.options.hideFavoriteHeaderIcon) && myEventUser && myEventUser.uid !==
					currentEventUserProfile.uid && !favoriteState?.[currentEventUserProfile.uid]) {
					<ion-icon
						slot="end"
						name="bookmark"
						style="position: relative; top: 5px"
						[style.color]="isMobile ? event.styling.titleColor : event.styling.menuTextColorGradient"
						src="assets/icon/bookmarks.svg"
						(click)="addToFavorite(currentEventUserProfile?.uid, $event)"
					></ion-icon>
					} @if (favoriteModule && favoriteModule.options && (!favoriteModule.options.hideFavoriteMenuIcon ||
					!favoriteModule.options.hideFavoriteHeaderIcon) && myEventUser && myEventUser.uid !==
					currentEventUserProfile.uid && favoriteState?.[currentEventUserProfile.uid] ) {
					<ion-icon
						slot="end"
						name="bookmark_remove"
						style="position: relative; top: 5px"
						[style.color]="isMobile ? event.styling.titleColor : event.styling.menuTextColorGradient"
						src="assets/icon/bookmark_remove.svg"
						(click)="removeFromFavorite(currentEventUserProfile.uid, $event)"
					>
					</ion-icon>
					} }
				</div>
				@if (!editMode && countCustomFieldTagged().length > 0) {
				<div class="info-pro">
					<app-custom-fields-tags
						[event]="event"
						[module]="module"
						[modulesCustomsFields]="modulesCustomsFields"
						[eventUserProfile]="eventUserProfile"
						[customFields]="customFields"
						[isPrivateMode]="isPrivateMode"
						[currentLanguage]="currentLanguage"
					></app-custom-fields-tags>
				</div>
				} @if (!editMode) {
				<div class="icons">
					@if ( ((isPrivateMode && !module.options.requiredFields?.facebook?.hiding.profil) ||
					(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields,
					eventUserProfile, 'facebook', 'baseFields' ) && !isPrivateMode &&
					!module.options.requiredFields?.facebook?.hiding.public)) &&
					currentEventUserProfile.socialMedias.facebook ) {
					<ion-icon
						[style.color]="isMobile ? event.styling.contentTextColor : event.styling.menuTextColorGradient"
						src="assets/icon/Social/Facebook.svg"
						class="social-icons"
						(click)="openFacebook(currentEventUserProfile.socialMedias.facebook)"
					>
					</ion-icon>
					} @if ( ((isPrivateMode && !module.options.requiredFields?.twitter?.hiding.profil) ||
					(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields,
					eventUserProfile, 'twitter', 'baseFields' ) && !isPrivateMode &&
					!module.options.requiredFields?.twitter?.hiding.public)) &&
					currentEventUserProfile.socialMedias.twitter ) {
					<ion-icon
						[style.color]="isMobile ? event.styling.contentTextColor : event.styling.menuTextColorGradient"
						src="assets/icon/Social/Twitter X.svg"
						class="social-icons"
						(click)="openX(currentEventUserProfile.socialMedias.twitter)"
					>
					</ion-icon>
					} @if ( ((isPrivateMode && !module.options.requiredFields?.linkedin?.hiding.profil) ||
					(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields,
					eventUserProfile, 'linkedin', 'baseFields' ) && !isPrivateMode &&
					!module.options.requiredFields?.linkedin?.hiding.public)) &&
					currentEventUserProfile.socialMedias.linkedin ) {
					<ion-icon
						[style.color]="isMobile ? event.styling.contentTextColor : event.styling.menuTextColorGradient"
						src="assets/icon/Social/Linkedin.svg"
						class="social-icons"
						(click)="openLinkedIn(currentEventUserProfile.socialMedias.linkedin)"
					>
					</ion-icon>
					} @if ( ((isPrivateMode && !module.options.requiredFields?.instagram?.hiding.profil) ||
					(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields,
					eventUserProfile, 'instagram', 'baseFields' ) && !isPrivateMode &&
					!module.options.requiredFields?.instagram?.hiding.public)) &&
					currentEventUserProfile.socialMedias.instagram ) {
					<ion-icon
						class="social-icons"
						src="assets/icon/Social/Instagram.svg"
						[style.color]="isMobile ? event.styling.contentTextColor : event.styling.menuTextColorGradient"
						(click)="openInstagram(currentEventUserProfile.socialMedias.instagram)"
					>
					</ion-icon>
					} @if ( ((isPrivateMode && !module.options.requiredFields?.website?.hiding.profil) ||
					(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields,
					eventUserProfile, 'website', 'baseFields' ) && !isPrivateMode &&
					!module.options.requiredFields?.website?.hiding.public)) &&
					currentEventUserProfile.socialMedias.website ) {
					<ion-icon
						[style.color]="isMobile ? event.styling.contentTextColor : event.styling.menuTextColorGradient"
						src="assets/icon/Web2.svg"
						class="social-icons"
						(click)="openLink(currentEventUserProfile.socialMedias.website)"
					>
					</ion-icon>
					}
				</div>
				} @if (editMode) {
				<button
					class="btn-cam"
					[style.background]="event.styling.btnBgColor"
					[style.color]="event.styling.btnTextColor"
					(click)="promptCameraModal()"
				>
					<ion-icon src="assets/icon/Camera.svg"></ion-icon>
					&nbsp;
					<span>{{ "texts.select_image" | translate }}</span>
				</button>
				}
			</div>
		</ion-label>
	</div>
	}
</div>
