import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import CountryList from "country-list-with-dial-code-and-flag";

@Component({
    selector: "app-custom-field-country",
    templateUrl: "./custom-field-country.component.html",
    styleUrls: ["./custom-field-country.component.scss"],
    standalone: false
})
export class CustomFieldCountryComponent implements OnInit {
	countryList: { name: string; dialCode: string; code: string; flag: string }[] = [];
	countryListFiltered: { name: string; dialCode: string; code: string; flag: string }[] = [];
	selectedCountry: { name: string; dialCode: string; code: string; flag: string };
	constructor(private modalCtrl: ModalController, private STranslate: TranslateService) {}

	ngOnInit() {
		this.countryList = CountryList.getList();
		this.countryList = this.countryList?.map((item) => {
			item.name = this.STranslate.instant(`countries.${item.code}`);
			return item;
		});

		this.countryListFiltered = this.countryList;
	}

	ionViewWillLeave() {
		this.closeModal();
	}

	selectCountry(evt) {
		this.selectedCountry = this.countryListFiltered.find(
			(item) => item.name.toLocaleLowerCase() === evt.name.toLocaleLowerCase()
		);
		if (this.selectedCountry) {
			this.closeModal();
		}
	}

	filterCountriesList(event) {
		// search country name
		if (event) {
			const value = event.target.value;
			this.countryListFiltered = this.countryList.filter((item) =>
				item.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
			);
		}
	}

	resetFilter() {
		this.countryListFiltered = this.countryList;
	}

	closeModal() {
		this.modalCtrl.dismiss({
			data: this.selectedCountry
		});
	}
}
