import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { skipWhile, Subscription, take } from "rxjs";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { IEvent, IEventUser, IExternalInteractivity, IModule, ISchedule } from "src/app/shared/interfaces";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AnalyticsService, SchedulesService } from "src/app/shared/services";
import { environment } from "src/environments/environment";
import * as _ from "lodash-es";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getAccessiblesExternalsInteractivityByType } from "src/app/shared/selectors/interactivity.selectors";
import { Router } from "@angular/router";
import { DateTime } from "luxon";

@Component({
    selector: "app-externals-interactivity",
    templateUrl: "./externals-interactivity.component.html",
    styleUrls: ["./externals-interactivity.component.scss"],
    standalone: false
})
export class ExternalsInteractivityComponent implements OnDestroy, OnChanges {
	subscriptions: Subscription[] = [];
	analyticsArraySub: { name: string; sub: Subscription }[] = [];

	@Input() componentMode: boolean = false;
	@Output() changeView: EventEmitter<{ segmentType: string; type: string; uid: string }> = new EventEmitter();

	@Input() eventId: string;
	event: IEvent;
	@Input() moduleId: string;
	module: IModule;
	@Input() sessionId: string;
	externalsInteractivity: IExternalInteractivity[] = [];
	session: ISchedule;
	eventUser: IEventUser;

	needRebuild: boolean = true;
	currentLanguage: string = environment.platform.defaultLanguage;

	isMobile: boolean = false;

	constructor(
		private platform: Platform,
		private SAnalytics: AnalyticsService,
		private SSchedules: SchedulesService,
		private STranslate: TranslateService,
		private router: Router,
		private store: Store,
		private navCtrl: NavController
	) {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) ||
			this.platform.is("mobileweb") ||
			window.innerWidth < 768
				? true
				: false;
	}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);
		if (!this.componentMode) {
			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe(() => {
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.moduleId = params.moduleId;
							this.subscriptions.forEach((sub) => sub.unsubscribe());

							// Analytics
							this.SAnalytics.moduleAccess(
								this.eventId,
								this.moduleId,
								TypeTracking.ACCESS_TO_WORDS_CLOUDS_MODULE
							);

							this.initDatas();
						});
				});
		}
	}

	ngOnChanges(): void {
		if (this.componentMode) {
			this.subscriptions.forEach((sub) => sub.unsubscribe());
			this.initDatas();
		}
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		if (this.sessionId) {
			this.getSession();
		}
		this.getExternalsInteractivity();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());
	}

	ionViewWillLeave() {
		if (!this.componentMode) {
			if (!this.router.routerState.snapshot.url.includes("externals-interactivity")) {
				this.needRebuild = true;
			}
			this.store.dispatch(ResetHeaderState(null));
		}

		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;
				}
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
				}
				if (this.module) {
					this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
				}
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;
				}
			})
		);
	}

	/**
	 * Get session
	 */
	getSession() {
		this.subscriptions.push(
			this.SSchedules.getSpecificSession(this.eventId, this.moduleId, this.sessionId).subscribe((session) => {
				// this.store.select(getSpecificSession(this.sessionId)).subscribe((session) => {
				if (!_.isEqual(this.session, session)) {
					this.session = session;
				}
			})
		);
	}

	/**
	 * Get accessibles externals interactivity
	 */
	getExternalsInteractivity() {
		this.subscriptions.push(
			this.store
				.pipe(
					skipWhile(() => this.componentMode && !this.session),
					select(
						getAccessiblesExternalsInteractivityByType(
							this.componentMode ? 1 : 0,
							this.componentMode && this.session ? this.session : null
						)
					)
				)
				.subscribe((externalsInteractivity) => {
					const externalsInteractivitySorted = externalsInteractivity.sort((a, b) =>
						a.order > b.order
							? 1
							: a.order < b.order
							? -1
							: DateTime.fromISO(b.creationDate).valueOf() - DateTime.fromISO(a.creationDate).valueOf()
					);

					if (!_.isEqual(this.externalsInteractivity, externalsInteractivitySorted) || this.needRebuild) {
						this.needRebuild = false;
						this.externalsInteractivity = externalsInteractivitySorted;

						if (this.externalsInteractivity.length === 1 && !this.componentMode) {
							this.openExternalInteractivity(this.externalsInteractivity[0]);
						}
					}
				})
		);
	}

	/**
	 * Open external interactivity
	 * @param externalInteractivity
	 */
	openExternalInteractivity(externalInteractivity: IExternalInteractivity) {
		if (!this.componentMode) {
			this.navCtrl.navigateForward(
				`/event/${this.eventId}/externals-interactivity/${this.moduleId}/${externalInteractivity.uid}`
			);
		} else {
			// Switch to external interactivity view on component mode
			this.changeView.emit({
				segmentType: "externalsInteractivity",
				type: "externalInteractivity",
				uid: externalInteractivity.uid
			});
		}
	}
}
