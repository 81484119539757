import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { ChartType, ChartConfiguration, ChartData } from "chart.js";
import DataLabelsPlugin from "chartjs-plugin-datalabels";
import { QuizsService, UtilityService } from "src/app/shared/services";
import { Subscription } from "rxjs";
import { IEvent, IEventUser, IModule, IQuiz, IQuizQuestion, IQuizQuestionResult } from "src/app/shared/interfaces";

@Component({
    selector: "app-quiz-result",
    templateUrl: "./quiz-result.component.html",
    styleUrls: ["./quiz-result.component.scss"],
    standalone: false
})
export class QuizResultComponent implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];
	loader: boolean = false;

	init: boolean = false;

	@Input() event: IEvent;
	@Input() module: IModule;
	@Input() quiz: IQuiz;
	@Input() question: IQuizQuestion;
	@Input() eventUser: IEventUser;

	barChartOptions: ChartConfiguration["options"] = {
		indexAxis: "y",
		responsive: true,
		scales: {
			x: {
				min: 0,
				max: 100,
				ticks: {
					maxTicksLimit: 100
				},
				grid: {
					display: false
				}
			},
			y: {
				grid: {
					display: false
				}
			}
		},
		plugins: {
			tooltip: {
				enabled: false
			},
			legend: {
				display: false,
				position: "bottom"
			},
			datalabels: {
				formatter: (value, ctx) => {
					let sum = 0;
					const dataArr: any = ctx.chart.data.datasets[0].data;
					dataArr.map((data) => {
						sum += data;
					});
					let percentage = ((value * 100) / sum).toFixed(2) + "%";

					if (percentage == "0.00%") {
						percentage = null;
					}

					return percentage;
				},
				color: "#fff",
				font: {
					size: 14,
					weight: 800
				}
			}
		}
	};

	barChartType: ChartType = "bar";
	barChartPlugins = [DataLabelsPlugin];

	// barChartData: ChartDataSets[] = [
	// 	{
	// 		backgroundColor: [],
	// 		borderColor: [],
	// 		hoverBackgroundColor: [],
	// 		hoverBorderColor: [],
	// 		data: []
	// 	}
	// ];

	public barChartData: ChartData<"bar"> = {
		labels: [],
		datasets: [
			{
				data: [],
				backgroundColor: [],
				borderColor: [],
				hoverBorderColor: [],
				hoverBackgroundColor: []
			}
		]
	};

	backedColorsArray: string[] = [
		"rgba(0, 113, 186, 1)",
		"rgba(109, 199, 221, 1)",
		"rgba(73, 60, 144, 1)",
		"rgba(150, 54, 139, 1)",
		"rgba(234, 82, 132, 1)",
		"rgba(231, 52, 88, 1)",
		"rgba(243, 147, 37, 1)",
		"rgba(253, 196, 31, 1)",
		"rgba(149, 193, 31, 1)",
		"rgba(0, 179, 187, 1)"
	];

	colorsArray: string[] = [
		"rgba(0, 113, 186, 1)",
		"rgba(109, 199, 221, 1)",
		"rgba(73, 60, 144, 1)",
		"rgba(150, 54, 139, 1)",
		"rgba(234, 82, 132, 1)",
		"rgba(231, 52, 88, 1)",
		"rgba(243, 147, 37, 1)",
		"rgba(253, 196, 31, 1)",
		"rgba(149, 193, 31, 1)",
		"rgba(0, 179, 187, 1)"
	];

	constructor(private SQuizs: QuizsService, private SUtility: UtilityService) {}

	ngOnInit() {
		this.loader = false;
		this.init = false;

		this.buildChartLabel();

		this.subscriptions.push(
			this.SQuizs.getResultsOfQuestion(
				this.event.uid,
				this.module.uid,
				this.quiz.uid,
				this.question.uid
			).subscribe((results) => {
				this.buildChartDataSet(results);
			})
		);
	}

	/**
	 * Unsubscribe subscriptions on destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Build chart label (answer name)
	 */
	buildChartLabel() {
		this.barChartData.labels = this.question.answers.map((answer) => {
			let label =
				answer.answer[
					this.eventUser &&
					this.eventUser.updatedSettings &&
					this.eventUser.updatedSettings.language &&
					answer.answer[this.eventUser.updatedSettings.language]
						? this.eventUser.updatedSettings.language
						: this.event.language
				];
			if (label.length > 15) {
				label = label.substring(0, 14) + "...";
			}
			return label;
		});
		// this.barChartLabels = this.question.answers.map((answer) => {
		// 	let label =
		// 		answer.answer[
		// 			this.eventUser &&
		// 			this.eventUser.updatedSettings &&
		// 			this.eventUser.updatedSettings.language &&
		// 			answer.answer[this.eventUser.updatedSettings.language]
		// 				? this.eventUser.updatedSettings.language
		// 				: this.event.language
		// 		];
		// 	if (label.length > 15) {
		// 		label = label.substring(0, 14) + "...";
		// 	}
		// 	return label;
		// });
	}

	/**
	 * Build chart options
	 * @param colors
	 */
	buildChartOptions(colors: string[]) {
		const scalesForVerticalOrPie = {
			x: {
				ticks: {
					maxTicksLimit: 100
				},
				grid: {
					display: false
				}
			},
			y: {
				min: 0,
				max: 100,
				grid: {
					display: false
				}
			}
		};

		// Set type, basic style and colors labels
		if (this.question && this.question.graphicType) {
			if (this.question.graphicType == "column") {
				this.barChartType = "bar";
				this.barChartOptions.indexAxis = "x";
				this.barChartOptions.scales = scalesForVerticalOrPie;
			} else if (this.question.graphicType == "pie") {
				this.barChartType = "pie";
				this.barChartOptions.scales = scalesForVerticalOrPie;
				this.barChartOptions.plugins.legend.display = true;
			} else {
				this.barChartType = "bar";
				this.barChartOptions.indexAxis = "y";
			}
		} else {
			this.barChartType = "bar";
			this.barChartOptions.indexAxis = "y";
		}

		// Set bar colors
		this.barChartData.datasets[0].backgroundColor = colors;
		this.barChartData.datasets[0].borderColor = colors;
		this.barChartData.datasets[0].hoverBackgroundColor = colors;
		this.barChartData.datasets[0].hoverBorderColor = colors;
	}

	/**
	 * Generate colors
	 */
	generateArrayColorsDefault() {
		if (this.colorsArray.length == 0) {
			this.colorsArray = this.backedColorsArray;
		}

		const random = this.randomInt(0, this.colorsArray.length - 1);
		const color = this.colorsArray[random];

		// Remove color from array
		this.colorsArray.splice(random, 1);

		return color;
	}

	/**
	 * Get random int
	 * @param min
	 * @param max
	 */
	randomInt(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	/**
	 * Build chart data set
	 * @param results
	 */
	buildChartDataSet(results: IQuizQuestionResult[]) {
		const colors: string[] = [];
		const datas: any[] = [];

		for (let i = 0; i < this.question.answers.length; i++) {
			const answer = this.question.answers[i];
			const answerCount = results.filter((result) => {
				if (this.question.type === "oneSelect") {
					return result.oneSelect.answerId === answer.uid ? true : false;
				} else {
					return result.multipleSelect.answersIds.includes(answer.uid) ? true : false;
				}
			}).length;
			colors.push(this.generateArrayColorsDefault());

			let percentage = 0;

			if (answerCount != 0) {
				percentage = Math.round((answerCount / results.length) * 100);
			}

			datas.push(percentage);
		}

		if (!this.init) {
			this.buildChartOptions(colors);
		}

		this.barChartData.datasets[0].data = datas;

		this.loader = true;
		this.init = true;
	}
}
