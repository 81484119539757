import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy, ViewChild, ViewContainerRef } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Subject, Subscription, combineLatest, firstValueFrom, skipWhile } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderState, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { TypeModule } from "src/app/shared/enums/type-module";
import { TypeUser } from "src/app/shared/enums/type-user";
import {
	ICustomField,
	IDocument,
	IEvent,
	IEventUser,
	IFeedback,
	IFullCustomField,
	IGroup,
	IMessage,
	IModule,
	IModuleCustomField,
	ISheet,
	IUser
} from "src/app/shared/interfaces";
import { IChat } from "src/app/shared/interfaces/chats.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getCurrentUser, getMyEventUser } from "src/app/shared/selectors/auth.selectors";
import { getChatOfTwo } from "src/app/shared/selectors/chats.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getAccessiblesFeedbacksByType,
	getBaseCustomFields,
	getGroups,
	getModulesCustomsFieldsOfModule
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getChatsModule, getModulesByType, getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import {
	AppointmentsService,
	AuthService,
	ChatsService,
	CustomFieldsService,
	EventUsersService,
	FirestoreService,
	SheetsService,
	UtilityService,
	VisiosService
} from "src/app/shared/services";
import { environment } from "src/environments/environment";
import { CustomFieldsViewComponent } from "../components/custom-fields/custom-fields-view/custom-fields-view.component";

@Component({
    selector: "app-profile",
    templateUrl: "./profile.page.html",
    styleUrls: ["./profile.page.scss"],
    standalone: false
})
export class ProfilePage implements OnDestroy {
	@ViewChild("customFieldComponent") customFieldComponentInstance: CustomFieldsViewComponent;
	@ViewChild("customFieldComponent", { read: ViewContainerRef }) customFieldViewContainer: ViewContainerRef;
	destroySuscription: Subject<any> = new Subject();

	languageSubscription: Subscription;
	necessaryDatasSub: Subscription;
	eventUserSub: Subscription;
	userDataSub: Subscription;
	feedbacksSub: Subscription;

	eventId: string;
	event: IEvent;
	dataId: string;
	data: IEventUser | ISheet;
	userDataProfile: IUser;
	moduleId: string;
	chatsModule: IModule;
	module: IModule;
	user: IUser;
	myEventUser: IEventUser;
	scheduleId: string;
	computedCustomFields: IFullCustomField[];

	typeModule = TypeModule;
	typeUser = TypeUser;
	false;
	canRequestAppointment: boolean = false;
	userSelectedDoc: IDocument;
	loaderToast: HTMLIonLoadingElement;
	chat_button_loader: boolean = false;
	visio_button_loader: boolean = false;
	// appointment_button_loader: boolean = false;

	isProfilMode: boolean = false;

	baseCF: ICustomField[];
	moduleCF: IModuleCustomField[];

	showMore: boolean = false;

	feedbacks: IFeedback[] = [];
	currentLanguage: string = environment.platform.defaultLanguage;

	isMobile: boolean = window.innerWidth < 768;
	groups: IGroup[] = [];

	loader: boolean = false;
	favoriteState: { [dataId: string]: boolean };

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		public STranslate: TranslateService,
		private store: Store,
		private alertController: AlertController,
		private SAppointments: AppointmentsService,
		private SAuth: AuthService,
		public SEventUsers: EventUsersService,
		private SSheets: SheetsService,
		private SChats: ChatsService,
		private SVisios: VisiosService,
		private SUtility: UtilityService,
		private navCtrl: NavController,
		private SFirestore: FirestoreService,
		private modalCtrl: ModalController,
		private snackbar: MatSnackBar,
		public SCustomFields: CustomFieldsService
	) {
		this.currentLanguage = this.STranslate.currentLang;
		this.languageSubscription = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});
	}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;
		this.languageSubscription = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;

						this.moduleId = params.moduleId;
						this.dataId = params.userId;
						this.getNecessaryDatas();
					});
			});
	}

	ionViewWillLeave() {
		this.canRequestAppointment = false;
		this.store.dispatch(ResetHeaderState(null));
		[
			this.necessaryDatasSub,
			this.eventUserSub,
			this.userDataSub,
			this.feedbacksSub,
			this.languageSubscription
		].forEach((sub) => {
			if (sub && !sub.closed) {
				sub.unsubscribe();
			}
		});

		// destroy customFieldComponentInstance component
		if (this.customFieldComponentInstance) {
			this.customFieldComponentInstance.cleanComponent();
			this.customFieldViewContainer.clear();
		}
	}

	ngOnDestroy() {
		[
			(this.necessaryDatasSub, this.eventUserSub, this.userDataSub, this.feedbacksSub, this.languageSubscription)
		].forEach((sub) => {
			if (sub && !sub.closed) {
				sub.unsubscribe();
			}
		});

		this.destroySuscription.complete();

		// destroy customField componentInstance
		if (this.customFieldComponentInstance) {
			this.customFieldComponentInstance.cleanComponent();
			this.customFieldViewContainer.clear();
		}
	}

	/**
	 * Getting all necessaries datas
	 */
	getNecessaryDatas() {
		this.necessaryDatasSub = combineLatest([
			this.store.select(getCurrentEvent),
			this.store.select(getCurrentUser).pipe(take(1)),
			this.store.select(getSpecificModule(this.moduleId)),
			this.store.select(getMyEventUser),
			this.store.select(getChatsModule),
			this.store.select(getBaseCustomFields).pipe(take(1)),
			this.store.select(getModulesCustomsFieldsOfModule(this.moduleId)).pipe(take(1)),
			this.store.select(getGroups).pipe(take(1))
		]).subscribe((results) => {
			this.event = results[0];
			this.user = results[1];
			this.module = results[2];

			if (
				(results[3] &&
					this.myEventUser &&
					!this.SEventUsers.compareEventUsersWithoutConnectedProp(results[3], this.myEventUser)) ||
				!this.myEventUser
			) {
				this.myEventUser = results[3];
			}

			if (this.event && this.module) {
				if (this.module.type === TypeModule.ATTENDEE || this.module.type === TypeModule.SPEAKER) {
					this.getEventUserProfile();
				} else {
					this.getSheet();
				}
			}

			this.chatsModule = results[4];
			this.baseCF = results[5];
			this.moduleCF = results[6];
			this.groups = results[7];
		});
	}

	/**
	 * Returns the color of the background, according to the allowGradient boolean.
	 */
	backgroundColor(allowGradient: boolean) {
		if (allowGradient) {
			return (
				"linear-gradient(to right, " +
				this.event.styling.menuColor +
				", " +
				this.event.styling.menuColorGradient +
				")"
			);
		} else {
			return this.event.styling.menuColor;
		}
	}

	getSheet() {
		if (this.eventUserSub && !this.eventUserSub.closed) {
			this.eventUserSub.unsubscribe();
		}
		this.eventUserSub = this.SSheets.getSpecificSheet(this.eventId, this.dataId)
			.pipe(skipWhile(() => !this.module))
			.subscribe({
				next: (result) => {
					this.data = _.cloneDeep(result);

					if (this.data) {
						this.store.dispatch(
							GetHeaderState({
								payload: {
									item: this.data,
									module: this.module,
									title: {
										ArAR: this.data.name,
										DeDE: this.data.name,
										EnUS: this.data.name,
										EsES: this.data.name,
										FrFR: this.data.name,
										PtBR: this.data.name
									},
									type: TypeHeader.PROFILE
								}
							})
						);

						// feedbacks subscription
						if (this.feedbacksSub && !this.feedbacksSub.closed) {
							this.feedbacksSub.unsubscribe();
						}
						this.feedbacksSub = this.store
							.select(getAccessiblesFeedbacksByType(1, this.data))
							.subscribe((feedbacks) => {
								this.feedbacks = feedbacks;
							});

						// Custom fields datas
						this.computedCustomFields = [];

						if (this.moduleCF.length > 0) {
							this.moduleCF.forEach((customField) => {
								const baseCustomFieldCorresponding = this.baseCF.find(
									(custField) => custField.uid === customField.uid
								);

								if (baseCustomFieldCorresponding) {
									const data = this.data.customFields.find((data) => data?.uid === customField.uid);
									this.computedCustomFields.push({
										baseSettings: baseCustomFieldCorresponding,
										moduleSettings: customField,
										fieldDatas: data
											? data
											: {
													uid: "",
													field: {}
											  }
									});
								}
							});
						}
					}
				}
			});
	}

	/**
	 * Get event user profile
	 */
	getEventUserProfile() {
		if (this.eventUserSub && !this.eventUserSub.closed) {
			this.eventUserSub.unsubscribe();
		}
		this.eventUserSub = combineLatest([
			this.SEventUsers.getSpecificEventUser(this.event.uid, this.dataId),
			this.SEventUsers.getSpecificEventUserUpdatedSettings(this.event.uid, this.moduleId, this.dataId)
		])
			.pipe(skipWhile(() => !this.module))
			.subscribe({
				next: (results) => {
					const tmpEventUserProfile: IEventUser = results[0];
					tmpEventUserProfile["updatedSettings"] = results[1];

					this.data = _.cloneDeep(tmpEventUserProfile);

					this.isProfilMode = this.myEventUser && this.data && this.myEventUser.uid === this.data.uid;

					if (this.data) {
						this.store.dispatch(
							GetHeaderState({
								payload: {
									item: this.data,
									module: this.module,
									title: {
										ArAR: this.data.name,
										DeDE: this.data.name,
										EnUS: this.data.name,
										EsES: this.data.name,
										FrFR: this.data.name,
										PtBR: this.data.name
									},
									type: TypeHeader.PROFILE
								}
							})
						);

						// init user visibility ability if needed
						if (this.module.options.enableUserFieldsHideAbility) {
							this.SEventUsers.buildFieldsVisibility(this.event.uid, this.module.uid, this.data);
						}

						// feedbacks subscription
						if (this.feedbacksSub && !this.feedbacksSub.closed) {
							this.feedbacksSub.unsubscribe();
						}
						this.feedbacksSub = this.store
							.select(getAccessiblesFeedbacksByType(1, this.data))
							.subscribe((feedbacks) => {
								this.feedbacks = feedbacks;
							});

						// Custom fields datas
						this.computedCustomFields = [];

						if (this.moduleCF.length > 0) {
							this.moduleCF.forEach((customField) => {
								const baseCustomFieldCorresponding = this.baseCF.find(
									(custField) => custField.uid === customField.uid
								);

								if (baseCustomFieldCorresponding) {
									const data = this.data.customFields.find((data) => data?.uid === customField.uid);
									this.computedCustomFields.push({
										baseSettings: baseCustomFieldCorresponding,
										moduleSettings: customField,
										fieldDatas: data
											? data
											: {
													uid: "",
													field: {}
											  }
									});
								}
							});
						}
						if (this.myEventUser) {
							this.SAppointments.canRequestAppointment(this.myEventUser, this.data)
								.pipe(take(1))
								.subscribe((canRequestAppointment) => {
									this.canRequestAppointment = canRequestAppointment;
								});
						}
					}
				}
			});

		if (this.userDataSub && !this.userDataSub.closed) {
			this.userDataSub.unsubscribe();
		}
		this.userDataSub = this.SAuth.getUserData(this.dataId).subscribe((userDataProfile) => {
			this.userDataProfile = userDataProfile;
		});
	}

	/**
	 * Open chat
	 */
	async openChat() {
		try {
			if (this.event.blocked) {
				this.SUtility.presentToast(
					this.STranslate.instant("alerts.blocked-event-info"),
					2500,
					"bottom",
					"danger"
				);
				return;
			}

			if (!this.myEventUser) {
				this.alertLoginRegister(this.STranslate.instant("chat.chat"));
			} else if (!this.userDataProfile.firstAccess) {
				this.alertLoginRegister(
					this.STranslate.instant("chat.chat"),
					this.STranslate.instant("alerts.user_chat_not_available"),
					true
				);
			} else {
				this.chat_button_loader = true;
				const chats = await firstValueFrom(
					this.store
						.select(
							getChatOfTwo({
								myId: this.myEventUser.uid,
								userId: this.dataId,
								eventId: this.eventId
							})
						)
						.pipe(take(1))
				);

				if (chats.length === 0) {
					// Chat don't exist so create it
					const chat: IChat = {
						uid: this.SFirestore.createId(`events/${this.eventId}/modules/${this.chatsModule.uid}/chats`),
						allowNotifs: true,
						creationDate: DateTime.local().toISO(),
						createdFrom: "app",
						disabled: false,
						eventId: this.eventId,
						lastAccess: "",
						lastMessageId: "",
						lastMessageUser: null,
						lastUpdated: "",
						members: [this.myEventUser.uid, this.dataId],
						moduleId: this.chatsModule.uid,
						options: {
							allowVisio: this.event.settings.allowVisioForTwo
						},
						type: 0,
						visibility: true
					};
					await this.SChats.createChat(this.eventId, this.chatsModule.uid, chat);
					this.navCtrl.navigateForward(
						`/event/${this.eventId}/chats/${this.chatsModule.uid}/chat/${chat.uid}`
					);
				} else {
					this.navCtrl.navigateForward(
						`/event/${this.eventId}/chats/${this.chatsModule.uid}/chat/${chats[0].uid}`
					);
				}
				this.chat_button_loader = false;
			}
		} catch (error) {
			this.chat_button_loader = false;
		}
	}

	/**
	 * Create visio and send mess on chat
	 */
	async createVisio() {
		try {
			if (this.event.blocked) {
				this.SUtility.presentToast(
					this.STranslate.instant("alerts.blocked-event-info"),
					2500,
					"bottom",
					"danger"
				);
				return;
			}

			if (!this.myEventUser) {
				this.SUtility.alertLoginRegister(this.event.uid, this.STranslate.instant("chat.chat"));
			} else if (!this.userDataProfile.firstAccess) {
				this.SUtility.alertLoginRegister(
					this.event.uid,
					this.STranslate.instant("chat.chat"),
					this.STranslate.instant("alerts.user_chat_not_available"),
					true
				);
			} else {
				this.visio_button_loader = true;

				const chats = await firstValueFrom(
					this.store
						.select(
							getChatOfTwo({
								myId: this.myEventUser.uid,
								userId: this.dataId,
								eventId: this.eventId
							})
						)
						.pipe(take(1))
				);

				const chat =
					chats.length === 0
						? {
								uid: this.SFirestore.createId(
									`events/${this.eventId}/modules/${this.chatsModule.uid}/chats`
								),
								allowNotifs: true,
								creationDate: DateTime.local().toISO(),
								createdFrom: "app",
								disabled: false,
								eventId: this.eventId,
								lastAccess: "",
								lastMessageId: "",
								lastMessageUser: null,
								lastUpdated: "",
								members: [this.myEventUser.uid, this.dataId],
								moduleId: this.chatsModule.uid,
								options: {
									allowVisio: this.event.settings.allowVisioForTwo
								},
								type: 0,
								visibility: true
						  }
						: chats[0];

				if (chats.length === 0) {
					// Chat don't exist so create it
					await this.SChats.createChat(this.eventId, this.chatsModule.uid, chat);
				}

				// Creating visio if necessary
				const visio = await firstValueFrom(
					this.SVisios.createNewVisioFor2(this.eventId, chat.moduleId, chat.uid, [
						this.myEventUser.uid,
						this.dataId
					])
				);

				const message: IMessage = {
					uid: this.SFirestore.createId(
						`events/${this.eventId}/modules/${this.moduleId}/chats/${chat.uid}/chat-messages`
					),
					appointment: null,
					content: this.STranslate.instant("texts.visio-launched"),
					chatId: chat.uid,
					chatType: chat.type,
					creationDate: DateTime.local().toISO(),
					eventId: this.event.uid,
					imageUrl: "",
					moduleId: this.chatsModule.uid,
					sender: {
						uid: this.myEventUser.uid,
						eventId: this.myEventUser.eventId,
						moduleId: this.myEventUser.moduleId,
						identifier: this.myEventUser.identifier,
						name: this.myEventUser.name,
						queryName: this.myEventUser.queryName,
						photoUrl: this.myEventUser.photoUrl,
						email: this.myEventUser.email,
						type: this.myEventUser.type
					},
					sendAt: DateTime.local().setZone(this.event.timezone).toISO(),
					type: 2,
					visioUrl: visio.url
				};

				// Create visio message on chat
				await this.SChats.createVisioMessage(this.event, this.chatsModule.uid, chat, message);
				this.SUtility.presentToast(this.STranslate.instant("toasts.visios.created"), 2500, "bottom", "primary");
				setTimeout(() => {
					this.openChat();
					this.visio_button_loader = false;
				}, 200);
			}
		} catch (error) {
			this.visio_button_loader = false;
			this.SUtility.presentToast(this.STranslate.instant("toasts.visios.error"), 2500, "bottom", "danger");
		}
	}

	async openUserTimeslots() {
		if (this.event.blocked) {
			this.SUtility.presentToast(this.STranslate.instant("alerts.blocked-event-info"), 2500, "bottom", "danger");
			return;
		}

		// if (!this.userDataProfile.firstAccess) {
		// 	const alert = await this.alertController.create({
		// 		header: this.STranslate.instant("chat.chat"),
		// 		message: this.STranslate.instant("alerts.user_chat_not_available"),
		// 		buttons: [this.STranslate.instant("buttons.ok")]
		// 	});

		// 	await alert.present();
		// } else {
		const modules = await firstValueFrom(this.store.select(getModulesByType(TypeModule.APPOINTMENTS)));
		const module = modules.length > 0 ? modules[0] : null;
		if (module) {
			this.navCtrl.navigateForward([
				// eslint-disable-next-line max-len
				`/event/${this.eventId}/appointments/${module.uid}/user-timeslots/${this.dataId}`
			]);
		}
		// }
	}

	/**
	 * alertLoginRegister
	 * @param alertTitle
	 * @param message
	 */
	async alertLoginRegister(alertTitle: string, message?: string, isLoggedIn?: boolean) {
		const alert = await this.alertController.create({
			header: alertTitle,
			message: message ? message : this.STranslate.instant("texts.need_login_to_use"),
			buttons: !isLoggedIn
				? [
						{
							text: this.STranslate.instant("buttons.authenticate"),
							handler: async () => this.openLogin()
						},
						{
							text: this.STranslate.instant("buttons.cancel"),
							role: "cancel",
							handler: () => {
								//
							}
						}
				  ]
				: [
						{
							text: this.STranslate.instant("buttons.ok"),
							role: "cancel",
							handler: () => {
								//
							}
						}
				  ],
			backdropDismiss: true,
			cssClass: "center-alert-items"
		});

		alert.present();
	}

	/**
	 * Open login modal
	 * @returns
	 */
	async openLogin() {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.loginModal,
				componentProps: {
					eventId: this.event.uid
				},
				cssClass: "full-sized-modal"
			});
			await modal.present();

			const { data } = await modal.onWillDismiss();
			if (data && data.openRegister) {
				this.openRegister();
			}

			// else if (data && data.openFillProfil) {
			// 	this.openFillProfil();
			// }
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Open register modal
	 * @returns
	 */
	async openRegister() {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.registerForm,
				componentProps: {
					eventId: this.event.uid
				},
				cssClass: "full-sized-modal"
			});
			await modal.present();

			const { data } = await modal.onWillDismiss();
			if (data && data.openLogin) {
				this.openLogin();
			}

			<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0] &&
				((<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0]).style.visibility =
					"hidden");

			// else if (data && data.openFillProfil) {
			// 	this.openFillProfil();
			// }
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * getCorrespondingGroups
	 * @param groupId
	 * @returns
	 */
	getCorrespondingGroups(groupIds: string[]) {
		return this.groups.filter((gr) => groupIds.includes(gr.uid));
	}

	/**
	 * Delete account
	 */
	async deleteAccount() {
		if (this.event.blocked) {
			this.SUtility.presentToast(this.STranslate.instant("alerts.blocked-event-info"), 25000, "bottom", "danger");
			return;
		}

		const alert = await this.SUtility.presentAlert(
			this.STranslate.instant("alerts.delete_account_title"),
			this.STranslate.instant("alerts.delete_account_message"),
			[
				{
					text: this.STranslate.instant("buttons.no")
				},
				{
					text: this.STranslate.instant("buttons.yes"),
					cssClass: "red-color",
					handler: (datas) => {
						this.loader = true;
						if (datas["password"] || datas["password"].length < 6) {
							this.SAuth.deleteUser(this.user.uid, this.user.email, datas["password"]).subscribe({
								next: () => {
									this.loader = false;
									this.snackbar.open(this.STranslate.instant("snackbar.success_delete_account"), "", {
										duration: 3000,
										panelClass: "success-snackbar"
									});
									this.SAuth.logoutUser();
								},
								error: (error) => {
									this.loader = false;
									if (error && error.code === "auth/wrong-password") {
										this.snackbar.open(this.STranslate.instant("snackbar.wrong_password"), "", {
											duration: 3000,
											panelClass: "error-snackbar"
										});
									} else {
										this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
											duration: 3000,
											panelClass: "error-snackbar"
										});
									}
								}
							});
						} else {
							this.loader = false;
							this.snackbar.open(this.STranslate.instant("snackbar.no_password"), "", {
								duration: 3000,
								panelClass: "error-snackbar"
							});
						}
					}
				}
			],
			[
				{
					type: "password",
					placeholder: this.STranslate.instant("login.password"),
					name: "password"
				}
			]
		);

		await alert.present();
	}

	checkTemplateColumns() {
		let count: number = 0;
		if (this.event.settings.allowChat && this.data["email"] && this.data?.uid !== this.myEventUser?.uid) {
			count++;
		}
		if (
			this.event &&
			this.event.settings.allowVisio &&
			this.event.settings.allowVisioForTwo &&
			this.module &&
			this.module.options.showVisioButton &&
			this.data?.uid !== this.myEventUser?.uid &&
			this.data["email"]
		) {
			count++;
		}
		if (
			this.event.settings.allowAppointments &&
			this.canRequestAppointment &&
			this.data?.uid !== this.myEventUser?.uid &&
			this.data["email"] &&
			this.data["type"] === this.typeUser.ATTENDEE
		) {
			count++;
		}
		return "repeat(" + count + ", " + 100 / count + "%)";
	}

	/**
	 * buildFavoriteState
	 * @param sessionId
	 */
	buildFavoriteState(dataId: string) {
		this.favoriteState = {
			[dataId]: this.myEventUser && this.myEventUser.favorites?.includes(dataId) ? true : false
		};
	}
}
