import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, OnChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderState, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeHeader } from "src/app/shared/enums/type-header";
import {
	IEvent,
	IEventUser,
	IModule,
	ISurvey,
	ISurveyQuestion,
	ISurveyQuestionAnswer,
	ISurveyQuestionResult
} from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificSurvey } from "src/app/shared/selectors/interactivity.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import {
	AnalyticsService,
	DocumentsService,
	FirestoreService,
	StorageService,
	SurveysService,
	UtilityService
} from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-survey",
    templateUrl: "./survey.component.html",
    styleUrls: ["./survey.component.scss"],
    standalone: false
})
export class SurveyComponent implements OnInit, OnDestroy, OnChanges {
	subscriptions: Subscription[] = [];

	loaderDoc: boolean = false;
	loadingSend: boolean = false;

	@Input() componentMode: boolean = false;
	@Output() changeView: EventEmitter<{ segmentType: string; type: string; uid: string }> = new EventEmitter();

	@Input() eventId: string;
	event: IEvent;
	@Input() moduleId: string;
	module: IModule;
	@Input() surveyId: string;
	survey: ISurvey;
	@Input() sessionId: string;
	eventUser: IEventUser;
	@Input() showBackBtn: boolean;

	viewOnly: boolean = true;

	isMobile: boolean = false;

	questionsForm: UntypedFormGroup;

	locale: string = "";
	currentLanguage: string = environment.platform.defaultLanguage;
	extension: string;
	assetFileName: string;
	documentName: string;
	documentType: string;

	constructor(
		private SAnalytics: AnalyticsService,
		private platform: Platform,
		private store: Store,
		private SSurveys: SurveysService,
		private STranslate: TranslateService,
		private SUtility: UtilityService,
		private SStorage: StorageService,
		private fb: UntypedFormBuilder,
		private SFirestore: FirestoreService,
		public SDocument: DocumentsService
	) {
		this.questionsForm = this.fb.group({});
	}

	ngOnInit() {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) ||
			this.platform.is("mobileweb") ||
			window.innerWidth < 768
				? true
				: false;
		if (this.componentMode) {
			this.currentLanguage = this.STranslate.currentLang;

			this.initDatas();
		}
	}

	ionViewWillEnter() {
		if (!this.componentMode) {
			this.currentLanguage = this.STranslate.currentLang;

			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe(() => {
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.moduleId = params.moduleId;
							this.surveyId = params.surveyId;
							this.subscriptions.forEach((sub) => sub.unsubscribe());

							// Analytics
							this.SAnalytics.surveyAccess(this.eventId, this.moduleId, this.surveyId);

							this.subscriptions.push(
								this.STranslate.onLangChange.subscribe((lang) => {
									this.currentLanguage = lang.lang;
								})
							);

							this.initDatas();
						});
				});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes.surveyId && !changes.surveyId.firstChange) {
			this.subscriptions.forEach((sub) => sub?.unsubscribe());
			this.event = null;
			this.module = null;
			this.survey = null;
			this.eventUser = null;
			this.initDatas();
		}
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		this.getSurvey();
	}

	ionViewWillLeave() {
		if (!this.componentMode) {
			this.survey = null;
			this.store.dispatch(ResetHeaderState(null));
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;
				}
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
				}
			})
		);
	}

	/**
	 * Get survey
	 */
	getSurvey() {
		this.subscriptions.push(
			this.store.select(getSpecificSurvey(this.surveyId)).subscribe((survey) => {
				if (!_.isEqual(this.survey, survey)) {
					this.survey = survey;
					if (this.survey) {
						this.survey.questions = this.survey.questions.filter((question) => question.visibility);
					}
					if (
						!this.survey ||
						(this.survey && !this.survey.visibility) ||
						(this.survey && this.survey.questions.length === 0)
					) {
						this.goBackToList();
						return;
					}
					if (this.survey) {
						this.patchResultsForm();
					}
				}

				if (this.survey && !this.componentMode) {
					this.store.dispatch(
						GetHeaderState({
							payload: {
								item: this.survey,
								title: this.survey.name,
								type: TypeHeader.SURVEY
							}
						})
					);
				}
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;
					this.locale = this.SUtility.getLocale(
						eventUser ? eventUser.updatedSettings.language : this.event.language
					);
				}
			})
		);
	}

	/**
	 * Patch form with all survey result
	 */
	patchResultsForm() {
		this.questionsForm = this.fb.group({});
		this.survey.questions.forEach((question) => {
			const resultOfQuestion = this.getResultOfQuestion(question);

			if (question.type === "oneSelect") {
				this.questionsForm.addControl(
					question.uid,
					new UntypedFormControl({
						value: resultOfQuestion ? resultOfQuestion.oneSelect.answerId : "",
						disabled: !this.checkCanChangeAnswer(question)
					})
				);
			} else if (question.type === "multipleSelect") {
				this.questionsForm.addControl(
					question.uid,
					new UntypedFormControl({
						value: resultOfQuestion ? resultOfQuestion.multipleSelect.answersIds : [],
						disabled: !this.checkCanChangeAnswer(question)
					})
				);
			} else if (question.type === "dissertative") {
				this.questionsForm.addControl(
					question.uid,
					new UntypedFormControl({
						value: resultOfQuestion ? resultOfQuestion.dissertative : "",
						disabled: !this.checkCanChangeAnswer(question)
					})
				);
			} else if (question.type === "date") {
				this.questionsForm.addControl(
					question.uid,
					new UntypedFormControl({
						value: resultOfQuestion ? resultOfQuestion.date : "",
						disabled: !this.checkCanChangeAnswer(question)
					})
				);
			} else if (question.type === "document") {
				this.assetFileName =
					resultOfQuestion && resultOfQuestion.document
						? this.SDocument.getFomatForSVG(resultOfQuestion.document.docType)
						: "generic-file_ico";
				this.questionsForm.addControl(
					question.uid,
					new UntypedFormControl({
						value: resultOfQuestion && resultOfQuestion.document ? resultOfQuestion.document.url : "",
						disabled: !this.checkCanChangeAnswer(question)
					})
				);
			} else if (question.type === "evaluation") {
				this.questionsForm.addControl(
					question.uid,
					new UntypedFormControl({
						value: resultOfQuestion ? resultOfQuestion.evaluation : 0,
						disabled: !this.checkCanChangeAnswer(question)
					})
				);
			}
		});
	}

	/**
	 * Chek if can change answer
	 */
	checkCanChangeAnswer(question: ISurveyQuestion) {
		const result = this.getResultOfQuestion(question);
		return result && !this.survey.changeAnswers ? false : true;
	}

	/**
	 * CHeck checkbox for multiple select
	 * @param question
	 */
	checkCheckbox(question: ISurveyQuestion, answer: ISurveyQuestionAnswer) {
		const answersIds = this.questionsForm.get(question.uid).value;
		return answersIds.includes(answer.uid) ? true : false;
	}

	/**
	 * Update checkbox
	 * @param question
	 * @param answer
	 * @param event
	 */
	updateCheckboxs(question: ISurveyQuestion, answer: ISurveyQuestionAnswer, event: any) {
		let answersIds = _.cloneDeep(this.questionsForm.get(question.uid).value);
		if (event.detail.checked) {
			if (!answersIds.includes(answer.uid)) {
				answersIds.push(answer.uid);
			}
		} else {
			if (answersIds.includes(answer.uid)) {
				answersIds = answersIds.filter((uid) => uid !== answer.uid);
			}
		}
		this.questionsForm.get(question.uid).patchValue(answersIds);
	}

	/**
	 * Check if can send survey
	 * @returns
	 */
	checkCanSendSurvey() {
		// let check = true;
		const noResult = this.survey.questions.some((question) => !this.getResultOfQuestion(question));
		// this.survey.questions.forEach((question) => {
		// 	const result = this.getResultOfQuestion(question);
		// 	if (!result) {
		// 		check = false;
		// 	}
		// });
		return noResult ? noResult : this.survey.changeAnswers;
		// return this.survey.changeAnswers || (!this.survey.changeAnswers && !check) ? true : false;
	}

	/**
	 * Check if document is an image
	 * @param question
	 * @returns
	 */
	checkImg(question: ISurveyQuestion) {
		const result = this.getResultOfQuestion(question);
		if (result && result.document && result.document.docType.includes("image")) {
			return true;
		} else {
			if (this.questionsForm.get(question.uid).value.includes("image")) {
				return true;
			}
			return false;
		}
	}

	/**
	 * Get result of question
	 * @param question
	 * @returns
	 */
	getResultOfQuestion(question: ISurveyQuestion) {
		return this.survey.eventUserResults.find(
			(result) => result.questionId === question.uid && result.userId === this.eventUser.uid
		);
	}

	/**
	 * Getting img from document type
	 * @param question
	 * @returns
	 */
	getImg(question: ISurveyQuestion) {
		return this.questionsForm.get(question.uid).value;
	}

	/**
	 * Get document
	 */
	async getDocument(evt: any, question: ISurveyQuestion) {
		this.loaderDoc = true;
		const item: File = evt.target.files[0];
		try {
			const documentBase64 = await this.SStorage.fromFileToBase64(item);
			// get type from base64
			this.documentType = item.type;
			this.documentName = item.name;
			// const type = (documentBase64 as string).split(";base64,")[0].split("data:")[1];

			// get extension from type
			this.extension = this.SDocument.checkType(this.documentType.split("/")[1]);
			this.assetFileName = this.SDocument.getFomatForSVG(this.documentType.split("/")[1]);

			if (this.documentType.includes("image")) {
				this.questionsForm.get(question.uid).patchValue(documentBase64);
			} else {
				this.questionsForm.get(question.uid).patchValue(`${this.documentType},${documentBase64}`);
				// this.questionsForm.get(question.uid).patchValue(documentBase64);
			}
			this.loaderDoc = false;
		} catch (error) {
			this.loaderDoc = false;
		}
	}

	/**
	 * Send survey
	 */
	async sendSurvey() {
		try {
			// Check force complete
			if (this.survey.forceComplete) {
				let check = true;
				const results = this.questionsForm.getRawValue();

				for (const key of Object.keys(results)) {
					const result = results[key];
					if (!result) {
						check = false;
					}
				}
				if (!check) {
					const alert = await this.SUtility.presentAlert(
						this.STranslate.instant("surveys.force_complete_title_alert"),
						this.STranslate.instant("surveys.force_complete_message_alert"),
						[
							{
								text: this.STranslate.instant("buttons.ok")
							}
						],
						[]
					);
					alert.present();
					return;
				}
			}

			const results: { result: ISurveyQuestionResult; new: boolean }[] = [];

			const datas = this.questionsForm.value;

			let points = 0;
			for (const key of Object.keys(datas)) {
				const formResult = datas[key];
				const result = _.cloneDeep(this.survey.eventUserResults.find((res) => res.questionId === key));
				const question = this.survey.questions.find((question) => question.uid === key);

				// Save image if document type
				const imageId: string = this.SFirestore.createId(
					`events/${this.eventId}/modules/${this.moduleId}/surveys/${this.survey.uid}/users-results`
				);
				let imageUrl: string = "";
				if (question.type === "document" && formResult && formResult !== "") {
					imageUrl = await this.SStorage.uploadBase64(
						formResult.split(";base64,")[1],
						// "image/jpeg",
						this.documentType,
						// eslint-disable-next-line max-len
						`events/${this.eventId}/modules/${this.moduleId}/surveys/${this.survey.uid}/users-results/${this.eventUser.uid}/${this.eventUser.uid}.jpg`
					);

					// const downloadUrl = await firstValueFrom(downloadUrl$);

					// imageUrl = downloadUrl;
				}
				if (result) {
					if (question.type === "oneSelect") {
						result.oneSelect = {
							answerId: formResult
						};
					} else if (question.type === "multipleSelect") {
						result.multipleSelect = {
							answersIds: formResult
						};
					} else if (question.type === "dissertative") {
						result.dissertative = formResult;
					} else if (question.type === "date") {
						result.date = formResult;
					} else if (question.type === "document") {
						result.document =
							result.document && result.document.url
								? result.document
								: {
										uid: imageId ? imageId : "",
										docType: this.documentType,
										name: this.documentName,
										url: imageUrl ? imageUrl : ""
								  };
					} else if (question.type === "evaluation") {
						result.evaluation = formResult;
					}

					points +=
						question.type === "oneSelect"
							? question.answers.find((answer) => answer.uid === result.oneSelect.answerId).weight
							: question.type === "multipleSelect"
							? question.answers
									.filter((answer) => result.multipleSelect.answersIds.includes(answer.uid))
									.reduce((sum, answer) => sum + answer.weight, 0)
							: question.weight;

					results.push({ result: result, new: false });
				} else if (
					(question.type === "multipleSelect" && formResult.length > 0) ||
					(question.type !== "multipleSelect" && formResult)
				) {
					const newResult: ISurveyQuestionResult = {
						uid: this.SFirestore.createId(
							`events/${this.eventId}/modules/${this.moduleId}/surveys/${this.survey.uid}/users-results`
						),
						creationDate: DateTime.local().toISO(),
						date: question.type === "date" ? formResult : null,
						dissertative: question.type === "dissertative" ? formResult : null,
						document:
							question.type === "document"
								? {
										uid: imageId ? imageId : "",
										docType: this.documentType,
										name: this.documentName,
										url: imageUrl ? imageUrl : ""
								  }
								: null,
						evaluation: question.type === "evaluation" ? formResult : null,
						eventId: this.eventId,
						moduleId: this.moduleId,
						multipleSelect:
							question.type === "multipleSelect"
								? {
										answersIds: formResult
								  }
								: null,
						oneSelect:
							question.type === "oneSelect"
								? {
										answerId: formResult
								  }
								: null,
						questionId: question.uid,
						surveyId: this.survey.uid,
						type: question.type,
						userId: this.eventUser.uid
					};

					points +=
						question.type === "oneSelect"
							? question.answers.find((answer) => answer.uid === newResult.oneSelect.answerId).weight
							: question.type === "multipleSelect"
							? question.answers
									.filter((answer) => newResult.multipleSelect.answersIds.includes(answer.uid))
									.reduce((sum, answer) => sum + answer.weight, 0)
							: question.weight;

					results.push({ result: newResult, new: true });
				}
			}
			await this.SSurveys.createSurveyResults(
				this.eventId,
				this.moduleId,
				this.surveyId,
				results,
				this.eventUser,
				this.survey.weight,
				points
			);

			// if (results.length === this.survey.questions.length) {
			const alert = await this.SUtility.presentAlert(
				this.STranslate.instant("alerts.thankyou_answer"),
				this.STranslate.instant("alerts.answer_saved_successfully"),
				[
					{
						text: "Ok",
						handler: () => {
							this.goBackToList();
						}
					}
				],
				[],
				false
			);
			alert.present();
			// }

			// Analytics
			this.SAnalytics.sendSurvey(
				this.eventId,
				this.moduleId,
				this.eventUser,
				this.surveyId,
				results.map((result) => result.result)
			);

			this.SUtility.presentToast(this.STranslate.instant("surveys.survey_send"), 3000, "bottom", "success");
			this.goBackToList();
		} catch (error) {
			this.SUtility.presentToast(this.STranslate.instant("surveys.cannot_send_survey"), 3000, "bottom", "danger");
		}
	}

	/**
	 * Go back to surveys list
	 */
	goBackToList() {
		if (this.componentMode) {
			this.changeView.emit({ segmentType: "surveys", type: "surveys", uid: "" });
		} else {
			this.SUtility.callGoBackOnHeader();
		}
	}
}
