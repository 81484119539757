{
	"alerts": {
		"add_to_my_calendar": "Ajouter à mon calendrier",
		"all_right": "OK",
		"already_scanned": "Qrcode déjà validé",
		"already_checked_checkin": "Vous êtes déjà enregistré à ce checkin",
		"answer_saved_successfully": "Votre contribution a bien été enregistrée",
		"app_open_doc_not_found_txt": "Vous n’avez pas d’application installée supportant ce type de format, voulez-vous en télécharger une ?",
		"asking_saved_successfully": "Votre contribution a bien été enregistrée",
		"attendee_not_found": "Participant introuvable. Voulez vous scanner à nouveau?",
		"cannot_scan_web": "Vous ne pouvez pas scanner de QRcode depuis la version web de l'application",
		"change_status": "Changer le statut",
		"change_status_confirm_msg": "Êtes-vous sûr(e) de vouloir changer le statut de",
		"close": "Fermer",
		"confirm_exit_event": "Voulez-vous vraiment quitter l'événement?",
		"confirmed_presence": "a été enregistré! Voulez-vous scanner à nouveau ?",
		"date_already_selected": "Vous avez déjà sélectionné une date, la nouvelle date ne sera pas enregistrée",
		"delete_account_title": "Supprimer son compte",
		"delete_contact": "Supprimer le contact",
		"delte_contact_confirm": "Voulez-vous vraiment supprimer ce contact?",
		"delete_account_message": "Etes-vous sur de vouloir supprimer votre compte ? Cela supprimera toutes vos données et donc vos inscriptions à tous les évènements.",
		"error": "Erreur",
		"error_scanning": "Erreur de lecture",
		"event_shortcode_blank": "Le code est invalide, veuillez réessayer",
		"exit_event": "Quitter l'application",
		"failure_scanning": "Impossible de décoder le Qrcode",
		"go_to_container_confirm": "Voulez-vous vraiment revenir à la liste des événements ?",
		"invalid_qrcode": "Vous n'êtes pas enregistré sur ce point de passage",
		"list_events": "Liste des événements",
		"login-page": "Page de connexion",
		"go-to-login-confirm": "Voulez-vous vraiment retourner à la page de connexion ?",
		"login_client_title_error": "Erreur de connexion",
		"login_client_error": "Vous tentez de vous connecter avec un compte client, vous devez créer un compte participant afin de pouvoir vous connecter !",
		"no_camera": "Impossible d'accéder à la caméra",
		"not_change_answer": "Vous ne pouvez pas éditer vos réponses",
		"not_found_title": "Fichier introuvable",
		"not_open_market_msg": "Il est impossible d’ouvrir le store, voulez-vous ouvrir le document dans le navigateur?",
		"not_possible_change_status": "Impossible de réaliser le Check-in de",
		"not_success": "L'opération n'a pas aboutie",
		"please_tryagain": ". Veuillez réessayer plus tard",
		"post_moderated": "Votre post a été envoyé pour validation",
		"not_confirm_your_presence": "Impossible d'effectuer le Check-in. Veuillez réessayer plus tard.",
		"clear-filter": "Effacer le filtre",
		"status_changed_successfully": "a été modifié!",
		"success": "Opération réussie",
		"thankyour_asking": "Merci!",
		"thankyou_answer": "Merci!",
		"the_presence": "Le Check-in de",
		"the_status_changed": "Le statut de",
		"type_event_shortcode": "Saisissez le code de l'événement",
		"user_chat_not_available": "Cet utilisateur ne s'est pas encore connecté, il est impossible de discuter avec lui",
		"your_presence_confirmed": "Votre Check-in a été réalisé",
		"blocked-event-info": "L'événement est bloqué, les fonctionnalités et accès sont limitées",
		"contact_has_been_add_to_your_fav": "Le contact a été ajouté à vos favoris",
		"contact_has_not_been_add_because_form_empty": "Le contact n'a pas été ajouté car le formulaire est vide",
		"contact_already_in_your_fav": "Le contact est déjà dans vos favoris"
	},
	"appointments": {
		"show-more-timeslots": "Afficher plus de créneaux",
		"no-rdv-accepted": "Vous n’avez aucun rendez-vous planifié prochainement...",
		"no-rdv-pending": "Vous n’avez actuellement aucun rendez-vous en attente de confirmation...",
		"no-rdv-ended": "Il n’y a aucun rendez-vous terminé pour le moment...",
		"no-rdv-cancelled": "Aucun rendez-vous annulé pour le moment...",
		"appointment-with": "Rendez-vous avec ",
		"save-on-personal-schedule": "Enregistrer votre rendez-vous dans votre agenda personnel",
		"appointment-infos": "Informations du rendez-vous",
		"aditionnal-infos": "Informations complémentaires",
		"select-day": "Sélectionnez un jour...",
		"select-disponibilities": "Sélectionnez vos disponibilités:",
		"appointment": "Rendez-vous",
		"someone-want-to-make-appointment": "{{user}} souhaite prendre un rendez-vous..",
		"take-appointment": "Prendre un rendez-vous",
		"confirm-appointment": "Confirmez le rendez-vous",
		"informations": "Informations",
		"fill-informations": "Renseignez les informations",
		"notation_title": "Qu'avez vous pensez de ce rendez-vous ?",
		"commentary_title": "Avez-vous des commentaires à faire ?",
		"commentary": "Commentaires",
		"input_subject": "Précisez le sujet...",
		"input_message": "Entrez un message...",
		"appointment_accepted": "Rendez-vous accepté",
		"appointment_pending": "Demande en attente...",
		"appointment_cancelled": "Rendez-vous annulé",
		"appointment_cancelled_automatically": "Rendez-vous annulé",
		"appointment_rejected": "Rendez-vous décliné",
		"appointment_rejected_automatically": "Rendez-vous décliné",
		"cancel_appointment": "Annuler le rdv",
		"join_visio": "Rejoindre en visio",
		"display_empty_time_slots": "Afficher les créneaux vides",
		"make_all_disabled": "Tout rendre indisponible",
		"make_all_enabled": "Tout rendre disponible",
		"available_time_slot": "Créneau disponible",
		"unavailable_time_slot": "Créneau indisponible",
		"request_appointment": "Demande de rendez-vous",
		"choose_time_slot": "Choisissez le créneau du rendez-vous",
		"appointment_request": "Demande de prise de rendez-vous",
		"applicant": "Demandeur",
		"invited": "Invité",
		"on_date": "Pour le",
		"from": "De",
		"to": "à",
		"subject": "Sujet",
		"status": "Statut",
		"see_details": "Voir les détails",
		"accept": "Accepter le RDV",
		"reject": "Décliner le RDV",
		"cancel": "Annuler le RDV",
		"choose_new_time_slot": "Choisir un nouvel horaire",
		"make_appointment": "Prendre un rdv",
		"select-location": "Sélectionner le lieu de votre rendez-vous...",
		"alerts": {
			"send-appointment-slot-disabled-recipient-title": "Créneau désactivé",
			"send-appointment-slot-disabled-recipient-message": "Le créneau a été désactivé par le destinataire",
			"send-appointment-slots-taken-title": "Créneau déjà pris",
			"send-appointment-slots-taken-message": "Le créneau pour lequel vous avez demandé le rendez-vous n'est plus disponible",
			"send-appointment-limit-accepted-applicant-title": "Limite atteinte",
			"send-appointment-limit-accepted-applicant-message": "Impossible de prendre le rendez-vous, votre limite de nombre de rendez-vous accepté est atteinte",
			"send-appointment-limit-accepted-recipient-title": "Limite atteinte",
			"send-appointment-limit-accepted-recipient-message": "Impossible de prendre le rendez-vous, la limite de rendez-vous accepté du destinataire est atteinte",
			"appointment-accepted-limit": "Impossible d'accepter le rendez-vous, la limite de rendez-vous accepté est atteinte.",
			"send-appointment-title": "Demande de rendez-vous soumise",
			"send-appointment-message": "Votre demande a bien été transmise. Une notification vous sera envoyée lorsque celle-ci aura été examinée et approuvée.",
			"send-appointment-exist-title": "Rendez-vous non disponible",
			"send-appointment-exist-message": "Le créneau pour lequel vous avez pris le rendez-vous n'est plus disponible.",
			"cancel_appointment": {
				"title": "Annuler ce rendez-vous",
				"message": "Voulez-vous vraiment annuler ce rendez-vous?"
			}
		},
		"notification": {
			"reminder-title": "Rappel de votre rendez-vous",
			"reminder-content": "Votre rendez-vous avec {{user}} commence dans {{time}} minutes",
			"accepted": "Le rendez-vous \"{{subject}}\" a été accepté.",
			"pending": "Demande de rendez-vous {{date}}",
			"rejected": "Le rendez-vous \"{{subject}}\" a été décliné.",
			"cancelled": "Le rendez-vous \"{{subject}}\" a été annulé.",
			"rejected_automatically": "Le rendez-vous \"{{subject}}\" a été décliné automatiquement. L'utilisateur n'est plus disponible à cet horaire.",
			"cancelled_automatically": "Le rendez-vous \"{{subject}}\" a été annulé automatiquement. L'utilisateur n'est plus disponible à cet horaire."
		},
		"buttons": {
			"send-message": "Envoyer un message",
			"see-details": "Voir les détails",
			"accept": "Accepter",
			"refuse": "Refuser",
			"cancel": "Annuler"
		},
		"app_status": {
			"coming": "A venir",
			"waiting": "En attente",
			"ended": "Terminé",
			"accepted": "Accepté",
			"cancelled": "Annulé",
			"cancelled-by": "Annulé par {{ user }}",
			"cancelled_automatically": "Annulé automatiquement",
			"rejected": "Décliné",
			"rejected_automatically": "Décliné automatiquement",
			"pending": "En attente de réponse"
		},
		"select_date": "Sélectionnez une date",
		"date_time_appointment": "Le Rendez-vous est:",
		"make_appointment_available": "Rendre le RDV disponible",
		"make_appointment_unavailable": "Rendre le RDV indisponible"
	},
	"app_settings": {
		"old_version_description": "Votre application possède une mise à jour plus récente, télécharger là sur les stores",
		"old_version_title": "Ancienne version"
	},
	"asks-questions": {
		"ask-questions-plc": "Tapez votre texte ici...",
		"error-send-question": "Impossible d'envoyer votre question",
		"error-voting": "Une erreur est survenue lors du like/unlike",
		"ask-question-anonymously": "Posez la question de manière anonyme",
		"your-question-awaiting-moderator-approbation": "Votre question est en attente de validation par le modérateur"
	},
	"auth": {
		"need_login_register_for_participating": "Vous devez vous connecter ou vous enregistrer afin de pouvoir participer."
	},
	"buttons": {
		"access": "Se connecter",
		"authenticate": "S'authentifier",
		"access_to": "Accéder",
		"access_by_email": "Accès avec email",
		"access_by_shortcode": "Accès avec code",
		"add": "Ajouter",
		"add-contact": "Ajouter un contact",
		"scan-a-profil": "Scanner un profil",
		"answer": "Valider",
		"apply": "Appliquer",
		"back": "Retour",
		"back_to_list_event": "Retour à la liste",
		"back-to-login": "Retour à la connexion",
		"btn_verify": "Accéder",
		"cancel": "Annuler",
		"close": "Fermer",
		"comment": "Commenter",
		"confirm": "Confirmer",
		"create": "Créer",
		"delete": "Supprimer",
		"download": "Télécharger",
		"download_vcf": "Télécharger le contact",
		"delete_account": "Supprimer son compte",
		"edit-profil": "Editer le profil",
		"enter": "Valider",
		"exit": "Déconnexion",
		"from_photos": "Photothèque",
		"take_photo": "Prendre une photo",
		"go": "Entrer",
		"generate-email": "Générer email",
		"generate-random-email-address": "Générer une adresse e-mail aleatoire",
		"log_out": "Se déconnecter",
		"next": "Suivant",
		"new": "Nouveau",
		"no": "Non",
		"nonees": "Aucunes",
		"open_gallery": "Mes photos",
		"ok": "Ok",
		"post": "Publier",
		"prev": "Précédent",
		"public_events_list": "Liste des événements publics",
		"register": "S’inscrire",
		"select_picture": "Sélectionnez une image",
		"click-to-select-document": "Cliquez pour sélectionner un document",
		"click-on-document-to-replace": "Cliquez sur le document pour le remplacer",
		"share": "Partager",
		"show_more": "Voir plus",
		"show_less": "Voir moins",
		"social_sharing": "Réseaux sociaux",
		"take_picture": "Prendre une photo",
		"update": "Modifier",
		"validate": "Valider",
		"yes": "Oui"
	},
	"chat": {
		"chat": "Chat",
		"chats": "Chats",
		"chat_history": "Conversations",
		"chat_empty_content_title": "Chattez entre participants",
		"chat_empty_content_text": "Pour démarrer une discussion, rendez-vous sur le profil d’un participant et cliquez sur « Démarrer une discussion »",
		"create_chat": "Créer un groupe de discussion",
		"display_group_info": "Afficher les informations",
		"group_chat_empty_content_title": "Discutez entre participants",
		"group_chat_empty_content_text": "Laissez-vous embarquer dans un groupe de discussions. Soyez le premier à participer et incitez les autres invités à se joindre à vous !",
		"groups": "Groupes",
		"group_info": "Informations du groupe",
		"new_chat": "Nouvelle conversation",
		"no_messages": "Vous n'avez pas de messages",
		"no_official": "Groupes non officiels",
		"official": "Groupes officiels",
		"notifications": "Notifications",
		"received_message": "Vous avez reçu un nouveau message",
		"silent_mode": "Mode silence",
		"toggle": "Activer/désactiver",
		"type_msg": "Tapez votre message...",
		"you": "Vous: "
	},
	"checkin": {
		"only-one-check-authorized": "Seulement un seul check est autorisé",
		"already_checked": "Déjà enregistré",
		"away": "Absents",
		"default_title": "Check-in",
		"empty_content_title": "Consultez les checkins",
		"empty_content_text": "Consultez les checkins",
		"last_checkins": "Derniers Check-ins",
		"qrcode_focus": "Flashez le QRCode",
		"showed": "Présents",
		"total": "Total",
		"user_registered": "Utilisateur enregistré !",
		"user_unregistered": "Utilisateur désenregistré !"
	},
	"documents": {
		"default_title": "Documents",
		"empty_content_title": "Consultez les documents de l’événement",
		"empty_content_text": "Visualisez et téléchargez les documents de l’événement !"
	},
	"edit-profil": {
		"name": "Nom",
		"email": "E-mail",
		"biography": "Biographie",
		"website": "Site web",
		"facebook": "Facebook",
		"linkedin": "LinkedIn",
		"twitter": "X (Twitter)",
		"instagram": "Instagram",
		"submit": "Valider",
		"placeholders": {
			"name_text-info": "Votre nom",
			"email_text-info": "Votre adresse email",
			"password_create_text-info": "Créez un mot de passe",
			"password_confirm_text-info": "Confirmez votre mot de passe",
			"password_text-info": "Votre mot de passe",
			"bio_text-info": "Comment vous décrivez-vous ?",
			"website_text-info": "Site web",
			"facebook_text-info": "Profil Facebook",
			"linkedin_text-info": "Profil LinkedIn",
			"twitter_text-info": "Profil Twitter / X",
			"instagram_text-info": "Profil instagram",
			"company_text-info": "Votre entreprise",
			"title_text-info": "Quel poste occupez-vous ?",
			"url_text-info": "Entrez un lien valide",
			"simple_text-info": "Entrez une valeur texte",
			"numeric_text-info": "Entrez une valeur numérique",
			"html_text-info": "Entrez du contenu text ou html",
			"select_text-info": "Choisissez une valeur",
			"multi-select_text-info": "Choisissez une ou plusieurs valeur",
			"multiple_text-info": "Entrez du texte et appuyez sur \"Entrer\" pour valider.",
			"date_text-info": "Choisissez une date",
			"phonetel_text-info": "Entrez un numéro de téléphone",
			"image_text-info": "Téléchargez une image",
			"file_text-info": "Téléchargez un fichier",
			"example": {
				"email1": "email@example.com",
				"email2": "Ex : jeandupont@mail.com"
			}
		},
		"errors": {
			"characters": "caractères",
			"blank": {
				"name": "Le nom est obligatoire !",
				"simple_text": "Champ vide ! Veuillez entrer une valeur.",
				"email": "L'email est obligatoire !",
				"select": "Veuillez sélectionner une option !",
				"multi_select": "Veuillez sélectionner une ou plusieurs options !",
				"required": "Champ obligatoire !"
			},
			"invalid": {
				"bio": "Le nombre de caractères doit être compris entre 15 et 5000 !",
				"url": "Lien/URL invalide !",
				"facebook": "Le lien facebook est invalide !",
				"linkedin": "Le lien Linkedin est invalide !",
				"twitter": "Le lien X (Twitter) est invalide !",
				"instagram": "Le lien Instagram est invalide !",
				"name": "Le nom doit contenir au moins 3 caractères !",
				"email": "Email invalide !",
				"date": "Date invalide !",
				"generic": "Le format est invalide !",
				"multiple_text-too-long": "Le nombre de caractères maximum est depassé",
				"multiple_text-content-exist": "Le contenu est déjà présent dans la liste",
				"min_length": "Veuillez entrer au moins",
				"phone_number": "Téléphone est invalide",
				"big_number": "Nombre trop grand",
				"file_size": "Fichier trop volumineux, Limite : "
			}
		}
	},
	"events": {
		"switch_timezone_user": "Passer en fuseau horaire local",
		"switch_timezone_event": "Passer en fuseau horaire de l'événement",
		"add_event_to_calendar": "Ajouter à mon calendrier"
	},
	"events_list": {
		"events": "Evénements",
		"my_events": "Mes événements",
		"publics_events": "Evénements publics",
		"no-events": "Vous n'êtes enregistré sur aucun événement de ce conteneur !"
	},
	"event_users": {
		"default_title": "Participants",
		"empty_content_title": "Consultez la liste de participants",
		"empty_content_text": "Consultez le trombinoscope avec les profils des participants. Chattez ou discutez en vidéo directement avec un participant depuis son profil !",
		"empty_content_text_card_exchange": "Rechercher un contact ou scanner son QR code pour l'enregistrer",
		"company": "Entreprise",
		"post": "Poste",
		"delete_all": "Tout supprimer",
		"not_specified": "Non spécifié"
	},
	"feedbacks": {
		"cannot_send_feedback": "Impossible d'envoyer les réponses à l'enquête",
		"default_title": "Sondage",
		"default_title_feedback": "Sondage",
		"answered": "Déjà répondu",
		"not_answered": "Non répondu",
		"answer_plc": "...",
		"force_complete_title_alert": "Attention",
		"force_complete_message_alert": "Vous devez remplir entièrement le formulaire",
		"feedback_send": "Vos réponses à l'enquête ont été envoyées"
	},
	"feed_news": {
		"default_title": "Fil d'actualité",
		"description_plc": "Exprimez-vous!",
		"comments_plc": "Ecrivez un commentaire...",
		"likes": "Likes",
		"comment": "Commentaire",
		"comments": "Commentaires",
		"thumb-up": "J'aime",
		"heart": "J'adore",
		"clap": "Bravo",
		"idea": "Instructif",
		"thinking": "Intéressant",
		"time": "Il y a {{time}} {{timeLabel}}",
		"empty_content_title": "Publiez du contenu sur le fil d’actualité de l’événement",
		"empty_content_text": "Échangez grâce à des posts, des likes et des commentaires publiés sur le fil d’actualités de l’événement !"
	},
	"filter": {
		"not_specified": "Non spécifié",
		"delete_all": "Tout supprimer",
		"text-information": "Utiliser les filtres pour affiner votre recherche"
	},
	"gallery": {
		"default_title": "Galerie",
		"empty_content_title": "Visualisez les photos de l’événement",
		"empty_content_text": "Regardez et téléchargez les photos de l’événement !"
	},
	"gamification": {
		"treasure_hunt_description": "Trouvez les QRcodes dispersés à travers l'événement"
	},
	"interactivity": {
		"default_title": "Interactivité",
		"default_empty_content_title": "Aucun résultat !",
		"default_empty_content_text": "Revenez plus tard !",
		"externals_interactivity_description": "Interagissez entre vous !",
		"note-taking-description": "Pour créer une note, cliquez sur le bouton + en bas de l'écran",
		"survey_description": "Répondez aux questionnaires!",
		"quiz_description": "Répondez aux QCM!",
		"training_description": "Diriger la formation",
		"ask_question_description": "Questionnez les orateurs!",
		"feedback_empty_content_title": "Répondez aux enquêtes",
		"feedback_empty_content_text": "Votre avis est essentiel ! Accédez et répondez à des enquêtes de satisfaction directement sur la plateforme.",
		"quiz_empty_content_title": "Répondez aux quiz",
		"quiz_empty_content_text": "Testez vos connaissances avec un quiz !",
		"questions_empty_content_title": "Posez vos questions",
		"questions_empty_content_text": "N’hésitez pas à poser toutes vos questions aux intervenants ! Les autres participants pourront liker celles-ci afin de les mettre en avant.",
		"survey_empty_content_title": "Répondez aux enquêtes",
		"survey_empty_content_text": "Votre avis est essentiel ! Accédez et répondez à des enquêtes de satisfaction directement sur la plateforme.",
		"min_points_msg_part_1": "You should at least achieve ",
		"min_points_msg_part_2": " points to enable this survey !"
	},
	"labels": {
		"accompanying": "Accompagnant",
		"address": "Adresse",
		"and": "et",
		"code": "Code",
		"comment": "Commentaire",
		"content": "Contenu",
		"choose-date": "Choisissez une date",
		"description": "Description",
		"contact-editing": "Edition du contact",
		"contact-creation": "Création d'un contact",
		"email": "E-mail",
		"end": "Fin",
		"empty_notes": "Aucune note",
		"filters": "Filtres",
		"groups": "Groupes",
		"group": "Groupe",
		"hidden": "Caché",
		"history": "Historique",
		"visible": "Visible",
		"hours": "heure(s)",
		"identifier": "Identifiant",
		"information": "Information",
		"internet_site": "Site Internet",
		"legal_mentions": "Juridique",
		"loading": "Chargement...",
		"load-the-editor": "Charger l'éditeur",
		"locations": "Localisations",
		"members": "Membres",
		"my_notes": "Mes notes",
		"minutes": "minute(s)",
		"name": "Nom",
		"no": "Non",
		"on-maintenance": "La plateforme est actuellement en maintenance, revenez plus tard",
		"plan": "Plan",
		"privacy": "Politique de confidentialité",
		"reset": "Réinitialiser",
		"scan_date": "Date de scan",
		"search": "Rechercher",
		"seconds": "s",
		"sessions": "Sessions",
		"start": "Début",
		"support": "Email de support",
		"tags": "Tags",
		"terms_of_use": "Conditions d'utilisation",
		"title": "Titre",
		"tracks": "Tracks",
		"yes": "Oui"
	},
	"placeholders": {
		"enter-phone-number": "Entrez un numéro de téléphone valide",
		"link-or-import-image": "Entrez un lien ou importez une image",
		"import-file": "Importez un fichier",
		"address_plc": "99 Av. des Champs-Élysées, 75008 Paris France",
		"phone_number_placeholder": "07 12 34 56 78"
	},
	"languages": {
		"choose-language": "Choisir le langage",
		"arabic": "Arabe",
		"english": "Anglais",
		"french": "Français",
		"portuguese": "Portugais",
		"spanish": "Espagnol",
		"german": "Allemand"
	},
	"login": {
		"accept_term": "J'accepte les",
		"confirm_new_pass": "Confirmez votre nouveau mot de passe",
		"email": "E-mail",
		"password": "Mot de passe",
		"forgot_pass": "J’ai oublié mon mot de passe",
		"first_access_txt_01": "Premier accès!",
		"first_access_txt_02": "Un code à six chiffres a été envoyé à votre adresse email, entrez-le ci-dessous pour confirmer votre identité:",
		"go_to_login": "Retour à la connexion",
		"new_pass": "Nouveau mot de passe",
		"not_registered": "Cet email n'est pas enregistré pour l'événement, veuillez vous enregistrer.",
		"incorrect_pass": "Mot de passe invalide !",
		"invalid_pass": "Mot de passe invalide! Votre mot de passe doit comporter 6 caractères minimum",
		"min_length_password": "Votre mot de passe doit comporter {{charNbr}} caractères minimum",
		"pass_error": "Nous n'avons pas pu créer votre mot de passe, veuillez réessayer plus tard!",
		"recovery_pass_title": "Réinitialisation du mot de passe",
		"recovery_pass_txt": "Veuillez confirmer votre e-mail afin de recevoir un e-mail de réinitialisation du mot de passe:",
		"your_email_plc": "Saisissez votre e-mail",
		"recovery_btn": "Envoyer",
		"recovery_success": "Nous vous avons envoyé un email permettant de réinitialiser votre mot de passe.",
		"recovery_text": "Nous venons de vous envoyer un email vous permettant de réinitialiser votre mot de passe. Pensez à vérifier vos courriers indésirables (spams) car il pourrait s’y trouver. Il vous suffira alors de cliquer sur le lien afin d’en créer un nouveau",
		"recovery_fail": "Nous n’avons pas pu réinitialiser votre mot de passe. Veuillez réessayer!",
		"access_your_account": "Se connecter",
		"term_check_required": "Vous devez accepter les conditions d'utilisation et la politique de confidentialité",
		"terms_of_use": "Conditions d'utilisation",
		"privacy_txt": "la Politique de confidentialité",
		"invalid_email": "E-mail invalide",
		"invalid_name": "Nom invalide",
		"blank_email": "Vous devez saisir un e-mail",
		"email_not_found": "L'e-mail n'est pas enregistré",
		"btn_no_have_acc": "Je n’ai pas de compte",
		"verify_code": "L'email contenant le code se trouve peut être dans vos spams",
		"btn_make_pass": "Créer un mot de passe",
		"great_title": "Bienvenue!",
		"make_pass_txt": "Veuillez créer un mot de passe individuel (minimum 6 caractères)",
		"make_pass_strong_txt": "Le mot de password doit comporter au minimum 12 caractères avec au moins un caractère majuscule, un chiffre et un caractère spécial (!@#$%€-_()[];:.<>+~#=*)",
		"error_send_email_code": "Nous n’avons pas pu vous envoyer l’email contenant le code à 6 chiffres. Veuillez réessayer plus tard!",
		"invalid_code": "Le code à 6 chiffes est invalide. Veuillez réessayer!",
		"password_not_match": "Les mots de passe ne sont pas identiques.",
		"email_already_use": "Nous n’avons pas pu créer un nouveau compte avec votre e-mail car il est déjà enregistré",
		"resend_code_number": "Si vous n'avez pas reçu le code à 6 chiffres: Vérifiez vos spams",
		"remaining_time": "temps restant",
		"many_pass_errors": "Votre compte a été bloqué temporairement suite à de nombreuses tentatives de connexions. Veuillez attendre quelques minutes avant de réessayer.",
		"input_name_plc": "Martine Rault",
		"input_email_plc": "martine@mail.com",
		"geral_error_register": "Une erreur s'est produite lors de l'inscription, veuillez réessayer.",
		"resend_code": "Renvoyer le code à six chiffres",
		"resend_email_reset_link": "Cliquez ici si vous n'avez pas reçu le mail de réinitialisation du mot de passe",
		"account_already_exist_with_email": "Un compte avec cet e-mail existe déjà. Comme il s'agit d'un événement public, connectez-vous simplement en renseignant votre mot de passe afin de vous enregistrer",
		"account_already_exist_with_email_private_event": "Un compte avec cet e-mail existe déjà. Connectez-vous en utilisant le formulaire de connexion.",
		"account_already_exist_with_email_public_event": "Un compte avec cet e-mail existe déjà. Comme il s'agit d'un événement public, vous entrerez votre mot de passe à la fin du formulaire afin de vous enregistrer",
		"event_user_already_exist_in_event": "Vous êtes déjà inscrit à cet événement. Connectez-vous simplement en entrant votre mot de passe pour y accéder.",
		"not_have_access_to_event": "L'utilisateur n'a pas accès à l'événement sollicité",
		"min_6_characters": "minimum 6 caractères",
		"account_disabled_many_request": "L'accès à ce compte a été suspendu temporairement en raison d'un nombre élévé de tentatives erronées. Réessayez plus tard.",
		"attempt_to_login_with_short_code_private_event_msg": "Cet événement est privé, vous ne pouvez pas vous connecter à partir d'un code d'accès. Veuillez vous connecter directement à l'aide de votre adresse e-mail",
		"attempt_to_login_with_short_code_private_event_title": "Accès à un événement"
	},
	"note-taking": {
		"success-create": "Note crée",
		"success-delete": "Note supprimée",
		"success-edit": "Note modifiée",
		"share-title": "Partager la note",
		"title-too-long": "Le titre ne doit pas faire plus de 70 caractères",
		"content-too-long": "Le contenu ne doit pas faire plus de 700 caractères"
	},
	"notifications": {
		"appointment_chat_title_received": "Rendez-vous reçu",
		"appointment_chat_title_updated": "Rendez-vous modifié",
		"appointment_chat_title": "Rendez-vous",
		"appointment_chat_title_cancelled": "Rendez-vous annulé"
	},
	"profile": {
		"start_chat": "Démarrer une discussion",
		"start_visio": "Démarrer une visio"
	},
	"quizs": {
		"warning-validation-definitive": "Attention ! La validation est définitive.",
		"score-is": "Votre score est de...",
		"correct-answered-out-of-1": "Vous avez bien répondu à ",
		"correct-answered-out-of-2": " questions sur ",
		"retry": "Recommencer",
		"retry-later": "Recommencer plus tard",
		"validate": "Valider",
		"cannot_send_quiz": "Impossible d'envoyer les réponses au quiz",
		"default_title": "Quiz",
		"default_title_quiz": "Quiz",
		"answered": "Déjà répondu",
		"not_answered": "Non répondu",
		"answer_plc": "...",
		"force_complete_title_alert": "Attention",
		"force_complete_message_alert": "Vous devez remplir entièrement le formulaire",
		"result_send": "Votre réponse a été enregistrée"
	},
	"rankings": {
		"empty_content_title": "Consultez les classements",
		"empty_content_text": "Consultez les classements",
		"global_ranking": "Classement général",
		"my_ranking": "Mon classement"
	},
	"register": {
		"register": "S’inscrire",
		"email_exist": "Un utilisateur est déjà associé à cette adresse email",
		"register_to_event": "S'inscrire à l'événement",
		"register_a_user_to_the_event": "Inscrire un participant",
		"user_already_has_an_account": "Vous avez déjà un compte ?",
		"user_doesnt_has_an_account": "Vous n'êtes pas encore inscrit ?",
		"next_step": "Etape suivante",
		"previous_step": "Etape précédente",
		"registrations_are_closed_please_contact_your_event_manager": "Les inscriptions à l'événement sont fermées. Veuillez contacter l'administrateur de l'événement",
		"register_an_accompanying_user_title": "Inscrire un accompagnant",
		"register_an_accompanying_user_question": "Voulez-vous inscrire un accompagnant à l'événement ?",
		"accompanying_users_limit_reached": "Vous ne pouvez pas inscrire d'accompagnant à l'événement car vous avez atteint la limite d'inscription definie par l'administrateur",

		"validator-error-msg": {
			"multi-text": {
				"required": "Champ obligatoire et doit contenir au moins une valeur validée",
				"multi_text-control_value_must_validated": "La valeur du champ doit être validée",
				"multi_text-control_value_must_be_unique": "La valeur du champ doit être unique",
				"multi_text_must_contain_value": "Au moins une valeur doit être renseignée et validée (appuyer sur Entrée pour valider)"
			},
			"strong-password-pattern": "Le mot de password n'est pas robuste, il doit comporter au minimum 12 caractères avec au moins un caractère majuscule, un chiffre et un caractère spécial (!@#$%€-_()[];:.<>+~#=*)",
			"date-minimum": "La date doit être supérieure au {{minDate}}",
			"date-maximum": "La date doit être inférieure au {{maxDate}}",
			"required": "Champ obligatoire",
			"checkbox-required": "Veuillez cocher l'option",
			"file": {
				"file_size": "Fichier trop volumineux. Limite : {{limit}} MB"
			}
		},
		"accompanying_remaining_places": "Vous pouvez encore inviter {{places}} accompagnant(s)"
	},
	"reports": {
		"alert_title": "Signalement",
		"alert_send": "Signalement envoyé"
	},
	"schedule": {
		"default_title": "Agenda",
		"filter_hour": "Par jour",
		"filter_track": "Par track",
		"empty_content_title": "Retrouvez le programme de votre événement",
		"empty_content_text": "Consultez rapidement le programme numérique de votre événement !",
		"download_schedule": "Télécharger l'agenda",
		"start-soon": "Début dans",
		"end-soon": "Fin dans"
	},
	"schedule_detail": {
		"cannot_register_to_session": "Impossible de s'inscrire à la session",
		"default_title": "Detail",
		"no_date": "Aucune",
		"number_attendees": "Participants déjà inscrits",
		"number_available_vacancies": "Nombre de places restantes",
		"register_to_session": "Inscription à la session",
		"unregister_to_session": "Se désinscrire de la session",
		"registered_to_session": "Vous êtes enregistré à la session",
		"unregistered_to_session": "Vous n'êtes plus enregistré à la session",
		"error_reg_unreg_to_session": "Impossible de vous enregistré/désenregistré de la session",
		"unavailable": "Aucune",
		"interactivity": {
			"details": "Détails",
			"ask-questions": "Questions",
			"ask-question": "Question",
			"externals_interactivity": "Interactivité externe",
			"survey": "Sondage",
			"quiz": "Quiz",
			"training": "Entrainement",
			"group-discussion": "Groupes de discussion",
			"feedback-session": "Sondage de la session"
		},
		"prev-session": "Session précédente",
		"next-session": "Session suivante",
		"session_limit_attendees": "La session atteint la limite de participants !",
		"registered_users_list": "Participants inscrits"
	},
	"snackbar": {
		"authentification_required": "Vous devez vous authentifier pour accéder à cet événement",
		"cannot_create_account": "Impossible de créer votre compte",
		"cannot_login": "Impossible de vous connecter",
		"cannot_perform_action": "Impossible de réaliser l'action",
		"error_occured": "Une erreur est survenue",
		"error_uploading_photo": "Erreur lors du téléchargement de la photo",
		"error_occured_on_verif_email": "Une erreur s'est produite lors de la verification de l'email",
		"event_shortcode_blank": "Le code est invalide, veuillez réessayer",
		"form_cannot_be_edited_after_submission": "Le formulaire ne peut pas être modifié après soumission",
		"no_form_attached": "Aucun formulaire n'a été détecté",
		"form_unavailable": "Le formulaire n'est pas disponible",
		"file_size_greater_than_limit": "La taille du fichier est supérieure à la limite autorisée ({{limit}} MB)",
		"no_module_found": "Aucun module trouvé",
		"no_homepage": "Cet événement n'a pas de page d'accueil de configuré",
		"no_network": "Pas de connexion internet",
		"no_shortcode": "Aucun shortcode",
		"no_user": "Pas de compte utilisateur",
		"not_authorized_event": "Vous n'êtes pas autorisé à accéder à cet événement",
		"register-form-already-connected-guard": "Vous êtes déjà inscrit à cet événement",
		"update_successfull": "Mise à jour avec succès",
		"file_updated_successfully": "Fichier téléchargé avec succès",
		"picture_updated_successfull": "Photo mise à jour avec succès",
		"failed_to_upload_media": "Le téléchargement de vos fichiers n'a pu aboutir",
		"no_password": "Veuillez remplir le champ mot de passe",
		"wrong_password": "Mauvais mot de passe",
		"success_delete_account": "Votre compte est en cours de suppression, cela sera effectif d'ici quelques minutes.",
		"limit_groups_exced": "Vous avez depassé la limite de groupes autorisés",
		"limit_avaible_place_exed": "Vous avez depassé la limite de places disponibles pour le champ \"{{field}}\"",
		"confirm_reset_password_error": "Une erreur s'est produite lors de la confirmation.",
		"invalid_reset_password_code": "Code d'action invalide ou expiré. Essayez de réinitialiser le mot de passe à nouveau.",
		"confirm_reset_password_success": "La réinitialisation du mot de passe a réussi. Vous pouvez à présent fermer cette page et vous connecter sur votre application en utilisant votre nouveau mot de passe."
	},
	"surveys": {
		"cannot_send_survey": "Impossible d'envoyer les réponses à l'enquête",
		"default_title": "Sondage",
		"default_title_survey": "Sondage",
		"answered": "Déjà répondu",
		"not_answered": "Non répondu",
		"answer_plc": "...",
		"force_complete_title_alert": "Attention",
		"force_complete_message_alert": "Vous devez remplir entièrement le formulaire",
		"survey_send": "Vos réponses à l'enquête ont été envoyées",
		"min_points_alert_msg": "Vous devez obtenir au moins <strong> {{ points }}</strong>  points pour activer cette enquête !"
	},
	"texts": {
		"see-filter-results": "Voir les résultats",
		"current-date": "Date du jour",
		"personalschedule": "Agenda Personnel",
		"speakers": "Orateurs",
		"addschedule": "Inscription à la session",
		"removeschedule": "Désinscription à la session",
		"event": "Événement",
		"events": "Événements",
		"survey": "Enquête de Satisfaction",
		"quiz": "Quiz",
		"training": "Quiz de Formation",
		"ics_link_google": "Synchroniser à votre agenda Google",
		"ics_download": "Synchroniser à votre agenda Apple, Outlook et autres outils",
		"ics_schedule_download": "En cliquant sur \"l'icône\", vous acceptez de synchroniser la totalité du programme dans l'agenda de votre smartphone.",
		"subscription": "Formulaire d'inscription",
		"profile": "Profil",
		"nation": "Pays",
		"state": "État/Région",
		"city": "Ville",
		"save": "Enregistrer",
		"site": "Site Internet",
		"pt_BR": "Portugais",
		"en_US": "Anglais",
		"es_ES": "Espagnol",
		"fr_FR": "Français",
		"de_DE": "Allemand",
		"sunday": "Dimanche",
		"monday": "Lundi",
		"tuesday": "Mardi",
		"wednesday": "Mercredi",
		"thursday": "Jeudi",
		"friday": "Vendredi",
		"saturday": "Samedi",
		"january": "Janvier",
		"february": "Février",
		"march": "Mars",
		"april": "Avril",
		"may": "Mai",
		"june": "Juin",
		"july": "Juillet",
		"august": "Août",
		"september": "Septembre",
		"october": "Octobre",
		"november": "Novembre",
		"december": "Décembre",
		"of": "de",
		"maps": "Plans",
		"btn_send": "Envoyer",
		"thankyou": "Merci!",
		"loading": "Chargement de l'application...",
		"and": "et",
		"terms_of_use": "Conditions d'utilisation",
		"privacy": "Politique de confidentialité",
		"cookie": "Politique de cookies",
		"legal_mentions": "Juridique",
		"terms_legal": "Juridique",
		"license": "Invent App - All Rights Reserved © 2021.",
		"search_plc": "Rechercher...",
		"description": "Description",
		"select": "Sélectionner",
		"select_image": "Sélectionner une image",
		"select_option": "Sélectionner une option",
		"image": "Image",
		"filter": "Filtrer",
		"all": "Tout",
		"about": "A propos",
		"start_chat": "Discuter",
		"picture": "Image",
		"name": "Nom Prénom",
		"points": "Points",
		"menu": "Menu",
		"need_login_to_use": "Vous devez vous connecter ou créer un compte afin d’accéder à ce contenu!",
		"timezone": "Fuseau horaire",
		"support": "Aide",
		"a_z": "Alphabétique A-Z",
		"z_a": "Alphabétique Z-A",
		"locations": "Localisations",
		"social_medias": "Réseaux sociaux",
		"position": "Pos.",
		"network_offline": "Aucune connexion internet!",
		"network_online": "La connexion internet a été rétablie!",
		"network_offline_detail": "Il n'y a pas de connexion internet. Certaines fonctionnalités peuvent ne pas être disponibles",
		"network_online_detail": "La Connexion est rétablie. Toutes les fonctionnalités sont à nouveau disponibles",
		"code": "Code",
		"read_qrcode": "Lire le QRCode",
		"join-visio": "Rejoindre la visio",
		"visio-launched": "Appel vidéo lancé",
		"visio-leaved": "Appel vidéo quitté",
		"start_visio": "Appel",
		"waiting_for_content": "En attente d’un contenu… ",
		"not_belonging_to_any_group": "Vous n'appartenez à aucun groupe",
		"no_result_title": "Aucun résultat !",
		"no_result_text": "Revenez plus tard !",
		"reactions": "Reactions",
		"zoom-visio-reload": "Votre session Zoom est terminée, la page va se recharger dans {{timer}}s.",
		"unsopported_navigator_msg1": "Vous utilisez une version de navigateur qui n'est pas compatible avec notre plateforme, passez à Google Chrome pour profiter de l'intégralité des fonctionnalités. Vous pouvez le télécharger en cliquant",
		"here": "ici",
		"previous-step": "Etape précédente",
		"x_selected_items": "{{count}} élément(s) sélectionné(s)"
	},
	"toasts": {
		"copied-to-clipboard": "Donnée copiée !",
		"download_image": "Téléchargement effectué.",
		"errors": {
			"create-basic-error": "Une erreur est survenue lors de la création",
			"error-open-document": "Impossible d'ouvrir le document",
			"cannot_send_message": "Impossible d'envoyer le message",
			"download_error": "Impossible de télécharger",
			"share_error": "Impossible de partager le fichier",
			"you_must_enable_permission": "Vous devez activer les permissions",
			"cannot-copy-to-clipboard": "Impossible de copier la donnée"
		}
	},
	"tooltips": {
		"click-for-open-document": "Cliquez pour ouvrir le document",
		"click-for-view-profil": "Cliquez pour voir le profil de l'utilisateur",
		"click-for-access-to-session": "Cliquez pour accéder à la session",
		"click-to-view-image": "Cliquez pour voir l'image",
		"click-to-replace-document": "Cliquez pour remplacer le document",
		"click-to-download-document": "Cliquez pour télécharger le document"
	},
	"treasure_hunts": {
		"already_scan": "Vous avez déjà scanné ce QRcode",
		"btn_qrcode": "FLASHER UN QR CODE",
		"error_scan": "Le QRcode est invalide. Voulez vous scanner à nouveau?",
		"gaming_default_txt": "Trouvez les QRcodes dispersés à travers l'événement afin de gagner des points",
		"go_gaming": "Jouons ?",
		"scan_read_error": "Erreur de lecture du QRcode, veuillez réessayer.",
		"success_scan": "Merci d'avoir scanné ce QRcode",
		"remaining_qrcodes": "QR CODES RESTANTS:",
		"title": "Gamification",
		"total_qrcodes": "NOMBRE TOTAL DE QRCODES:"
	},
	"visios": {
		"creating-visio": "Création de la visio...",
		"opening-visio": "Accès à la visio...",
		"visio-created": "Visio crée",
		"visio-created-error": "La visio n'a pas pu être crée",
		"visio-not-available": "La fonctionnalité de visio n'est pas disponible",
		"visio-open-error": "Impossible d'accéder à la visio"
	},
	"widgets": {
		"external_link_title": "Lien externe"
	},
	"word_cloud": {
		"add_word": "Ajouter un mot",
		"error-send-word": "Impossible d'envoyer le mot",
		"success-send-word": "Mot envoyé"
	},
	"countries": {
		"AF": "Afghanistan",
		"AL": "Albanie",
		"DZ": "Algérie",
		"AS": "Samoa américaines",
		"AD": "Andorre",
		"AO": "Angola",
		"AI": "Anguilla",
		"AQ": "Antarctique",
		"AG": "Antigua-et-Barbuda",
		"AR": "Argentine",
		"AM": "Arménie",
		"AW": "Aruba",
		"AU": "Australie",
		"AT": "Autriche",
		"AZ": "Azerbaïdjan",
		"BS": "Bahamas",
		"BH": "Bahreïn",
		"BD": "Bangladesh",
		"BB": "Barbade",
		"BY": "Biélorussie",
		"BE": "Belgique",
		"BZ": "Belize",
		"BJ": "Bénin",
		"BM": "Bermudes",
		"BT": "Bhoutan",
		"BO": "Bolivie",
		"BA": "Bosnie-Herzégovine",
		"BW": "Botswana",
		"BV": "Île Bouvet",
		"BR": "Brésil",
		"IO": "Territoire britannique de l'océan Indien",
		"BN": "Brunéi Darussalam",
		"BG": "Bulgarie",
		"BF": "Burkina Faso",
		"BI": "Burundi",
		"KH": "Cambodge",
		"CM": "Cameroun",
		"CA": "Canada",
		"CV": "Cap-Vert",
		"KY": "Îles Caïmans",
		"CF": "République centrafricaine",
		"TD": "Tchad",
		"CL": "Chili",
		"CN": "Chine",
		"CX": "Île Christmas",
		"CC": "Îles Cocos",
		"CO": "Colombie",
		"KM": "Comores",
		"CG": "Congo",
		"CD": "République démocratique du Congo",
		"CK": "Îles Cook",
		"CR": "Costa Rica",
		"HR": "Croatie",
		"CU": "Cuba",
		"CY": "Chypre",
		"CZ": "République tchèque",
		"DK": "Danemark",
		"DJ": "Djibouti",
		"DM": "Dominique",
		"DO": "République dominicaine",
		"EC": "Équateur",
		"EG": "Égypte",
		"SV": "Salvador",
		"GQ": "Guinée équatoriale",
		"ER": "Érythrée",
		"EE": "Estonie",
		"ET": "Éthiopie",
		"FK": "Îles Malouines",
		"FO": "Îles Féroé",
		"FJ": "Fidji",
		"FI": "Finlande",
		"FR": "France",
		"GF": "Guyane française",
		"PF": "Polynésie française",
		"TF": "Terres australes françaises",
		"GA": "Gabon",
		"GM": "Gambie",
		"GE": "Géorgie",
		"DE": "Allemagne",
		"GH": "Ghana",
		"GI": "Gibraltar",
		"GR": "Grèce",
		"GL": "Groenland",
		"GD": "Grenade",
		"GP": "Guadeloupe",
		"GU": "Guam",
		"GT": "Guatemala",
		"GN": "Guinée",
		"GW": "Guinée-Bissau",
		"GY": "Guyana",
		"HT": "Haïti",
		"HM": "Îles Heard-et-MacDonald",
		"VA": "Saint-Siège (État de la Cité du Vatican)",
		"HN": "Honduras",
		"HK": "Hong Kong",
		"HU": "Hongrie",
		"IS": "Islande",
		"IN": "Inde",
		"ID": "Indonésie",
		"IR": "Iran",
		"IQ": "Irak",
		"IE": "Irlande",
		"IL": "Israël",
		"IT": "Italie",
		"JM": "Jamaïque",
		"JP": "Japon",
		"JO": "Jordanie",
		"KZ": "Kazakhstan",
		"KE": "Kenya",
		"KI": "Kiribati",
		"KP": "Corée du Nord",
		"KR": "Corée du Sud",
		"KW": "Koweït",
		"KG": "Kirghizistan",
		"LA": "Laos",
		"LV": "Lettonie",
		"LB": "Liban",
		"LS": "Lesotho",
		"LR": "Libéria",
		"LY": "Libye",
		"LI": "Liechtenstein",
		"LT": "Lituanie",
		"LU": "Luxembourg",
		"MO": "Macao",
		"MK": "Macédoine du Nord",
		"MG": "Madagascar",
		"MW": "Malawi",
		"MY": "Malaisie",
		"MV": "Maldives",
		"ML": "Mali",
		"MT": "Malte",
		"MH": "Îles Marshall",
		"MQ": "Martinique",
		"MR": "Mauritanie",
		"MU": "Maurice",
		"YT": "Mayotte",
		"MX": "Mexique",
		"FM": "Micronésie",
		"MD": "Moldavie",
		"MC": "Monaco",
		"MN": "Mongolie",
		"MS": "Montserrat",
		"MA": "Maroc",
		"MZ": "Mozambique",
		"MM": "Myanmar",
		"NA": "Namibie",
		"NR": "Nauru",
		"NP": "Népal",
		"NL": "Pays-Bas",
		"AN": "Antilles néerlandaises",
		"NC": "Nouvelle-Calédonie",
		"NZ": "Nouvelle-Zélande",
		"NI": "Nicaragua",
		"NE": "Niger",
		"NG": "Nigeria",
		"NU": "Niue",
		"NF": "Île Norfolk",
		"MP": "Îles Mariannes du Nord",
		"NO": "Norvège",
		"OM": "Oman",
		"PK": "Pakistan",
		"PW": "Palaos",
		"PS": "Palestine",
		"PA": "Panama",
		"PG": "Papouasie-Nouvelle-Guinée",
		"PY": "Paraguay",
		"PE": "Pérou",
		"PH": "Philippines",
		"PN": "Îles Pitcairn",
		"PL": "Pologne",
		"PT": "Portugal",
		"PR": "Porto Rico",
		"QA": "Qatar",
		"RE": "Réunion",
		"RO": "Roumanie",
		"RU": "Russie",
		"RW": "Rwanda",
		"SH": "Sainte-Hélène",
		"KN": "Saint-Christophe-et-Niévès",
		"LC": "Sainte-Lucie",
		"PM": "Saint-Pierre-et-Miquelon",
		"VC": "Saint-Vincent-et-les-Grenadines",
		"WS": "Samoa",
		"SM": "Saint-Marin",
		"ST": "Sao Tomé-et-Principe",
		"SA": "Arabie saoudite",
		"SN": "Sénégal",
		"CS": "Serbie-et-Monténégro",
		"SC": "Seychelles",
		"SL": "Sierra Leone",
		"SG": "Singapour",
		"SK": "Slovaquie",
		"SI": "Slovénie",
		"SB": "Îles Salomon",
		"SO": "Somalie",
		"ZA": "Afrique du Sud",
		"GS": "Géorgie du Sud et îles Sandwich du Sud",
		"ES": "Espagne",
		"LK": "Sri Lanka",
		"SD": "Soudan",
		"SR": "Suriname",
		"SJ": "Svalbard et Jan Mayen",
		"SZ": "Swaziland",
		"SE": "Suède",
		"CH": "Suisse",
		"SY": "Syrie",
		"TW": "Taïwan",
		"TJ": "Tadjikistan",
		"TZ": "Tanzanie",
		"TH": "Thaïlande",
		"TL": "Timor oriental",
		"TG": "Togo",
		"TK": "Tokelau",
		"TO": "Tonga",
		"TT": "Trinité-et-Tobago",
		"TN": "Tunisie",
		"TR": "Turquie",
		"TM": "Turkménistan",
		"TC": "Îles Turques-et-Caïques",
		"TV": "Tuvalu",
		"UG": "Ouganda",
		"UA": "Ukraine",
		"AE": "Émirats arabes unis",
		"GB": "Royaume-Uni",
		"US": "États-Unis",
		"UM": "Îles mineures éloignées des États-Unis",
		"UY": "Uruguay",
		"UZ": "Ouzbékistan",
		"VU": "Vanuatu",
		"VE": "Venezuela",
		"VN": "Vietnam",
		"VG": "Îles Vierges britanniques",
		"VI": "Îles Vierges des États-Unis",
		"WF": "Wallis-et-Futuna",
		"EH": "Sahara occidental",
		"YE": "Yémen",
		"ZM": "Zambie",
		"ZW": "Zimbabwe",
		"GG": "Guernesey",
		"IM": "Île de Man",
		"JE": "Jersey",
		"ME": "Monténégro",
		"BL": "Saint-Barthélemy",
		"MF": "Saint-Martin (partie française)",
		"RS": "Serbie",
		"SS": "Soudan du Sud",
		"AX": "Îles Åland",
		"CI": "Côte d'Ivoire"
	}
}
