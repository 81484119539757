import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderState, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { TypeModule } from "src/app/shared/enums/type-module";
import { IEvent, IEventUser, IMessage, IModule } from "src/app/shared/interfaces";
import { IChat } from "src/app/shared/interfaces/chats.interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getAccessiblesChatsByType, getMessages } from "src/app/shared/selectors/chats.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { EventUsersService, UtilityService } from "src/app/shared/services";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-chats",
    templateUrl: "./chats.component.html",
    styleUrls: ["./chats.component.scss"],
    standalone: false
})
export class ChatsComponent implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];
	langSubscription: Subscription;

	@Input() componentMode: boolean;
	@Output() changeView: EventEmitter<{ segmentType: string; type: string; uid: string }> = new EventEmitter();

	@Input() type: string = "chats";

	@Input() eventId: string;
	event: IEvent;
	@Input() moduleId: string;
	module: IModule;
	@Input() sessionId: string;
	eventUser: IEventUser;
	chats: IChat[] = [];
	filteredChats: IChat[] = [];
	messages: IMessage[] = [];
	menuBadge: number = 0;
	chatBadge: number = 0;
	q: string;
	searchOpen: boolean = false;
	currentLanguage: string = environment.platform.defaultLanguage;
	frame: any;

	isMobile: boolean = false;

	constructor(
		private platform: Platform,
		private SAnalytics: AnalyticsService,
		private SEventUsers: EventUsersService,
		private STranslate: TranslateService,
		private store: Store,
		private navCtrl: NavController,
		private SUtility: UtilityService
	) {
		this.q = "";
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) ||
			this.platform.is("mobileweb") ||
			window.innerWidth < 768
				? true
				: false;
	}

	ngOnInit() {
		this.currentLanguage = this.STranslate.currentLang;
		this.langSubscription = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});
		if (this.componentMode) {
			this.initDatas();
		}
	}

	ionViewWillEnter() {
		if (!this.componentMode) {
			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe((url) => {
					this.type = url.includes("chats-groups") ? "groups" : "chats";
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.moduleId = params.moduleId;
							this.subscriptions.forEach((sub) => sub.unsubscribe());

							// Analytics
							this.SAnalytics.moduleAccess(
								this.eventId,
								this.moduleId,
								TypeTracking.ACCESS_TO_CHATS_MODULE
							);

							this.initDatas();
						});
				});
		}
	}

	ionViewWillLeave() {
		if (!this.componentMode) {
			this.store.dispatch(ResetHeaderState(null));
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ngOnDestroy() {
		this.subscriptions.concat([this.langSubscription]).forEach((sub) => sub.unsubscribe());
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		this.getChats();
		this.getMessages();
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;
				}
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
				}
				if (this.module && !this.componentMode) {
					this.store.dispatch(
						GetHeaderState({
							payload: {
								item: null,
								module: this.module,
								title: this.module.name,
								type: TypeModule.CHAT
							}
						})
					);
					// this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
					// this.store.dispatch(GetHeaderType({ payload: TypeModule.CHAT }));
				}
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;
				}
			})
		);
	}

	/**
	 * Getting all chats
	 */
	getChats() {
		this.subscriptions.push(
			this.store
				.select(getAccessiblesChatsByType(this.componentMode ? 1 : 0, this.sessionId))
				.subscribe((chats) => {
					if (!_.isEqual(this.chats, chats)) {
						this.chats = chats;
					}
				})
		);
	}

	/**
	 * Get all messages
	 */
	getMessages() {
		this.subscriptions.push(
			this.store.select(getMessages).subscribe((messages) => {
				if (!_.isEqual(this.messages, messages)) {
					this.messages = messages;
				}
			})
		);
	}

	/**
	 * Check if there is discussion groups
	 * @returns
	 */
	checkDiscussionGroup() {
		return this.chats.filter((chat) => chat.type === 1).length > 0 ? true : false;
	}

	getChatImage(chat: IChat) {
		if (chat.type === 0) {
			return chat.interlocutor && chat.interlocutor.photoUrl ? chat.interlocutor.photoUrl : "";
		} else if (chat.type === 1) {
			return chat.options && chat.options.groupImage ? chat.options.groupImage : "/assets/icon/GroupeChat.svg";
		}
	}

	trackByChat(index: number, item: IChat) {
		return item.uid;
	}

	/**
	 * Get chats by type
	 * @returns
	 */
	getChatsByType() {
		const type = this.type === "chats" ? 0 : 1;
		if (type === 0) {
			return this.chats
				.filter((chat) => chat.type === type)
				.sort((a, b) => (a.lastUpdated < b.lastUpdated ? 1 : a.lastUpdated > b.lastUpdated ? -1 : 0));
		} else {
			return this.chats
				.filter((chat) => chat.type === type)
				.sort((a, b) => {
					if (this.module.options.groupsChatsOrder) {
						switch (this.module.options.groupsChatsOrder) {
							case "recent":
								return (
									DateTime.fromISO(b.creationDate).valueOf() -
									DateTime.fromISO(a.creationDate).valueOf()
								);
							case "oldest":
								return (
									DateTime.fromISO(a.creationDate).valueOf() -
									DateTime.fromISO(b.creationDate).valueOf()
								);
							case "asc":
								return a.options.name.toLowerCase() < b.options.name.toLowerCase()
									? -1
									: a.options.name.toLowerCase() > b.options.name.toLowerCase()
									? 1
									: 0;
							case "desc":
								return a.options.name.toLowerCase() < b.options.name.toLowerCase()
									? 1
									: a.options.name.toLowerCase() > b.options.name.toLowerCase()
									? -1
									: 0;
						}
					} else {
						return a.lastUpdated < b.lastUpdated ? 1 : a.lastUpdated > b.lastUpdated ? -1 : 0;
					}
				});
		}
	}

	/**
	 * Get notifications for chats
	 * @param chat
	 * @returns
	 */
	getNotificationsForChat(chat: IChat) {
		if (this.eventUser && chat) {
			const lastAccess = chat.lastAccess[this.eventUser.uid];
			if (!lastAccess && chat.lastMessageId) {
				return true;
			}
			return this.messages.filter(
				(message) =>
					message.chatId === chat.uid &&
					message.sendAt > lastAccess &&
					message.sender.uid !== this.eventUser.uid
			).length > 0
				? true
				: false;
		} else {
			return false;
		}
	}

	/**
	 * Get last message
	 * @param chat
	 * @returns
	 */
	getLastMessageOfChat(chat: IChat) {
		const lastMessage = this.messages.find(
			(message) => chat.lastMessageId === message.uid && chat.uid === message.chatId
		);
		return lastMessage;
	}

	/**
	 * Get chat url
	 * @param chat
	 * @returns
	 */
	goToChat(chat: IChat) {
		if (!this.componentMode) {
			this.navCtrl.navigateForward(`/event/${this.eventId}/chats/${this.moduleId}/chat/${chat.uid}`);
		} else {
			// Switch to chat view on component mode
			this.changeView.emit({ segmentType: "chats", type: "chat", uid: chat.uid });
		}
	}

	/**
	 * Get date
	 * @param date
	 * @returns
	 */
	getDate(date: string) {
		return this.SUtility.getPartOfDate(this.event, this.eventUser, date, "full");
	}

	/**
	 * Searchbar
	 * @param ev
	 */
	searchBar(ev) {
		this.q = ev.target.value.toLowerCase();
	}

	/**
	 * Check if user allow notifs for specific chat
	 * @param chat
	 * @returns
	 */
	checkAllowNotifsUserChat(chat: IChat) {
		if (
			chat &&
			chat.allowNotifs &&
			(!this.eventUser.options ||
				!this.eventUser.options.chatsNotifs ||
				this.eventUser.options.chatsNotifs[chat.uid] === undefined ||
				this.eventUser.options.chatsNotifs[chat.uid])
		) {
			return true;
		} else {
			return false;
		}
	}

	async changeAllowNotifForUser(chat: IChat) {
		if (!this.eventUser.options || !this.eventUser.options.chatsNotifs) {
			this.eventUser.options.chatsNotifs = {};
		}
		if (this.eventUser.options.chatsNotifs[chat.uid] === undefined) {
			this.eventUser.options.chatsNotifs[chat.uid] = false;
		} else {
			this.eventUser.options.chatsNotifs[chat.uid] = !this.eventUser.options.chatsNotifs[chat.uid];
		}
		this.eventUser.options.chatsNotifs[chat.uid] = !this.eventUser.options.chatsNotifs[chat.uid];
		await this.SEventUsers.updatePartOfEventUser(this.eventId, this.eventUser.moduleId, this.eventUser.uid, {
			options: {
				chatsNotifs: this.eventUser.options.chatsNotifs
			}
		});
	}
}
