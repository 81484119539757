import { DOCUMENT } from "@angular/common";
import {
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Platform } from "@ionic/angular";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { IEvent, IEventUser, ISchedule, IVisio } from "src/app/shared/interfaces";
import { AnalyticsService, VisiosService } from "src/app/shared/services";
import { environment } from "src/environments/environment";
// import { IZoom } from "src/app/shared/interfaces/integrations.interface";
// import { ZoomService } from "src/app/shared/services/zoom/zoom.service";

@Component({
    selector: "app-visio",
    templateUrl: "./visio.component.html",
    styleUrls: ["./visio.component.scss"],
    standalone: false
})
export class VisioComponent implements OnInit, OnDestroy, OnChanges {
	subscriptions: Subscription[] = [];

	@Input() event: IEvent;
	@Input() eventUser: IEventUser;
	@Input() session: ISchedule;
	@Input() visio: IVisio;
	@Input() joined: boolean = false;
	// @Input() zoomIntegration: IZoom;
	@Input() type: string = "chat";
	@Input() showJoinBtn: boolean = true;
	@Output() closeVisioEvent: EventEmitter<boolean> = new EventEmitter();

	// @ViewChild("zoomContainer", null)
	// zoomContainer: ElementRef;

	@ViewChild("backgroundIframe") backgroundIframe: ElementRef;

	@ViewChild("container") container: ElementRef;

	urlIframe: SafeResourceUrl;
	savedUrlIframe: string = "";

	fullscreenMode: boolean = false;

	@Input() width: string = "100%";
	@Input() height: string = "600px";
	miniPlayer: boolean = false;
	moveMiniPlayer: boolean = false;
	moveSubscription: Subscription;
	noTransform: boolean = false;

	isMobile: boolean = false;

	constructor(
		@Inject(DOCUMENT) private document: any,
		private platform: Platform,
		private SVisios: VisiosService,
		private SAnalytics: AnalyticsService,
		private sanitizer: DomSanitizer // private SIntegration: IntegrationsService, // public zoomService: ZoomService
	) {}

	ngOnInit() {
		this.isMobile = this.platform.is("mobile") && window.innerWidth < 768 ? true : false;

		if (this.visio && this.visio.type !== "zoom") {
			this.buildUrl();
		}
	}

	/**
	 * On changes
	 */
	ngOnChanges() {
		if (this.visio && this.visio.type !== "zoom") {
			this.buildUrl();
		}
	}

	ngOnDestroy() {
		// this.zoomService.shutdownZoom();
		this.subscriptions.forEach((s) => s.unsubscribe());
	}

	/**
	 * Build url for iframe
	 */
	buildUrl() {
		const baseUrl =
			this.eventUser &&
			this.visio.options.hostGroups.length > 0 &&
			this.eventUser.groups.some((gr) => this.visio.options.hostGroups.includes(gr))
				? this.visio.options.hostRoomUrl
				: this.visio.options.viewerMode && this.visio.options.viewerUrl
				? this.visio.options.viewerUrl
				: this.visio.url;
		const newUrlIframe =
			baseUrl +
			(baseUrl.includes("?") ? "&" : "?") +
			(this.visio.options.allowChat ? "&chat=on" : "&chat=off") +
			(this.visio.options.allowScreenshare ? "&screenshare=on" : "&screenshare=off") +
			(this.visio.options.allowLeaveBtn ? "&leaveButton=on" : "&leaveButton=off") +
			(this.event && this.event.language && this.event.language !== "DeDE"
				? "&lang=" + this.event.language.slice(0, 2).toLowerCase()
				: "&lang=" + environment.platform.defaultLanguage.slice(0, 2).toLowerCase()) +
			"&recording=on" +
			"&personality=off" +
			"&precallReview=on" +
			"&breakout=on" +
			"&timer=on";
		if (!_.isEqual(this.savedUrlIframe, newUrlIframe)) {
			this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(newUrlIframe);
			this.savedUrlIframe = newUrlIframe;
		}
	}

	/**
	 * Full screen mode visio
	 */
	fullScreenVisio() {
		const elem = document.getElementById("backgroundIframe") as any;
		if (this.fullscreenMode) {
			if (this.document.exitFullscreen) {
				this.document.exitFullscreen();
			} else if (this.document.mozCancelFullScreen) {
				/* Firefox */
				this.document.mozCancelFullScreen();
			} else if (this.document.webkitExitFullscreen) {
				/* Chrome, Safari and Opera */
				this.document.webkitExitFullscreen();
			} else if (this.document.msExitFullscreen) {
				/* IE/Edge */
				this.document.msExitFullscreen();
			}
			this.height = "100%";
		} else {
			this.deactivateMiniPlayer();

			const methodToBeInvoked =
				elem.requestFullscreen ||
				elem.webkitRequestFullScreen ||
				elem["mozRequestFullscreen"] ||
				elem["msRequestFullscreen"];
			if (methodToBeInvoked) {
				methodToBeInvoked.call(elem);
			}
			this.height = "100%";
		}
		this.fullscreenMode = !this.fullscreenMode;
	}

	/**
	 * Leave visio
	 */
	leaveVisio() {
		if (this.fullscreenMode) {
			this.fullScreenVisio();
		}

		if (this.miniPlayer) {
			this.deactivateMiniPlayer();
		}

		this.closeVisioEvent.emit(true);
		this.joined = false;
	}

	/**
	 * Join visio
	 */
	async openVisio() {
		// Tracking access to visio
		this.SAnalytics.visioAccess(this.event.uid, this.eventUser, this.session);

		if (
			!this.session ||
			(this.session.visio && this.session.visio.type && this.session.visio.type !== "zoom" && this.isMobile)
		) {
			// const fullUrl =
			// 	this.urlVisio +
			// 	(this.urlVisio.includes("?") ? "&" : "?") +
			// 	"embed&iframeSource=b3app" +
			// 	(this.allowChat ? "&chat=on" : "&chat=off") +
			// 	(this.allowScreenshare ? "&screenshare=on" : "&screenshare=off") +
			// 	"&leaveButton=" +
			// 	(this.platform.is("ios") ? "on" : "off") +
			//     "&recording=on&skipMediaPermissionPrompt";

			const fullUrl =
				this.visio.url +
				(this.visio.url.includes("?") ? "&" : "?") +
				(this.visio.options.allowChat ? "&chat=on" : "&chat=off") +
				(this.visio.options.allowScreenshare ? "&screenshare=on" : "&screenshare=off") +
				(this.visio.options.allowLeaveBtn ? "&leaveButton=on" : "&leaveButton=off") +
				(this.event && this.event.language && this.event.language !== "DeDE"
					? "&lang=" + this.event.language.slice(0, 2).toLowerCase()
					: "&lang=" + environment.platform.defaultLanguage.slice(0, 2).toLowerCase()) +
				"&recording=on" +
				"&personality=off" +
				"&precallReview=on" +
				"&breakout=on" +
				"&timer=on" +
				"&skipMediaPermissionPrompt";

			await this.SVisios.openInAppBrowserVisio(fullUrl);
		} else if (
			this.session.visio &&
			this.session.visio.type &&
			this.session.visio.type === "zoom" &&
			this.isMobile
		) {
			// Zoom
			// let zoomUrl = this.userType == 1 ? this.session.visio.hostRoomUrl : this.session.visio.roomUrl;
			// if (this.SVisios.visioAvailableOnSF() && this.SVisios.isAndroid()) {
			// 	this.subscriptions.push(this.SVisios.openSFVisio(zoomUrl).subscribe());
			// } else {
			// 	this.subscriptions.push(this.SVisios.openInAppBrowserVisio(zoomUrl).subscribe());
			// }
		}
	}

	/**
	 * Activate or deactivate miniplayer
	 */
	miniPlayerActivation() {
		this.miniPlayer ? this.deactivateMiniPlayer() : this.activateMiniPlayer();
	}

	/**
	 * ActivateMiniPlayer
	 */
	activateMiniPlayer() {
		// Activate mini player
		this.miniPlayer = true;
		this.width = "350px";
		this.height = "240px";
		this.noTransform = false;
	}

	/**
	 * Desactivate miniplayer
	 */
	deactivateMiniPlayer() {
		// Deactivate mini player
		this.miniPlayer = false;
		this.width = "100%";
		this.height = "100%";
		this.noTransform = true;
	}

	/**
	 * Move mini player
	 * @param move
	 * @param evt
	 */
	moveMiniPlayerActivation(move, evt) {
		evt.preventDefault();
		if (move) {
			this.moveMiniPlayer = true;
		} else {
			this.moveMiniPlayer = false;
		}
	}

	/**
	 * Join visio on web
	 */
	joinedVisio() {
		if (this.session && this.session.visio && this.session.visio.type && this.session.visio.type === "zoom") {
			// if (this.plat === "mobile") {
			// 	let zoomUrl = this.userType == 1 ? this.session.visio.hostRoomUrl : this.session.visio.roomUrl;
			// 	window.open(zoomUrl, "_system");
			// } else {
			// 	const data: any = await this.SIntegration.generateSignature(
			// 		this.session.visio.meetingId,
			// 		this.userType,
			// 		this.zoomIntegration
			// 	);
			// 	const meetingConfig = {
			// 		signature: data.signature,
			// 		meetingNumber: this.session.visio.meetingId,
			// 		userName: this.global.user.name,
			// 		apiKey: this.zoomIntegration.apiKey,
			// 		userEmail: this.global.user.email,
			// 		passWord: this.session.visio.password,
			// 		success: (success) => {
			// 			//
			// 		},
			// 		error: (error) => {
			// 			this.leaveVisio();
			// 		}
			// 	};
			// 	await this.zoomService.startAndJoinMeeting(
			// 		this.container,
			// 		this.backgroundIframe,
			// 		this.zoomContainer,
			// 		this.subscriptions,
			// 		meetingConfig
			// 	);
			// }
		} else {
			this.joined = true;
			// Tracking access to visio
			this.SAnalytics.visioAccess(this.event.uid, this.eventUser, this.session);
		}
	}
}
