/* eslint-disable @angular-eslint/component-selector */
import { Component, Input } from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { DateTime } from "luxon";
import { TypeCustomFields } from "src/app/shared/enums/type-custom-fields";
import { IRegisterFormItem } from "src/app/shared/interfaces/register-form.interfaces";
import { FormsService, LuxonService } from "src/app/shared/services";

@Component({
    selector: "form-field-errors",
    templateUrl: "./form-field-errors.component.html",
    styleUrls: ["./form-field-errors.component.scss"],
    standalone: false
})
export class FormFieldErrorsComponent {
	@Input() field: IRegisterFormItem;
	@Input() form: UntypedFormGroup | AbstractControl;
	@Input() language: string;
	@Input() fieldType?: "singleLanguage" | "multiLanguage";

	typeCustomFields = TypeCustomFields;
	dateTime = DateTime;

	constructor(public SForm: FormsService, public SLuxon: LuxonService) {}
}
