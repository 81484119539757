import { Component, OnInit } from "@angular/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { ModalController, NavParams, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";
import { FileService, StorageService, UtilityService } from "src/app/shared/services";

@Component({
    selector: "app-image-viewer",
    templateUrl: "./image-viewer.component.html",
    styleUrls: ["./image-viewer.component.scss"],
    standalone: false
})
export class ImageViewerComponent implements OnInit {
	isMobile: boolean;

	url: string;
	type: string = "";
	name: string = "";
	allowDownload: boolean = false;
	allowSharing: boolean = false;
	downloading: boolean = false;
	zoomed: boolean = false;

	constructor(
		private navParams: NavParams,
		private modalCtrl: ModalController,
		private SFile: FileService,
		private SStorage: StorageService,
		private SUtility: UtilityService,
		private STranslate: TranslateService,
		public platform: Platform
	) {
		this.isMobile = this.platform.is("ios") || this.platform.is("android");
	}

	ngOnInit() {
		this.isMobile =
			this.platform.is("ios") ||
			this.platform.is("android") ||
			this.platform.is("mobileweb") ||
			window.innerWidth < 768
				? true
				: false;

		this.url = this.navParams.get("url");
		this.allowDownload = this.navParams.get("allowDownload");
		this.allowSharing = this.navParams.get("allowSharing");
		this.type = this.navParams.get("type") || "jpg";
		this.name = this.navParams.get("name") || "";
	}

	zoom(zoomType: boolean) {
		this.zoomed = zoomType;
	}

	/**
	 * Download file
	 * @returns
	 */
	async download() {
		if (this.downloading) {
			return;
		}

		try {
			this.downloading = true;

			await firstValueFrom(this.SFile.downloadFileFromUrl(this.url, this.name));
			this.SUtility.presentToast(this.STranslate.instant("toasts.download_image"), 2000, "bottom", "success");
			this.downloading = false;
		} catch (e) {
			this.SUtility.presentToast(
				this.STranslate.instant("toasts.errors.download_error"),
				2000,
				"bottom",
				"error"
			);
			this.downloading = false;
		}
	}

	/**
	 * Close modal
	 */
	close() {
		this.modalCtrl.dismiss();
	}

	/**
	 * Share plugin
	 */
	share() {
		this.SStorage.getImageFileFromUrl(this.url)
			.then((blob) => {
				if (this.platform.is("mobile") && window.innerWidth < 768 && blob) {
					const reader = new FileReader();
					reader.readAsDataURL(blob);
					reader.onloadend = async () => {
						const fileWriteResult = await Filesystem.writeFile({
							path: "./" + this.name,
							data: reader.result as string,
							directory: Directory.Cache,
							recursive: true
						});
						if (fileWriteResult && fileWriteResult.uri) this.SUtility.shareImage(fileWriteResult.uri);
					};
				} else {
					this.SUtility.presentToast(
						this.STranslate.instant("toasts.errors.share_error"),
						2000,
						"bottom",
						"error"
					);
				}
			})
			.catch(() => {
				this.SUtility.presentToast(
					this.STranslate.instant("toasts.errors.share_error"),
					2000,
					"bottom",
					"error"
				);
			});
	}
}
