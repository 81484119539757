import { Injectable } from "@angular/core";
import { QueryConstraint, orderBy, where } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import * as _ from "lodash-es";
import { Observable, Subject, Subscription, forkJoin, from, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { GetAllCheckins } from "../actions/checkins.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { ICheckin, ICheckinChecked } from "../interfaces/checkin.interfaces";
import { checkSameEvent } from "../selectors/checkins.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";
import { MongodbService } from "./mongodb.service";

@Injectable({
	providedIn: "root"
})
export class CheckinsService {
	checkinsSub: Subscription;
	eventUsersSub: Subscription;

	scanQrSubject: Subject<boolean> = new Subject();

	constructor(private SFirestore: FirestoreService, private SMongo: MongodbService, private store: Store) {}

	/**
	 * Get all checkins for event
	 * @param eventId
	 * @returns
	 */
	getCheckinsOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent(eventId))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.checkinsSub && !this.checkinsSub.closed) {
					return;
				} else if (!sameEvent && this.checkinsSub && !this.checkinsSub.closed) {
					this.checkinsSub.unsubscribe();
				}

				this.checkinsSub = this.SFirestore.collectionGroupValueChangesDocuments("checkins", [
					where("eventId", "==", eventId),
					where("visibility", "==", true)
				]).subscribe((checkins: ICheckin[]) => {
					this.store.dispatch(GetAllCheckins({ payload: checkins, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initCheckins"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initCheckins", payload: true })
								);
							}
						});
				});
			});
	}

	unsubscribeAll() {
		if (this.checkinsSub) {
			this.checkinsSub.unsubscribe();
		}
	}

	/**
	 * Get checkin checked
	 * @param eventId
	 * @param moduleId
	 * @param checkinId
	 * @returns
	 */
	getCheckinCheckeds(eventId: string, moduleId: string, checkinId: string) {
		return this.SFirestore.valueChangesDocuments(
			`events/${eventId}/modules/${moduleId}/checkins/${checkinId}/checkin-checked`,
			[]
		);
	}

	/**
	 * Get checkin checked for users
	 * @param eventId
	 * @param checkinId
	 * @param usersIds
	 * @returns
	 */
	getCheckinCheckedForUsers(eventId: string, checkinId: string, usersIds: string[]) {
		if (usersIds.length === 0) {
			return of([]);
		}
		const allIds = _.chunk(usersIds, 10);
		const obsArray: Observable<ICheckinChecked[]>[] = [];
		const queryConstraints: QueryConstraint[] = [
			where("eventId", "==", eventId),
			where("checkinId", "==", checkinId)
		];

		allIds.forEach((ids) => {
			obsArray.push(
				from(
					this.SFirestore.getDocumentsCollectionGroup(
						`checkin-checked`,
						queryConstraints.concat([where("userId", "in", ids)])
					)
				).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as ICheckinChecked)))
			);
		});
		return forkJoin(obsArray).pipe(
			switchMap((results) => {
				const checkinCheckeds: ICheckinChecked[] = _.uniqBy(_.flatten(results), "userId");
				return of(checkinCheckeds);
			})
		);
	}

	/**
	 * Get checkin checked for users
	 * @param eventId
	 * @param checkinId
	 * @param usersIds
	 * @returns
	 */
	getCheckinCheckedForUser(eventId: string, checkinId: string, userId: string) {
		const queryConstraints: QueryConstraint[] = [
			where("eventId", "==", eventId),
			where("checkinId", "==", checkinId),
			where("userId", "==", userId)
		];

		return from(this.SFirestore.getDocumentsCollectionGroup(`checkin-checked`, queryConstraints)).pipe(
			map((snapshot) => snapshot.docs.map((doc) => doc.data() as ICheckinChecked))
		);
	}

	/**
	 * Get checkin checkeds for event user
	 * @param eventId
	 * @param eventUserId
	 * @returns
	 */
	getCheckedsForEventUser(eventId: string, eventUserId: string) {
		return this.SFirestore.collectionGroupValueChangesDocuments("checkin-checked", [
			where("eventId", "==", eventId),
			where("userId", "==", eventUserId),
			// where("checkinStatus", "==", true),
			orderBy("creationDate", "desc")
		]);
	}

	/**
	 * Get checkin
	 * @param eventId
	 * @param moduleId
	 * @param checkinId
	 * @returns
	 */
	getCheckin(eventId: string, moduleId: string, checkinId: string) {
		return this.SFirestore.valueChangesDocument(`events/${eventId}/modules/${moduleId}/checkins/${checkinId}`);
	}

	/**
	 * Create checkin checked
	 * @param eventId
	 * @param moduleId
	 * @param checkinId
	 * @param checked
	 * @returns
	 */
	createCheckinChecked(eventId: string, moduleId: string, checkinId: string, checked: ICheckinChecked) {
		checked.uid = checked.uid
			? checked.uid
			: this.SFirestore.createId(`events/${eventId}/modules/${moduleId}/checkins/${checkinId}/checkin-checked`);
		return Promise.all([
			this.SMongo.manageDatasMongodb({
				type: "insert-one",
				collection: "checkin-checked",
				datas: [checked]
			}),
			this.SFirestore.setDocument(
				`events/${eventId}/modules/${moduleId}/checkins/${checkinId}/checkin-checked/${checked.uid}`,
				checked
			)
		]);
	}

	/**
	 * Update checkin checked
	 * @param eventId
	 * @param moduleId
	 * @param checkinId
	 * @param checked
	 * @returns
	 */
	updateCheckinChecked(eventId: string, moduleId: string, checkinId: string, checked: ICheckinChecked) {
		return Promise.all([
			this.SMongo.manageDatasMongodb({
				type: "update-one",
				collection: "checkin-checked",
				query: {
					eventId: { $eq: eventId },
					moduleId: { $eq: moduleId },
					checkinId: { $eq: checkinId },
					uid: { $eq: checked.uid }
				},
				datas: [
					this.SMongo.transformObjectToUpdateItem(
						_.omit(checked, ["_id", "uid", "moduleId", "checkinId", "eventId", "userId"])
					)
				]
			}),
			this.SFirestore.updateDocument(
				`events/${eventId}/modules/${moduleId}/checkins/${checkinId}/checkin-checked/${checked.uid}`,
				_.omit(checked, ["eventId", "moduleId", "checkinId", "uid", "userId", "validationType", "_id"])
			)
		]);
	}
}
