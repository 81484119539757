import { Component, OnDestroy } from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { IEvent, IEventUser, IModule, IRanking } from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { getRankingsOfModule } from "src/app/shared/selectors/rankings.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AnalyticsService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-rankings-list",
    templateUrl: "./rankings-list.component.html",
    styleUrls: ["./rankings-list.component.scss"],
    standalone: false
})
export class RankingsListComponent implements OnDestroy {
	necessaryDatasSub: Subscription;
	rankingsSub: Subscription;
	languageSub: Subscription;

	loader: boolean = true;

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	rankings: IRanking[] = [];
	eventUser: IEventUser;
	currentLanguage: string = environment.platform.defaultLanguage;
	isMobile: boolean = false;

	constructor(
		private platform: Platform,
		private SAnalytics: AnalyticsService,
		private STranslate: TranslateService,
		private navCtrl: NavController,
		private store: Store
	) {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) || this.platform.is("mobileweb") ? true : false;
	}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;
		this.languageSub = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;

						// Analytics
						this.SAnalytics.moduleAccess(
							this.eventId,
							this.moduleId,
							TypeTracking.ACCESS_TO_RANKING_MODULE
						);

						this.getNecessaryDatas();
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		[this.necessaryDatasSub, this.rankingsSub, this.languageSub].forEach((sub) => {
			if (sub && !sub.closed) {
				sub.unsubscribe();
			}
		});
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		[this.necessaryDatasSub, this.rankingsSub, this.languageSub].forEach((sub) => {
			if (sub && !sub.closed) {
				sub.unsubscribe();
			}
		});
	}

	/**
	 * Getting necessary datas
	 */
	getNecessaryDatas() {
		if (this.necessaryDatasSub && !this.necessaryDatasSub.closed) {
			this.necessaryDatasSub.unsubscribe();
		}

		this.necessaryDatasSub = combineLatest([
			this.store.select(getCurrentEvent),
			this.store.select(getSpecificModule(this.moduleId)),
			this.store.select(getCurrentEventUser)
		]).subscribe((results) => {
			this.event = results[0];
			this.module = results[1];
			this.eventUser = results[2];

			if (this.module) {
				this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
				this.getRankings();
			}
		});
	}

	/**
	 * Getting all rankings of module
	 */
	getRankings() {
		if (this.rankingsSub && !this.rankingsSub.closed) {
			this.rankingsSub.unsubscribe();
		}
		this.rankingsSub = this.store.select(getRankingsOfModule(this.moduleId)).subscribe((rankings) => {
			this.rankings = rankings;
			this.loader = false;
		});
	}

	/**
	 * Navigate to ranking
	 * @param ranking
	 */
	navigateTo(ranking: IRanking) {
		this.navCtrl.navigateForward(`/event/${this.eventId}/rankings/${this.moduleId}/${ranking.uid}`);
	}
}
