import { Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { Subject, Subscription, combineLatest } from "rxjs";
import { debounceTime, distinctUntilChanged, skipWhile, take } from "rxjs/operators";
import { Loading } from "src/app/shared/actions/utility.actions";
import { IContainer, IEvent, IUser } from "src/app/shared/interfaces";
import { getCurrentUser } from "src/app/shared/selectors/auth.selectors";
import { getContainersState } from "src/app/shared/selectors/containers.selectors";
import { getInitUser } from "src/app/shared/selectors/utility.selectors";
import { AuthService, EventsService, UtilityService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-events-list",
    templateUrl: "./events-list.page.html",
    styleUrls: ["./events-list.page.scss"],
    standalone: false
})
export class EventsListPage implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];

	page: number = 0;
	limit: number = 10;
	totalDocuments: number = 0;

	loader: boolean = true;
	eventsGetted: boolean = false;

	searchOpen: boolean = false;
	terms: string = "";
	searchValueUpdated: Subject<string> = new Subject<string>();

	events: IEvent[] = [];
	user: IUser;
	container: IContainer;

	currentLanguage: string = environment.platform.defaultLanguage;

	constructor(
		private SAuth: AuthService,
		private store: Store,
		private navCtrl: NavController,
		private SEvents: EventsService,
		private STranslate: TranslateService,
		private elementRef: ElementRef,
		private SUtility: UtilityService
	) {
		this.subscriptions.push(
			this.searchValueUpdated.pipe(debounceTime(200), distinctUntilChanged()).subscribe((evtValue) => {
				this.store.dispatch(Loading({ payload: true }));
				this.resetFilters();
				this.terms = evtValue;
				this.getEvents();
			})
		);
	}

	ngOnInit() {
		this.store.dispatch(Loading({ payload: true }));
		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);

		this.subscriptions.push(
			this.store.select(getContainersState).subscribe((containersState) => {
				this.container =
					!environment.platform.containerId || environment.platform.containerId === "defaultContainer"
						? containersState.defaultContainer
						: containersState.currentContainer;
			})
		);

		combineLatest([this.store.select(getInitUser).pipe(skipWhile((init) => !init))])
			.pipe(take(1))
			.subscribe(() => {
				this.getUser();
				this.getEvents();
			});
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Init events and user
	 */
	getUser() {
		this.subscriptions.push(
			this.store.select(getCurrentUser).subscribe((user) => {
				if (!_.isEqual(user, this.user)) {
					this.user = user;
				}
			})
		);
	}

	getEvents() {
		this.eventsGetted = false;
		this.SEvents.getEventsQuery(
			this.terms.length > 0
				? [
						{
							$search: {
								index: "events-search",
								regex: {
									query: `.*${this.terms}.*`,
									path: "title",
									allowAnalyzedField: true
								},
								count: {
									type: "total"
								}
							}
						}
				  ]
				: [],
			this.page,
			this.limit
		).subscribe({
			next: (results) => {
				this.totalDocuments = results.totalDocuments;
				this.events = this.events.concat(results.docs);
				this.store.dispatch(Loading({ payload: false }));
				this.eventsGetted = true;
			},
			error: () => {
				this.store.dispatch(Loading({ payload: false }));
				this.eventsGetted = true;
			}
		});
	}

	moreDatas() {
		this.page++;
		this.getEvents();
	}

	resetFilters() {
		this.page = 0;
		this.events = [];
	}

	/**
	 * Go to specific event
	 * @param event
	 */
	openEvent(event: IEvent) {
		this.navCtrl.navigateRoot(event.homePage ? event.homePage : "/event/" + event.uid);
		this.elementRef.nativeElement.remove();
	}

	/**
	 * Get date
	 * @param date
	 * @returns
	 */
	getDate(event: IEvent, date: string) {
		return this.SUtility.getPartOfDate(event, null, date, "long");
	}

	/**
	 * Log out user
	 */
	logOut() {
		this.SAuth.logoutUser();
	}
}
