/* eslint-disable max-len */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { map, take } from "rxjs";
import { TypeModule } from "src/app/shared/enums/type-module";
import {
	ICheckinChecked,
	ICustomFieldData,
	IEvent,
	IEventUser,
	IFullCustomField,
	IModule
} from "src/app/shared/interfaces";
import { ICardExchangeForm } from "src/app/shared/interfaces/card-exchange.interfaces";
import { IEventUserUpdatedSettings } from "src/app/shared/interfaces/event-users.interfaces";
import { IFavoriteFolder } from "src/app/shared/interfaces/folders.interfaces";
import { CardExchangeService, EventUsersService, FirestoreService, UtilityService } from "src/app/shared/services";
import { environment } from "src/environments/environment";
import { CardExchangeFormComponent } from "../../components/card-exchange-form/card-exchange-form.component";

@Component({
    selector: "app-event-user-item",
    templateUrl: "./event-user-item.component.html",
    styleUrls: ["./event-user-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EventUserItemComponent {
	@Input() designType: "card" | "row" = "row";

	@Input() rootElement: Element;
	@Input() event: IEvent;
	@Input() mainModule: IModule;
	@Input() targetModule: IModule;
	@Input() customFields: IFullCustomField[] = [];
	@Input() groupedLetters: any = null;
	@Input() eventUser: IEventUser;
	@Input() data: IEventUser;
	@Input() favoriteFolder: IFavoriteFolder;
	@Input() favoriteModule: IModule;
	@Input({ required: false }) cardExchangeForm: ICardExchangeForm;
	@Input({ required: false }) cardExchangeModule: IModule;
	@Input() currentLanguage: string = environment.platform.defaultLanguage;

	@Input() networkStatus: boolean = true;

	@Input() multiCheck: boolean = false;
	@Input() isChecked: boolean = false;
	@Input() checked: ICheckinChecked = null;
	@Output() modifyMultiCheck: EventEmitter<"add" | "remove"> = new EventEmitter();

	connectedStatusGetted: boolean = false;
	isConnected: boolean = false;

	updating: boolean = false;

	isMobile = window.innerWidth < 768 ? true : false;

	typesModules = TypeModule;

	// card exchange
	eventUserStates: { [key: string]: boolean } = {};

	constructor(
		private cdr: ChangeDetectorRef,
		private snackbar: MatSnackBar,
		private SFirestore: FirestoreService,
		private STranslate: TranslateService,
		private SEventUsers: EventUsersService,
		private modalCtrl: ModalController,
		private SCardExchange: CardExchangeService,
		public SUtility: UtilityService
	) {}

	onVisibleEventUser(evt: { target: HTMLElement; state: "ENTER" | "LEAVE"; scrollDirection: "UP" | "DOWN" }) {
		if (evt.state === "ENTER") {
			// let checkAlreadyGetSetting = this.mappedEventUsersConnectedStatus[eventUser.uid];

			if (this.isConnected || !this.networkStatus) {
				return;
			} else {
				this.SFirestore.getDocumentObs(
					`events/${this.event.uid}/modules/${this.data.moduleId}/event-users-updated-settings/${this.data.uid}`
				)
					.pipe(
						take(1),
						map((doc) => doc.data() as IEventUserUpdatedSettings)
					)
					.subscribe((updatedSettings) => {
						this.isConnected = updatedSettings?.connected;
					});
			}
		}
	}

	/**
	 * getEventUserTags
	 * @param eventUser
	 * @returns
	 */
	getEventUserTags(eventUser: IEventUser): ICustomFieldData[] {
		return eventUser.customFields
			.filter((eventUserCustomField) => {
				return (
					eventUserCustomField &&
					this.customFields.find(
						(computedCustomField) =>
							eventUserCustomField &&
							computedCustomField &&
							computedCustomField.baseSettings &&
							computedCustomField.baseSettings.uid === eventUserCustomField.uid &&
							computedCustomField.moduleSettings.canBeTag
					) &&
					!this.isUserOrModuleFieldsVisibilityHidden(eventUser, eventUserCustomField.uid, "customFields") &&
					((eventUserCustomField.field.text &&
						eventUserCustomField.field.text.trim() &&
						eventUserCustomField.field.text?.trim() !== "") ||
						(eventUserCustomField.field.multiLanguageText &&
							eventUserCustomField.field.multiLanguageText[this.currentLanguage]?.trim() &&
							eventUserCustomField.field.multiLanguageText[this.currentLanguage]?.trim() !== "") ||
						(eventUserCustomField.field.numeric && eventUserCustomField.field.numeric !== -1))
				);
			})
			.sort((a, b) =>
				this.getCustomFieldOrder(a) > this.getCustomFieldOrder(b)
					? 1
					: this.getCustomFieldOrder(a) < this.getCustomFieldOrder(b)
					? -1
					: 0
			);
	}

	/**
	 * isUserOrModuleFieldsVisibilityHidden
	 * @param key
	 */
	isUserOrModuleFieldsVisibilityHidden(eventUser: IEventUser, key: string, type: "baseFields" | "customFields") {
		if (type === "baseFields") {
			return this.targetModule.options.enableUserFieldsHideAbility &&
				(eventUser.updatedSettings?.fieldsVisibility?.[key] === undefined ||
					(eventUser.updatedSettings?.fieldsVisibility?.[key] !== undefined &&
						eventUser.updatedSettings?.fieldsVisibility?.[key] !==
							this.targetModule.options.requiredFields?.[key]?.hiding?.default &&
						!eventUser.editedProfile))
				? this.targetModule.options.requiredFields?.[key]?.hiding?.default ?? false
				: eventUser.updatedSettings?.fieldsVisibility?.[key] ?? false;
		} else {
			const correspondingCus = this.customFields.find((cus) => cus.baseSettings.uid === key);

			if (correspondingCus) {
				return !this.targetModule.options.enableUserFieldsHideAbility
					? false
					: this.targetModule.options.enableUserFieldsHideAbility &&
					  (eventUser.updatedSettings?.fieldsVisibility?.[key] === undefined ||
							(eventUser.updatedSettings?.fieldsVisibility?.[key] !== undefined &&
								eventUser.updatedSettings?.fieldsVisibility?.[key] !==
									correspondingCus.moduleSettings.hiding?.default &&
								!eventUser.editedProfile))
					? correspondingCus.moduleSettings.hiding?.default ?? false
					: eventUser.updatedSettings?.fieldsVisibility?.[key] ?? false;
			}
		}
	}

	/**
	 * getCustomFieldOrder
	 */
	getCustomFieldOrder(customField: ICustomFieldData): number {
		return this.customFields.find((cus) => cus.baseSettings.uid === customField.uid)
			? this.customFields.find((cus) => cus.baseSettings.uid === customField.uid).moduleSettings.order
			: -1;
	}

	checkIsFavorite() {
		return this.eventUser.favorites?.includes(this.data.uid);
	}

	async changeFavorites(eventUserId: string, type: boolean) {
		try {
			if (!this.updating) {
				this.updating = true;
				if (this.eventUser) {
					if (this.eventUser.favorites) {
						if (type && !this.eventUser.favorites.includes(eventUserId)) {
							this.eventUser.favorites.push(eventUserId);
						} else {
							this.eventUser.favorites = this.eventUser.favorites.filter((fav) => fav !== eventUserId);
						}
					} else {
						this.eventUser["favorites"] = [eventUserId];
					}
					await this.SEventUsers.updatePartOfEventUser(
						this.eventUser.eventId,
						this.eventUser.moduleId,
						this.eventUser.uid,
						{
							favorites: this.eventUser.favorites
						}
					);
					this.snackbar.open(this.STranslate.instant("snackbar.update_successfull"), null, {
						duration: 3000,
						panelClass: ["snackbar-success"]
					});
					this.updating = false;
					this.cdr.markForCheck();
				}
			}
		} catch (error) {
			// Snackbar error
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
			this.updating = false;
			this.cdr.markForCheck();
		}
	}

	checkIsNew() {
		return (
			DateTime.fromISO(this.data.creationDate) >
			DateTime.local().minus({
				minute:
					this.mainModule.options && this.mainModule.options.newHintChipDelay
						? this.mainModule.options.newHintChipDelay
						: 1440
			})
		);
	}

	checkIsContact() {
		return this.eventUser.contactIds.includes(this.data.uid);
	}

	/**
	 * addToContacts
	 * @param eventUserToAdd
	 * @param event
	 */
	async addToContacts(eventUserToAdd: IEventUser, event: Event) {
		try {
			event.stopPropagation();

			// open card exchange form component
			if (this.cardExchangeForm) {
				// add contact with form
				const modal = await this.modalCtrl.create({
					component: CardExchangeFormComponent,
					componentProps: {
						event: this.event,
						module: this.cardExchangeModule,
						myEventUser: this.eventUser,
						eventUserContact: eventUserToAdd,
						eventUserContactId: eventUserToAdd.uid
					},
					cssClass: this.isMobile ? "card-exhange-form-modal-css-mobile" : "card-exchange-form-modal-css"
				});

				await modal.present();

				const { data } = await modal.onWillDismiss();

				if (!data) {
					return;
				}

				if (data && data?.error) {
					this.SUtility.presentToast(
						this.STranslate.instant("snackbar.error_occured"),
						3000,
						"bottom",
						"error"
					);

					return;
				}

				if (this.eventUser.contactIds && !this.eventUser.contactIds.includes(eventUserToAdd.uid)) {
					this.eventUser.contactIds.push(eventUserToAdd.uid);
				} else {
					this.eventUser = { ...this.eventUser, contactIds: [] };
				}

				await this.SEventUsers.updatePartOfEventUser(
					this.event.uid,
					this.eventUser.moduleId,
					this.eventUser.uid,
					{
						contactIds: this.eventUser.contactIds
					}
				);
			} else {
				await this.storeContactInEventUserDoc(eventUserToAdd.uid);
			}

			this.SUtility.presentToast(
				this.STranslate.instant("snackbar.update_successfull"),
				3000,
				"bottom",
				"success"
			);
		} catch (error) {
			console.error("🚀 ~ addToContacts ~ error:", error);
			this.eventUserStates[eventUserToAdd.uid] = false;
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}

	/**
	 * storeContactInEventUserDoc
	 * @param contactId
	 */
	async storeContactInEventUserDoc(contactId: string) {
		try {
			await this.SCardExchange.storeContactInEventUserDoc(this.event, this.eventUser, contactId);
			this.eventUserStates[contactId] = true;
		} catch (_error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}
}
