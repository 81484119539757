import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { BehaviorSubject, Subscription, combineLatest, skipWhile, take } from "rxjs";
import { TypeModule } from "src/app/shared/enums/type-module";
import {
	IAskQuestions,
	IEvent,
	IEventUser,
	IExternalInteractivity,
	IFeedback,
	IFullCustomField,
	IGroup,
	IModule,
	IQuiz,
	ISchedule,
	ISurvey
} from "src/app/shared/interfaces";
import { IChat } from "src/app/shared/interfaces/chats.interfaces";
import { IField } from "src/app/shared/interfaces/custom-fields.interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getSessionDiscussionsGroups } from "src/app/shared/selectors/chats.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getAccessiblesFeedbacksByType,
	getBaseCustomFields,
	getGroupsByOrder,
	getModulesCustomsFieldsOfModule
} from "src/app/shared/selectors/generics-modules-data.selectors";
import {
	getAccessiblesAsksQuestionsByType,
	getAccessiblesExternalsInteractivityByType,
	getAccessiblesQuizsByType,
	getAccessiblesSurveysByType
} from "src/app/shared/selectors/interactivity.selectors";
import { getModulesByTypes, getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { CustomFieldsService, SchedulesService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-interactivity-manager",
    templateUrl: "./interactivity-manager.component.html",
    styleUrls: ["./interactivity-manager.component.scss"],
    standalone: false
})
export class InteractivityManagerComponent implements OnInit, OnDestroy, OnChanges {
	eventSubscription: Subscription;
	moduleSubscription: Subscription;
	eventUserSubscription: Subscription;
	sessionSubscription: Subscription;
	groupsSubscription: Subscription;
	customFieldsSubscription: Subscription;
	customFieldsScheduleSubscription: Subscription;
	interactivityDatasSubscription: Subscription;
	languageSubscription: Subscription;
	@Input() eventId: string;
	event: IEvent;
	@Input() moduleId: string;
	module: IModule;
	@Input() sessionId: string;
	session: ISchedule;
	eventUser: IEventUser;
	computedCustomFields: IFullCustomField[] = [];

	savedResults: [IModule[], IAskQuestions[], IFeedback[], IQuiz[], ISurvey[], IExternalInteractivity[], IChat[]] =
		null;

	asksQuestions: IAskQuestions[] = [];
	asksQuestionsModule: IModule;

	quizs: IQuiz[] = [];
	quizsModule: IModule;

	surveys: ISurvey[] = [];
	surveysModule: IModule;

	externalsInteractivity: IExternalInteractivity[] = [];
	externalsInteractivityModule: IModule;

	feedbacks: IFeedback[] = [];
	feedbacksModule: IModule;

	discussionsGroups: IChat[] = [];
	discussionsGroupsModule: IModule;

	sortedModules: IModule[] = [];

	segmentType: string = "";
	type: string = "details";
	isMobile: boolean = false;
	itemViewUid: string = "";

	init: boolean = false;
	customFieldsInit: boolean = false;

	typesModules = TypeModule;

	showBackBtnAskQuestion: boolean = true;
	showBackBtnSurvey: boolean = true;
	showBackBtnQuiz: boolean = true;
	showBackBtnFeedback: boolean = true;
	showBackBtnExternalInteractivity: boolean = true;

	currentLanguage: string = environment.platform.defaultLanguage;
	groups: IGroup[];
	groupIds: string[] = [];

	initDatasChecks: { initSession: boolean; initCustomFields: boolean } = {
		initSession: false,
		initCustomFields: false
	};
	initDatasSubject: BehaviorSubject<{ initSession: boolean; initCustomFields: boolean }> = new BehaviorSubject(
		this.initDatasChecks
	);

	constructor(
		private store: Store,
		public STranslate: TranslateService,
		private platform: Platform,
		private SCustomFields: CustomFieldsService,
		private SSchedules: SchedulesService
	) {
		this.currentLanguage = this.STranslate.currentLang;
		this.languageSubscription = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});
		this.isMobile = this.platform.is("mobile") && window.innerWidth < 768 ? true : false;
	}

	ngOnInit() {
		this.initDatas();
	}

	ngOnChanges(changes) {
		if (changes && changes.eventId && !changes.eventId.firstChange) {
			this.eventSubscription?.unsubscribe();
			this.event = null;
			this.getEvent();
		}
		if (changes && changes.moduleId && !changes.moduleId.firstChange) {
			this.init = false;
			this.initDatasChecks = { initCustomFields: false, initSession: false };
			this.initDatasSubject.next(this.initDatasChecks);
			this.savedResults = null;
			this.moduleSubscription?.unsubscribe();
			this.sessionSubscription?.unsubscribe();
			this.customFieldsScheduleSubscription?.unsubscribe();
			this.interactivityDatasSubscription?.unsubscribe();
			this.module = null;
			this.session = null;
			this.asksQuestionsModule = null;
			this.asksQuestions = [];
			this.externalsInteractivityModule = null;
			this.externalsInteractivity = [];
			this.quizsModule = null;
			this.quizs = [];
			this.surveysModule = null;
			this.surveys = [];
			this.feedbacksModule = null;
			this.feedbacks = [];
			this.discussionsGroupsModule = null;
			this.discussionsGroups = [];
			this.getModule();
			this.getSession();
			this.getCustomFieldsFromScheduleModule();
			this.getInteractivityDatas();
		}
		if (changes && changes.sessionId && !changes.sessionId.firstChange) {
			this.init = false;
			this.initDatasChecks = { initCustomFields: false, initSession: false };
			this.initDatasSubject.next(this.initDatasChecks);
			this.savedResults = null;
			this.sessionSubscription?.unsubscribe();
			this.customFieldsScheduleSubscription?.unsubscribe();
			this.interactivityDatasSubscription?.unsubscribe();
			this.session = null;
			this.asksQuestionsModule = null;
			this.asksQuestions = [];
			this.externalsInteractivityModule = null;
			this.externalsInteractivity = [];
			this.quizsModule = null;
			this.quizs = [];
			this.surveysModule = null;
			this.surveys = [];
			this.feedbacksModule = null;
			this.feedbacks = [];
			this.discussionsGroupsModule = null;
			this.discussionsGroups = [];
			this.getSession();
			this.getCustomFieldsFromScheduleModule();
			this.getInteractivityDatas();
		}
	}

	/**
	 * Init all datas
	 */
	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		this.getSession();
		this.getGroups();
		this.getCustomFieldsFromScheduleModule();
		this.getInteractivityDatas();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.eventSubscription?.unsubscribe();
		this.moduleSubscription?.unsubscribe();
		this.eventUserSubscription?.unsubscribe();
		this.sessionSubscription?.unsubscribe();
		this.groupsSubscription?.unsubscribe();
		this.customFieldsSubscription?.unsubscribe();
		this.customFieldsScheduleSubscription?.unsubscribe();
		this.interactivityDatasSubscription?.unsubscribe();
		this.languageSubscription?.unsubscribe();
		// this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.eventSubscription = this.store.select(getCurrentEvent).subscribe((event) => {
			this.event = event;
		});
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.moduleSubscription = this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
			this.module = module;
		});
	}

	/**
	 * Get session
	 */
	getSession() {
		this.sessionSubscription = this.SSchedules.getSpecificSession(
			this.eventId,
			this.moduleId,
			this.sessionId
		).subscribe((session) => {
			this.session = session;
			if (this.session) {
				this.initDatasChecks.initSession = true;
				this.initDatasSubject.next(this.initDatasChecks);
			}
		});
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.eventUserSubscription = this.store.select(getCurrentEventUser).subscribe((eventUser) => {
			this.eventUser = eventUser;
		});
	}

	/**
	 * Get groups
	 */
	getGroups() {
		this.groupsSubscription = this.store.select(getGroupsByOrder("asc")).subscribe((groups) => {
			this.groups = groups;
			this.groupIds = this.groups.map((group) => group.uid);
		});
	}

	/**
	 * Get custom fields
	 */
	getCustomFields() {
		this.customFieldsSubscription = combineLatest([
			this.store.select(getBaseCustomFields),
			this.store.select(getModulesCustomsFieldsOfModule(this.moduleId))
		]).subscribe((results) => {
			this.computedCustomFields =
				results[1].length > 0
					? results[1].map((customField) => {
							const baseCustomFieldCorresponding = results[0].find(
								(custField) => custField.uid === customField.uid
							);
							return {
								baseSettings: baseCustomFieldCorresponding ? baseCustomFieldCorresponding : null,
								moduleSettings: customField,
								fieldDatas: null
							};
					  })
					: [];
		});
	}

	/**
	 * Get custom fields from "schedule" module
	 */
	getCustomFieldsFromScheduleModule() {
		this.customFieldsInit = false;
		if (this.sessionId) {
			this.initDatasSubject
				.pipe(
					skipWhile((init) => !init.initSession),
					take(1)
				)
				.subscribe(() => {
					this.customFieldsScheduleSubscription = combineLatest([
						this.store.select(getBaseCustomFields),
						this.store.select(getModulesCustomsFieldsOfModule(this.module.uid))
					]).subscribe((results) => {
						this.computedCustomFields = [];

						if (results[1].length > 0) {
							results[1].forEach((customField) => {
								const baseCustomFieldCorresponding = results[0].find(
									(custField) => custField.uid === customField.uid
								);

								if (baseCustomFieldCorresponding) {
									const data = this.session.customFields.find((data) => data.uid === customField.uid);

									if (data) {
										this.computedCustomFields.push({
											baseSettings: baseCustomFieldCorresponding,
											moduleSettings: customField,
											fieldDatas: data
												? data
												: {
														uid: "",
														field: {} as IField
												  }
										});
									}
								}
							});
						}
						this.customFieldsInit = true;

						this.initDatasChecks.initCustomFields = true;
						this.initDatasSubject.next(this.initDatasChecks);
					});
				});
		}
	}

	/**
	 * Get all interactivity datas
	 */
	getInteractivityDatas() {
		this.initDatasSubject
			.pipe(
				skipWhile((init) => !init.initSession || !init.initCustomFields),
				take(1)
			)
			.subscribe(() => {
				this.interactivityDatasSubscription = combineLatest([
					this.store.select(
						getModulesByTypes([
							TypeModule.ASK_QUESTION,
							TypeModule.FEEDBACKS,
							TypeModule.QUIZ,
							TypeModule.SURVEY,
							TypeModule.CHAT,
							TypeModule.EXTERNAL_INTERACTIVITY
						])
					),
					this.store.select(getAccessiblesAsksQuestionsByType(1, this.session)),
					this.store.select(getAccessiblesFeedbacksByType(1, this.session)),
					this.store.select(getAccessiblesQuizsByType(1, this.session)),
					this.store.select(getAccessiblesSurveysByType(1, this.session)),
					this.store.select(getAccessiblesExternalsInteractivityByType(1, this.session)),
					this.store.select(getSessionDiscussionsGroups(this.session))
				]).subscribe((results) => {
					if (!_.isEqual(this.savedResults, results)) {
						this.savedResults = results;

						this.asksQuestionsModule =
							results[0].length > 0 &&
							results[0].find((module) => module.type === TypeModule.ASK_QUESTION)
								? results[0].find((module) => module.type === TypeModule.ASK_QUESTION)
								: null;
						this.feedbacksModule =
							results[0].length > 0 && results[0].find((module) => module.type === TypeModule.FEEDBACKS)
								? results[0].find((module) => module.type === TypeModule.FEEDBACKS)
								: null;
						this.quizsModule =
							results[0].length > 0 && results[0].find((module) => module.type === TypeModule.QUIZ)
								? results[0].find((module) => module.type === TypeModule.QUIZ)
								: null;
						this.surveysModule =
							results[0].length > 0 && results[0].find((module) => module.type === TypeModule.SURVEY)
								? results[0].find((module) => module.type === TypeModule.SURVEY)
								: null;
						this.externalsInteractivityModule =
							results[0].length > 0 &&
							results[0].find((module) => module.type === TypeModule.EXTERNAL_INTERACTIVITY)
								? results[0].find((module) => module.type === TypeModule.EXTERNAL_INTERACTIVITY)
								: null;
						this.discussionsGroupsModule =
							results[0].length > 0 && results[0].find((module) => module.type === TypeModule.CHAT)
								? results[0].find((module) => module.type === TypeModule.CHAT)
								: null;
						this.asksQuestions = results[1];
						this.showBackBtnAskQuestion = this.asksQuestions.length > 1;
						this.feedbacks = results[2];
						this.showBackBtnFeedback = this.feedbacks.length > 1;
						this.quizs = results[3];
						this.showBackBtnQuiz = this.quizs.length > 1;
						this.surveys = results[4];
						this.showBackBtnSurvey = this.surveys.length > 1;
						this.externalsInteractivity = results[5];
						this.showBackBtnExternalInteractivity = this.externalsInteractivity.length > 1;
						this.discussionsGroups = results[6];

						this.initSegments();
					} else {
						this.init = true;
					}
				});
			});
	}

	/**
	 * Segment type changed
	 * @param evt
	 */
	segmentTypeChanged(evt: any) {
		this.segmentType = evt.detail.value;
		if (this.segmentType === "chats") {
			this.type = this.discussionsGroups.length === 1 ? "chat" : "chats";
			this.itemViewUid = this.discussionsGroups.length === 1 ? this.discussionsGroups[0].uid : "";
		} else if (this.segmentType === "feedbacks") {
			this.type =
				this.feedbacks.filter(
					(feedback) => !feedback.eventUserResults || feedback.eventUserResults.length === 0
				).length === 1
					? "feedback"
					: "feedbacks";
			this.itemViewUid =
				this.feedbacks.filter(
					(feedback) => !feedback.eventUserResults || feedback.eventUserResults.length === 0
				).length === 1
					? this.feedbacks[0].uid
					: "";
		} else if (this.segmentType === "questions") {
			this.type = this.asksQuestions.length === 1 ? "question" : "questions";
			this.itemViewUid = this.asksQuestions.length === 1 ? this.asksQuestions[0].uid : "";
		} else if (this.segmentType === "quizs") {
			const unansweredQuizs = this.quizs.filter(
				(quiz) =>
					!quiz.eventUserResults ||
					quiz.eventUserResults.length === 0 ||
					(quiz.eventUserResults && quiz.eventUserResults.length !== quiz.questions.length)
			);
			this.itemViewUid = unansweredQuizs.length === 1 ? unansweredQuizs[0].uid : "";
			this.type = unansweredQuizs.length === 1 ? "quiz" : "quizs";
		} else if (this.segmentType === "surveys") {
			this.type =
				this.surveys.filter((survey) => !survey.eventUserResults || survey.eventUserResults.length === 0)
					.length === 1
					? "survey"
					: "surveys";
			this.itemViewUid =
				this.surveys.filter((survey) => !survey.eventUserResults || survey.eventUserResults.length === 0)
					.length === 1
					? this.surveys[0].uid
					: "";
		} else if (this.segmentType === "externalsInteractivity") {
			this.type = this.externalsInteractivity.length === 1 ? "externalInteractivity" : "externalsInteractivity";
			this.itemViewUid = this.externalsInteractivity.length === 1 ? this.externalsInteractivity[0].uid : "";
		} else {
			this.type = evt.detail.value;
		}
	}

	/**
	 * Get modules by order
	 * @returns
	 */
	getModulesOrder() {
		let sorted: IModule[] = [];
		if (this.asksQuestionsModule) sorted.push(this.asksQuestionsModule);
		if (this.quizsModule) sorted.push(this.quizsModule);
		if (this.surveysModule) sorted.push(this.surveysModule);
		if (this.externalsInteractivityModule) sorted.push(this.externalsInteractivityModule);
		sorted = sorted.sort((a, b) =>
			a.options.interactivityOrder > b.options.interactivityOrder
				? 1
				: a.options.interactivityOrder < b.options.interactivityOrder
				? -1
				: 0
		);

		if (this.feedbacksModule) sorted.unshift(this.feedbacksModule);
		if (this.discussionsGroupsModule) sorted.push(this.discussionsGroupsModule);

		return sorted;
	}

	/**
	 * Init segments
	 * @returns
	 */
	initSegments() {
		if (
			!this.init ||
			((this.segmentType === "questions" || this.segmentType === "question") &&
				(!this.asksQuestionsModule || this.asksQuestions.length === 0)) ||
			((this.segmentType === "quizs" || this.segmentType === "quiz") &&
				(!this.quizsModule || this.quizs.length === 0)) ||
			((this.segmentType === "surveys" || this.segmentType === "survey") &&
				(!this.surveysModule || this.surveys.length === 0)) ||
			((this.segmentType === "externalsInteractivity" || this.segmentType === "externalInteractivity") &&
				(!this.externalsInteractivityModule || this.externalsInteractivity.length === 0)) ||
			((this.segmentType === "chats" || this.segmentType === "chat") &&
				(!this.discussionsGroupsModule || this.discussionsGroups.length === 0)) ||
			((this.segmentType === "feedbacks" || this.segmentType === "feedback") &&
				(!this.feedbacksModule || this.feedbacks.length === 0)) ||
			(!this.segmentType && this.init)
		) {
			this.segmentType = "";
			this.type = "";
			// At init check for showing an activating the first segment
			this.init = true;
			this.sortedModules = this.getModulesOrder();
			if (this.isMobile && this.canShowDetailSegment()) {
				this.segmentType = "details";
				this.type = "details";
				return;
			}
			if (this.sortedModules && this.sortedModules.length > 0) {
				for (let i = 0; i < this.sortedModules.length; i++) {
					const module = this.sortedModules[i];
					if (module.type === TypeModule.FEEDBACKS && this.feedbacksModule && this.feedbacks.length > 0) {
						this.segmentType = "feedbacks";
						const answeredFeedback = this.feedbacks.filter(
							(feedback) =>
								!feedback.eventUserResults ||
								feedback.eventUserResults.length !== feedback.questions.length
						);
						this.type = answeredFeedback.length === 1 ? "feedback" : "feedbacks";
						this.itemViewUid = answeredFeedback.length === 1 ? answeredFeedback[0].uid : "";
						break;
					}

					if (
						module.type === TypeModule.ASK_QUESTION &&
						this.asksQuestionsModule &&
						this.asksQuestions.length > 0
					) {
						this.segmentType = "questions";
						this.type = this.asksQuestions.length === 1 ? "question" : "questions";
						this.itemViewUid = this.asksQuestions[0].uid;
						break;
					}

					if (module.type === TypeModule.SURVEY && this.surveysModule && this.surveys.length > 0) {
						this.segmentType = "surveys";
						this.type = this.surveys.length === 1 ? "survey" : "surveys";
						this.itemViewUid = this.surveys[0].uid;
						break;
					}

					if (module.type === TypeModule.QUIZ && this.quizsModule && this.quizs.length > 0) {
						this.segmentType = "quizs";
						this.type = this.quizs.length === 1 ? "quiz" : "quizs";
						this.itemViewUid = this.quizs[0].uid;
						break;
					}

					if (
						module.type === TypeModule.EXTERNAL_INTERACTIVITY &&
						this.externalsInteractivityModule &&
						this.externalsInteractivity.length > 0
					) {
						this.segmentType = "externalsInteractivity";
						this.type =
							this.externalsInteractivity.length === 1
								? "externalInteractivity"
								: "externalsInteractivity";
						this.itemViewUid = this.externalsInteractivity[0].uid;
						break;
					}

					if (
						module.type === TypeModule.CHAT &&
						this.discussionsGroupsModule &&
						this.discussionsGroups.length > 0
					) {
						this.segmentType = "chats";
						this.type = this.discussionsGroups.length === 1 ? "chat" : "chats";
						this.itemViewUid = this.discussionsGroups.length === 1 ? this.discussionsGroups[0].uid : "";
						break;
					}
				}
			}
		}
	}

	/**
	 * Changing view
	 * @param event
	 */
	changingView(evt: { segmentType: string; type: string; uid: string }) {
		setTimeout(() => {
			this.segmentType = evt.segmentType;
			this.type = evt.type;
			this.itemViewUid = evt.uid;
		}, 200);
	}

	/**
	 * canShowDetailSegment
	 * @returns boolean
	 */
	canShowDetailSegment() {
		return (
			this.computedCustomFields.filter((cus) =>
				this.SCustomFields.checkValueCustomField(cus.baseSettings.type, cus.fieldDatas, this.currentLanguage)
			).length > 0 && this.isMobile
		);
	}

	/**
	 * Can show specific segment and component
	 * @param segmentType
	 * @param type
	 * @param datas
	 * @returns
	 */
	canShowSegment(segmentType: string, type: string, datas: any[]) {
		return segmentType === this.segmentType && type === this.type && datas.length > 0 ? true : false;
	}

	/**
	 * check if the "detail" segment is alone in the session
	 * @returns
	 */
	detailSegmentsIsAlone() {
		return (
			this.module &&
			this.feedbacks.length === 0 &&
			this.discussionsGroups.length === 0 &&
			this.quizs.length === 0 &&
			this.asksQuestions.length === 0 &&
			this.externalsInteractivity.length === 0 &&
			this.surveys.length === 0
		);
	}

	/**
	 * getCorrespondingGroups
	 * @param groupId
	 * @returns
	 */
	getCorrespondingGroups(groupIds: string[]) {
		return this.groups.filter((gr) => groupIds.includes(gr.uid));
	}
}
