import { Component, Input, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { CloudData, CloudOptions } from "angular-tag-cloud-module";
import { DateTime } from "luxon";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderState, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { IColorChart, IEvent, IEventUser, IModule, IWordCloud, IWordCloudWord } from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getColorsCharts, getSpecificWordCloud } from "src/app/shared/selectors/interactivity.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { EventUsersService, UtilityService, WordCloudsService } from "src/app/shared/services";
import * as _ from "lodash-es";
import { SearchFilter } from "src/app/shared/helpers-functions/filter-search";
import { TypeUser } from "src/app/shared/enums/type-user";

@Component({
    selector: "app-words-cloud",
    templateUrl: "./words-cloud.component.html",
    styleUrls: ["./words-cloud.component.scss"],
    standalone: false
})
export class WordsCloudComponent implements OnDestroy {
	subscriptions: Subscription[] = [];
	wordsSubscription: Subscription;
	@Input() componentMode: boolean = false;

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	wordsCloudId: string;
	wordsCloud: IWordCloud;
	words: IWordCloudWord[] = [];
	eventUser: IEventUser;
	colorsCharts: IColorChart[] = [];
	eventUsers: IEventUser[] = [];

	options: CloudOptions = {
		background: "",
		delay: 50,
		font: "",
		height: 500,
		overflow: false,
		randomizeAngle: false,
		realignOnResize: true,
		strict: false,
		width: 1,
		zoomOnHover: {
			color: "",
			delay: 0,
			scale: 1.2,
			transitionTime: 0.6
		}
	};
	data: CloudData[] = [];
	inputWord: string = "";

	constructor(
		private store: Store,
		private SEventUsers: EventUsersService,
		private SWordsClouds: WordCloudsService,
		private STranslate: TranslateService,
		private SUtility: UtilityService
	) {}

	ionViewWillEnter() {
		if (!this.componentMode) {
			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe(() => {
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.moduleId = params.moduleId;
							this.wordsCloudId = params.wordsCloudId;
							if (this.wordsSubscription && !this.wordsSubscription.closed) {
								this.wordsSubscription.unsubscribe();
							}
							this.subscriptions.forEach((sub) => sub.unsubscribe());

							this.initDatas();
						});
				});
		} else {
			this.initDatas();
		}
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		this.getColorsCharts();
		this.getWordsCloud();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		if (this.wordsSubscription && !this.wordsSubscription.closed) {
			this.wordsSubscription.unsubscribe();
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ionViewWillLeave() {
		if (!this.componentMode) {
			this.store.dispatch(ResetHeaderState(null));
		}
		if (this.wordsSubscription && !this.wordsSubscription.closed) {
			this.wordsSubscription.unsubscribe();
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;
				}
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
				}
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;
				}
			})
		);
	}

	/**
	 * Get words cloud
	 */
	getWordsCloud() {
		this.subscriptions.push(
			this.store.select(getSpecificWordCloud(this.wordsCloudId)).subscribe((wordsCloud) => {
				if (!_.isEqual(this.wordsCloud, wordsCloud)) {
					this.wordsCloud = wordsCloud;
					if (this.wordsCloud) {
						this.options = {
							background: this.wordsCloud.options.background,
							delay: this.wordsCloud.options.delay,
							font: this.wordsCloud.options.font,
							height: this.wordsCloud.options.height,
							overflow: this.wordsCloud.options.overflow,
							randomizeAngle: this.wordsCloud.options.randomizeAngle,
							realignOnResize: this.wordsCloud.options.realignOnResize,
							strict: false,
							width: this.wordsCloud.options.width,
							zoomOnHover: {
								color: this.wordsCloud.options.zoomOnHover.color,
								delay: this.wordsCloud.options.zoomOnHover.delay,
								scale: this.wordsCloud.options.zoomOnHover.scale,
								transitionTime: this.wordsCloud.options.zoomOnHover.transitionTime
							}
						};

						if (this.wordsCloud.type === 0) {
							this.getWordsOfWordsCloud();
						} else {
							this.getEventUsersOfWordsCloud();
						}
					}
				}

				if (this.wordsCloud) {
					this.store.dispatch(
						GetHeaderState({
							payload: {
								item: this.wordsCloud,
								title: this.wordsCloud.name,
								type: TypeHeader.WORDCLOUD
							}
						})
					);
				}
			})
		);
	}

	/**
	 * Get event users for words cloud
	 */
	getEventUsersOfWordsCloud() {
		if (this.wordsSubscription && !this.wordsSubscription.closed) {
			this.wordsSubscription.unsubscribe();
		}

		// Get all attendees event users
		if (
			this.wordsCloud.type === 1 &&
			this.wordsCloud.datasLink.linked &&
			this.wordsCloud.datasLink.linkType === 0
		) {
			const searchFilter = new SearchFilter();
			searchFilter.equalityFields = [
				{ fieldKey: "eventId", compareData: this.event.uid },
				{ fieldKey: "type", compareData: TypeUser.ATTENDEE }
			];
			this.wordsSubscription = this.SEventUsers.getAllEventUsersForEvent(searchFilter, "aggregation").subscribe(
				(results) => {
					if (!_.isEqual(this.eventUsers, results.docs)) {
						this.eventUsers = results.docs;

						this.buildDatas();
					}
				}
			);
		} else if (
			this.wordsCloud.type === 2 &&
			this.wordsCloud.datasLink.linked &&
			this.wordsCloud.datasLink.linkType === 0
		) {
			const searchFilter = new SearchFilter();
			searchFilter.equalityFields = [
				{ fieldKey: "eventId", compareData: this.event.uid },
				{ fieldKey: "type", compareData: TypeUser.SPEAKER }
			];
			this.wordsSubscription = this.SEventUsers.getAllEventUsersForEvent(searchFilter, "aggregation").subscribe(
				(results) => {
					if (!_.isEqual(this.eventUsers, results.docs)) {
						this.eventUsers = results.docs;

						this.buildDatas();
					}
				}
			);
		} else if (
			(this.wordsCloud.type === 1 || this.wordsCloud.type === 2) &&
			this.wordsCloud.datasLink.linked &&
			this.wordsCloud.datasLink.linkType === 1
		) {
			const searchFilter = new SearchFilter();
			searchFilter.equalityFields = [
				{ fieldKey: "eventId", compareData: this.event.uid },
				{
					fieldKey: "moduleId",
					compareData: this.wordsCloud.datasLink.linkedModules
				}
			];
			this.wordsSubscription = this.SEventUsers.getAllEventUsersForEvent(searchFilter, "aggregation").subscribe(
				(results) => {
					if (!_.isEqual(this.eventUsers, results.docs)) {
						this.eventUsers = results.docs;

						this.buildDatas();
					}
				}
			);
		} else if (
			(this.wordsCloud.type === 1 || this.wordsCloud.type === 2) &&
			this.wordsCloud.datasLink.linked &&
			this.wordsCloud.datasLink.linkType === 2
		) {
			const searchFilter = new SearchFilter();
			searchFilter.equalityFields = [
				{ fieldKey: "eventId", compareData: this.event.uid },
				{
					fieldKey: "uid",
					compareData: this.wordsCloud.datasLink.linkedDatas
				}
			];
			this.wordsSubscription = this.SEventUsers.getAllEventUsersForEvent(searchFilter, "aggregation").subscribe(
				(results) => {
					if (!_.isEqual(this.eventUsers, results.docs)) {
						this.eventUsers = results.docs;

						this.buildDatas();
					}
				}
			);
		}
	}

	/**
	 * Get words of a words cloud
	 */
	getWordsOfWordsCloud() {
		if (this.wordsSubscription && !this.wordsSubscription.closed) {
			this.wordsSubscription.unsubscribe();
		}

		this.wordsSubscription = this.SWordsClouds.getWordsOfWordCloud(
			this.eventId,
			this.moduleId,
			this.wordsCloudId
		).subscribe((words) => {
			if (!_.isEqual(this.words, words)) {
				this.words = words;

				this.buildDatas();
			}
		});
	}

	/**
	 * Get all colors charts
	 */
	getColorsCharts() {
		this.subscriptions.push(
			this.store.select(getColorsCharts).subscribe((colorsCharts) => {
				if (!_.isEqual(this.colorsCharts, colorsCharts)) {
					this.colorsCharts = colorsCharts;
				}
			})
		);
	}

	/**
	 * Build datas
	 */
	buildDatas() {
		if (this.wordsCloud.type === 0) {
			// Delete words deleted
			this.data = this.data.filter((word) =>
				this.words.map((word) => word.word.toUpperCase()).includes(word.text)
			);
			// Add new words
			this.words.forEach((word) => {
				if (!this.data.map((wd) => wd.text).includes(word.word.toUpperCase())) {
					this.data.push({
						color:
							this.colorsCharts.length > 0
								? this.colorsCharts[this.randomInt(0, this.colorsCharts.length - 1)].color
								: "#" + ("000000" + Math.floor(0x1000000 * Math.random()).toString(16)).slice(-6),
						rotate: this.wordsCloud.options.randomizeAngle ? this.randomInt(-20, 20) : 0,
						text: word.word.toUpperCase(),
						weight: 1
					});
				}
			});
		} else {
			// Delete words deleted
			this.data = this.data.filter((word) =>
				this.eventUsers.map((eventUser) => eventUser.name.toUpperCase()).includes(word.text)
			);
			// Add new words
			this.eventUsers.forEach((eventUser) => {
				if (!this.data.map((wd) => wd.text).includes(eventUser.name.toUpperCase())) {
					this.data.push({
						color:
							this.colorsCharts.length > 0
								? this.colorsCharts[this.randomInt(0, this.colorsCharts.length - 1)].color
								: "#" + ("000000" + Math.floor(0x1000000 * Math.random()).toString(16)).slice(-6),
						rotate: this.wordsCloud.options.randomizeAngle ? this.randomInt(-20, 20) : 0,
						text: eventUser.name.toUpperCase(),
						weight: 1
					});
				}
			});
		}
	}

	/**
	 * Get random int
	 * @param min
	 * @param max
	 */
	randomInt(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	/**
	 * Add a word to word cloud
	 */
	async addWord() {
		if (this.inputWord) {
			const newWord: IWordCloudWord = {
				creationDate: DateTime.local().toISO(),
				eventId: this.eventId,
				moduleId: this.moduleId,
				userId: this.eventUser.uid,
				uid: "",
				word: this.inputWord,
				wordCloudId: this.wordsCloudId
			};
			try {
				await this.SWordsClouds.createWordOfWordsCloud(this.eventId, this.moduleId, this.wordsCloudId, newWord);
				this.SUtility.presentToast(
					this.STranslate.instant("word_cloud.success-send-word"),
					3000,
					"bottom",
					"success"
				);
				this.inputWord = "";
			} catch (error) {
				this.SUtility.presentToast(
					this.STranslate.instant("word_cloud.error-send-word"),
					3000,
					"bottom",
					"danger"
				);
			}
		}
	}

	/**
	 * Check words limit for a user
	 */
	checkWordsLimit() {
		const numberOfWords: number = this.words.filter((word) => word.userId === this.eventUser.uid).length;
		return numberOfWords < this.wordsCloud.options.maxWordsPerUser &&
			this.words.length < this.wordsCloud.options.maxWords
			? true
			: false;
	}
}
