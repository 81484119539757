<ion-content style="width: 100vw; height: 100vh">
	<div
		*ngIf="container"
		class="background"
		[ngStyle]="{'background-image': isMobile ? 'url('+container.loginBgImgSecond+')' : 'url('+container.loginBgImgFirst+')' }"
	>
		<div class="align-loader" *ngIf="loader">
			<div class="loader-default"></div>
		</div>
		<div *ngIf="!loader">
			<div class="p-t-40">
				<img [src]="container?.logo" class="banner-logo" />
			</div>

			<form [formGroup]="loginForm">
				<!-- Shortcode view -->
				<div *ngIf="shortcodeView">
					<ion-grid class="ion-padding">
						<!-- Title -->
						<ion-row class="padding-top-40">
							<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
								<h3 class="login-title">{{"buttons.access_by_shortcode" | translate}}</h3>
							</ion-col>
						</ion-row>

						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="4" push-md="4" push-lg="4">
								<ion-item lines="none" mode="md" class="input-item">
									<ion-label
										position="floating"
										mode="md"
										style="color: var(--text-color-2nd-login-desktop)"
										class="input-label-text"
									>
										{{"labels.code" | translate}}
									</ion-label>
									<ion-input mode="md" class="custom-input" type="text" formControlName="shortcode">
									</ion-input>
								</ion-item>
							</ion-col>
						</ion-row>

						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="4" push-md="4" push-lg="4" text-center>
								<ion-button
									shape="round"
									class="btn-default access-btn"
									(click)="checkShortCode(null, 'shortcodeView')"
									[disabled]="activeLoader"
								>
									<span class="login-font" *ngIf="!activeLoader"
										>{{"buttons.btn_verify"| translate}}</span
									>
									<ion-spinner name="crescent" color="light" *ngIf="activeLoader"></ion-spinner>
								</ion-button>
							</ion-col>
						</ion-row>
					</ion-grid>
				</div>

				<!-- Email view -->
				<div *ngIf="emailView">
					<ion-grid class="ion-padding">
						<ion-row class="p-t-40">
							<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
								<h3
									class="login-title"
									[ngClass]="passwordView ? 'color-2nd-screen' : 'color-1st-screen'"
								>
									{{"login.access_your_account" | translate}}
								</h3>
							</ion-col>
						</ion-row>

						<!-- Input email  -->
						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="4" push-md="4" push-lg="4">
								<ion-item
									lines="none"
									mode="md"
									class="input-item"
									[ngStyle]="{border: passwordView ? '1px solid var(--label-login-color)' :  '1px solid var(--text-color-2nd-login-desktop)' }"
								>
									<ion-label
										position="floating"
										mode="md"
										style="color: var(--text-color-2nd-login-desktop)"
										class="input-label-text"
									>
										{{ "login.email" | translate }}</ion-label
									>
									<ion-input
										mode="md"
										class="custom-input email-input"
										type="email"
										formControlName="email"
										(keyup.enter)="firstStepVerifEmail()"
									>
									</ion-input>
									<ion-icon class="icon" slot="end" *ngIf="passwordView" src="assets/icon/Check.svg">
									</ion-icon>
								</ion-item>
							</ion-col>
						</ion-row>

						<!-- Input password -->
						<ion-row *ngIf="passwordView">
							<ion-col size-sm="12" size-md="6" size-lg="4" push-md="4" push-lg="4">
								<ion-item lines="none" mode="md" class="input-item">
									<ion-label
										position="floating"
										mode="md"
										style="color: var(--text-color-2nd-login-desktop)"
										class="input-label-text"
									>
										{{"login.password" | translate}}</ion-label
									>
									<ion-input
										mode="md"
										class="custom-input password-input"
										[type]="showPwd ? 'text' : 'password'"
										formControlName="password"
										(keyup.enter)="login()"
									>
									</ion-input>
									<ion-button slot="end" class="pwd-btn" (click)="showPwd = !showPwd">
										<ion-icon
											slot="icon-only"
											[src]="showPwd ? 'assets/icon/Password_1.svg': 'assets/icon/Password_2.svg'"
											style="color: var(--text-color-2nd-login-desktop)"
										></ion-icon>
									</ion-button>
								</ion-item>
							</ion-col>
						</ion-row>

						<!-- Term & conditions -->
						<ion-row *ngIf="passwordView">
							<ion-col size-sm="12" size-md="6" size-lg="4" push-md="4" push-lg="4">
								<div class="term-conditions">
									<ion-checkbox
										class="term-checkbox"
										color="primary"
										formControlName="termCheck"
									></ion-checkbox>
									<ion-label
										class="p-l-10"
										[ngClass]="passwordView ? 'color-2nd-screen' : 'color-1st-screen'"
									>
										{{ "login.accept_term" | translate }}&nbsp;
										<a
											class="underline"
											ion-button
											clear
											icon-only
											(click)="openModalTermsOrPrivacy('terms')"
											>{{ "login.terms_of_use" | translate }}</a
										>
										{{ "labels.and" | translate }}&nbsp;
										<a
											class="underline"
											ion-button
											clear
											icon-only
											(click)="openModalTermsOrPrivacy('privacy')"
											>{{ "login.privacy_txt" | translate }}</a
										>
									</ion-label>
								</div>
							</ion-col>
						</ion-row>

						<!-- Buttons-->
						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="4" push-md="4" push-lg="4" text-center>
								<ion-button
									shape="round"
									class="btn-default access-btn"
									(click)="firstStepVerifEmail()"
									*ngIf="!passwordView"
									[disabled]="activeLoader || !loginForm.get('email').valid"
								>
									<span *ngIf="!activeLoader" class="login-font"
										>{{"buttons.btn_verify"| translate}}</span
									>
									<ion-spinner name="crescent" color="light" *ngIf="activeLoader"></ion-spinner>
								</ion-button>
								<ion-button
									shape="round"
									class="btn-default access-btn login-btn"
									(click)="login()"
									*ngIf="passwordView"
									[disabled]="activeLoader"
								>
									<span *ngIf="!activeLoader" class="login-font">{{"buttons.enter"| translate}}</span>
									<ion-spinner name="crescent" color="light" *ngIf="activeLoader"></ion-spinner>
								</ion-button>
								<div class="divider-default" *ngIf="!passwordView"></div>
								<ion-button
									shape="round"
									fill="outline"
									class="btn-default btn-outline-default login-font"
									*ngIf="(container?.loginSettings.showShortCodeBtn && !event) && !passwordView && !activeLoader"
									(click)="openShortcodeCtrl()"
								>
									{{"buttons.access_by_shortcode" | translate}}</ion-button
								>
								<div
									class="divider-default"
									*ngIf="(container?.loginSettings.showAccessPublicEventsBtn && !event) && !passwordView && !activeLoader"
								></div>
								<!-- <ion-button shape="round" fill="outline"
                                    class="btn-default btn-outline-default login-font"
                                    *ngIf="(container?.loginSettings.showAccessPublicEventsBtn && !event) && !passwordView && !activeLoader"
                                    (click)="goPublicEventsList()">
                                    {{"buttons.public_events_list" | translate}}</ion-button> -->
								<ion-button
									shape="round"
									fill="outline"
									class="btn-default btn-outline-default login-font"
									*ngIf="event"
									(click)="backToAppLogin()"
								>
									{{"buttons.cancel" | translate}}</ion-button
								>
							</ion-col>
						</ion-row>
						<!-- forgot password  -->
						<ion-row *ngIf="passwordView">
							<ion-col size-sm="12" size-md="6" size-lg="4" offset-md="4" offset-lg="4">
								<a
									class="forgot-password"
									[ngClass]="passwordView ? 'color-2nd-screen' : 'color-1st-screen'"
									(click)="recoveryPassword()"
									>{{"login.forgot_pass" | translate}}</a
								>
							</ion-col>
						</ion-row>
					</ion-grid>
				</div>
				<div *ngIf="firstAccessView">
					<ion-grid class="ion-padding">
						<ion-row>
							<ion-col
								size-sm="12"
								size-md="6"
								size-lg="6"
								push-md="3"
								push-lg="3"
								[ngClass]="passwordView ? 'color-2nd-screen' : 'color-1st-screen' "
							>
								<p class="login-title">{{"login.first_access_txt_01" | translate}}</p>
								<p class="font-roobert" style="text-align: center">
									{{"login.first_access_txt_02" | translate}}
								</p>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="4" push-md="4" push-lg="4">
								<ion-item
									lines="none"
									mode="md"
									class="input-item"
									[ngStyle]="{border: passwordView ? '1px solid var(--label-login-color)' :  '1px solid var(--text-color-2nd-login-desktop)' }"
								>
									<ion-label
										position="floating"
										mode="md"
										style="color: var(--text-color-2nd-login-desktop)"
										class="input-label-text"
									>
										{{ "login.verify_code" | translate }}</ion-label
									>
									<ion-input
										mode="md"
										class="custom-input"
										type="number"
										pattern="[0-9]*"
										min="0"
										max="10"
										formControlName="codeNumber"
									></ion-input>
								</ion-item>

								<br /><span *ngIf="count !== 0" class="timer" style="text-align: center"
									>{{ "login.remaining_time" | translate }} {{ timeRemaining }}</span
								>
							</ion-col>
						</ion-row>
						<ion-row *ngIf="count === 0">
							<ion-col size-sm="12" size-md="6" size-lg="4" push-md="4" push-lg="4">
								<ion-button
									class="btn-default btn-outline-default"
									shape="round"
									fill="outline"
									[disabled]="activeLoader"
									(click)="createCodeNumber(); activeLoader = true;"
								>
									<span *ngIf="!activeLoader">{{ "login.resend_code" | translate }}</span>
									<ion-spinner name="crescent" color="secondary" *ngIf="activeLoader"></ion-spinner>
								</ion-button>
							</ion-col>
						</ion-row>

						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="4" push-md="4" push-lg="4" text-center>
								<ion-button
									shape="round"
									class="btn-default access-btn"
									(click)="verifyCodeNumber()"
									[disabled]="activeLoader"
								>
									<span *ngIf="!activeLoader">{{ "buttons.btn_verify" | translate }}</span>
									<ion-spinner name="crescent" color="secondary" *ngIf="activeLoader"></ion-spinner>
								</ion-button>
							</ion-col>
						</ion-row>
						<!-- forgot password  -->
						<ion-row *ngIf="passwordView">
							<ion-col size-sm="12" size-md="6" size-lg="4" offset-md="4" offset-lg="4">
								<a
									class="forgot-password"
									[ngClass]="passwordView ? 'color-2nd-screen' : 'color-1st-screen'"
									(click)="recoveryPassword()"
									>{{ "login.forgot_pass" | translate }}</a
								>
							</ion-col>
						</ion-row>
					</ion-grid>
				</div>

				<div *ngIf="createPasswordView">
					<ion-grid class="ion-padding">
						<ion-row>
							<ion-col
								size-sm="12"
								size-md="6"
								size-lg="6"
								push-md="3"
								push-lg="3"
								[ngClass]="passwordView ? 'color-2nd-screen' : 'color-1st-screen' "
							>
								<p class="login-title">{{"login.great_title" | translate}}</p>
								<p class="font-roobert align-center">
									{{ !container.loginSettings.passwordSecurityLevel ||
									container.loginSettings.passwordSecurityLevel === 1 ? ("login.make_pass_txt" |
									translate) : ("login.make_pass_strong_txt" | translate)}}
								</p>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="4" push-md="4" push-lg="4">
								<ion-item lines="none" mode="md" class="input-item">
									<ion-label
										position="floating"
										mode="md"
										style="color: var(--text-color-2nd-login-desktop)"
										class="input-label-text"
									>
										{{"login.new_pass" | translate}}</ion-label
									>
									<ion-input
										mode="md"
										class="custom-input"
										[type]="showPwd ? 'text' : 'password'"
										formControlName="password"
									>
									</ion-input>

									<ion-button slot="end" class="pwd-btn" (click)="showPwd = !showPwd">
										<ion-icon
											slot="icon-only"
											[src]="showPwd ? 'assets/icon/Password_1.svg': 'assets/icon/Password_2.svg'"
											style="color: var(--text-color-2nd-login-desktop)"
										></ion-icon>
									</ion-button>
								</ion-item>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="4" push-md="4" push-lg="4">
								<ion-item lines="none" mode="md" class="input-item">
									<ion-label
										position="floating"
										mode="md"
										style="color: var(--text-color-2nd-login-desktop)"
										class="input-label-text"
									>
										{{"login.confirm_new_pass" | translate}}</ion-label
									>
									<ion-input
										mode="md"
										class="custom-input"
										[type]="showPwd ? 'text' : 'password'"
										formControlName="confirmPassword"
									>
									</ion-input>
									<ion-button slot="end" class="pwd-btn" (click)="showPwd = !showPwd">
										<ion-icon
											slot="icon-only"
											[src]="showPwd ? 'assets/icon/Password_1.svg': 'assets/icon/Password_2.svg'"
											style="color: var(--text-color-2nd-login-desktop)"
										></ion-icon>
									</ion-button>
								</ion-item>
								<span
									*ngIf="loginForm.getRawValue().password && loginForm.getRawValue().confirmPassword && loginForm.getRawValue().password !== loginForm.getRawValue().confirmPassword"
									class="red-color tw-block tw-text-center"
									>{{ "login.password_not_match" | translate }}</span
								>
								@if (loginForm.get("password").errors) {
								<val-errors
									class="red-color tw-flex tw-justify-center tw-text-center tw-items-center tw-flex-col"
									controlName="password"
									label="{{ 'login.password' | translate }}"
								>
									<ng-template valError="required" let-error="error"
										>{{'register.validator-error-msg.required' | translate}}</ng-template
									>
									<ng-template valError="password" let-error="error"
										>{{'login.incorrect_pass' | translate}}</ng-template
									>
									<ng-template valError="minlength" let-error="error"
										>{{'login.min_length_password' | translate:{charNbr: minLengthPasswordChar}
										}}</ng-template
									>
								</val-errors>
								}
							</ion-col>
						</ion-row>
						<br />
						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="4" push-md="4" push-lg="4">
								<div class="term-conditions">
									<ion-checkbox color="primary" formControlName="termCheck"></ion-checkbox>
									<ion-label
										class="p-l-10"
										[ngClass]="passwordView ? 'color-2nd-screen' : 'color-1st-screen' "
									>
										{{"login.accept_term" | translate}}&nbsp;
										<a
											class="conditions-class underline"
											ion-button
											clear
											icon-only
											(click)="openModalTermsOrPrivacy('terms')"
											>{{"login.terms_of_use" | translate}}</a
										>
										{{"labels.and" | translate}}&nbsp;
										<a
											class="conditions-class underline"
											ion-button
											clear
											icon-only
											(click)="openModalTermsOrPrivacy('privacy')"
											>{{"login.privacy_txt" | translate}}</a
										>
									</ion-label>
								</div>
							</ion-col>
						</ion-row>
						<br />
						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="4" push-md="4" push-lg="4" text-center>
								<ion-button
									shape="round"
									class="btn-default access-btn"
									(click)="createAccount()"
									[disabled]="activeLoader || !loginForm.get('password').valid
                                    || loginForm.getRawValue().password !== loginForm.getRawValue().confirmPassword || !loginForm.getRawValue().termCheck"
								>
									<span *ngIf="!activeLoader">{{"login.btn_make_pass" | translate}}</span>
									<ion-spinner name="crescent" color="secondary" *ngIf="activeLoader"></ion-spinner>
								</ion-button>
							</ion-col>
						</ion-row>
						<!-- forgot password  -->
						<ion-row *ngIf="passwordView">
							<ion-col size-sm="12" size-md="6" size-lg="4" offset-md="4" offset-lg="4">
								<a
									class="forgot-password"
									[ngClass]="passwordView ? 'color-2nd-screen' : 'color-1st-screen'"
									(click)="recoveryPassword()"
									>{{"login.forgot_pass" | translate}}</a
								>
							</ion-col>
						</ion-row>
					</ion-grid>
				</div>
			</form>

			@if (container.showBottomMessageOnLoginPage && container.bottomMessageOnLoginPage) {
			<div class="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-center">
				<div
					class="tw-my-3"
					[class.tw-w-[50%]]="!isMobile"
					[class.tw-w-full]="isMobile"
					[innerHTML]="container.bottomMessageOnLoginPage | safeHtml"
				></div>
			</div>
			}
		</div>
	</div>
</ion-content>
