import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, OnChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderState, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeHeader } from "src/app/shared/enums/type-header";
import {
	IEvent,
	IEventUser,
	IFeedback,
	IFeedbackQuestion,
	IFeedbackQuestionAnswer,
	IFeedbackQuestionResult,
	IModule
} from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificFeedback } from "src/app/shared/selectors/generics-modules-data.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { FeedbacksService, FirestoreService, UtilityService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-feedback",
    templateUrl: "./feedback.component.html",
    styleUrls: ["./feedback.component.scss"],
    standalone: false
})
export class FeedbackComponent implements OnInit, OnDestroy, OnChanges {
	subscriptions: Subscription[] = [];

	loadingSend: boolean = false;

	@Input() componentMode: boolean = false;
	@Output() changeView: EventEmitter<{ segmentType: string; type: string; uid: string }> = new EventEmitter();

	@Input() eventId: string;
	event: IEvent;
	@Input() moduleId: string;
	module: IModule;
	@Input() feedbackId: string;
	feedback: IFeedback;
	@Input() sessionId: string;
	eventUser: IEventUser;
	@Input() showBackBtn: boolean;

	viewOnly: boolean = true;

	isMobile: boolean = false;

	questionsForm: UntypedFormGroup;

	currentLanguage: string = environment.platform.defaultLanguage;

	constructor(
		private platform: Platform,
		private store: Store,
		private SFeedbacks: FeedbacksService,
		private STranslate: TranslateService,
		private SUtility: UtilityService,
		private fb: UntypedFormBuilder,
		private SFirestore: FirestoreService
	) {
		this.questionsForm = this.fb.group({});
	}

	ngOnInit() {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) ||
			this.platform.is("mobileweb") ||
			window.innerWidth < 768
				? true
				: false;
		if (this.componentMode) {
			this.currentLanguage = this.STranslate.currentLang;
			this.subscriptions.push(
				this.STranslate.onLangChange.subscribe((lang) => {
					this.currentLanguage = lang.lang;
				})
			);
			this.initDatas();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes.feedbackId && !changes.feedbackId.firstChange) {
			this.subscriptions.forEach((sub) => sub?.unsubscribe());
			this.event = null;
			this.module = null;
			this.feedback = null;
			this.eventUser = null;
			this.initDatas();
		}
	}

	ionViewWillEnter() {
		if (!this.componentMode) {
			this.currentLanguage = this.STranslate.currentLang;
			this.subscriptions.push(
				this.STranslate.onLangChange.subscribe((lang) => {
					this.currentLanguage = lang.lang;
				})
			);
			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe(() => {
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.moduleId = params.moduleId;
							this.feedbackId = params.feedbackId;

							this.initDatas();
						});
				});
		}
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		this.getFeedback();
	}

	ionViewWillLeave() {
		if (!this.componentMode) {
			this.store.dispatch(ResetHeaderState(null));
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				this.module = module;
			})
		);
	}

	/**
	 * Get feedback
	 */
	getFeedback() {
		this.subscriptions.push(
			this.store.select(getSpecificFeedback(this.feedbackId)).subscribe((feedback) => {
				this.feedback = feedback;
				if (!this.feedback || (this.feedback && !this.feedback.visibility)) {
					this.goBackToList();
				}
				if (this.feedback) {
					this.patchResultsForm();

					if (!this.componentMode) {
						this.store.dispatch(
							GetHeaderState({
								payload: {
									item: this.feedback,
									title: this.feedback.name,
									type: TypeHeader.SURVEY
								}
							})
						);
					}
				}
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				this.eventUser = eventUser;
			})
		);
	}

	/**
	 * Patch form with all feedback result
	 */
	patchResultsForm() {
		this.questionsForm = this.fb.group({});
		this.feedback.questions.forEach((question) => {
			const resultOfQuestion = this.getResultOfQuestion(question);

			if (question.type === "oneSelect") {
				this.questionsForm.addControl(
					question.uid,
					new UntypedFormControl({
						value: resultOfQuestion ? resultOfQuestion.oneSelect.answerId : "",
						disabled: !this.checkCanChangeAnswer(question)
					})
				);
			} else if (question.type === "multipleSelect") {
				this.questionsForm.addControl(
					question.uid,
					new UntypedFormControl({
						value: resultOfQuestion ? resultOfQuestion.multipleSelect.answersIds : [],
						disabled: !this.checkCanChangeAnswer(question)
					})
				);
			} else if (question.type === "dissertative") {
				this.questionsForm.addControl(
					question.uid,
					new UntypedFormControl({
						value: resultOfQuestion ? resultOfQuestion.dissertative : "",
						disabled: !this.checkCanChangeAnswer(question)
					})
				);
			} else if (question.type === "yesOrNo") {
				this.questionsForm.addControl(
					question.uid,
					new UntypedFormControl({
						value: resultOfQuestion ? resultOfQuestion.yesOrNo : "",
						disabled: !this.checkCanChangeAnswer(question)
					})
				);
			} else if (question.type === "evaluation") {
				this.questionsForm.addControl(
					question.uid,
					new UntypedFormControl({
						value: resultOfQuestion ? resultOfQuestion.evaluation : 0,
						disabled: !this.checkCanChangeAnswer(question)
					})
				);
			}
		});
	}

	/**
	 * Chek if can change answer
	 */
	checkCanChangeAnswer(question: IFeedbackQuestion) {
		const result = this.getResultOfQuestion(question);
		return result && !this.feedback.changeAnswers ? false : true;
	}

	checkCanChangeAnswerMsg(question: IFeedbackQuestion) {
		!this.checkCanChangeAnswer(question) &&
			this.SUtility.presentToast(this.STranslate.instant("alerts.not_change_answer"), 3000, "bottom", "success");
	}

	/**
	 * CHeck checkbox for multiple select
	 * @param question
	 */
	checkCheckbox(question: IFeedbackQuestion, answer: IFeedbackQuestionAnswer) {
		const answersIds = this.questionsForm.get(question.uid).value;
		return answersIds.includes(answer.uid) ? true : false;
	}

	/**
	 * Update checkbox
	 * @param question
	 * @param answer
	 * @param event
	 */
	updateCheckboxs(question: IFeedbackQuestion, answer: IFeedbackQuestionAnswer, event: any) {
		let answersIds = this.questionsForm.get(question.uid).value;
		if (event.detail.checked) {
			if (!answersIds.includes(answer.uid)) {
				answersIds.push(answer.uid);
			}
		} else {
			if (answersIds.includes(answer.uid)) {
				answersIds = answersIds.filter((uid) => uid !== answer.uid);
			}
		}
		this.questionsForm.get(question.uid).patchValue(answersIds);
	}

	/**
	 * Check if can send feedback
	 * @returns
	 */
	checkCanSendFeedback() {
		// let check = true;
		// this.feedback.questions.forEach((question) => {
		// 	const result = this.getResultOfQuestion(question);
		// 	if (!result) {
		// 		check = false;
		// 	}
		// });
		const check = this.feedback.questions.every((question) => this.getResultOfQuestion(question));
		return this.feedback.changeAnswers || (!this.feedback.changeAnswers && !check) ? true : false;
	}

	/**
	 * Get result of question
	 * @param question
	 * @returns
	 */
	getResultOfQuestion(question: IFeedbackQuestion) {
		return this.feedback.eventUserResults.find(
			(result) => result.questionId === question.uid && result.sessionId === this.sessionId
		);
	}

	/**
	 * Send feedback
	 */
	async sendFeedback() {
		try {
			const results: IFeedbackQuestionResult[] = [];
			const datas = this.questionsForm.value;
			for (const key of Object.keys(datas)) {
				const formResult = datas[key];
				const result = this.feedback.eventUserResults.find(
					(res) =>
						res.questionId === key && res.sessionId === this.sessionId && res.userId === this.eventUser.uid
				);
				const question = this.feedback.questions.find((question) => question.uid === key);
				if (result) {
					if (question.type === "oneSelect") {
						result.oneSelect = {
							answerId: formResult
						};
					} else if (question.type === "multipleSelect") {
						result.multipleSelect = {
							answersIds: formResult
						};
					} else if (question.type === "dissertative") {
						result.dissertative = formResult;
					} else if (question.type === "yesOrNo") {
						result.yesOrNo = formResult;
					} else if (question.type === "evaluation") {
						result.evaluation = formResult;
					}
					results.push(result);
				} else if (
					(question.type === "multipleSelect" && formResult.length > 0) ||
					(question.type !== "multipleSelect" && formResult)
				) {
					const newResult: IFeedbackQuestionResult = {
						uid: this.SFirestore.createId(`events/${this.eventId}/modules/${this.moduleId}/feedbacks`),
						creationDate: DateTime.local().toISO(),
						dissertative: question.type === "dissertative" ? formResult : null,
						evaluation: question.type === "evaluation" ? formResult : null,
						eventId: this.eventId,
						moduleId: this.moduleId,
						multipleSelect:
							question.type === "multipleSelect"
								? {
										answersIds: formResult
								  }
								: null,
						oneSelect:
							question.type === "oneSelect"
								? {
										answerId: formResult
								  }
								: null,
						questionId: question.uid,
						feedbackId: this.feedback.uid,
						sessionId: this.sessionId,
						type: question.type,
						userId: this.eventUser.uid,
						yesOrNo: question.type === "yesOrNo" ? formResult : null
					};
					results.push(newResult);
				}
			}
			await this.SFeedbacks.createFeedbackResults(this.eventId, this.moduleId, this.feedbackId, results);

			this.SUtility.presentToast(this.STranslate.instant("feedbacks.feedback_send"), 3000, "bottom", "success");
			this.goBackToList();
		} catch (error) {
			this.SUtility.presentToast(
				this.STranslate.instant("feedbacks.cannot_send_feedback"),
				3000,
				"bottom",
				"danger"
			);
		}
	}

	/**
	 * Go back to feedbacks list
	 */
	goBackToList() {
		if (this.componentMode) {
			this.changeView.emit({ segmentType: "feedbacks", type: "feedbacks", uid: "" });
		} else {
			this.SUtility.callGoBackOnHeader();
		}
	}
}
