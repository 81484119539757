import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import Player from "@vimeo/player";
import { BehaviorSubject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import {
	UpdateBasicVideoPlayer,
	UpdateBasicVideoPlayerState,
	UpdateBasicVideoPlayerTime
} from "src/app/shared/actions/video-player.actions";
import { IEvent } from "src/app/shared/interfaces";
import { IVideoPlayerSettings } from "src/app/shared/interfaces/video-player.interfaces";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getBaseVideoPlayer, getBasicVideoPlayerState } from "src/app/shared/selectors/video-player.selectors";

@Component({
    selector: "app-video-player",
    templateUrl: "./video-player.component.html",
    styleUrls: ["./video-player.component.scss"],
    standalone: false
})
export class VideoPlayerComponent implements OnInit, OnDestroy, AfterViewInit {
	subscriptions: Subscription[] = [];
	@ViewChild("videoPlayer", { static: false }) videoPlayer: any;
	@ViewChild("youtubeVideoPlayerContainer") youtubeVideoPlayerContainer: ElementRef;

	src: string;
	type: string;
	start: number = 0;
	width: number = 640;
	height: number = 360;

	event: IEvent;

	vimeoPlayer: Player;

	/**
	 * When true, the player is hidden (css)
	 * avoiding performance issues when dragging
	 */
	moving: boolean = false;
	init: boolean = false;
	videoPlayerState: IVideoPlayerSettings;
	youtubeState: number = -1;

	youtubePlayerReady: boolean = false;
	isMobile: boolean;
	frame: HTMLElement;
	canLoadYtPlayer: boolean = false;

	constructor(private store: Store, private platform: Platform) {
		this.isMobile =
			this.platform.is("ios") || this.platform.is("android") || this.platform.is("mobileweb") ? true : false;
	}
	ngAfterViewInit(): void {
		setTimeout(() => {
			this.getWidth();
			this.canLoadYtPlayer = true;
		}, 1000);
	}

	ngOnInit() {
		this.getEvent();
		this.subscriptions.push(
			this.store.select(getBaseVideoPlayer).subscribe((videoPlayerState) => {
				if (videoPlayerState && videoPlayerState.src && videoPlayerState.type) {
					this.videoPlayerState = videoPlayerState;
					this.type = videoPlayerState.type ? videoPlayerState.type : "";
					this.src = videoPlayerState.src
						? this.type === "youtube"
							? videoPlayerState.src.split("embed/")[1]?.split('"')?.[0].split("?")[0]
							: this.type === "vimeo"
							? videoPlayerState.src.split("https://player.vimeo.com/video/")[1]?.split("?")?.[0]
							: this.type === "iframe"
							? videoPlayerState.src.split('src="')[1]?.split('"')?.[0]
							: videoPlayerState.src
						: "";
					this.start = videoPlayerState.start ? videoPlayerState.start : 0;

					// On youtube player seek and play video on update time or state if start > 0 (so already played)
					if (this.type === "youtube" && this.youtubePlayerReady && this.start && this.start > 0) {
						this.seekToYoutubeVideo(this.start, true);
						if (this.youtubeState === 1) {
							this.playYoutubeVideo();
						} else if (this.youtubeState === 2 || this.youtubeState === -1) {
							this.pauseWithoutUpdateYoutubeVideo();
						}
					}

					if (this.type === "vimeo" && !this.init) {
						if (!this.vimeoPlayer) {
							setTimeout(() => {
								this.initVimeoVideo();
							}, 200);
						} else {
							this.vimeoPlayer.setCurrentTime(this.start);
						}
					}
					this.init = true;
				} else if (this.init) {
					this.destroyPlayer();
				}
			})
		);

		// Get state of youtube player
		this.subscriptions.push(
			this.store.select(getBasicVideoPlayerState).subscribe((state) => {
				if (this.type && this.type === "youtube") {
					this.youtubeState = state;
				}
			})
		);
	}

	ngOnDestroy() {
		if (this.init) {
			this.destroyPlayer();
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		if (this.stateChangedSubscription && !this.stateChangedSubscription.closed)
			this.stateChangedSubscription.unsubscribe();
	}

	/**
	 * Get current event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 *
	 * Youtube part
	 *
	 */

	/**
	 * On ready youtube video player
	 * @param evt
	 */
	onReady(evt) {
		if (evt.target.playerInfo.playerState === 5 || evt.target.playerInfo.playerState === -1) {
			this.youtubePlayerReady = true;

			// Pause video directly on ready / no auto play
			this.pauseWithoutUpdateYoutubeVideo();

			if (this.start > 0) {
				this.seekToYoutubeVideo(this.start, true);
			}
		}
	}

	stateChangedSubject: BehaviorSubject<any> = new BehaviorSubject(null);
	stateChangedSubscription: Subscription;
	/**
	 * On state change of youtube video player
	 * @param evt
	 */
	stateChange() {
		if (!this.stateChangedSubscription || this.stateChangedSubscription.closed) {
			this.stateChangedSubscription = this.stateChangedSubject.pipe(debounceTime(300)).subscribe((evt) => {
				// -1 = unstarted, 1 = playing, 2 = Paused, 3 = buffering, 5 = cued
				if (evt.data === -1 || evt.data === 5) {
					this.youtubePlayerReady = true;
					this.pauseWithoutUpdateYoutubeVideo();

					if (this.start > 0) {
						this.seekToYoutubeVideo(this.start, true);
					}
				}

				if (this.youtubeState === 2) {
					// setTimeout(() => {
					this.pauseWithoutUpdateYoutubeVideo();
					// }, 200);
				}

				if (evt.data === 1 || evt.data === 2) {
					this.store.dispatch(UpdateBasicVideoPlayerState({ payload: evt.data }));
				}
			});
		}
	}

	onError(_e: any) {
		this.youtubePlayerReady = false;
	}

	/**
	 * Play youtube video
	 */
	playYoutubeVideo() {
		if (this.youtubePlayerReady) {
			this.videoPlayer.playVideo();
		}
	}

	/**
	 * Pause youtube video and update time
	 */
	pauseYoutubeVideo() {
		if (this.youtubePlayerReady) {
			this.videoPlayer.pauseVideo();
			this.updateCurrentTimeYoutubeVideo();
		}
	}

	/**
	 * Pause youtube video
	 */
	pauseWithoutUpdateYoutubeVideo() {
		this.videoPlayer.pauseVideo();
	}

	/**
	 * Stop and unload video
	 */
	stopYoutubeVideo() {
		if (this.youtubePlayerReady) {
			this.videoPlayer.stopVideo();
		}
	}

	/**
	 * Go to number of seconds specified of the video
	 * @param seconds
	 * @param allowSeekAhead
	 */
	seekToYoutubeVideo(seconds: number, allowSeekAhead: boolean) {
		if (this.youtubePlayerReady) {
			this.videoPlayer.seekTo(seconds, allowSeekAhead);
		}
	}

	/**
	 * Get current time of youtube video
	 */
	getCurrentTimeYoutubeVideo() {
		if (this.youtubePlayerReady) {
			return this.videoPlayer.getCurrentTime();
		}
	}

	/**
	 * Update current time of a youtube video on store
	 */
	updateCurrentTimeYoutubeVideo() {
		if (this.youtubePlayerReady) {
			const time = this.videoPlayer.getCurrentTime();
			this.store.dispatch(UpdateBasicVideoPlayerTime({ payload: time }));
		}
	}

	/**
	 * Destroy player
	 */
	destroyPlayer() {
		// let time: number = 0;
		// if (this.videoPlayerState.type === "youtube" && this.videoPlayer && this.youtubeState !== -1) {
		// 	time = this.videoPlayer.getCurrentTime();
		// } else if (this.videoPlayerState.type === "vimeo" && this.vimeoPlayer) {
		// 	time = await this.vimeoPlayer.getCurrentTime();
		// } else {
		// 	time =
		// 		this.videoPlayer && this.videoPlayer.nativeElement && this.videoPlayer.nativeElement.currentTime
		// 			? this.videoPlayer.nativeElement.currentTime
		// 			: 0;
		// }
		// // Update mini video player if video started
		// if (time > 0) {
		// 	this.store
		// 		.select(getMiniVideoPlayerActivated)
		// 		.pipe(
		// 			take(1),
		// 			switchMap((activated) => {
		// 				// If mini player activated passed it to false for destroying it and recreating new player
		// 				if (activated) {
		// 					this.store.dispatch(UpdateMiniVideoPlayerActivated({ payload: false }));
		// 				}
		// 				return of(activated);
		// 			}),
		// 			delay(100)
		// 		)
		// 		.subscribe(() => {
		// 			// After a delay (for the change coming and the player will be destroyed),
		// 			// update the state of the mini player to the new datas
		// 			this.store.dispatch(
		// 				UpdateMiniVideoPlayer({
		// 					payload: {
		// 						activated: time > 0 ? true : false,
		// 						link: {
		// 							moduleType: this.videoPlayerState.link.moduleType,
		// 							moduleId: this.videoPlayerState.link.moduleId,
		// 							itemId: this.videoPlayerState.link.itemId
		// 						},
		// 						src: this.videoPlayerState.src,
		// 						start: time,
		// 						options: {
		// 							state: this.videoPlayerState.type === "youtube" ? this.youtubeState : -1
		// 						},
		// 						type: this.videoPlayerState.type
		// 					}
		// 				})
		// 			);
		// 		});
		// }

		this.src = "";
		this.type = "";
		this.start = 0;
		this.youtubeState = -1;
		if (this.youtubePlayerReady) {
			this.stopYoutubeVideo();
		}
		if (this.vimeoPlayer) {
			this.vimeoPlayer.destroy();
			this.vimeoPlayer = null;
		}

		this.init = false;
	}

	/**
	 *
	 * Vimeo part
	 *
	 */

	/**
	 * Init a vimeo video
	 */
	initVimeoVideo() {
		if (this.src.includes("https")) {
			this.vimeoPlayer = new Player(this.videoPlayer.nativeElement, {
				width: this.getWidth(),
				height: this.getHeight(),
				url: this.src
			});
		} else {
			this.vimeoPlayer = new Player(this.videoPlayer.nativeElement, {
				width: this.getWidth(),
				height: this.getHeight(),
				id: this.src
			});
		}

		this.vimeoPlayer.setCurrentTime(this.start);
		if (this.start > 0) {
			this.vimeoPlayer.play();
		}

		this.vimeoPlayer.on("timeupdate", (currentTime) => {
			this.store.dispatch(UpdateBasicVideoPlayerTime({ payload: Math.round(currentTime.seconds) }));
		});
	}

	/**
	 * Get width of video player
	 * @returns
	 */
	getWidth() {
		if (this.platform.is("ios") || this.platform.is("android")) {
			return this.platform.width() * 0.9;
		} else {
			if (this.type === "vimeo") {
				const parentWidth = document.getElementById("videoPlayer").clientWidth;

				return parentWidth && parentWidth > 0 ? parentWidth : 950;
			} else {
				return this.youtubeVideoPlayerContainer && this.youtubeVideoPlayerContainer?.nativeElement
					? this.youtubeVideoPlayerContainer?.nativeElement?.clientWidth
					: undefined;
			}
		}
	}

	/**
	 * Get height of video player
	 * @returns
	 */
	getHeight() {
		if (this.platform.is("ios") || this.platform.is("android")) {
			return this.platform.height() * 0.4;
		} else {
			if (this.type === "vimeo") {
				return 650;
			} else {
				return (this.platform.height() * 80) / 100;
			}
		}
	}

	/**
	 * If true, the iframe is displayed.
	 * Otherwise, the <video></video>
	 */
	isYoutubeOrVimeo() {
		// youtu because can be youtube or youtu.be
		return ["youtu", "vimeo"].some((p) => (this.src || "").includes(p));
	}

	onCloseClick() {
		this.store.dispatch(
			UpdateBasicVideoPlayer({
				payload: {
					activated: false,
					link: {
						moduleType: null,
						moduleId: null,
						itemId: null
					},
					options: {
						state: null
					},
					src: null,
					start: null,
					type: null
				}
			})
		);
	}

	getIframeVideoHeight() {
		if (this.isMobile) {
			return this.platform.height() - 20;
		} else {
			return this.type === "iframe"
				? Number(this.videoPlayerState.src.split('height="')[1]?.split('"')?.[0]) - 50
				: 650;
		}
	}

	getIframeVideoWidth() {
		if (this.isMobile) {
			return this.platform.width() - 20;
		} else {
			return "100%";
		}
	}
}
