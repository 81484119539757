<app-mini-video-player *ngIf="!loader && videoPlayerActivated"> </app-mini-video-player>
<link *ngIf="customFont" [href]="customFont" rel="stylesheet" />

<ion-split-pane
	*ngIf="event() && !(event().settings.requiredSignup && !user)"
	contentId="main-content"
	[disabled]="queryParams() && queryParams().menu === 'false' ? true : false"
>
	<ion-menu
		[hidden]="!menuAndHeaderVisibility"
		swipeGesture="false"
		*ngIf="(isMobile() && event().settings.activateBurgerMenuOnMobile) || (!isMobile() && event().settings.activateBurgerMenuOnDesktop)"
		contentId="main-content"
		[class.show-menu]="menuActivated"
		[class.menu-closed]="!menuActivated"
		(mouseenter)="!queryParams() || queryParams().menu !== 'false' ? mouseEnterMenu(): ''"
		(mouseleave)="!queryParams() || queryParams().menu !== 'false' ? mouseLeaveMenu(): ''"
		(ionWillOpen)="menuOpen()"
		(ionWillClose)="menuClose()"
	>
		<ion-header mode="md">
			<ion-toolbar class="toolbar-bottom-border basic-toolbar" mode="md">
				<ion-avatar
					(click)="showProfile();closeMenuOnMobile()"
					[slot]="menuActivated ? 'start' : ''"
					[style.background]="'rgb(234, 239, 242)'"
					*ngIf="eventUser"
					[style.margin]="!menuActivated ? 'auto' : '0 0 0 10px'"
					class="menu-user-avatar"
				>
					<div
						class="is-online"
						*ngIf="event().settings.enableOnlineStatus && eventUser && eventUser.updatedSettings && eventUser.updatedSettings.connected"
					></div>
					<img style="height: 100%; width: 100%" [src]="eventUser.photoUrl" *ngIf="eventUser.photoUrl" />
					<div class="profile-picture" *ngIf="!eventUser.photoUrl">
						<ion-img class="cus-picture" src="assets/icon/User2.svg"></ion-img>
					</div>
				</ion-avatar>
				<ion-avatar *ngIf="!eventUser && !menuActivated" class="menu-user-avatar" style="margin: auto">
					<div class="profile-picture">
						<div
							class="is-online"
							*ngIf="event().settings.enableOnlineStatus && eventUser && eventUser.updatedSettings && eventUser.updatedSettings.connected"
						></div>
						<ion-icon
							class="cus-picture"
							src="assets/icon/User Add.svg"
							style="width: 17px; height: 17px"
							[style.color]="event().styling.menuTextColor"
						></ion-icon>
					</div>
				</ion-avatar>

				<ion-buttons *ngIf="menuActivated" slot="end">
					<ion-menu-button
						*ngIf="!isMobile()"
						[style.color]="event().styling.menuTextColorGradient"
						(click)="hamburgerClicked(!hamburgerActivated)"
					>
					</ion-menu-button>
					<ion-button
						*ngIf="eventUser && event() && event().settings && !event().settings.hideChat"
						slot="icon-only"
						(click)="openModule(chatsModule);closeMenuOnMobile()"
						class="btn-notifs-menu"
						[style.color]="event().styling.menuTextColorGradient"
					>
						<ion-icon src="assets/icon/Message.svg"></ion-icon>
						<div id="notify-badge-menu" *ngIf="chatsBadge > 0"></div>
					</ion-button>
					<ion-button
						*ngIf="eventUser"
						slot="icon-only"
						(click)="openNotifications();closeMenuOnMobile()"
						class="btn-notifs-menu"
						[style.color]="event().styling.menuTextColorGradient"
					>
						<ion-icon src="assets/icon/Bell.svg"></ion-icon>
						<div id="notify-badge-menu" *ngIf="notificationsBadge > 0"></div>
					</ion-button>
				</ion-buttons>
				<ion-buttons *ngIf="!eventUser && menuActivated" class="auth-buttons">
					<ion-button
						*ngIf="event().settings.allowPublicLogin"
						shape="round"
						(click)="openLogin(true);closeMenuOnMobile()"
						[style.color]="event().styling.menuTextColorGradient"
						[style.border]="'2px solid ' + event().styling.menuTextColorGradient"
						[style.margin]="!event().settings.allowPublicRegister && 'auto'"
					>
						{{"buttons.access" | translate}}
					</ion-button>
					<ion-button
						*ngIf="event().settings.defaultAttendeesModule && event().settings.defaultAttendeesModule !== '' && event().settings.allowPublicRegister"
						shape="round"
						(click)="openRegister(true);closeMenuOnMobile()"
						[style.color]="event().styling.menuColor.includes('linear') ? event().styling.menuColor.split(',')[1] : event().styling.menuColor"
						[style.background]="event().styling.menuTextColorGradient"
						[style.margin]="!event().settings.allowPublicLogin && 'auto'"
					>
						{{"buttons.register" | translate}}
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content [style.--background]="'var(--bg-header)'">
			<ion-menu-toggle auto-hide="false">
				<ion-img
					[src]="event().identity.banner"
					*ngIf="event().styling.bannerMenuVisibility && event().identity.banner && menuActivated"
					style="max-height: 200px"
				>
				</ion-img>
				<ion-list class="menu-btn-style basic-toolbar" [style.background]="'transparent'" lines="none">
					<ng-container *ngFor="let module of menuModules; trackBy: trackById">
						<ion-item
							[id]="module.uid"
							*ngIf="module.type !== typesModules.DIVIDER"
							[detail]="menuActivated"
							style="--detail-icon-opacity: 0.4"
							[hidden]="checkShowModule(module) | async"
							[style.--color]="module.uid === activeModuleId ? event().styling.menuActiveColor : event().styling.menuTextColor"
							[class.left-bar]="module.uid === activeModuleId"
							(click)="openModule(module)"
							class="no-paddings basic-toolbar"
						>
							<div
								class="menu-item-active"
								*ngIf="module.uid === activeModuleId"
								[style.background]="event().styling.menuActiveColor"
							></div>
							<div class="menu-list-items" [class.menu-items]="!menuActivated">
								@if (module.iconFamily === 'material-icons') {
								<i class="material-icons menu-icon"> {{ module.icon }} </i>
								} @if (module.iconFamily === 'custom-icon') {
								<span>
									<img [src]="module.icon" class="menu-icon-img" />
								</span>
								} @if(module.iconFamily !== 'custom-icon' && module.iconFamily !== 'material-icons') {
								<span class="b3-icons {{ module.iconFamily }} menu-icon"></span>
								} @if (menuActivated) {
								<ion-label [style.font-weight]="module.uid === activeModuleId ? 600 : 500">
									&nbsp;&nbsp; {{ module?.name[currentLanguage] }}
								</ion-label>
								} @if (module.type === typeModule.NEWS_FEED && getNewsCount(module) > 0) {
								<ion-badge style="margin-left: 5px" color="warning"
									>{{ getNewsCount(module) }}</ion-badge
								>
								}
							</div>
						</ion-item>
						<div
							*ngIf="module.type === typesModules.DIVIDER"
							[style.border-top]="'solid 1px ' + event().styling.menuTextColor"
							style="pointer-events: none"
						></div>
					</ng-container>
					<div class="legal-btn" *ngIf="event().showLegalButton">
						<a
							class="btn-legal-custom"
							[hidden]="!menuActivated"
							[style.color]="event().styling.menuTextColor"
							(click)="openLegalTerms()"
						>
							{{event().legalButtonName && event().legalButtonName[currentLanguage] ?
							event().legalButtonName[currentLanguage] : ("labels.legal_mentions" | translate) }}</a
						>
					</div>
					<div
						[hidden]="!menuActivated"
						style="text-align: center"
						*ngIf="event().mentionOfCredits && event().mentionOfCredits.showMention"
						[style.color]="event().styling.menuTextColor"
					>
						<div style="font-size: 14px">{{ event().mentionOfCredits.plainText[currentLanguage] }}</div>
						<div>
							<span *ngIf="event().mentionOfCredits.type === 1"
								>{{event().mentionOfCredits.creditText}}</span
							>
							<img
								*ngIf="event().mentionOfCredits.type === 2"
								style="width: 50%"
								[src]="event().mentionOfCredits.logo || event().mentionOfCredits.logo !== null ? event().mentionOfCredits.logo : event().mentionOfCredits.defaultLogo "
							/>
						</div>
					</div>

					@if (version) {
					<div
						[hidden]="!menuActivated"
						style="text-align: center"
						[style.color]="event().styling.menuTextColor"
					>
						<div style="font-size: 14px">{{ version }}</div>
					</div>
					}
				</ion-list>
			</ion-menu-toggle>
		</ion-content>

		<ion-footer
			class="menu-footer"
			[style.background]="'var(--bg-header)'"
			[style.color]="event().styling.menuTextColorGradient"
		>
			<ion-buttons class="btns-log-back" [style.width]="!menuActivated ? '100%' : '70%'" slot="start" mode="md">
				<ion-button
					shape="round"
					(click)="confirmRedirectListEvents()"
					mode="md"
					*ngIf="user && events.length > 1"
				>
					<ion-icon src="assets/icon/ArrowLeft.svg"></ion-icon>
					<span [hidden]="!menuActivated">{{ "buttons.back_to_list_event" | translate }}</span>
				</ion-button>
				<ion-button shape="round" (click)="goBackToLogin()" mode="md" *ngIf="!user && events.length > 1">
					<ion-icon src="assets/icon/ArrowLeft.svg"></ion-icon>
					<span [hidden]="!menuActivated">{{ "buttons.back-to-login" | translate }}</span>
				</ion-button>
				<ion-button shape="round" (click)="logOut()" mode="md" *ngIf="user">
					<ion-icon src="assets/icon/Power.svg"></ion-icon>
					<span [hidden]="!menuActivated">{{ "buttons.log_out" | translate }}</span>
				</ion-button>
			</ion-buttons>
			<ion-buttons *ngIf="menuActivated" mode="md">
				<ion-button
					*ngIf="languages.length > 1"
					(click)="changeLanguage()"
					class="btn-menu-lang"
					[style.border-color]="event().styling.menuTextColorGradient"
					mode="md"
				>
					<span class="lang-name">{{ language | slice:0:2 }}</span>
					<ion-icon src="assets/icon/ArrowDown.svg"></ion-icon>
				</ion-button>
			</ion-buttons>
		</ion-footer>
	</ion-menu>

	<div id="main-content">
		<app-header
			[hidden]="!menuAndHeaderVisibility"
			style="height: fit-content"
			*ngIf="!queryParams() || queryParams().header !== 'false'"
			[currentUrl]=" currentUrl"
			(elementHeight)="headerHeight = $event;"
			(hamburgerClickedEvent)="hamburgerClicked($event)"
		>
		</app-header>
		<!-- <div class="main-content custom-scrollbar" [style.background-color]="getBackgroundColor()"
            [style.background-image]="event && event().styling.backgroundImage && event().styling.allowBackgroundImage ? ('url(' + event().styling.backgroundImage | safeHtml: 'resourceUrl' + ')') : ''"
            style="position: relative; flex-grow: 1; width: 100vw;"> -->

		<ion-tabs
			style="position: relative; flex-grow: 1"
			[ngStyle]="{'width': isMobile() && deviceWidth < 768 ? '100vw' : ''}"
			[style.background-color]="getBackgroundColor()"
			[style.background-image]="event() && event().styling.backgroundImage && event().styling.allowBackgroundImage ? ('url(' + event().styling.backgroundImage | safeHtml: 'resourceUrl' + ')') : ''"
		>
			<!-- <ion-router-outlet style="flex-grow: 1"></ion-router-outlet> -->
			@if(showNavBar) {
			<ion-tab-bar
				[hidden]="!menuAndHeaderVisibility"
				[style.--background]="event().styling.menuColor"
				slot="bottom"
			>
				<ion-tab-button
					*ngFor="let link of event().settings.navigationBarLinks"
					(click)="openModule(getModuleByUid(link.moduleId))"
					[style.--background]="'transparent'"
					[style.--background-focused]="'transparent'"
					[style.--color]="getModuleByUid(link.moduleId)?.uid === activeModuleId ? event().styling.menuActiveColor : event().styling.menuTextColor"
					[style.--color-focused]="getModuleByUid(link.moduleId)?.uid === activeModuleId ? event().styling.menuActiveColor : event().styling.menuTextColor"
					[style.--color-selected]="getModuleByUid(link.moduleId)?.uid === activeModuleId ? event().styling.menuActiveColor : event().styling.menuTextColor"
				>
					<ng-container *ngIf="getModuleByUid(link.moduleId) as module">
						<div class="menu-list-items" [class.menu-items]="!menuActivated">
							<i
								class="material-icons menu-icon"
								style="width: 24px; height: 24px; font-size: 24px; object-fit: cover"
								*ngIf="module.iconFamily === 'material-icons'"
							>
								{{ module.icon }}
							</i>

							<span *ngIf="module.iconFamily === 'custom-icon'">
								<img
									style="width: 24px; height: 24px; font-size: 24px; object-fit: cover"
									[src]="module.icon"
									class="menu-icon-img"
								/>
							</span>

							<span
								*ngIf="module.iconFamily !== 'custom-icon' && module.iconFamily !== 'material-icons'"
								style="width: 24px; height: 24px; font-size: 24px; object-fit: cover"
								class="b3-icons {{ module.iconFamily }} menu-icon"
							></span>
						</div>
						<span style="font-family: var(--font); white-space: nowrap"
							>{{ module?.name[currentLanguage] }}</span
						>
					</ng-container>
				</ion-tab-button>
			</ion-tab-bar>
			}
		</ion-tabs>
	</div>
</ion-split-pane>

<div
	*ngIf="event() && event().blocked"
	style="
		position: fixed;
		z-index: 99999;
		bottom: 0;
		left: calc(50% - 175px);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	"
>
	<div
		style="
			width: 350px;
			height: 60px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			background-color: orange;
			padding: 10px;
			border-radius: 5px;
			text-align: center;
			color: white;
			font-weight: bold;
		"
	>
		{{ "alerts.blocked-event-info" | translate }}
	</div>
</div>
