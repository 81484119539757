import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { where } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ModalController, NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { Subscription, combineLatest, firstValueFrom } from "rxjs";
import { map, skipWhile, take } from "rxjs/operators";
import { GetHeaderState, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { UpdateBasicVideoPlayer } from "src/app/shared/actions/video-player.actions";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { TypeModule } from "src/app/shared/enums/type-module";
import {
	ICustomFieldData,
	IEvent,
	IEventUser,
	IFullCustomField,
	IGroup,
	ILocation,
	IModule,
	IModuleCustomField,
	ISchedule,
	ITrack,
	IVisio
} from "src/app/shared/interfaces";
import { IFavoriteFolder } from "src/app/shared/interfaces/folders.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getBaseCustomFields,
	getFavoritesFolders,
	getGroupsByOrder,
	getLocations,
	getModulesCustomFields,
	getModulesCustomsFieldsOfModule,
	getTracks
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getModulesByType, getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectQueryParams, selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { getBasicVideoPlayerActivated } from "src/app/shared/selectors/video-player.selectors";
import { EventUsersService, FirestoreService, SchedulesService, UtilityService } from "src/app/shared/services";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { environment } from "src/environments/environment";
import { ConfirmIcsDownloadComponent } from "../../../modals/confirm-ics-download/confirm-ics-download.component";
import { getNetworkStatus } from "src/app/shared/selectors/utility.selectors";
import { VideoPlayerComponent } from "../../components/video-player/video-player.component";

@Component({
    selector: "app-session",
    templateUrl: "./session.component.html",
    styleUrls: ["./session.component.scss"],
    standalone: false
})
export class SessionComponent implements OnInit, OnDestroy {
	@ViewChild("videoPlayerComponent") videoPlayerComponent: VideoPlayerComponent;
	subscriptions: Subscription[] = [];
	sessionSubscription: Subscription;
	customFieldsSub: Subscription;
	sessionSub: Subscription;

	inApp: boolean = false;

	// Video player
	@ViewChild("videoComponent", { static: false }) videoComponent: any;

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	sessionId: string;
	session: ISchedule;
	sessions: ISchedule[] = [];
	visio: IVisio;
	eventUser: IEventUser;
	tracks: ITrack[] = [];
	locations: ILocation[] = [];
	computedCustomFields: IFullCustomField[] = [];

	typeModule = TypeModule;

	prevBtn: boolean = false;
	nextBtn: boolean = false;

	fullWidth: boolean = false;
	isMobile: boolean = false;
	videoPlayerActivated: boolean = false;

	currentLanguage: string = environment.platform.defaultLanguage;

	buttonIconSource: string;
	isButtonDisabled: boolean = false;
	buttonTextShow: string = "";

	registeredUsersCurrentSchedule: IEventUser[] = [];
	allCustomFieldsModules: IModuleCustomField[];

	userInit: boolean = false;
	groups: IGroup[] = [];
	groupIds: string[] = [];

	queryParams: any = {};
	favoriteModule: IModule;
	favoriteState: { [sessionId: string]: boolean };
	favoriteFolder: IFavoriteFolder;

	networkStatus: boolean = true;

	constructor(
		private SAnalytics: AnalyticsService,
		private store: Store,
		private modalCtrl: ModalController,
		private location: Location,
		private SSchedules: SchedulesService,
		public SUtility: UtilityService,
		private SFirestore: FirestoreService,
		public STranslate: TranslateService,
		private platform: Platform,
		private navCtrl: NavController,
		private SEventUsers: EventUsersService,
		private snackBar: MatSnackBar
	) {
		this.subscriptions.push(
			this.store.select(selectQueryParams).subscribe((queryParams) => {
				this.queryParams = queryParams;
			})
		);

		this.subscriptions.push(
			this.store.select(getNetworkStatus).subscribe((status) => {
				this.networkStatus = status;
			})
		);
	}

	ngOnInit() {
		this.isMobile = this.platform.is("mobile") && window.innerWidth < 768 ? true : false;
	}

	ionViewWillEnter() {
		this.userInit = false;
		this.currentLanguage = this.STranslate.currentLang;

		this.subscriptions.push(
			this.SAnalytics.appIsActive$.subscribe((state) => {
				state
					? this.SAnalytics.scheduleSessionAccess(this.eventId, this.moduleId, this.sessionId)
					: this.SAnalytics.scheduleSessionLeaving(
							this.eventId,
							this.moduleId,
							this.eventUser,
							this.sessionId
					  );
			})
		);

		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.sessionId = params.sessionId;

						// Tracking session access analytics
						this.SAnalytics.scheduleSessionAccess(this.eventId, this.moduleId, this.sessionId);

						this.initDatas();
					});
			});
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		this.getFavoriteModuleAndFolders();
		this.getTracks();
		this.getGroups();
		this.getLocations();
		this.getSession();
		this.getSessions();
		// this.checkFullWidth();

		this.subscriptions.push(
			this.store.select(getBasicVideoPlayerActivated).subscribe((activated) => {
				this.videoPlayerActivated = activated;
			})
		);
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		if (this.customFieldsSub && !this.customFieldsSub.closed) {
			this.customFieldsSub.unsubscribe();
		}
		if (this.sessionSubscription && !this.sessionSubscription.closed) {
			this.sessionSubscription.unsubscribe();
		}

		this.subscriptions.concat([this.sessionSub]).forEach((sub) => sub?.unsubscribe());
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		// Tracking session leaving analytics
		this.SAnalytics.scheduleSessionLeaving(this.eventId, this.moduleId, this.eventUser, this.sessionId);

		this.removeIframes();
		this.videoPlayerActivated = false;
		if (this.videoPlayerComponent) {
			this.videoPlayerComponent.destroyPlayer();
		}
		this.store.dispatch(
			UpdateBasicVideoPlayer({
				payload: {
					activated: false,
					link: {
						moduleType: null,
						moduleId: null,
						itemId: null
					},
					src: null,
					start: null,
					options: {
						state: null
					},
					type: null
				}
			})
		);
		// this.leavingSessionForPlayer();
		if (this.customFieldsSub && !this.customFieldsSub.closed) {
			this.customFieldsSub.unsubscribe();
		}
		this.subscriptions.concat([this.sessionSub]).forEach((sub) => sub?.unsubscribe());
		this.session = null;
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				this.module = module;
				if (this.module) {
					this.getSessions();
					this.SSchedules.sessionIsLastPrev(this.eventId, this.moduleId, this.sessionId)
						// this.store
						// 	.select(sessionIsLastPrev({ currentSessionId: this.sessionId, moduleId: this.moduleId }))
						.pipe(take(1))
						.subscribe((response) => {
							if (response.status == "error") {
								this.prevBtn = false;
								this.nextBtn = false;
							} else {
								this.prevBtn = response.first ? false : true;
								this.nextBtn = response.last ? false : true;
							}
						});
				}
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				this.eventUser = eventUser;
				this.userInit = true;
			})
		);
	}

	/**
	 * Get groups
	 */
	getGroups() {
		this.subscriptions.push(
			this.store.select(getGroupsByOrder("asc")).subscribe((groups) => {
				this.groups = groups;
				this.groupIds = this.groups.map((group) => group.uid);
			})
		);
	}
	/**
	 * getFavoriteModuleAndFolders
	 */
	getFavoriteModuleAndFolders() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getModulesByType(TypeModule.FAVORITES)),
				this.store.select(getFavoritesFolders)
			])
				.pipe(take(1))
				.subscribe(([modules, favoriteFolders]) => {
					if (modules && modules.length > 0) {
						this.favoriteModule = modules[0];
					}

					if (favoriteFolders && favoriteFolders.length > 0) {
						this.favoriteFolder = favoriteFolders.filter(
							(folder) => this.module && folder.moduleLinkedId === this.module.uid && !folder.hidden
						)?.[0];
					}
				})
		);
	}

	/**
	 * Get all eventUsers registered in this session.
	 */
	getEventUsersRegistered() {
		this.SEventUsers.getSpecificsEventUsers(this.eventId, _.uniq(this.session.registeredUsers))
			.pipe(take(1))
			.subscribe((eventUsers) => {
				if (!_.isEqual(this.registeredUsersCurrentSchedule, eventUsers)) {
					this.registeredUsersCurrentSchedule = eventUsers;
				}
			});
	}

	/**
	 * Get all sessions of module
	 */
	getSessions() {
		this.SSchedules.getSessionsOfModuleWithFilters(this.eventId, this.module.uid, null, false).subscribe(
			(sessions) => {
				this.sessions = sessions.datas;
			}
		);
	}

	/**
	 * Get session
	 */
	getSession() {
		if (this.sessionSubscription && !this.sessionSubscription.closed) {
			this.sessionSubscription.unsubscribe();
		}
		this.SSchedules.getSpecificSession(this.eventId, this.moduleId, this.sessionId)
			.pipe(skipWhile(() => !this.userInit))
			.subscribe((session) => {
				if (!_.isEqual(this.session, session)) {
					this.session = session;
					this.buildVisio();
					this.loadVideoPlayer();
					this.getCustomFields();

					if (this.session) {
						this.getEventUsersRegistered();
						this.checkFullWidth();
						this.buttonText();
					}
				}

				if (session) {
					this.buildFavoriteState(session.uid);
					this.store.dispatch(
						GetHeaderState({
							payload: {
								item: this.session,
								title: this.session.name,
								type: TypeHeader.SESSION
							}
						})
					);
				}
			});
	}

	/**
	 * Build visio object
	 */
	buildVisio() {
		this.visio =
			this.session && this.session.visio
				? {
						uid: this.session.uid,
						endDate: this.session.visio.endDate ? this.session.visio.endDate : "",
						eventId: this.session.eventId,
						moduleId: this.session.moduleId,
						members: [],
						options: {
							allowChat: this.session.visio.allowChatOnVisio,
							allowScreenshare: this.session.visio.allowScreenShareOnVisio,
							allowLeaveBtn: this.session.visio.allowLeaveOnVisio,
							hostGroups: this.session.visio.hostGroups,
							hostRoomUrl: this.session.visio.hostRoomUrl,
							isLocked: this.session.visio.locked,
							meetingId: this.session.visio.meetingId,
							roomMode: this.session.visio.roomMode,
							roomPrefix: this.session.visio.roomName,
							viewerMode: this.session.visio.viewerMode,
							viewerUrl: this.session.visio.viewerUrl
						},
						startDate: this.session.visio.startDate,
						type: this.session.visio.type,
						url: this.session.visio.roomUrl
				  }
				: null;
	}

	/**
	 * Get sparkup src
	 * @returns
	 */
	getSparkupSrc() {
		let url: string = "";
		if (
			this.session &&
			this.session.integrations &&
			this.session.integrations.sparkup &&
			this.session.integrations.sparkup.url
		) {
			url += `${this.session.integrations.sparkup.url}?embed=true`;
			if (!this.session.integrations.sparkup.visitors && this.eventUser) {
				url += `&email=${this.eventUser.email}&firstName=${this.eventUser.name}`;
			}

			if (!this.session.integrations.sparkup.menuButtons) {
				url += `&tabs=live`;
			}
			return url;
		} else {
			return "";
		}
	}

	/**
	 * Check if full width or not
	 */
	checkFullWidth() {
		this.subscriptions.push(
			this.SSchedules.checkFullWidth(this.session)
				// this.store
				// 	.select(checkFullWidth(this.session))
				.pipe(skipWhile(() => !this.event))
				.subscribe((fullWidth) => {
					this.fullWidth = this.event && !this.event.blocked ? fullWidth : true;
				})
		);
	}

	/**
	 * Get custom fields
	 */
	getCustomFields() {
		if (this.customFieldsSub && !this.customFieldsSub.closed) {
			this.customFieldsSub.unsubscribe();
		}
		this.customFieldsSub = combineLatest([
			this.store.select(getBaseCustomFields),
			this.store.select(getModulesCustomsFieldsOfModule(this.moduleId)),
			this.store.select(getModulesCustomFields)
		]).subscribe((results) => {
			this.allCustomFieldsModules = results[2];
			this.computedCustomFields =
				results[1].length > 0
					? results[1].map((customField) => {
							const baseCustomFieldCorresponding = results[0].find(
								(custField) => custField.uid === customField.uid
							);
							const data = this.session.customFields.find(
								(customData) => customData.uid === customField.uid
							);
							return {
								baseSettings: baseCustomFieldCorresponding ? baseCustomFieldCorresponding : null,
								moduleSettings: customField,
								fieldDatas: data ? data : null
							};
					  })
					: [];
		});
	}

	/**
	 * getEventUserTags
	 * @param eventUser
	 * @returns
	 */
	getEventUserTags(eventUser: IEventUser): ICustomFieldData[] {
		return eventUser.customFields
			.filter((eventUserCustomField) =>
				this.allCustomFieldsModules.find(
					(customFieldModule) =>
						customFieldModule.uid === eventUserCustomField.uid &&
						customFieldModule.canBeTag &&
						(eventUserCustomField.field.numeric ||
							(eventUserCustomField.field.text && eventUserCustomField.field.text !== "") ||
							(eventUserCustomField.field.multiLanguageText &&
								eventUserCustomField.field.multiLanguageText[this.currentLanguage] !== "") ||
							(eventUserCustomField.field.phoneNumber &&
								eventUserCustomField.field.phoneNumber.nationalNumber !== ""))
				)
			)
			.sort((a, b) =>
				this.getCustomFieldOrder(a.uid, eventUser.moduleId) >
				this.getCustomFieldOrder(b.uid, eventUser.moduleId)
					? 1
					: this.getCustomFieldOrder(a.uid, eventUser.moduleId) <
					  this.getCustomFieldOrder(b.uid, eventUser.moduleId)
					? -1
					: 0
			);
	}

	/**
	 * getCustomFieldOrder
	 */
	getCustomFieldOrder(customId: string, moduleId: string): number {
		return this.allCustomFieldsModules.find((cus) => cus.uid === customId && cus.moduleId === moduleId).order;
	}

	/**
	 * openUserProfil
	 * @param eventUser
	 */
	openUserProfil(user: IEventUser): void {
		const path = `event/${this.event.uid}/profile/${user.moduleId}/${user.uid}`.split("/");
		this.navCtrl.navigateForward(path, {
			queryParams: this.queryParams
		});
	}

	/**
	 * Get all tracks
	 */
	getTracks() {
		this.subscriptions.push(
			this.store.select(getTracks).subscribe((tracks) => {
				this.tracks = tracks;
			})
		);
	}

	/**
	 * Get all locations
	 */
	getLocations() {
		this.subscriptions.push(
			this.store.select(getLocations("asc")).subscribe((locations) => {
				this.locations = locations;
			})
		);
	}

	/**
	 * Load video player if exist
	 */
	loadVideoPlayer() {
		if (this.session && this.session.videoPlayer && this.session.videoPlayer.type && this.session.videoPlayer.url) {
			// If session have a video, first destroy close the possibly existing video player
			// and update the link object with the datas of the new session

			this.store.dispatch(
				UpdateBasicVideoPlayer({
					payload: {
						activated: true,
						link: {
							moduleType: TypeModule.SCHEDULE,
							moduleId: this.moduleId,
							itemId: this.sessionId
						},
						src: this.session.videoPlayer.url,
						start: 0,
						options: {
							state: -1
						},
						type: this.session.videoPlayer.type
					}
				})
			);
		} else {
			this.store.dispatch(
				UpdateBasicVideoPlayer({
					payload: {
						activated: false,
						link: {
							moduleType: null,
							moduleId: null,
							itemId: null
						},
						src: null,
						start: null,
						options: {
							state: null
						},
						type: null
					}
				})
			);
		}
	}

	/**
	 * Get part of date
	 * @param date
	 * @param unit
	 * @returns
	 */
	getPartOfDate(date: string, unit: string) {
		return this.SUtility.getPartOfDate(this.event, this.eventUser, date, unit);
	}

	/**
	 * Get location with id
	 * @param locationId
	 * @returns
	 */
	getLocation(locationId: string) {
		return this.locations.find((location) => location.uid === locationId);
	}

	/**
	 * Go next or previous session of the module
	 * @param type
	 */
	goNextOrPrevSession(type: string) {
		// Tracking session leaving analytics
		this.SAnalytics.scheduleSessionLeaving(this.eventId, this.moduleId, this.eventUser, this.sessionId);

		(type === "prev"
			? this.SSchedules.getPrevSession(this.eventId, this.moduleId, this.sessionId)
			: this.SSchedules.getNextSession(this.eventId, this.moduleId, this.sessionId)
		)
			.pipe(take(1))
			.subscribe((response) => {
				this.sessionId = response.session.uid;
				this.getSession();
				this.SSchedules.sessionIsLastPrev(this.eventId, this.moduleId, this.sessionId)
					.pipe(take(1))
					.subscribe((response) => {
						if (response.status == "error") {
							this.prevBtn = false;
							this.nextBtn = false;
						} else {
							this.prevBtn = response.first ? false : true;
							this.nextBtn = response.last ? false : true;
						}
					});

				// Tracking session access analytics
				this.SAnalytics.scheduleSessionAccess(this.eventId, this.moduleId, this.sessionId);

				// this.zoomService.shutdownZoom();
				this.location.replaceState(
					`/event/${this.eventId}/schedule/${this.moduleId}/session/${this.sessionId}`
				);
			});
	}

	checkDisabledAddPersonal() {
		return !this.module.options.habilitedPersonal ||
			(this.module.options.habilitedLimit && this.session.attendeesLimit === 0) ||
			(this.module.options.habilitedPersonal &&
				this.module.options.habilitedLimit &&
				this.session.attendeesLimit > 0 &&
				!this.checkRegister() &&
				this.session.registeredUsers.length >= this.session.attendeesLimit)
			? true
			: false;
	}

	/**
	 * Check limit of registered attendees
	 * @returns
	 */
	checkLimit() {
		return !this.module.options.habilitedLimit ||
			(this.module.options.habilitedLimit && this.session.attendeesLimit > 0) ||
			(this.module.options.habilitedLimit && this.session.attendeesLimit < 0)
			? true
			: false;
	}

	/**
	 * Returns the text in the register button.
	 */
	async buttonText() {
		this.isButtonDisabled = false;
		const overlap = await this.checkOverlap();

		// register
		if (!this.checkDisabledAddPersonal() && this.checkLimit() && !this.checkRegister() && !overlap) {
			this.buttonIconSource = "assets/icon/Calendar2.svg";
			this.buttonTextShow = this.module.options.registerSessionBtnName[this.currentLanguage]
				? this.module.options.registerSessionBtnName[this.currentLanguage]
				: this.STranslate.instant("schedule_detail.register_to_session");

			// unregister
		} else if (!this.checkDisabledAddPersonal() && this.checkLimit() && this.checkRegister()) {
			this.buttonIconSource = "assets/icon/Calendar2.svg";
			this.buttonTextShow = this.module.options.unregisterSessionBtnName[this.currentLanguage]
				? this.module.options.unregisterSessionBtnName[this.currentLanguage]
				: this.STranslate.instant("schedule_detail.unregister_to_session");

			// cannot register
		} else {
			this.isButtonDisabled = true;
			this.buttonIconSource = "assets/icon/Close.svg";
			this.buttonTextShow = this.STranslate.instant("schedule_detail.cannot_register_to_session");
		}
	}

	/**
	 * Check if attendees is registered for the session
	 * @returns
	 */
	checkRegister() {
		return this.session &&
			this.session.registeredUsers &&
			this.session.registeredUsers.find((uid) => this.eventUser && uid === this.eventUser.uid)
			? true
			: false;
	}

	/**
	 * Check if this EventUser is registered
	 * to a session that overlaps this session
	 * when overlapping is not allowed.
	 * @returns
	 */
	async checkOverlap() {
		let bool = false;
		if (this.module.options.forbidSchedulesOverlap) {
			const userSessions = await firstValueFrom(
				this.SFirestore.getDocumentsObs(`events/${this.eventId}/modules/${this.moduleId}/schedules`, [
					where("eventId", "==", this.eventId),
					where("registeredUsers", "array-contains", this.eventUser.uid)
				]).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as ISchedule)))
			);

			userSessions.forEach((userSession) => {
				// Attention si les deux sessions commence à la même date elles ne sont pas considérées comme overlap.
				if (
					(this.session.endDate >= userSession.startDate && this.session.endDate <= userSession.endDate) ||
					(this.session.startDate >= userSession.startDate && this.session.startDate <= userSession.endDate)
				) {
					bool = true;
				}
			});
		}
		return bool;
	}

	/**
	 * Register or unregister session on personal agenda
	 */
	async addOrRemoveSessionOnPersonalSchedule() {
		if (this.event.blocked) {
			this.SUtility.presentToast(this.STranslate.instant("alerts.blocked-event-info"), 2500, "bottom", "danger");
			return;
		}
		try {
			if (this.eventUser) {
				const session = _.cloneDeep(this.session);
				const check = session.registeredUsers.find((uid) => uid === this.eventUser.uid);
				check
					? (session.registeredUsers = _.cloneDeep(session.registeredUsers).filter(
							(uid) => uid !== this.eventUser.uid
					  ))
					: session.registeredUsers.push(this.eventUser.uid);
				await this.SFirestore.updateDocument(
					`events/${this.eventId}/modules/${this.moduleId}/schedules/${session.uid}`,
					{
						registeredUsers: session.registeredUsers
					}
				);
				// Groups registration
				if (this.module.options.allowSessionGroupRegistration) {
					this.session.groups.forEach((sessionGroup) => {
						if (this.checkRegister()) {
							// register
							if (!this.eventUser.groups.includes(sessionGroup)) {
								this.eventUser.groups.push(sessionGroup);
							}
						} else if (!this.checkRegister()) {
							// unregister
							if (this.eventUser.groups.includes(sessionGroup)) {
								this.eventUser.groups.splice(this.eventUser.groups.indexOf(sessionGroup), 1);
							}
						}
					});
					await this.SEventUsers.updatePartOfEventUser(
						this.eventId,
						this.eventUser.moduleId,
						this.eventUser.uid,
						{
							groups: this.eventUser.groups
						}
					);
				}

				// this.initDatas();
				this.SUtility.presentToast(
					check
						? this.STranslate.instant("schedule_detail.unregistered_to_session")
						: this.STranslate.instant("schedule_detail.registered_to_session"),
					3000,
					"bottom",
					"success"
				);
			}
		} catch (error) {
			this.SUtility.presentToast(
				this.STranslate.instant("schedule_detail.error_reg_unreg_to_session"),
				3000,
				"bottom",
				"danger"
			);
		}
	}

	/**
	 * Returns the color of the background, according to the allowGradient boolean.
	 */
	getBackgroundColor(allowGradient: boolean) {
		return allowGradient
			? `linear-gradient(to right,${this.event.styling.menuColor}, ${this.event.styling.menuColorGradient})`
			: this.event.styling.menuColor;
	}

	/**
	 * getCorrespondingGroups
	 * @param groupId
	 * @returns
	 */
	getCorrespondingGroups(groupIds: string[]) {
		return this.groups.filter((gr) => groupIds.includes(gr.uid));
	}

	/**
	 * removeIframes
	 */
	removeIframes() {
		const frame = window.parent.document.getElementById("iframe");
		if (frame) {
			frame.parentNode.removeChild(frame);
		}
	}

	/**
	 * Open location modal
	 * @param locationId
	 * @returns
	 */
	async openLocationModal(locationId: string) {
		const modal = await this.modalCtrl.create({
			component: PathComponents.locationModal,
			componentProps: {
				eventId: this.eventId,
				locationId: locationId
			},
			cssClass: "full-sized-modal"
		});
		return await modal.present();
	}

	/**
	 * openDownloadModal
	 * @param session
	 */
	async openDownloadModal(session: ISchedule, event) {
		if (this.event.blocked) {
			this.SUtility.presentToast(this.STranslate.instant("alerts.blocked-event-info"), 2500, "bottom", "danger");
			return;
		}
		try {
			event.stopPropagation();
			const modal = await this.modalCtrl.create({
				component: ConfirmIcsDownloadComponent,
				componentProps: {
					event: this.event,
					module: this.module,
					eventUser: this.eventUser,
					session: session,
					sessions: this.sessions,
					language: this.event.language,
					mode: "session"
				},
				cssClass: this.isMobile ? "confirm-ics-download-modal-mobile" : "confirm-ics-download-modal"
			});

			modal.present();
		} catch (error) {
			// snackbar error
		}
	}

	/**
	 * addToFavorite
	 * @param sessionId
	 * @param event
	 */
	async addToFavorite(sessionId: string, event) {
		try {
			event.stopPropagation();
			if (this.eventUser.favorites) {
				!this.eventUser.favorites.includes(sessionId) && this.eventUser.favorites.push(sessionId);
			} else {
				this.eventUser = {
					...this.eventUser,
					favorites: [sessionId]
				};
			}

			this.buildFavoriteState(sessionId);

			await this.SEventUsers.updatePartOfEventUser(this.eventId, this.eventUser.moduleId, this.eventUser.uid, {
				favorites: this.eventUser.favorites
			});
			this.snackBar.open(this.STranslate.instant("snackbar.update_successfull"), null, {
				duration: 3000,
				panelClass: ["snackbar-success"]
			});
		} catch (error) {
			// Snackbar error
			this.snackBar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}

	/**
	 * removeFromFavorite
	 * @param sessionId
	 * @param event
	 */
	async removeFromFavorite(sessionId: string, event) {
		try {
			event.stopPropagation();

			if (this.eventUser.favorites) {
				this.eventUser.favorites.includes(sessionId) &&
					(this.eventUser.favorites = this.eventUser.favorites.filter((fav) => fav !== sessionId));
			} else {
				this.eventUser = {
					...this.eventUser,
					favorites: []
				};
			}
			this.buildFavoriteState(sessionId);
			await this.SEventUsers.updatePartOfEventUser(this.eventId, this.eventUser.moduleId, this.eventUser.uid, {
				favorites: this.eventUser.favorites
			});
			this.snackBar.open(this.STranslate.instant("snackbar.update_successfull"), null, {
				duration: 3000,
				panelClass: ["snackbar-success"]
			});
		} catch (error) {
			// Snackbar error
			this.snackBar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}

	/**
	 * buildFavoriteState
	 * @param sessionId
	 */
	buildFavoriteState(sessionId: string) {
		this.favoriteState = {
			[sessionId]: this.eventUser && this.eventUser.favorites?.includes(sessionId) ? true : false
		};
	}
}
