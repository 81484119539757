/* eslint-disable max-len */
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { DateTime } from "luxon";
import { Subscription, debounceTime, distinctUntilChanged, filter, firstValueFrom, take } from "rxjs";
import { IEvent, IModule, IEventUser } from "src/app/shared/interfaces";
import {
	ICardExchangeField,
	ICardExchangeFieldResult,
	ICardExchangeForm,
	ICardExchangeFieldResultData
} from "src/app/shared/interfaces/card-exchange.interfaces";
import {
	AnalyticsService,
	UtilityService,
	StorageService,
	FirestoreService,
	DocumentsService,
	FileService
} from "src/app/shared/services";
import { CardExchangeService } from "src/app/shared/services/card-exchange.service";
import { environment } from "src/environments/environment";
import { v4 as uuidv4 } from "uuid";

@Component({
    selector: "app-card-exchange-form",
    templateUrl: "./card-exchange-form.component.html",
    styleUrls: ["./card-exchange-form.component.scss"],
    standalone: false
})
export class CardExchangeFormComponent implements OnInit, OnDestroy, OnChanges {
	subscriptions: Subscription[] = [];
	contactDatasSub: Subscription;
	formSub: Subscription;

	componentLoader: boolean = true;
	loaderDoc: boolean = false;
	loadingSend: boolean = false;

	@Input() componentMode: boolean = false;
	@Output() changeView: EventEmitter<{ segmentType: string; type: string; uid: string }> = new EventEmitter();

	showBackBtn: boolean;
	event: IEvent;
	module: IModule;
	myEventUser: IEventUser;
	form: ICardExchangeForm;
	formId: string;
	contactDatas: ICardExchangeFieldResultData[] = [];
	eventUserResultDatas: ICardExchangeFieldResultData[] = [];
	eventUserContactId: string;
	eventUserContact: IEventUser;

	viewOnly: boolean = true;

	isMobile: boolean = false;

	fieldsForm: UntypedFormGroup;

	locale: string = "";
	currentLanguage: string = environment.platform.defaultLanguage;
	extension: string;
	assetFileName: string;
	documentName: string;
	documentType: string;
	device: string;

	constructor(
		private SAnalytics: AnalyticsService,
		private modalCtrl: ModalController,
		private platform: Platform,
		private store: Store,
		private SSCardExchange: CardExchangeService,
		private STranslate: TranslateService,
		private SUtility: UtilityService,
		private SStorage: StorageService,
		private fb: UntypedFormBuilder,
		private SFirestore: FirestoreService,
		private snackbar: MatSnackBar,
		private FileService: FileService,
		public SDocument: DocumentsService
	) {
		this.fieldsForm = this.fb.group({});
	}

	ngOnInit() {
		this.isMobile =
			this.platform.is("mobile") || this.platform.is("mobileweb") || window.innerWidth < 768 ? true : false;

		this.device = this.platform.is("android") ? "android" : this.platform.is("ios") ? "ios" : "desktop";

		this.currentLanguage = this.STranslate.currentLang;
		if (this.myEventUser && this.myEventUser?.groups?.length > 0) {
			this.locale = this.SUtility.getLocale(
				this.myEventUser ? this.myEventUser.updatedSettings.language : this.event.language
			);
			this.getForm();
		} else {
			this.closeModal();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes.formId && !changes.formId.firstChange) {
			if (this.myEventUser && this.myEventUser?.groups?.length > 0 && this.eventUserContact) {
				this.getForm();
			}
		}
	}

	ionViewWillLeave() {
		if (!this.componentMode) {
			this.form = null;
		}
		this.subscriptions.concat([this.contactDatasSub, this.formSub]).forEach((sub) => sub?.unsubscribe());
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.concat([this.contactDatasSub, this.formSub]).forEach((sub) => sub?.unsubscribe());
	}

	/**
	 * Get form
	 */
	getForm() {
		if (this.formSub && !this.formSub.closed) {
			this.formSub.unsubscribe();
		}

		this.formSub = this.SSCardExchange.getSpecificFormByGroupId(
			this.event.uid,
			this.module.uid,
			this.myEventUser.groups
		)
			.pipe(
				filter((form) => form?.visibility),
				take(1)
			)
			.subscribe({
				next: (form) => {
					if (!form) {
						this.closeModal();
						return;
					}

					if (!_.isEqual(this.form, form) || !this.form) {
						this.form = form;
						if (this.form) {
							this.form.fields = this.form.fields.filter((field) => field.visibility);
							this.initFieldsForm();
						}

						if (this.contactDatas && this.contactDatas.length > 0) {
							this.patchResultsForm();
						} else {
							this.getContactDatas();
						}

						if (!this.form || (this.form && this.form.fields.length === 0)) {
							this.closeModal("unavailable");
							return;
						}
					}
				},
				error: (error) => {
					console.error("🚀 ~ CardExchangeFormComponent ~ .subscribe ~ error:", error);
				}
			});
	}

	/**
	 * getContactDatas
	 * @param eventId
	 * @param moduleId
	 * @param formId
	 * @param userId
	 */
	getContactDatas() {
		if (this.contactDatasSub && !this.contactDatasSub.closed) {
			this.contactDatasSub?.unsubscribe();
		}

		this.contactDatasSub = this.SSCardExchange.getSpecificContactFormDatasOfUser(
			this.event.uid,
			this.myEventUser.moduleId,
			this.myEventUser.uid,
			this.eventUserContactId
		).subscribe({
			next: (datas: ICardExchangeFieldResultData[]) => {
				this.contactDatas = datas;

				if (this.contactDatas && this.contactDatas.length > 0) {
					this.patchResultsForm();
				}
			},
			error: (error) => {
				console.error("🚀 ~ CardExchangeFormComponent ~ getContactDatas ~ error:", error);
			}
		});
	}

	/**
	 * initFieldsForm
	 */
	initFieldsForm() {
		this.form.fields.forEach((field) => {
			if (field.type === "oneSelect") {
				this.fieldsForm.addControl(field.uid, new UntypedFormControl(""));
			} else if (field.type === "multipleSelect") {
				this.fieldsForm.addControl(
					field.uid,
					new UntypedFormGroup(
						field.answers
							.map((answer) => ({ [answer.uid]: new UntypedFormControl(false) }))
							.reduce((acc, curr) => ({ ...acc, ...curr }), {})
					)
				);

				this.fieldsForm
					.get(field.uid)
					.valueChanges.pipe(debounceTime(300), distinctUntilChanged())
					.subscribe((value) => {
						const answersIds = Object.entries(value)
							.map(([key, value]) => (value ? key : null))
							.filter((value) => value !== null);

						answersIds.forEach((answerId) => {
							if (!this.fieldsForm.get(field.uid).get(answerId).value) {
								this.fieldsForm.get(field.uid).get(answerId).patchValue(true);
							}
						});
					});
			} else if (field.type === "dissertative") {
				this.fieldsForm.addControl(field.uid, new UntypedFormControl(""));
			} else if (field.type === "date") {
				this.fieldsForm.addControl(field.uid, new UntypedFormControl(""));
			} else if (field.type === "document") {
				this.fieldsForm.addControl(field.uid, new UntypedFormControl(""));
			} else if (field.type === "evaluation") {
				this.fieldsForm.addControl(field.uid, new UntypedFormControl(0));
			} else if (field.type === "plainText") {
				this.fieldsForm.addControl(field.uid, new UntypedFormControl(""));
			}
		});

		this.componentLoader = false;
	}

	/**
	 * Patch form with all form result
	 */
	patchResultsForm() {
		this.form.fields.forEach((field) => {
			const resultOfField = this.getresultOfField(field);

			if (field.type === "oneSelect") {
				this.fieldsForm
					.get(field.uid)
					.patchValue(resultOfField.oneSelect ? resultOfField.oneSelect.answerId : "");
			} else if (field.type === "multipleSelect") {
				field.answers.forEach((answer) => {
					this.fieldsForm
						.get(field.uid)
						.get(answer.uid)
						.patchValue(
							resultOfField && resultOfField.multipleSelect
								? resultOfField.multipleSelect.answersIds.includes(answer.uid)
								: false
						);
				});
			} else if (field.type === "dissertative" || field.type === "plainText") {
				this.fieldsForm.get(field.uid).patchValue(resultOfField ? resultOfField.dissertative : "");
			} else if (field.type === "date") {
				this.fieldsForm.get(field.uid).patchValue(resultOfField ? resultOfField.date : "");
			} else if (field.type === "document") {
				this.assetFileName =
					resultOfField && resultOfField.document
						? this.SDocument.getFomatForSVG(resultOfField.document.docType)
						: "generic-file_ico";
				this.fieldsForm
					.get(field.uid)
					.patchValue(resultOfField && resultOfField.document ? resultOfField.document.url : "");
			} else if (field.type === "evaluation") {
				this.fieldsForm.get(field.uid).patchValue(resultOfField ? resultOfField.evaluation : 0);
			}
		});

		this.componentLoader = false;
	}

	/**
	 * CHeck checkbox for multiple select
	 * @param field
	 */
	checkCheckbox(field: ICardExchangeField, answer: ICardExchangeFieldResult) {
		const answersIds = this.fieldsForm.get(field.uid).value;
		return answersIds.includes(answer.uid) ? true : false;
	}

	/**
	 * Update checkbox
	 * @param field
	 * @param answer
	 * @param event
	 */
	updateCheckboxs(field: ICardExchangeField, answer: ICardExchangeFieldResult, event: any) {
		let answersIds = _.cloneDeep(this.fieldsForm.get(field.uid).get(answer.uid).value);
		if (event.detail.checked) {
			if (!answersIds.includes(answer.uid)) {
				answersIds.push(answer.uid);
			}
		} else {
			if (answersIds.includes(answer.uid)) {
				answersIds = answersIds.filter((uid) => uid !== answer.uid);
			}
		}
		this.fieldsForm.get(field.uid).patchValue(answersIds);
	}

	/**
	 * Check if document is an image
	 * @param field
	 * @returns
	 */
	checkImg(field: ICardExchangeField) {
		const result = this.getresultOfField(field);
		return (
			(result && result.document && result.document.docType.includes("image")) ||
			this.fieldsForm.get(field.uid).value.includes("image")
		);
	}

	/**
	 * Get result of field
	 * @param field
	 * @returns
	 */
	getresultOfField(field: ICardExchangeField) {
		return this.contactDatas.find(
			(result) => result.fieldId === field.uid && result.userId === this.myEventUser.uid
		);
	}

	/**
	 * Getting img from document type
	 * @param field
	 * @returns
	 */
	getImg(field: ICardExchangeField) {
		return this.fieldsForm.get(field.uid).value;
	}

	/**
	 * Get document
	 */
	async getDocument(evt: any, field: ICardExchangeField) {
		this.loaderDoc = true;
		const item: File = evt.target.files[0];

		// check if fiel size is less than 1MB
		if (item.size > this.SDocument.CARD_EXCHANGE_DOC_SIZE_LIMIT) {
			this.loaderDoc = false;
			this.snackbar.open(
				this.STranslate.instant("snackbar.file_size_greater_than_limit", {
					limit: this.SDocument.CARD_EXCHANGE_DOC_SIZE_LIMIT / 1000000
				}),
				"",
				{
					duration: 3000,
					panelClass: "error-snackbar"
				}
			);

			return;
		}
		try {
			const documentBase64 = await this.SStorage.fromFileToBase64(item);
			// get type from base64
			this.documentType = item.type;
			this.documentName = item.name;
			// const type = (documentBase64 as string).split(";base64,")[0].split("data:")[1];

			// get extension from type
			this.extension = this.SDocument.checkType(this.documentType.split("/")[1]);
			this.assetFileName = this.SDocument.getFomatForSVG(this.documentType.split("/")[1]);

			if (this.documentType.includes("image")) {
				this.fieldsForm.get(field.uid).patchValue(documentBase64);
			} else {
				this.fieldsForm.get(field.uid).patchValue(`${this.documentType},${documentBase64}`);
				// this.fieldsForm.get(field.uid).patchValue(documentBase64);
			}
			this.loaderDoc = false;
		} catch (error) {
			this.loaderDoc = false;
		}
	}

	/**
	 * Send form
	 */
	async sendForm() {
		try {
			const results: ICardExchangeFieldResultData[] = [];

			const datas = this.fieldsForm.getRawValue();
			console.log("🚀 ~ CardExchangeFormComponent ~ sendForm ~ datas:", datas);

			for (const key of Object.keys(datas)) {
				const formResult = datas[key];
				const field = this.form.fields.find((field) => {
					return field.uid === key;
				});
				const result = _.cloneDeep(this.contactDatas.find((data) => data.fieldId === key));
				let imageUrl: string = "";
				let imageId: string = "";

				if (
					formResult &&
					typeof formResult === "string" &&
					formResult !== "" &&
					formResult?.includes("base64,")
				) {
					imageId = result?.document ? result?.document?.uid : uuidv4();
					imageUrl = await this.SStorage.uploadBase64(
						formResult?.split(";base64,")?.[1],
						// "image/jpeg",
						this.documentType,
						// eslint-disable-next-line max-len
						`events/${this.event?.uid}/modules/${this.myEventUser.moduleId}/event-users/${this.myEventUser.uid}/card-exchange-datas/${this.eventUserContactId}/${imageId}.jpg`
					);
				}

				if (result) {
					!result.uid ||
						(result.uid === "" &&
							(result.uid = this.SFirestore.createId(
								`events/${this.event?.uid}/modules/${this.myEventUser.moduleId}/event-users/${this.myEventUser.uid}/card-exchange-datas`
							)));

					if (field.type === "oneSelect") {
						result.oneSelect = {
							answerId: formResult
						};
					} else if (field.type === "multipleSelect") {
						result.multipleSelect = {
							answersIds: Object.entries(formResult)
								.map(([key, value]) => (value ? key : null))
								.filter((value) => value !== null)
						};
					} else if (field.type === "dissertative" || field.type === "plainText") {
						result.dissertative = formResult;
					} else if (field.type === "date") {
						result.date = formResult;
					} else if (field.type === "document") {
						// Save image if document type
						imageId = result.document.uid ? result.document.uid : uuidv4();
						this.documentType = result.document?.docType ?? "";
						this.documentName = this.documentName
							? this.documentName
							: result.document?.name
							? result.document?.name
							: "Document";

						if (result.document) {
							result.document.name = this.documentName;
						}

						result.document =
							result.document && result.document.url
								? result.document
								: {
										uid: imageId ?? "",
										docType: this.documentType ?? "",
										name: this.documentName ?? "Document",
										url: imageUrl ?? ""
								  };

						console.log("🚀 ~ CardExchangeFormComponent ~ sendForm ~ result.document:", result.document);
					} else if (field.type === "evaluation") {
						result.evaluation = formResult;
					}

					results.push(result);
				} else {
					const newResult: ICardExchangeFieldResultData = {
						uid: this.SFirestore.createId(
							`events/${this.event?.uid}/modules/${this.myEventUser.moduleId}/event-users/${this.myEventUser.uid}/card-exchange-datas`
						),
						creationDate: DateTime.local().toISO(),
						date: field.type === "date" ? formResult : null,
						dissertative: field.type === "dissertative" || field.type === "plainText" ? formResult : null,
						document:
							field.type === "document"
								? {
										uid: imageId ?? "",
										docType: this.documentType ?? "",
										name: this.documentName ?? "",
										url: imageUrl ?? ""
								  }
								: null,
						evaluation: field.type === "evaluation" ? formResult : null,
						multipleSelect:
							field.type === "multipleSelect"
								? {
										...field,
										answersIds: Object.entries(formResult)
											.map(([key, value]) => (value ? key : null))
											.filter((value) => value !== null)
								  }
								: null,
						oneSelect:
							field.type === "oneSelect"
								? {
										answerId: formResult
								  }
								: null,
						type: field.type ?? null,
						userId: this.myEventUser.uid,
						contactId: this.eventUserContact.uid,
						formId: this.form.uid,
						fieldId: field.uid
					};

					results.push(newResult);
				}
			}

			await this.SSCardExchange.createOrUpdateCardExchangeResultDatas(
				this.event.uid,
				this.myEventUser.moduleId,
				this.myEventUser.uid,
				results
			);

			if (results.length === 0) {
				this.closeModal("emptyForm");
				return;
			}

			// TODO: (update) Analytics
			// this.SAnalytics.sendSurvey(
			// 	this.event?.uid,
			// 	this.module?.uid,
			// 	this.myEventUser,
			// 	this.formId,
			// 	results.map((result) => result.result)
			// );

			this.SUtility.presentToast(
				this.STranslate.instant("snackbar.update_successfull"),
				3000,
				"bottom",
				"success"
			);
			this.closeModal("success");
		} catch (error) {
			console.error("🚀 ~ CardExchangeFormComponent ~ sendForm ~ error:", error);
			this.SUtility.presentToast(this.STranslate.instant("snackback.error_occured"), 3000, "bottom", "danger");
		}
	}

	/**
	 * closeModal
	 */
	async closeModal(type: "error" | "success" | "unavailable" | "emptyForm" | "unknown" = "unknown") {
		try {
			switch (type) {
				case "error":
					await this.modalCtrl.dismiss({
						error: true,
						success: false,
						unavailable: false,
						emptyForm: false
					});
					this.snackbar.open(this.STranslate.instant("snackbar.no_form_attached"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
					break;
				case "success":
					await this.modalCtrl.dismiss({
						error: false,
						success: true,
						unavailable: false,
						emptyForm: false
					});
					break;
				case "unavailable":
					await this.modalCtrl.dismiss({
						error: false,
						success: false,
						unavailable: true,
						emptyForm: false
					});
					this.snackbar.open(this.STranslate.instant("snackbar.form_unavailable"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
					break;

				case "emptyForm":
					await this.modalCtrl.dismiss({
						error: false,
						success: false,
						unavailable: false,
						emptyForm: true
					});
					this.snackbar.open(
						this.STranslate.instant("alerts.contact_has_not_been_add_because_form_empty"),
						"",
						{
							duration: 3000,
							panelClass: "error-snackbar"
						}
					);
					break;

				default:
					await this.modalCtrl.dismiss();
			}
		} catch (error) {
			console.error(error);
		}
	}

	/**
	 * downloadDocument
	 * @param url
	 */
	async downloadDocument(url: string) {
		try {
			await firstValueFrom(this.FileService.downloadFileFromUrl(url, "document"));
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error-download"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}
}
