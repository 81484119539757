import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { IEvent } from "src/app/shared/interfaces";

@Component({
    selector: "app-external-link",
    templateUrl: "./external-link.component.html",
    styleUrls: ["./external-link.component.scss"],
    standalone: false
})
export class ExternalLinkComponent {
	@Input() url: string = "";
	@Input() event: IEvent;

	constructor(public modalCtrl: ModalController) {}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
