<div class="container">
	<!-- <div [ngClass]="device === 'ios' ? 'ios-close-btn' : 'close-btn'" class="p-l-10 p-t-20">
    <ion-button fill=" clear" color="dark" (click)="closeModal()">
      <ion-icon color="danger" src="assets/icon/Close.svg"></ion-icon>
    </ion-button>
  </div> -->

	<div class="content" style="overflow: scroll" [innerHTML]="message | safeHtml"></div>

	<div *ngIf="enableAddToCalendar" class="calendar-bloc">
		<div class="calendar-text">
			<h5>
				{{ "events.add_event_to_calendar" | translate }}
			</h5>
		</div>
		<div class="agenda-logos">
			<div class="google-logo img-logo" (click)="addToCalendar('google')">
				<img src="assets/images/google-calendar.png" alt="Google Calendar Logo" />
			</div>

			<div class="outlook-logo img-logo" (click)="addToCalendar('outlook')">
				<img src="assets/images/outlook.png" alt="Outlook Calendar Logo" />
			</div>

			<div class="ics-logo img-logo" (click)="addToCalendar('ics')">
				<img src="assets/images/ics.png" alt="ICS Calendar Logo" />
			</div>
		</div>
	</div>

	<div class="tw-font-[sans-serif] tw-space-x-4 tw-space-y-4 tw-text-center">
		<button
			type="button"
			(click)="closeModal()"
			class="tw-bg-[--btn-bg-color] !tw-text-[--btn-text-color] tw-py-2.5 tw-min-w-[140px] tw-shadow-sm tw-shadow-[--btn-bg-color] tw-rounded-full tw-text-sm tw-tracking-wider tw-font-medium tw-outline-none tw-border tw-border-[--btn-bg-color] active:tw-shadow-inner"
		>
			{{ "alerts.close" | translate }}
		</button>
	</div>
</div>
