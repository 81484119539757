{
    "alerts": {
        "add_to_my_calendar": "meinem Kalender hinzufügen",
        "all_right": "okay",
        "already_scanned": "QRcode bereits validiert",
        "already_checked_checkin": "Sie sind bereits bei diesem Checkin registriert",
        "answer_saved_successfully": "Ihr Beitrag wurde gespeichert",
        "app_open_doc_not_found_txt": "Sie haben keine Anwendung installiert, die diese Art von Format unterstützt, möchten Sie eine herunterladen?",
        "asking_saved_successfully": "Ihr Beitrag wurde gespeichert",
        "attendee_not_found": "Teilnehmer nicht gefunden. Möchten Sie erneut scannen?",
        "cannot_scan_web": "Sie können den QRcode nicht aus der Webversion der Anwendung scannen",
        "change_status": "Status ändern",
        "change_status_confirm_msg": "Möchten Sie den Status von wirklich ändern?",
        "close": "Nah dran",
        "confirm_exit_event": "Möchten Sie die Veranstaltung wirklich verlassen?",
        "confirmed_presence": "wurde registriert! Möchten Sie erneut scannen?",
        "date_already_selected": "Sie haben bereits ein Datum ausgewählt, das neue Datum wird nicht gespeichert",
        "delete_account_title": "Verwijder je account",
        "delete_contact": "Kontakt löschen",
        "delte_contact_confirm": "Sind Sie sicher, dass Sie diesen Kontakt löschen möchten?",
        "delete_account_message": "Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Alle Ihre Daten und Registrierungen für Veranstaltungen werden dadurch entfernt.",
        "error": "Fehler",
        "error_scanning": "Wiedergabefehler",
        "event_shortcode_blank": "Der Code ist ungültig, bitte versuchen Sie es erneut",
        "exit_event": "Beenden Sie die Anwendung",
        "failure_scanning": "QR-Code kann nicht dekodiert werden",
        "go_to_container_confirm": "Möchten Sie wirklich zur Ereignisliste zurückkehren?",
        "invalid_qrcode": "Sie sind an diesem Punkt nicht registriert",
        "list_events": "Ereignisliste",
        "login-page": "Verbindingspagina",
        "go-to-login-confirm": "Wil je echt terug naar de verbindingspagina?",
        "login_client_title_error": "Verbindungsfehler",
        "login_client_error": "Sie versuchen, sich mit einem Kundenkonto anzumelden. Sie müssen ein Teilnehmerkonto erstellen, um sich anmelden zu können!",
        "no_camera": "Zugriff auf die Kamera nicht möglich",
        "not_change_answer": "Sie können Ihre Antworten nicht bearbeiten",
        "not_found_title": "Datei nicht gefunden",
        "not_open_market_msg": "Der Store kann nicht geöffnet werden. Möchten Sie das Dokument im Browser öffnen?",
        "not_possible_change_status": "Der Check-in von kann nicht durchgeführt werden",
        "not_success": "Die Operation war nicht erfolgreich",
        "please_tryagain": ". Bitte versuchen Sie es später erneut",
        "post_moderated": "Ihr Beitrag wurde zur Validierung gesendet",
        "not_confirm_your_presence": "Einchecken nicht möglich. Bitte versuchen Sie es später erneut.",
        "clear-filter": "Filter löschen",
        "status_changed_successfully": "wurde modifiziert!",
        "success": "Erfolgreiche Operation",
        "thankyour_asking": "Danke schön!",
        "thankyou_answer": "Danke schön!",
        "the_presence": "Der Check-in von",
        "the_status_changed": "Der Status von",
        "type_event_shortcode": "Geben Sie den Ereigniscode ein",
        "user_chat_not_available": "Dieser Benutzer hat sich noch nicht eingeloggt, es ist nicht möglich mit ihm zu gespräch",
        "your_presence_confirmed": "Ihr Check-in ist abgeschlossen",
        "blocked-event-info": "Het evenement is geblokkeerd, de functies en toegang zijn beperkt",
        "contact_has_been_add_to_your_fav": "Kontakt wurde zu Ihren Favoriten hinzugefügt",
        "contact_has_not_been_add_because_form_empty": "Kontakt wurde nicht hinzugefügt, da das Formular leer ist",
        "contact_already_in_your_fav": "Kontakt ist bereits in Ihren Favoriten"
    },
    "appointments": {
        "show-more-timeslots": "Toon meer slots",
        "no-rdv-accepted": "Sie haben demnächst keine geplanten Termine...",
        "no-rdv-pending": "Sie haben derzeit keine ausstehenden Terminbestätigungen...",
        "no-rdv-ended": "Er is geen afspraak voor de MoMent...",
        "no-rdv-cancelled": "Geen afspraak op dit moment geannuleerd...",
        "appointment-with": "Ontmoeting met ",
        "save-on-personal-schedule": "Speichern Sie Ihren Termin in Ihrem persönlichen Kalender",
        "appointment-infos": "Vergaderinformatie",
        "aditionnal-infos": "Verdere informatie",
        "select-day": "Selecteer één dag ...",
        "select-disponibilities": "Wählen Sie Ihre Verfügbarkeit:",
        "appointment": "Afspraak",
        "someone-want-to-make-appointment": "{{user}} een afspraak willen maken..",
        "take-appointment": "Een afspraak maken",
        "confirm-appointment": "Bevestig de afspraak",
        "informations": "Informatie",
        "fill-informations": "Informeer de informatie",
        "notation_title": "Was haltet ihr von diesem Treffen?",
        "commentary_title": "Haben Sie Anmerkungen?",
        "commentary": "Bemerkungen",
        "input_subject": "Betreff angeben...",
        "input_message": "Geben Sie eine Nachricht ein...",
        "appointment_accepted": "Termin angenommen",
        "appointment_pending": "Anfrage ausstehend...",
        "appointment_cancelled": "Termin abgesagt",
        "appointment_cancelled_automatically": "Termin abgesagt",
        "appointment_rejected": "Termin abgelehnt",
        "appointment_rejected_automatically": "Termin abgelehnt",
        "cancel_appointment": "Termin stornieren",
        "join_visio": "Video mitmachen",
        "display_empty_time_slots": "Leere Slots anzeigen",
        "make_all_disabled": "Alle nicht verfügbar machen",
        "make_all_enabled": "Alles zur Verfügung stellen",
        "available_time_slot": "Steckplatz vorhanden",
        "unavailable_time_slot": "Steckplatz nicht verfügbar",
        "request_appointment": "Einen Termin beantragen",
        "choose_time_slot": "Wählen Sie das Terminfenster aus",
        "appointment_request": "Terminanfrage",
        "applicant": "Antragsteller",
        "invited": "Gast",
        "on_date": "Für die",
        "from": "Von",
        "to": "beim",
        "subject": "Objekt",
        "status": "Status",
        "see_details": "Siehe Einzelheiten",
        "accept": "Termin annehmen",
        "reject": "Den Termin ablehnen",
        "cancel": "Termin stornieren",
        "choose_new_time_slot": "Wählen Sie einen neuen Zeitplan",
        "make_appointment": "Einen Termin machen",
        "select-location": "Wählen Sie den Ort Ihres Termins...",
        "alerts": {
            "send-appointment-slot-disabled-recipient-title": "Schakel niche uit",
            "send-appointment-slot-disabled-recipient-message": "De niche is uitgeschakeld door de ontvanger",
            "send-appointment-slots-taken-title": "Niche al ingenomen",
            "send-appointment-slots-taken-message": "De niche waarvoor u om de afspraak hebt gevraagd, is niet langer beschikbaar",
            "send-appointment-limit-accepted-applicant-title": "Limiet bereikt",
            "send-appointment-limit-accepted-applicant-message": "Es ist nicht möglich, den Termin zu erstellen, da Ihr Limit an akzeptierten Besprechungen erreicht ist",
            "send-appointment-limit-accepted-recipient-title": "Limiet bereikt",
            "send-appointment-limit-accepted-recipient-message": "Onmogelijk om de afspraak te maken, is de geaccepteerde vergaderlimiet van de ontvanger bereikt",
            "appointment-accepted-limit": "Onmogelijk om de benoeming te accepteren, wordt de geaccepteerde afspraaklimiet bereikt.",
            "send-appointment-title": "Ingediend afspraakverzoek",
            "send-appointment-message": "Ihre Anfrage wurde gesendet. Sie erhalten eine Benachrichtigung, sobald diese geprüft und genehmigt wurde.",
            "send-appointment-exist-title": "Rendezvous niet beschikbaar",
            "send-appointment-exist-message": "De niche waarvoor u de afspraak hebt gemaakt, is niet langer beschikbaar.",
            "cancel_appointment": {
                "title": "Stornieren Sie diesen Termin",
                "message": "Möchten Sie diesen Termin wirklich stornieren?"
            }
        },
        "buttons": {
            "send-message": "Stuur een bericht",
            "see-details": "Zie de details",
            "accept": "Accepteren",
            "refuse": "Weigeren",
            "cancel": "Annuleren"
        },
        "notification": {
            "reminder-title": "Erinnerung an Ihren Termin",
            "reminder-content": "Ihr Termin mit {{user}} beginnt in {{time}} Minuten",
            "accepted": "Der Termin \"{{subject}}\" wurde angenommen.",
            "pending": "Terminanfrage {{date}}",
            "rejected": "Der Termin \"{{subject}}\" wurde abgelehnt.",
            "cancelled": "Der Termin \"{{subject}}\" wurde abgesagt.",
            "rejected_automatically": "Der Termin \"{{subject}}\" wurde automatisch abgelehnt. Der Benutzer ist derzeit nicht mehr verfügbar.",
            "cancelled_automatically": "Der Termin \"{{subject}}\" wurde automatisch storniert. Der Benutzer ist derzeit nicht mehr verfügbar."
        },
        "app_status": {
            "coming": "Komen",
            "waiting": "In de wacht",
            "ended": "Afgerond",
            "accepted": "Akzeptiert",
            "cancelled": "Abgesagt",
            "cancelled-by": "Geannuleerd door {{ user }}",
            "cancelled_automatically": "Automatisch abgebrochen",
            "rejected": "Abgelehnt",
            "rejected_automatically": "Automatisch dekliniert",
            "pending": "Warten auf Antwort"
        },
        "select_date": "Wählen Sie ein Datum aus",
        "date_time_appointment": "Das Datum ist:",
        "make_appointment_available": "Machen Sie den Termin verfügbar",
        "make_appointment_unavailable": "Machen Sie den Termin nicht verfügbar"
    },
    "app_settings": {
        "old_version_description": "Ihre App hat eine neuere Aktualisierung, laden Sie diese aus den Stores herunter.",
        "old_version_title": "Alte Version"
    },
    "asks-questions": {
        "ask-questions-plc": "Geben Sie hier Ihren Text ein...",
        "error-send-question": "Ihre Frage konnte nicht gesendet werden",
        "error-voting": "Beim Liken/Unliken ist ein Fehler aufgetreten",
        "ask-question-anonymously": "Stellen Sie die Frage anonym",
        "your-question-awaiting-moderator-approbation": "Ihre Frage wird vom Moderator überprüft"
    },
    "auth": {
        "need_login_register_for_participating": "Sie müssen sich einloggen oder registrieren, um teilnehmen zu können."
    },
    "buttons": {
        "access": "Einloggen",
        "authenticate": "Zu authentifizieren",
        "access_to": "Zugreifen",
        "access_by_email": "Zugriff per E-Mail",
        "access_by_shortcode": "Zugang mit Code",
        "add-contact": "Kontakt hinzufügen",
        "scan-a-profil": "Scannen Sie ein Profil",
        "add": "Hinzufügen",
        "answer": "Bestätigen",
        "apply": "Sich bewerben",
        "back": "Zurückkehren",
        "back_to_list_event": "zurück zur Liste",
        "back-to-login": "Terug naar verbinding",
        "btn_verify": "Zugreifen",
        "cancel": "abbrechen",
        "close": "Nah dran",
        "comment": "Kommentar",
        "confirm": "Bestätigen",
        "create": "Erstellen",
        "delete": "Löschen",
        "download": "Herunterladen",
        "delete_account": "Verwijder je account",
        "download_vcf": "Herunterladen",
        "edit-profil": "Profil bearbeiten",
        "enter": "Bestätigen",
        "exit": "Ausloggen",
        "from_photos": "Fotobibliotheek",
        "take_photo": "Een foto maken",
        "go": "Eingeben",
        "generate-email": "E-Mail generieren",
        "generate-random-email-address": "E-Mail-Adresse generieren",
        "log_out": "Ausloggen",
        "next": "Nächste",
        "new": "Neu",
        "no": "Nein",
        "nonees": "Geen",
        "open_gallery": "Meine Bilder",
        "ok": "Okay",
        "post": "Veröffentlichen",
        "prev": "vorig",
        "public_events_list": "Liste der öffentlichen Veranstaltungen",
        "register": "Registrieren",
        "select_picture": "Wählen Sie ein Bild aus",
        "click-to-select-document": "Klicken Sie hier, um ein Dokument auszuwählen",
        "click-on-document-to-replace": "Klicken Sie auf das Dokument, um es zu ersetzen",
        "share": "Teilen",
        "show_more": "Mehr sehen",
        "show_less": "Zeige weniger",
        "social_sharing": "Soziale Netzwerke",
        "take_picture": "Foto machen",
        "update": "Modificator",
        "validate": "Bestätigen",
        "yes": "ja"
    },
    "chat": {
        "chat": "Gespräch",
        "chats": "Gespräche",
        "chat_history": "Gespräche",
        "chat_empty_content_title": "Gespräch Sie zwischen den Teilnehmern",
        "chat_empty_content_text": "Um eine Diskussion zu starten, gehen Sie zum Profil eines Teilnehmers und klicken Sie auf 'Diskussion starten'.",
        "create_chat": "Erstellen einer Diskussionsgruppe",
        "display_group_info": "Informationen anzeigen",
        "group_chat_empty_content_title": "Diskutieren Sie zwischen den Teilnehmern",
        "group_chat_empty_content_text": "Beteiligen Sie sich an einer Diskussionsgruppe. Seien Sie der Erste, der teilnimmt, und ermutigen Sie andere Gäste, sich Ihnen anzuschließen!",
        "groups": "Gruppen",
        "group_info": "Gruppeninformationen",
        "new_chat": "Neues Gespräch",
        "no_messages": "Du hast keine Nachrichten",
        "no_official": "Inoffizielle Gruppen",
        "official": "Offizielle Gruppen",
        "notifications": "Benachrichtigungen",
        "received_message": "Sie haben eine neue Nachricht erhalten",
        "silent_mode": "Stiller Modus",
        "toggle": "Aktivieren/deaktivieren",
        "type_msg": "Geben Sie Ihre Nachricht ein...",
        "you": "Sie:"
    },
    "checkin": {
        "only-one-check-authorized": "Er is slechts één cheque toegestaan",
        "already_checked": "Bereits registriert",
        "away": "Abwesend",
        "default_title": "Einchecken",
        "empty_content_title": "Überprüfen Sie die Check-Ins",
        "empty_content_text": "Überprüfen Sie die Check-Ins",
        "last_checkins": "Neueste Check-ins",
        "qrcode_focus": "Scannen Sie den QR-Code",
        "showed": "Geschenk",
        "total": "Gesamt",
        "user_registered": "Registrierter Nutzer!",
        "user_unregistered": "Abgemeldeter Benutzer!"
    },
    "documents": {
        "default_title": "Unterlagen",
        "empty_content_title": "Konsultieren Sie die Unterlagen der Veranstaltung",
        "empty_content_text": "Veranstaltungsunterlagen ansehen und herunterladen!"
    },
    "edit-profil": {
        "name": "Nachname",
        "email": "Email",
        "biography": "Biografie",
        "website": "Webseite",
        "facebook": "Facebook",
        "linkedin": "LinkedIn",
        "twitter": "Twitter",
        "instagram": "instagram",
        "submit": "Bestätigen",
        "placeholders": {
            "name_text-info": "Dein Name",
            "email_text-info": "Ihre E-Mail-Adresse",
            "password_create_text-info": "Erstellen Sie Ihr Passwort",
            "password_confirm_text-info": "Bestätigen Sie Ihr Passwort",
            "password_text-info": "Ihr Passwort",
            "bio_text-info": "Wie beschreibst du dich selbst?",
            "website_text-info": "Webseite",
            "facebook_text-info": "Facebook Profil",
            "linkedin_text-info": "LinkedIn Profil",
            "twitter_text-info": "Twitter-Profil",
            "instagram_text-info": "Instagram-Profil",
            "company_text-info": "Ihr Unternehmen",
            "title_text-info": "Welche Position bekleiden Sie?",
            "url_text-info": "Geben Sie einen gültigen Link ein",
            "simple_text-info": "Geben Sie einen Textwert ein",
            "numeric_text-info": "Geben Sie einen numerischen Wert ein",
            "html_text-info": "Geben Sie Text oder HTML-Inhalt ein",
            "select_text-info": "Wählen Sie einen Wert",
            "multi-select_text-info": "Wählen Sie einen oder mehrere Werte aus",
            "multiple_text-info": "Geben Sie Text ein und drücken Sie zur Bestätigung \"Enter\".",
            "date_text-info": "Wählen Sie ein Datum aus",
            "phonetel_text-info": "Eine Telefonnummer eingeben",
            "image_text-info": "Lade ein Bild hoch",
            "file_text-info": "Laden Sie eine Datei herunter",
            "example": {
                "email1": "email@example.com",
                "email2": "Beispiel: jeandupont@mail.com"
            }
        },
        "errors": {
            "characters": "Zeichen",
            "blank": {
                "name": "Der Name ist erforderlich!",
                "simple_text": "Leeres Feld! Bitte einen Wert eingeben.",
                "email": "E-Mail ist erforderlich!",
                "select": "Bitte wählen Sie eine Option !",
                "multi_select": "Bitte wählen Sie eine oder mehrere Optionen aus!",
                "required": "Required field !"
            },
            "invalid": {
                "bio": "Die Anzahl der Zeichen muss zwischen 15 und 5000 liegen!",
                "url": "Ungültiger Link/URL!",
                "facebook": "Der Facebook-Link ist ungültig!",
                "linkedin": "Linkedin-Link ist ungültig!",
                "twitter": "Der Twitter-Link ist ungültig!",
                "instagram": "Der Instagram-Link ist ungültig!",
                "name": "Der Name muss mindestens 3 Zeichen enthalten!",
                "email": "Ungültige E-Mail!",
                "date": "Ungültiges Datum!",
                "generic": "Das Format ist ungültig!",
                "multiple_text-too-long": "Die maximale Zeichenanzahl wurde überschritten",
                "multiple_text-content-exist": "Der Inhalt ist bereits in der Liste vorhanden",
                "min_length": "Bitte geben Sie mindestens ein",
                "phone_number": "Telefon ist ungültig",
                "big_number": "Zahl zu groß",
                "file_size": "Datei zu groß, Limit : "
            }
        }
    },
    "events": {
        "switch_timezone_user": "Zur lokalen Zeitzone wechseln",
        "switch_timezone_event": "In die Zeitzone des Ereignisses wechseln",
        "add_event_to_calendar": "Zum Kalender hinzufügen"
    },
    "events_list": {
        "events": "Veranstaltungen",
        "my_events": "Meine Veranstaltungen",
        "publics_events": "Öffentliche Veranstaltungen",
        "no-events": "Sie sind auf keiner Veranstaltung dieses Containers registriert!"
    },
    "event_users": {
        "default_title": "Teilnehmer",
        "empty_content_title": "Konsultieren Sie die Teilnehmerliste",
        "empty_content_text": "Konsultieren Sie das Posaunenoskop mit den Profilen der Teilnehmer. Chatten oder videochatten Sie direkt mit einem Teilnehmer aus seinem Profil!",
        "empty_content_text_card_exchange": "Konsultieren Sie die Liste der Teilnehmer, um ihre Kontaktdaten zu erhalten und sie zu Ihrem Adressbuch hinzuzufügen!",
        "company": "Geschäft",
        "post": "Arbeit",
        "delete_all": "Alles löschen",
        "not_specified": "Nicht spezifiziert"
    },
    "feedbacks": {
        "cannot_send_feedback": "Umfrageantworten können nicht gesendet werden",
        "default_title": "Umfrage",
        "default_title_feedback": "Umfrage",
        "answered": "Schon geantwortet",
        "not_answered": "Nicht beantwortet",
        "answer_plc": "...",
        "force_complete_title_alert": "Warnung",
        "force_complete_message_alert": "Sie müssen das Formular vollständig ausfüllen",
        "feedback_send": "Ihre Umfrageantworten wurden gesendet"
    },
    "feed_news": {
        "default_title": "Nachrichten",
        "description_plc": "Drück dich aus!",
        "comments_plc": "Schreibe einen Kommentar...",
        "likes": "Likes",
        "comment": "Kommentar",
        "comments": "Bemerkungen",
        "thumb-up": "Like",
        "heart": "Love",
        "clap": "Bravo",
        "idea": "Instructive",
        "thinking": "Interesting",
        "time": "Vor {{time}} {{timeLabel}}",
        "empty_content_title": "Veröffentlichen Sie Inhalte im Ereignis-Feed",
        "empty_content_text": "Diskutieren Sie dank Posts, Likes und Kommentaren, die im Newsfeed der Veranstaltung veröffentlicht wurden!"
    },
    "filter": {
        "not_specified": "Nicht spezifiziert",
        "delete_all": "Alles löschen",
        "text-information": "Verwenden Sie Filter, um Ihre Suche zu verfeinern"
    },
    "gallery": {
        "default_title": "Galerie",
        "empty_content_title": "Sehen Sie sich Fotos von der Veranstaltung an",
        "empty_content_text": "Fotos von der Veranstaltung ansehen und herunterladen!"
    },
    "gamification": {
        "treasure_hunt_description": "Finden Sie die QR-Codes, die über das gesamte Event verteilt sind"
    },
    "interactivity": {
        "default_title": "Interaktivität",
        "default_empty_content_title": "Kein Ergebnis !",
        "default_empty_content_text": "Komme später wieder!",
        "externals_interactivity_description": "Interagiert miteinander!",
        "note-taking-description": "Om een ​​notitie te maken, klikt u op de knop + onderaan het scherm",
        "survey_description": "Beantworte die Quizze!",
        "quiz_description": "Beantworten Sie die MC-Fragen!",
        "training_description": "Leite die Ausbildung",
        "ask_question_description": "Hinterfragen Sie die Redner!",
        "feedback_empty_content_title": "Antworten Sie auf Umfragen",
        "feedback_empty_content_text": "Ihre Meinung ist wichtig! Greifen Sie direkt auf der Plattform auf Zufriedenheitsumfragen zu und beantworten Sie diese.",
        "quiz_empty_content_title": "Beantworten Sie die Quizfragen",
        "quiz_empty_content_text": "Testen Sie Ihr Wissen mit einem Quiz!",
        "questions_empty_content_title": "Stellen Sie Ihre Fragen",
        "questions_empty_content_text": "Zögern Sie nicht, all Ihre Fragen an die Referenten zu stellen! Andere Teilnehmer können diese mit Gefällt mir markieren, um sie hervorzuheben.",
        "survey_empty_content_title": "Antworten Sie auf Umfragen",
        "survey_empty_content_text": "Ihre Meinung ist wichtig! Greifen Sie direkt auf der Plattform auf Zufriedenheitsumfragen zu und beantworten Sie diese.",
        "min_points_msg_part_1": "Sie sollten zumindest erreichen",
        "min_points_msg_part_2": " Punkte, um diese Umfrage zu ermöglichen!"
    },
    "labels": {
        "accompanying": "Veranstaltung",
        "address": "Adres",
        "and": "und",
        "code": "Codiert",
        "comment": "Kommentar",
        "content": "Inhoud",
        "choose-date": "Wählen Sie ein Datum",
        "description": "Beschreibung",
        "contact-editing": "Kontakt bearbeiten",
        "contact-creation": "Kontakt erstellen",
        "email": "Email",
        "end": "Vin",
        "empty_notes": "Keine Notizen",
        "filters": "Filter",
        "groups": "Gruppen",
        "group": "Gruppe",
        "hidden": "Versteckt",
        "history": "Geschichte",
        "visible": "Sichtbar",
        "hours": "stunden",
        "identifier": "ICH WÜRDE",
        "information": "Informatie",
        "internet_site": "Webseite",
        "legal_mentions": "Gesetzlich",
        "loading": "Wird geladen...",
        "load-the-editor": "Laden Sie den Editor",
        "locations": "Standorte",
        "members": "Mitglieder",
        "my_notes": "Mes notes",
        "minutes": "minuten",
        "name": "Nachname",
        "no": "Nein",
        "on-maintenance": "Het platform is momenteel onderhoud, kom later terug",
        "plan": "Plan",
        "privacy": "Datenschutz-Bestimmungen",
        "reset": "Resetten",
        "search": "Forschen",
        "seconds": "sekunden",
        "sessions": "Sitzungen",
        "start": "Begin",
        "support": "Support-E-Mail",
        "tags": "Stichworte",
        "terms_of_use": "Nutzungsbedingungen",
        "title": "titre",
        "tracks": "Spuren",
        "yes": "ja"
    },
    "placeholders": {
        "enter-phone-number": "Geben Sie eine gültige Telefonnummer ein",
        "link-or-import-image": "Geben Sie einen Link ein oder importieren Sie ein Bild",
        "import-file": "Importieren Sie eine Datei",
        "address_plc": "99 Av. des Champs-Élysées, 75008 Paris France",
        "phone_number_placeholder": "07 12 34 56 78"
    },
    "languages": {
        "choose-language": "Kies een taal",
        "arabic": "Arabisch",
        "english": "Englisch",
        "french": "Französisch",
        "portuguese": "Portugiesisch",
        "spanish": "Spanisch",
        "german": "Deutsch"
    },
    "login": {
        "accept_term": "Ich akzeptiere das",
        "confirm_new_pass": "Bestätigen Sie Ihr neues Passwort",
        "email": "Email",
        "password": "Passwort",
        "forgot_pass": "Ich habe mein Passwort vergessen",
        "first_access_txt_01": "Erster Zugriff!",
        "first_access_txt_02": "Ein sechsstelliger Code wurde an Ihre E-Mail-Adresse gesendet. Geben Sie ihn unten ein, um Ihre Identität zu bestätigen:",
        "go_to_login": "Zurück zur Verbindungsseite",
        "new_pass": "Neues Passwort",
        "not_registered": "Diese E-Mail ist nicht für die Veranstaltung registriert, bitte registrieren Sie sich.",
        "incorrect_pass": "Ungültiges Passwort!",
        "invalid_pass": "Ungültiges Passwort! Ihr Passwort muss mindestens 6 Zeichen enthalten",
        "pass_error": "Wir konnten Ihr Passwort nicht erstellen, bitte versuchen Sie es später erneut!",
        "recovery_pass_title": "Passwort zurücksetzen",
        "recovery_pass_txt": "Bitte bestätigen Sie Ihre E-Mail, um eine E-Mail zum Zurücksetzen des Passworts zu erhalten:",
        "your_email_plc": "geben sie ihre E-Mail Adresse ein",
        "recovery_btn": "Senden",
        "recovery_success": "Wir haben Ihnen eine E-Mail zum Zurücksetzen Ihres Passworts gesendet:",
        "recovery_text": "Wir haben Ihnen soeben eine E-Mail gesendet, mit der Sie Ihr Passwort zurücksetzen können. Denken Sie daran, Ihre Junk-Mail (Spam) zu überprüfen, da sie dort sein könnte. Alles, was Sie tun müssen, ist auf den Link zu klicken, um einen neuen zu erstellen.",
        "recovery_fail": "Wir konnten Ihr Passwort nicht zurücksetzen. Versuchen Sie es erneut!",
        "access_your_account": "Einloggen",
        "term_check_required": "Sie müssen den Nutzungsbedingungen und der Datenschutzrichtlinie zustimmen",
        "terms_of_use": "Nutzungsbedingungen",
        "privacy_txt": "Datenschutz-Bestimmungen",
        "invalid_email": "Ungültige E-Mail",
        "invalid_name": "Ungültiger Name",
        "blank_email": "Sie müssen eine E-Mail eingeben",
        "email_not_found": "E-Mail ist nicht registriert",
        "btn_no_have_acc": "Ich habe keinen Account",
        "verify_code": "Die E-Mail mit dem Code befindet sich möglicherweise in Ihrem Spam-Ordner.",
        "btn_make_pass": "Erstelle ein Passwort",
        "great_title": "Herzlich willkommen!",
        "make_pass_txt": "Bitte erstellen Sie ein individuelles Passwort (mindestens 6 Zeichen)",
        "make_pass_strong_txt": "Maak een individueel wachtwoord (minimaal 12 hoofdletters, kleine, figuur, symbolen)",
        "error_send_email_code": "Wir konnten Ihnen die E-Mail mit dem 6-stelligen Code nicht zusenden. Bitte versuchen Sie es später noch einmal!",
        "invalid_code": "Der 6-stellige Code ist ungültig. Versuchen Sie es erneut!",
        "password_not_match": "Die Passwörter sind nicht identisch.",
        "email_already_use": "Wir konnten mit Ihrer E-Mail-Adresse kein neues Konto erstellen, da sie bereits registriert ist",
        "resend_code_number": "Wenn Sie den 6-stelligen Code nicht erhalten haben: Überprüfen Sie Ihren Spam",
        "remaining_time": "verbleibende Zeit",
        "many_pass_errors": "Ihr Konto wurde nach zahlreichen Anmeldeversuchen vorübergehend gesperrt. Bitte warten Sie einige Minuten, bevor Sie es erneut versuchen.",
        "input_name_plc": "Martine Rault",
        "input_email_plc": "martine@mail.com",
        "geral_error_register": "Bei der Registrierung ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
        "resend_code": "Sechsstelligen Code erneut senden",
        "resend_email_reset_link": "Klicken Sie hier, wenn Sie die E-Mail zum Zurücksetzen des Passworts nicht erhalten haben",
        "account_already_exist_with_email": "Ein Konto mit dieser E-Mail existiert bereits. Da es sich um eine öffentliche Veranstaltung handelt, loggen Sie sich einfach mit Ihrem Passwort ein, um sich zu registrieren.",
        "account_already_exist_with_email_private_event": "Ein Konto mit dieser E-Mail existiert bereits. Bitte melden Sie sich über das Anmeldeformular an.",
        "account_already_exist_with_email_public_event": "Ein Konto mit dieser E-Mail existiert bereits. Da es sich um eine öffentliche Veranstaltung handelt, geben Sie Ihr Passwort am Ende des Formulars ein, um sich zu registrieren.",
        "event_user_already_exist_in_event": "Sie sind bereits für diese Veranstaltung angemeldet. Melden Sie sich einfach an, indem Sie Ihr Passwort eingeben, um darauf zuzugreifen.",
        "not_have_access_to_event": "Der Benutzer hat keinen Zugriff auf das angeforderte Ereignis",
        "min_6_characters": "mindestens 6 Zeichen",
        "account_disabled_many_request": "Der Zugriff auf dieses Konto wurde aufgrund einer hohen Anzahl fehlgeschlagener Versuche vorübergehend gesperrt. Versuchen Sie es später noch einmal.",
        "attempt_to_login_with_short_code_private_event_msg": "Diese Veranstaltung ist privat, Sie können sich nicht mit einem Zugangscode anmelden. Bitte melden Sie sich direkt mit Ihrer E-Mail-Adresse an",
        "attempt_to_login_with_short_code_private_event_title": "Zugang zur Veranstaltung"
    },
    "note-taking": {
        "success-create": "Opmerking maakt",
        "success-delete": "Verwijderde notitie",
        "success-edit": "Gemodificeerde opmerking",
        "share-title": "Zullen",
        "title-too-long": "De titel mag niet meer dan 70 tekens zijn",
        "content-too-long": "De inhoud mag niet meer dan 700 tekens maken"
    },
    "notifications": {
        "appointment_chat_title_received": "Termin erhalten",
        "appointment_chat_title_updated": "Termin geändert",
        "appointment_chat_title": "Treffpunkt",
        "appointment_chat_title_cancelled": "Termin abgesagt"
    },
    "profile": {
        "start_chat": "Starten Sie einen Chat",
        "start_visio": "Starten Sie ein Video"
    },
    "quizs": {
        "warning-validation-definitive": "Sie können Ihre Antworten nicht mehr ändern, sobald Sie sie bestätigt haben",
        "score-is": "Ihr Ergebnis ist",
        "correct-answered-out-of-1": "Frage über",
        "correct-answered-out-of-2": " Fragen über ",
        "retry": "Wiederholen",
        "retry-later": "Versuchen Sie es später erneut",
        "validate": "Bestätigen",
        "cannot_send_quiz": "Quizantworten können nicht gesendet werden",
        "default_title": "Detail",
        "default_title_quiz": "Quiz",
        "answered": "Schon geantwortet",
        "not_answered": "Nicht beantwortet",
        "answer_plc": "...",
        "force_complete_title_alert": "Warnung",
        "force_complete_message_alert": "Sie müssen das Formular vollständig ausfüllen",
        "result_send": "Ihre Quizantworten wurden gesendet"
    },
    "rankings": {
        "empty_content_title": "Überprüfen Sie die Rangliste",
        "empty_content_text": "Überprüfen Sie die Rangliste",
        "global_ranking": "Allgemeine Einteilung",
        "my_ranking": "Mein Ranking"
    },
    "register": {
        "register": "Registrieren",
        "email_exist": "Dieser E-Mail-Adresse ist bereits ein Benutzer zugeordnet",
        "register_to_event": "Registrieren Sie sich für die Veranstaltung",
        "register_a_user_to_the_event": "Registrieren Sie einen Benutzer für die Veranstaltung",
        "user_already_has_an_account": "Sie haben bereits ein Konto ?",
        "user_doesnt_has_an_account": "Sie sind noch nicht registriert?",
        "next_step": "Weiter",
        "previous_step": "Zurück",
        "registrations_are_closed_please_contact_your_event_manager": "Sie können die Veranstaltungen verlassen, da Sie das Veranstaltung abgebrochen haben",
        "register_an_accompanying_user_title": "Einen Begleiter für die Veranstaltung registrieren",
        "register_an_accompanying_user_question": "Möchten Sie einen Begleiter für die Veranstaltung registrieren?",
        "accompanying_users_limit_reached": "Sie können keine Begleitperson für die Veranstaltung registrieren, da Sie das vom Administrator festgelegte Anmeldelimit erreicht haben",
        "validator-error-msg": {
            "multi-text": {
                "required": "Feld erforderlich und muss mindestens einen validierten Wert enthalten",
                "multi_text-control_value_must_validated": "Der Feldwert muss validiert werden",
                "multi_text-control_value_must_be_unique": "Der Feldwert muss einzigartig sein",
                "multi_text_must_contain_value": "Mindestens ein Wert muss eingegeben und validiert werden (drücken Sie Enter zum Validieren)"
            },
            "date-minimum": "Das Datum muss nach dem {{minDate}} liegen",
            "date-maximum": "Das Datum muss vor dem {{maxDate}} liegen",
            "required": "Feld erforderlich und muss mindestens einen validierten Wert enthalten",
            "checkbox-required": "Feld erforderlich und muss mindestens einen validierten Wert enthalten",
            "file": {
                "file_size": "Datei zu groß, Limit : {{limit}} MB"
            }
        },
        "accompanying_remaining_places": "Sie können noch {{places}} Begleitpersonen für die Veranstaltung registrieren"
    },
    "reports": {
        "alert_title": "Ausschreibung",
        "alert_send": "Gesendete Ausschreibung"
    },
    "schedule": {
        "default_title": "Tagebuch",
        "filter_hour": "Pro Tag",
        "filter_track": "Per Bahn",
        "empty_content_title": "Finden Sie das Programm Ihrer Veranstaltung",
        "empty_content_text": "Konsultieren Sie schnell das digitale Programm Ihrer Veranstaltung!",
        "download_schedule": "Laden Sie die Tagesordnung herunter",
        "start-soon": "Start in",
        "end-soon": "Eindigen"
    },
    "schedule_detail": {
        "cannot_register_to_session": "Der Sitzung kann nicht beigetreten werden",
        "default_title": "Detail",
        "no_date": "Beliebig",
        "number_attendees": "Teilnehmer bereits angemeldet",
        "number_available_vacancies": "Anzahl der verbleibenden Plätze",
        "register_to_session": "Anmeldung zur Sitzung",
        "unregister_to_session": "Melden Sie sich von der Sitzung ab",
        "registered_to_session": "Sie sind für die Sitzung registriert",
        "unregistered_to_session": "Sie sind nicht mehr für die Sitzung registriert",
        "error_reg_unreg_to_session": "Anmeldung/Abmeldung von der Sitzung nicht möglich",
        "unavailable": "Beliebig",
        "interactivity": {
            "details": "Einzelheiten",
            "ask-questions": "Fragen",
            "ask-question": "Frage stellen",
            "externals_interactivity": "Externe Interaktion",
            "survey": "Umfrage",
            "quiz": "Quiz",
            "training": "Coaching",
            "group-discussion": "Diskussionsgruppen",
            "feedback-session": "Sitzungsumfrage"
        },
        "prev-session": "Vorherige Sitzung",
        "next-session": "Nächste Sitzung",
        "session_limit_attendees": "Die Sitzung hat das Teilnehmerlimit erreicht!",
        "registered_users_list": "Angemeldete Teilnehmer"
    },
    "snackbar": {
        "authentification_required": "Sie müssen sich authentifizieren, um auf dieses Ereignis zuzugreifen",
        "cannot_create_account": "Ihr Konto kann nicht erstellt werden",
        "cannot_login": "Kann nicht einloggen",
        "cannot_perform_action": "Aktion kann nicht ausgeführt werden",
        "error_occured": "Ein Fehler ist aufgetreten",
        "error_uploading_photo": "Fehler beim Hochladen des Fotos",
        "error_occured_on_verif_email": "Ein Fehler ist bei der Überprüfung Ihrer E-Mail-Adresse aufgetreten",
        "event_shortcode_blank": "Der Code ist ungültig, bitte versuchen Sie es erneut",
        "form_cannot_be_edited_after_submission": "Das Formular kann nach dem Senden nicht bearbeitet werden",
        "no_form_attached": "Der Formular ist nicht verfügbar",
        "form_unavailable": "Das Formular ist nicht verfügbar",
        "file_size_greater_than_limit": "Die Dateigröße ist größer als die zulässige Größe ({{size}})",
        "no_module_found": "Kein Modul gefunden",
        "no_homepage": "Für dieses Ereignis ist keine Zielseite konfiguriert",
        "no_network": "Geen internet verbinding",
        "no_shortcode": "Keine Shortcodes",
        "no_user": "Kein Benutzerkonto",
        "not_authorized_event": "Sie sind nicht berechtigt, auf dieses Ereignis zuzugreifen",
        "register-form-already-connected-guard": "Sie haben bereits ein Konto, bitte melden Sie sich an",
        "update_successfull": "Update erfolgreich",
        "picture_updated_successfull": "Foto erfolgreich aktualisiert",
        "failed_to_upload_media": "Ihre Dateien konnten nicht hochgeladen werden",
        "no_password": "Vul het veld wachtwoord in",
        "wrong_password": "Slecht wachtwoord",
        "success_delete_account": "Ihr Konto wird gelöscht. Dies wird in ein paar Minuten wirksam sein.",
        "limit_groups_exced": "Sie haben die maximale Anzahl von Gruppen erreicht, die Sie erstellen können",
        "limit_avaible_place_exed": "Sie haben die maximale Anzahl von verfügbaren Plätzen erreicht \"{{field}}\"",
        "confirm_reset_password_error": "Während der Bestätigung ist ein Fehler aufgetreten.",
        "invalid_reset_password_code": "Ungültiger oder abgelaufener Aktionscode. Versuchen Sie, das Passwort erneut zurückzusetzen.",
        "confirm_reset_password_success": "Das Zurücksetzen des Passworts war erfolgreich. Sie können sich jetzt mit Ihrem neuen Passwort anmelden."
    },
    "surveys": {
        "cannot_send_survey": "Umfrageantworten können nicht gesendet werden",
        "default_title": "Umfrage",
        "default_title_survey": "Umfrage",
        "respondido": "Jbereits beantwortet",
        "answered": "Schon geantwortet",
        "not_answered": "Nicht beantwortet",
        "answer_plc": "...",
        "force_complete_title_alert": "Warnung",
        "force_complete_message_alert": "Sie müssen das Formular vollständig ausfüllen",
        "survey_send": "Ihre Umfrageantworten wurden gesendet",
        "min_points_alert_msg": "Sie sollten mindestens <strong> {{ points }} </strong> Punkte erreichen, um diese Umfrage zu aktivieren"
    },
    "texts": {
        "see-filter-results": "Zie de resultaten",
        "current-date": "De datum van vandaag",
        "personalschedule": "Persönliches Tagebuch",
        "speakers": "Sprecher",
        "addschedule": "Anmeldung zur Sitzung",
        "removeschedule": "Melden Sie sich von der Sitzung ab",
        "event": "Fall",
        "events": "Veranstaltungen",
        "survey": "Zufriedenheitsumfrage",
        "quiz": "Quiz",
        "training": "Trainings-Quiz",
        "ics_link_google": "Synchronisieren Sie mit Ihrem Google-Kalender",
        "ics_download": "Synchronisieren Sie mit Ihrem Apple-Kalender, Outlook und anderen Tools",
        "ics_schedule_download": "Durch Klicken auf das Symbol erklären Sie sich damit einverstanden, den gesamten Zeitplan in Ihrem Smartphone-Kalender zu synchronisieren.",
        "subscription": "Anmeldeformular",
        "profile": "Profil",
        "nation": "Land",
        "state": "Staat/Region",
        "city": "Stadt",
        "save": "Aufzeichnen",
        "site": "Webseite",
        "pt_BR": "Portugiesisch",
        "en_US": "Englisch",
        "es_ES": "Spanisch",
        "fr_FR": "Französisch",
        "de_DE": "Deutsch",
        "sunday": "Sonntag",
        "monday": "Montag",
        "tuesday": "Dienstag",
        "wednesday": "Mittwoch",
        "thursday": "Heute",
        "friday": "Freitag",
        "saturday": "Am Samstag",
        "january": "Januar",
        "february": "Februar",
        "march": "Marsch",
        "april": "April",
        "may": "Kann",
        "june": "Juni",
        "july": "Juli",
        "august": "August",
        "september": "September",
        "october": "Oktober",
        "november": "November",
        "december": "Dezember",
        "of": "von",
        "maps": "Karten",
        "btn_send": "Senden",
        "thankyou": "Danke schön!",
        "loading": "App wird geladen...",
        "and": "und",
        "terms_of_use": "Nutzungsbedingungen",
        "privacy": "Datenschutz-Bestimmungen",
        "cookie": "Cookie-Richtlinie",
        "legal_mentions": "Gesetzlich",
        "terms_legal": "Gesetzlich",
        "license": "Invent-App - Alle Rechte vorbehalten © 2021.",
        "search_plc": "Forschen...",
        "description": "Beschreibung",
        "select": "Zur Auswahl",
        "select_image": "Bild auswählen",
        "select_option": "Wähle eine Option",
        "image": "Bild",
        "filter": "Filter",
        "all": "Alle",
        "about": "In Bezug auf",
        "start_chat": "Diskutieren",
        "picture": "Bild",
        "name": "Nachname Vorname",
        "points": "Punkte",
        "menu": "Speisekarte",
        "need_login_to_use": "Sie müssen sich anmelden oder ein Konto erstellen, um auf diese Inhalte zugreifen zu können!",
        "timezone": "Zeitzone",
        "support": "Hilfe",
        "a_z": "Alphabetisch von A-Z",
        "z_a": "Alphabetisch Z-A",
        "locations": "Standorte",
        "social_medias": "Soziale Netzwerke",
        "position": "Pos.",
        "network_offline": "Keine Internetverbindung!",
        "network_online": "Die Internetverbindung wurde wiederhergestellt!",
        "network_offline_detail": "Es besteht keine Internetverbindung. Einige Funktionen sind möglicherweise nicht verfügbar",
        "network_online_detail": "Verbindung wird wiederhergestellt. Alle Funktionen sind wieder verfügbar",
        "code": "Codiert",
        "read_qrcode": "Lesen Sie den QR-Code",
        "join-visio": "Treten Sie dem Video bei",
        "visio-launched": "Videoanruf initiiert",
        "visio-leaved": "Videoanruf links",
        "start_visio": "Anruf",
        "waiting_for_content": "Warten auf Inhalt...",
        "not_belonging_to_any_group": "Du gehörst keiner Gruppe an",
        "no_result_title": "Kein Ergebnis !",
        "no_result_text": "Komme später wieder!",
        "reactions": "Reactions",
        "zoom-visio-reload": "Ihre Zoom-Sitzung ist beendet, die Seite wird in {{timer}}s neu geladen.",
        "unsopported_navigator_msg1": "Sie verwenden eine Browserversion, die nicht mit unserer Plattform kompatibel ist, wechseln Sie zu Google Chrome, um alle Funktionen nutzen zu können. Sie können es herunterladen, indem Sie darauf klicken",
        "here": "Hier",
        "previous-step": "Vorige stap",
        "x_selected_items": "{{count}} ausgewählte Elemente"
    },
    "toasts": {
        "copied-to-clipboard": "Gegeven gekopieerd!",
        "download_image": "Herunterladen abgeschlossen.",
        "errors": {
            "create-basic-error": "Beim Erstellen ist ein Fehler aufgetreten",
            "error-open-document": "Dokument kann nicht geöffnet werden",
            "cannot_send_message": "Nachricht kann nicht gesendet werden",
            "download_error": "Download nicht möglich",
            "share_error": "Kan het bestand niet delen",
            "you_must_enable_permission": "Sie müssen die Berechtigung zum Herunterladen von Dateien aktivieren",
            "cannot-copy-to-clipboard": "Kan gegevens niet kopiëren"
        }
    },
    "tooltips": {
        "click-for-open-document": "Klicken Sie, um das Dokument zu öffnen",
        "click-for-view-profil": "Klicken Sie hier, um das Benutzerprofil anzuzeigen",
        "click-for-access-to-session": "Klicken Sie hier, um auf die Sitzung zuzugreifen",
        "click-to-view-image": "Clique para ver a imagem",
        "click-to-replace-document": "Klicken Sie hier, um das Dokument zu ersetzen",
        "click-to-download-document": "Klicken Sie hier, um das Dokument herunterzuladen"
    },
    "treasure_hunts": {
        "already_scan": "Sie haben diesen QR-Code bereits gescannt",
        "btn_qrcode": "SCANNEN SIE EINEN QR-CODE",
        "error_scan": "Der QR-Code ist ungültig. Möchten Sie erneut scannen?",
        "gaming_default_txt": "Finden Sie die QR-Codes, die über das Event verstreut sind, um Punkte zu sammeln",
        "go_gaming": "Lass uns spielen?",
        "scan_read_error": "Fehler beim Lesen des QR-Codes, bitte versuchen Sie es erneut.",
        "success_scan": "Vielen Dank für das Scannen dieses QR-Codes",
        "remaining_qrcodes": "VERBLEIBENDE QR-CODES:",
        "title": "Gamifizierung",
        "total_qrcodes": "GESAMTANZAHL QRCODES:"
    },
    "visios": {
        "creating-visio": "Visionen erschaffen...",
        "opening-visio": "Zugriff auf Videos...",
        "visio-created": "Visio erstellt",
        "visio-created-error": "Das Video konnte nicht erstellt werden",
        "visio-not-available": "Die Videofunktion ist nicht verfügbar",
        "visio-open-error": "Zugriff auf Video nicht möglich"
    },
    "widgets": {
        "external_link_title": "Externes Pfandrecht"
    },
    "word_cloud": {
        "add_word": "Fügen Sie ein Wort hinzu",
        "error-send-word": "Nachricht kann nicht gesendet werden",
        "success-send-word": "Wort gesendet"
    },
    "countries": {
        "AF": "Afghanistan",
        "AL": "Albanien",
        "DZ": "Algerien",
        "AS": "Amerikanisch-Samoa",
        "AD": "Andorra",
        "AO": "Angola",
        "AI": "Anguilla",
        "AQ": "Antarktis",
        "AG": "Antigua und Barbuda",
        "AR": "Argentinien",
        "AM": "Armenien",
        "AW": "Aruba",
        "AU": "Australien",
        "AT": "Österreich",
        "AZ": "Aserbaidschan",
        "BS": "Bahamas",
        "BH": "Bahrain",
        "BD": "Bangladesch",
        "BB": "Barbados",
        "BY": "Weißrussland",
        "BE": "Belgien",
        "BZ": "Belize",
        "BJ": "Benin",
        "BM": "Bermuda",
        "BT": "Bhutan",
        "BO": "Bolivien",
        "BA": "Bosnien und Herzegowina",
        "BW": "Botsuana",
        "BV": "Bouvetinsel",
        "BR": "Brasilien",
        "IO": "Britisches Territorium im Indischen Ozean",
        "BN": "Brunei Darussalam",
        "BG": "Bulgarien",
        "BF": "Burkina Faso",
        "BI": "Burundi",
        "KH": "Kambodscha",
        "CM": "Kamerun",
        "CA": "Kanada",
        "CV": "Cape Verde",
        "KY": "Kaimaninseln",
        "CF": "Zentralafrikanische Republik",
        "TD": "Tschad",
        "CL": "Chile",
        "CN": "China",
        "CX": "Weihnachtsinsel",
        "CC": "Kokosinseln",
        "CO": "Kolumbien",
        "KM": "Komoren",
        "CG": "Kongo",
        "CD": "Kongo, Demokratische Republik",
        "CK": "Cookinseln",
        "CR": "Costa Rica",
        "HR": "Kroatien",
        "CU": "Kuba",
        "CY": "Zypern",
        "CZ": "Tschechische Republik",
        "DK": "Dänemark",
        "DJ": "Dschibuti",
        "DM": "Dominica",
        "DO": "Dominikanische Republik",
        "EC": "Ecuador",
        "EG": "Ägypten",
        "SV": "El Salvador",
        "GQ": "Äquatorialguinea",
        "ER": "Eritrea",
        "EE": "Estland",
        "ET": "Äthiopien",
        "FK": "Falklandinseln",
        "FO": "Färöer-Inseln",
        "FJ": "Fidschi",
        "FI": "Finnland",
        "FR": "Frankreich",
        "GF": "Französisch-Guayana",
        "PF": "Französisch-Polynesien",
        "TF": "Französische Süd- und Antarktisgebiete",
        "GA": "Gabun",
        "GM": "Gambia",
        "GE": "Georgien",
        "DE": "Deutschland",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GR": "Griechenland",
        "GL": "Grönland",
        "GD": "Grenada",
        "GP": "Guadeloupe",
        "GU": "Guam",
        "GT": "Guatemala",
        "GN": "Guinea",
        "GW": "Guinea-Bissau",
        "GY": "Guyana",
        "HT": "Haiti",
        "HM": "Heard und McDonaldinseln",
        "VA": "Vatikanstadt",
        "HN": "Honduras",
        "HK": "Hongkong",
        "HU": "Ungarn",
        "IS": "Island",
        "IN": "Indien",
        "ID": "Indonesien",
        "IR": "Iran, Islamische Republik",
        "IQ": "Irak",
        "IE": "Irland",
        "IL": "Israel",
        "IT": "Italien",
        "JM": "Jamaika",
        "JP": "Japan",
        "JO": "Jordanien",
        "KZ": "Kasachstan",
        "KE": "Kenia",
        "KI": "Kiribati",
        "KP": "Korea, Demokratische Volksrepublik",
        "KR": "Korea, Republik",
        "KW": "Kuwait",
        "KG": "Kirgisistan",
        "LA": "Laos, Demokratische Volksrepublik",
        "LV": "Lettland",
        "LB": "Libanon",
        "LS": "Lesotho",
        "LR": "Liberia",
        "LY": "Libyen",
        "LI": "Liechtenstein",
        "LT": "Litauen",
        "LU": "Luxemburg",
        "MO": "Macao",
        "MK": "Nordmazedonien",
        "MG": "Madagaskar",
        "MW": "Malawi",
        "MY": "Malaysia",
        "MV": "Malediven",
        "ML": "Mali",
        "MT": "Malta",
        "MH": "Marshallinseln",
        "MQ": "Martinique",
        "MR": "Mauretanien",
        "MU": "Mauritius",
        "YT": "Mayotte",
        "MX": "Mexiko",
        "FM": "Mikronesien, Föderierte Staaten von",
        "MD": "Moldau, Republik",
        "MC": "Monaco",
        "MN": "Mongolei",
        "MS": "Montserrat",
        "MA": "Marokko",
        "MZ": "Mosambik",
        "MM": "Myanmar",
        "NA": "Namibia",
        "NR": "Nauru",
        "NP": "Nepal",
        "NL": "Niederlande",
        "NC": "Neukaledonien",
        "NZ": "Neuseeland",
        "NI": "Nicaragua",
        "NE": "Niger",
        "NG": "Nigeria",
        "NU": "Niue",
        "NF": "Norfolkinsel",
        "MP": "Nördliche Marianen",
        "NO": "Norwegen",
        "OM": "Oman",
        "PK": "Pakistan",
        "PW": "Palau",
        "PS": "Palästinensisches Gebiet, besetztes",
        "PA": "Panama",
        "PG": "Papua-Neuguinea",
        "PY": "Paraguay",
        "PE": "Peru",
        "PH": "Philippinen",
        "PN": "Pitcairn",
        "PL": "Polen",
        "PT": "Portugal",
        "PR": "Puerto Rico",
        "QA": "Katar",
        "RE": "Réunion",
        "RO": "Rumänien",
        "RU": "Russische Föderation",
        "RW": "Ruanda",
        "SH": "St. Helena, Ascension und Tristan da Cunha",
        "KN": "St. Kitts und Nevis",
        "LC": "St. Lucia",
        "PM": "St. Pierre und Miquelon",
        "VC": "St. Vincent und die Grenadinen",
        "WS": "Samoa",
        "SM": "San Marino",
        "ST": "São Tomé und Príncipe",
        "SA": "Saudi-Arabien",
        "SN": "Senegal",
        "RS": "Serbien",
        "SC": "Seychellen",
        "SL": "Sierra Leone",
        "SG": "Singapur",
        "SK": "Slowakei",
        "SI": "Slowenien",
        "SB": "Salomonen",
        "SO": "Somalia",
        "ZA": "Südafrika",
        "GS": "Südgeorgien und die Südlichen Sandwichinseln",
        "ES": "Spanien",
        "LK": "Sri Lanka",
        "SD": "Sudan",
        "SR": "Suriname",
        "SJ": "Svalbard und Jan Mayen",
        "SZ": "Swasiland",
        "SE": "Schweden",
        "CH": "Schweiz",
        "SY": "Syrien, Arabische Republik",
        "TW": "Taiwan, Provinz China",
        "TJ": "Tadschikistan",
        "TZ": "Tansania, Vereinigte Republik",
        "TH": "Thailand",
        "TL": "Timor-Leste",
        "TG": "Togo",
        "TK": "Tokelau",
        "TO": "Tonga",
        "TT": "Trinidad und Tobago",
        "TN": "Tunesien",
        "TR": "Türkei",
        "TM": "Turkmenistan",
        "TC": "Turks- und Caicosinseln",
        "TV": "Tuvalu",
        "UG": "Uganda",
        "UA": "Ukraine",
        "AE": "Vereinigte Arabische Emirate",
        "GB": "Vereinigtes Königreich",
        "US": "Vereinigte Staaten von Amerika",
        "UM": "Amerikanisch-Ozeanien",
        "UY": "Uruguay",
        "UZ": "Usbekistan",
        "VU": "Vanuatu",
        "VE": "Venezuela, Bolivarische Republik",
        "VN": "Vietnam",
        "VG": "Britische Jungferninseln",
        "VI": "Amerikanische Jungferninseln",
        "WF": "Wallis und Futuna",
        "EH": "Westsahara",
        "YE": "Jemen",
        "ZM": "Sambia",
        "ZW": "Simbabwe",
        "GG": "Guernsey",
        "IM": "Isle of Man",
        "JE": "Jersey",
        "ME": "Montenegro",
        "BL": "Saint-Barthélemy",
        "MF": "Saint-Martin (französischer Teil)",
        "SS": "Südsudan",
        "AX": "Ålandinseln",
        "CI": "Elfenbeinküste"
    }
}