import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "splitFirst",
    standalone: false
})
export class splitFirstPipe implements PipeTransform {
	transform(val: string, separators: string[]): string {
		return val.split(separators[0])[0]; // returns the first item of splited string array
	}
}
